import React from "react";
import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../components/Layouts/Card.styles";
import { DataTable } from "@carbon/icons-react";
import Typography from "../../components/Basics/Typography";
import Card from '../../components/Layouts/Card';
import { IFrameDrive } from "../../components/Basics/IFrameDrive.styles";

const DatabaseContainer = ({ driveLink, title }) => {
    return (
        <Stack gap={3}>
            <CardComponent width="100%">
                <Card justifyContent='flex-start' >
                    <DataTable size={18} />
                    <Typography className='cds--type-heading-compact-01'>
                        {title} - TABLA DE REGISTROS
                    </Typography>
                </Card>
                <CardRow width='inherit'>
                    <IFrameDrive
                        title={title}
                        src={driveLink}
                    />
                </CardRow>
            </CardComponent>
        </Stack >
    )
};

export default DatabaseContainer;