import { makeActionCreator } from '../../config/store/utils';
import { getNotificationReport } from '../../services/notifications';
import { batch } from 'react-redux';
import { getTimeDifference } from '../../modules/utils/helpers';
import { getTime, format } from 'date-fns';
const formatDateForBackend = (date) => {
  return format(date, 'yyyy-MM-dd'); // Formats date as YYYY-MM-DD
};

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_ERROR = 'SGET_NOTIFICATION_ERROR';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const onGetNotification = makeActionCreator(GET_NOTIFICATION);
export const onGetNotificationError = makeActionCreator(
  GET_NOTIFICATION_ERROR,
  'payload'
);
export const onGetNotificationSuccess = makeActionCreator(
  GET_NOTIFICATION_SUCCESS,
  'payload'
);
export const onGetNotificationThunk = (date, currTurn) => async (dispatch, getState) => {
  dispatch(onGetNotification());

  try {
    const formattedDate = formatDateForBackend(date[0]);
    const { data: response } = await getNotificationReport({
      date: formattedDate,
      currTurn,
    });

    const velocity = response.speed_transgressions || [];
    const flat = response.punctures || [];
    const adas = response.adas || [];
    const fatigue = response.fatigue || [];
    const yawn = response.yawn || [];

    // Add `allId` to each row, starting from 0
    let counter = 0;
    const addAllId = (rows) =>
      rows.map((row) => ({
        ...row,
        allId: counter++, // Add a unique identifier and increment the counter
      }));

    const all = [
      ...addAllId(velocity),
      ...addAllId(flat),
      ...addAllId(adas),
      ...addAllId(fatigue),
      ...addAllId(yawn),
    ];

    return batch(() => {
      dispatch(
        onGetNotificationSuccess({
          all,
          velocity,
          flat,
          adas,
          fatigue,
          yawn,
        })
      );
    });
  } catch (error) {
    console.error(error);
    return batch(() => {
      dispatch(onGetNotificationError({ error }));
    });
  }
};
