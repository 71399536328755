import { makeActionCreator } from '../../../config/store/utils';
import {
  getDailyTurnReport,
  getDailyTruckInfoTurn,
} from '../../../services/productivityService';
import { red50, green40, yellow30, gray60 } from '@carbon/colors';
import { capitalizeNames } from '../../../modules/utils/helpers';
import { truckList, getValueFromList } from '../../../modules/utils/formatters';

// Get yesterday's date
const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString();
};

// Helper function to get yesterday's date
const getYesterdayDateEndpoint = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return [yesterday];
};

const formatKeyToDayNightAppointment = input => {
  return input.map(obj => ({
    // Override keys 'Horas productivas sin vel 0' to 'Turno dia' and 'Turno noche'
    ...obj,
    key: obj.key.includes('turno noche') ? 'Turno Noche' : 'Turno Día',
  }));
};

const getCurrentShift = () => {
  const now = new Date();
  const hours = now.getHours();

  // Turn A is from 08:00 to 19:59
  if (hours >= 8 && hours < 20) {
    return 'Turno Día';
  }

  // Turn B is from 20:00 to 07:59
  return 'Turno Noche';
};

const getColor = (item, beforeItem) => {
  if (beforeItem) {
    if (item.value > beforeItem.value) {
      return green40; // Higher value
    } else if (item.value < beforeItem.value) {
      return red50; // Lower value
    } else {
      return yellow30; // Same value
    }
  } else {
    return gray60; // Default color if no corresponding item is found
  }
};

const transformKeys = data => {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data.map(item => {
    const transformedValue = Object.keys(item.value || {}).reduce(
      (acc, key) => {
        const transformedKey = key
          .replace(/_/g, ' ')
          .replace(/\b\w/g, char => char.toUpperCase());
        acc[transformedKey] = item.value[key] || 0; // Default to 0 if value is missing or undefined
        return acc;
      },
      {}
    );

    return {
      ...item,
      value: transformedValue,
    };
  });
};

export const GET_REPORT_V2 = 'GET_REPORT_V2';
export const GET_REPORT_ERROR_V2 = 'GET_REPORT_ERROR_V2';
export const GET_REPORT_SUCCESS_V2 = 'GET_REPORT_SUCCESS_V2';
export const onGetReportV2 = makeActionCreator(GET_REPORT_V2);
export const onGetReportErrorV2 = makeActionCreator(
  GET_REPORT_ERROR_V2,
  'payload'
);
export const onGetReportSuccessV2 = makeActionCreator(
  GET_REPORT_SUCCESS_V2,
  'payload'
);
export const onGetReportThunkV2 = (
  date = getYesterdayDateEndpoint(),
  typeValue
) => async dispatch => {
  dispatch(onGetReportV2());
  try {
    const actualTurn = getCurrentShift();
    let endpointParams = {};
    if (date) {
      endpointParams = {
        day: date[0].getDate(),
        month: date[0].getMonth() + 1,
        year: date[0].getFullYear(),
        turn: typeValue,
      };
    }
    const { data } = await getDailyTurnReport(endpointParams);
    const yesterday = data.yesterday.tablas;
    const m3Production = yesterday.produccion_m3
      .filter(entry => entry.value !== 0)
      .map(entry => ({
        ...entry,
        group: actualTurn,
      }));

    const dailyProduction = yesterday.produccion_total_m3.value;
    const cycleCountYesterday = yesterday.heatmap_conteo_ciclos;

    const cycleCount = cycleCountYesterday
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            cycleCountYesterday
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        const colorScale = item.value == 0 ? gray60 : green40;

        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: colorScale,
        };
      });
    const prodHoursyesterday = [
      {
        group: '',
        key: actualTurn,
        value: yesterday.horas_productivas_sin_vel_0,
      },
    ];
    const prodHours = prodHoursyesterday;
    const prodHoursIdleYesterday = [
      { group: '', key: actualTurn, value: yesterday.horas_vel_0 },
    ];
    const prodHoursIdle = prodHoursIdleYesterday;

    const mappingDay = {
      tolva: 'cantidad_camiones_tolva_turno_dia',
      retro: 'cantidad_excavadora_turno_dia',
      aljibe: 'cantidad_aljibe_turno_dia',
      bulldozer: 'cantidad_bulldozer_turno_dia',
      bus: 'cantidad_bus_turno_dia',
      fuel: 'cantidad_camiones_combustible_turno_dia',
      rodillo: 'cantidad_rodillo_turno_dia',
    };

    const mappingNight = {
      tolva: 'cantidad_camiones_tolva_turno_noche',
      retro: 'cantidad_excavadora_turno_noche',
      aljibe: 'cantidad_aljibe_turno_noche',
      bulldozer: 'cantidad_bulldozer_turno_noche',
      bus: 'cantidad_bus_turno_noche',
      fuel: 'cantidad_camiones_combustible_turno_noche',
      rodillo: 'cantidad_rodillo_turno_noche',
    };

    const initializeCount = () => ({
      tolva: 0,
      retro: 0,
      aljibe: 0,
      bulldozer: 0,
      bus: 0,
      fuel: 0,
      pluma: 0,
      rodillo: 0,
      front: 0,
      level: 0,
      dig: 0,
      minibus: 0,
    });

    const mapData = (data, mapping) => {
      const result = initializeCount();
      data.forEach(item => {
        for (let key in mapping) {
          if (mapping[key] === item.group) {
            result[key] = item.value;
          }
        }
      });
      return result;
    };

    // Process day data
    const trucksCountDay = mapData(yesterday.cantidad_camiones, mappingDay);

    // Process night data
    const trucksCountNight = mapData(yesterday.cantidad_camiones, mappingNight);

    const textToKeyMap = {
      'Camión Tolva': 'tolva',
      'Camión Aljibe': 'aljibe',
      'Camión Combustible': 'fuel',
      'Camión Pluma': 'pluma',
      Rodillo: 'rodillo',
      'Cargadora Frontal': 'front',
      Retroexcavadora: 'retro',
      Motoniveladora: 'level',
      Excavadora: 'dig',
      Bulldozer: 'bulldozer',
      Bus: 'bus',
      'Mini Bus': 'minibus',
    };

    const sumValues = countObject => {
      return Object.values(countObject).reduce((sum, value) => sum + value, 0);
    };

    const numberTrucks = sumValues(trucksCountDay);

    const numberTrucksNight = sumValues(trucksCountNight);

    const discharge = yesterday.cantidad_descargas
      .filter(item => item.value !== 0)
      .map(entry => ({
        ...entry,
        group: actualTurn,
      }));
    const wallTime = yesterday.tiempo_en_muros
      .filter(item => item.value !== 0)
      .map(entry => ({
        ...entry,
        group: actualTurn,
      }));
    const avgSpeedYesterday = yesterday.heatmap_velocidad_promedio;
    const avgSpeed = avgSpeedYesterday
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            avgSpeedYesterday
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        const colorScale = item.value == 0 ? gray60 : green40;
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: colorScale,
        };
      });
    const goodStart = yesterday.inicio_efectivo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const ending = yesterday.fin_efectivo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const startCycle = yesterday.inicio_primero_ciclo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const breakFastDay = yesterday.tiempos_colacion.map(obj => {
      return { group: actualTurn, value: obj };
    });

    const breakFast = breakFastDay;
    let _counter = 0;
    const trucks = JSON.parse(yesterday.tabla_camiones).map((item, index) => {
      return { ...item, id: `${_counter++}` };
    });
    const loadCount = yesterday.cantidad_cargas.map(entry => ({
      ...entry,
      group: actualTurn,
    }));
    const loadTime = yesterday.tiempo_cargas.map(entry => ({
      ...entry,
      group: actualTurn,
    }));
    const queueTimes = yesterday.tiempos_cola
      .filter(entry => entry.value !== 0)
      .map(entry => ({
        ...entry,
        group: actualTurn,
      }));
    const heatmapHourYesterday = yesterday.heatmap_m3_hora;
    const heatmapHour = heatmapHourYesterday
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            avgSpeedYesterday
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        const colorScale = item.value == 0 ? gray60 : green40;

        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: colorScale,
        };
      });
    const _cycleTime = yesterday.heatmap_tiempos_ciclo;
    const cycleTime = _cycleTime
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            _cycleTime
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        const colorScale = item.value == 0 ? gray60 : green40;

        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: colorScale,
        };
      });

    const maintenance = [
      { group: '', key: actualTurn, value: yesterday.tiempo_mantencion },
    ];
    const meanCyclePerTurn = [
      { group: '', key: actualTurn, value: yesterday.ciclos_promedio },
    ];
    const distanceTraveled = [
      {
        group: '',
        key: actualTurn,
        value: yesterday.distancia_recorrida_promedio,
      },
    ];

    const currDate = date ? date : getYesterdayDate();

    return dispatch(
      onGetReportSuccessV2({
        m3Production: m3Production,
        dailyProduction: dailyProduction,
        cycleCount: cycleCount,
        prodHours: prodHours,
        prodHoursIdle: prodHoursIdle,
        numberTrucks: numberTrucks,
        numberTrucksNight: numberTrucksNight,
        discharge: discharge,
        wallTime: wallTime,
        avgSpeed: avgSpeed,
        goodStart: goodStart,
        ending: ending,
        startCycle: startCycle,
        breakFast: breakFast,
        trucks: trucks,
        loadCount: loadCount,
        loadTime: loadTime,
        meanCyclePerTurn: meanCyclePerTurn,
        queueTimes: queueTimes,
        heatmapHour: heatmapHour,
        cycleTime: cycleTime,
        distanceTraveled: distanceTraveled,
        maintenance: maintenance,
        currDate: currDate,
        trucksDay: trucksCountDay,
        trucksNight: trucksCountNight,
        truckMapping: textToKeyMap,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetReportErrorV2({
        error: error,
      })
    );
  }
};

export const GET_TRUCK_MODAL_V2 = 'GET_TRUCK_MODAL_V2';
export const GET_TRUCK_MODAL_ERROR_V2 = 'GET_TRUCK_MODAL_ERROR_V2';
export const GET_TRUCK_MODAL_SUCCESS_V2 = 'GET_TRUCK_MODAL_SUCCESS_V2';
export const onGetTruckModalV2 = makeActionCreator(GET_TRUCK_MODAL_V2);
export const onGetTruckModalErrorV2 = makeActionCreator(
  GET_TRUCK_MODAL_ERROR_V2,
  'payload'
);
export const onGetTruckModalSuccessV2 = makeActionCreator(
  GET_TRUCK_MODAL_SUCCESS_V2,
  'payload'
);
export const onGetTruckModalThunkV2 = (
  date,
  truck,
  typeValue
) => async dispatch => {
  dispatch(onGetTruckModalV2());
  try {
    let endpointParams = {};
    if (date) {
      const _date = new Date(date);
      endpointParams = {
        day: _date.getDate(),
        month: _date.getMonth() + 1,
        year: _date.getFullYear(),
        turn: typeValue,
      };
    } else {
      const today = new Date();
      const yesterday = new Date(today);

      date = new Date(yesterday.setDate(yesterday.getDate() - 1));
    }
    if (truck) {
      endpointParams['truck'] = truck;
    }

    const { data } = await getDailyTruckInfoTurn(endpointParams);
    let _id = 0;
    const modalData = JSON.parse(data.data).map((item, index) => {
      return { ...item, id: `${_id++}` };
    });
    return dispatch(
      onGetTruckModalSuccessV2({
        currTruck: getValueFromList(truckList, truck),
        modalData: modalData,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetTruckModalErrorV2({
        error: error,
      })
    );
  }
};
