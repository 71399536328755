import {
  LOG_IN,
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT,
  LOG_OUT_ERROR,
  LOG_OUT_SUCCESS,
  SILENT_LOG_IN,
  SILENT_LOG_IN_ERROR,
  SILENT_LOG_IN_SUCCESS,
  CLOSE_CHANGEPASSWORD_MODAL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
} from './Authentication.actions';

const authenticationState = {
  clientName: '',
  error: false,
  loading: true,
  signedIn: false,
  startDatetime: 0,
  userId: null,
  username: null,
  resetMessage: '',
};

/**
 * The authentication's reducer.
 */
const authenticationReducer = (
  state = authenticationState,
  { payload, type }
) => {
  switch (type) {
    case LOG_IN:
    case LOG_OUT:
    case SILENT_LOG_IN:
    case FORGOT_PASSWORD: {
      return { ...state, loading: true, error: false };
    }
    case LOG_IN_ERROR:
    case SILENT_LOG_IN_ERROR:
    case FORGOT_PASSWORD_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case LOG_IN_SUCCESS:
    case SILENT_LOG_IN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        clientName: payload.clientName,
        signedIn: payload.signedIn,
        startDatetime: payload.startDatetime,
        userId: payload.userId,
        username: payload.username,
      };
    }
    case LOG_OUT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        showLogoutModal: false,
        apis: {},
        baseUrl: '',
        clientName: payload.clientName,
        signedIn: false,
        startDatetime: 0,
        userId: null,
        username: null,
        websocketPath: '',
      };
    }
    case LOG_OUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        showLogoutModal: false,
        apis: {},
        baseUrl: '',
        clientName: '',
        signedIn: false,
        startDatetime: 0,
        userId: null,
        username: null,
        websocketPath: '',
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        resetMessage: payload.message,
      };
    }
    case CLOSE_CHANGEPASSWORD_MODAL: {
      return {
        ...state,
        showChangePasswordModal: !state.showChangePasswordModal,
      };
    }
    default: {
      return state;
    }
  }
};

export default authenticationReducer;
