import React from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from '@carbon/react';

const CarbonTableComponent = ({ firstColumnTitle = '', secondColumnTitle, thirdColumnTitle, rows }) => {
    const headers = [firstColumnTitle, secondColumnTitle, thirdColumnTitle]; // Nombres de las columnas como props

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Table size="lg" useZebraStyles={false} aria-label="Sample Table" style={{ width: '100%', height: '100%' }}>
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableHeader key={index}>{header}</TableHeader>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell> {/* Primera columna */}
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.example}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default CarbonTableComponent;