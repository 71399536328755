import styled, { keyframes } from 'styled-components';
import { ActionableNotification } from '@carbon/react'
import { gray100, gray90, gray80 } from '@carbon/colors';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const AnimatedNotification = styled(ActionableNotification)`
  animation: ${slideIn} 0.5s ease forwards;
`;

export const NotificationPopup = styled.div`
  position: fixed;
  top: 60px; /* Adjust top position as needed */
  z-index: 1000;
`;

export const NotificationItem = styled.div`
  margin-bottom: 10px; /* Adjust spacing between items */
`;