import { API } from 'aws-amplify';
import {
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
  REACT_APP_BASE_KEY,
} from '../config/settings/environments';

/**
 * To create a query.
 */
export const createQuery = async params => {
  const paths = {
    talabre: '/v1/create-athena-query',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/create-athena-query';

  const body = params;

  const response = await API.post(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
    body,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get all query.
 */
export const getAthenaQuery = async type => {
  const paths = {
    talabre: '/v1/get-athena-query-history',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-athena-query-history';

  if (type) {
    path = `${path}?type=${type}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};
