import { GET_NOTIFICATIONS_NUMBER, SET_NOTIFICATIONS } from './Navbar.actions';

const notificationsState = {
  number_of_notifications: 0,
  notifications: [],
};

/**
 * The navbar reducer.
 */
const navbarReducer = (state = notificationsState, { payload, type }) => {
  switch (type) {
    case GET_NOTIFICATIONS_NUMBER: {
      return { ...state, numberOfNotifications: payload.numberOfNotifications };
    }
    case SET_NOTIFICATIONS: {
      return { ...state, notifications: payload.notifications };
    }
    default: {
      return state;
    }
  }
};

export default navbarReducer;
