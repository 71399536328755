import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, TextInput, Form, Stack, ButtonSkeleton, Button } from '@carbon/react'
import routes from '../../config/settings/routes'
import { REACT_APP_BASE_URL } from '../../config/settings/environments'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { onChangePasswordThunk } from './Authentication.actions'
import Typography from '../../components/Basics/Typography'
import { checkErrorRequired } from '../../modules/errors/validations'
import { getFromCookie } from '../../modules/storages/cookies'
import { Root, BorderRoot } from './Login.styles'
import { gray70 } from '@carbon/colors'
import { ArrowRight } from '@carbon/icons-react'

/**
 * The change password container
 */
const ChangePasswordModal = ({ button, open, setOpen }) => {
  const dispatch = useDispatch()
  const { loading, error, showChangePasswordModal } = useSelector(state => state.auth)
  const redirectPath = getFromCookie('redirect_path') || routes.root

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: passwordError, value: passwordValue, onChange: handlePasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: newPasswordError, value: newPasswordValue, onChange: handleNewPasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const handleChangePassword = event => {
    event.preventDefault()
    dispatch(
      onChangePasswordThunk({
        username: usernameValue,
        password: passwordValue,
        newPassword: newPasswordValue,
        onSuccessCallback: () => window.open(`${REACT_APP_BASE_URL}${redirectPath}`, '_top')
      })
    )
  }

  const canNotSubmit = useCallback(() => {
    return passwordError !== '' || usernameError !== ''
  }, [passwordError, usernameError])
  const disabledSubmit = canNotSubmit()

  const getLoadingStatus = () => {
    if (loading) {
      return 'active'
    }
    return 'inactive'
  }

  return (
    <Modal onRequestSubmit={handleChangePassword} danger primaryButtonDisabled={disabledSubmit} loadingStatus={getLoadingStatus()} launcherButtonRef={button} primaryButtonText="Completar" open={open} onRequestClose={() => setOpen(false)}>
      <Root>
        <BorderRoot>
          <Form className='login-form'>
            <Stack gap={3}>
              <Typography>Por favor crear nueva contraseña</Typography>
              <TextInput labelText='Correo Electronico' placeholder='Correo' id='reset-mail' onChange={handleUsernameChange} value={usernameValue} invalid={Boolean(usernameError)} invalidText={usernameError} />
              <TextInput.PasswordInput type='password' placeholder='Password' labelText='Contraseña actual' id='reset-password' onChange={handlePasswordChange} value={passwordValue} invalid={Boolean(passwordError)} invalidText={passwordError} />
              <Typography>
                Nueva contraseña debe contener 8 caracteres mínimo
              </Typography>
              <TextInput.PasswordInput type='password' placeholder='New Password' labelText='Nueva Contraseña' id='new-password' onChange={handleNewPasswordChange} value={newPasswordValue} invalid={Boolean(newPasswordError)} invalidText={newPasswordError} />
              {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
                "Su email o contraseña son incorrectas. Si no recuerdas tu contraseña, contáctanos."
              )}

              {!loading && error && error.reason === 'PASSWORD_DOES_NOT_CONFORM_TO_POLICY' && (
                "Contraseña nueva debe contener minimo 8 caracteres"
              )}

              {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
                "Lo sentimos, estamos presentando problemas con nuestro servidor"
              )}
              <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                ¿Tienes algún problema? Contáctanos en talabre@terrestra.tech
              </Typography>
            </Stack>
          </Form>
        </BorderRoot>
      </Root>
    </Modal>
  )
}

export default ChangePasswordModal