import {
  GET_WEEK_REPORT,
  GET_WEEK_REPORT_SUCCESS,
  GET_WEEK_REPORT_ERROR,
} from './WeeklyReport.actions';

const weeklyReportState = {
  data: [],
  m3Production: [],
  weeklyProduction: 0,
  weeklyProductionBefore: 0,
  cycleCount: 0,
  prodHours: [],
  prodHoursIdle: [],
  numberTrucks: [],
  discharge: [],
  wallTime: [],
  avgSpeed: [],
  goodStart: [],
  ending: [],
  startCycle: [],
  breakFast: [],
  trucks: [],
  loadCount: [],
  loadTime: [],
  meanCyclePerTurn: [],
  weeklyProductionMeanTurn: [],
  queueTimes: [],
  heatmapHour: [],
  cycleTime: [],
  m3Moved: [],
  distanceTraveled: [],
  maintenance: [],
  url: '',
  loading: true,
  error: undefined,
  currDate: '',
  currTruck: '',
};

/**
 * The weekly report reducer.
 */

const weeklyReportReducer = (state = weeklyReportState, { payload, type }) => {
  switch (type) {
    case GET_WEEK_REPORT_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_WEEK_REPORT: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_WEEK_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        m3Production: payload.m3Production,
        weeklyProduction: payload.weeklyProduction,
        weeklyProductionBefore: payload.weeklyProductionBefore,
        cycleCount: payload.cycleCount,
        prodHours: payload.prodHours,
        prodHoursIdle: payload.prodHoursIdle,
        numberTrucks: payload.numberTrucks,
        discharge: payload.discharge,
        wallTime: payload.wallTime,
        avgSpeed: payload.avgSpeed,
        goodStart: payload.goodStart,
        ending: payload.ending,
        startCycle: payload.startCycle,
        breakFast: payload.breakFast,
        trucks: payload.trucks,
        loadCount: payload.loadCount,
        loadTime: payload.loadTime,
        meanCyclePerTurn: payload.meanCyclePerTurn,
        weeklyProductionMeanTurn: payload.weeklyProductionMeanTurn,
        queueTimes: payload.queueTimes,
        heatmapHour: payload.heatmapHour,
        cycleTime: payload.cycleTime,
        m3Moved: payload.m3Moved,
        distanceTraveled: payload.distanceTraveled,
        maintenance: payload.maintenance,
        url: payload.url,
        currDate: payload.currDate,
      };
    }
    default: {
      return state;
    }
  }
};

export default weeklyReportReducer;
