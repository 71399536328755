import React from 'react'
import routes from "../config/settings/routes";
import ProtectBySession from "../components/Security/ProtectBySession";
import BrandLayout from "../components/Layouts/BrandLayout";
import { DataTable, IotPlatform } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem, Stack, Pagination } from '@carbon/react'
import Navbar from "../containers/Navbar/Navbar.container";
import ManagementMenu from "../containers/ManagementMenu/ManagementMenu.container";
import Heading from "../components/Layouts/Heading";
import InformationContainer from '../components/Basics/Information.container';
import { g100 } from "@carbon/themes";
import { CardComponent } from '../components/Layouts/Card.styles';
import Card from '../components/Layouts/Card';
import Typography from '../components/Basics/Typography';
import SimpleTable from '../components/Basics/SimpleTable.container';

/**
 * The Devices page.
 */
const DevicesPage = () => {
    const DevicesBreadcrumbs = () => {
        return (
            <Breadcrumb noTrailingSlash>
                <BreadcrumbItem>
                    <a href="/">UCO</a>
                </BreadcrumbItem>
                <BreadcrumbItem href="#" isCurrentPage>
                    Dispositivos
                </BreadcrumbItem>
            </Breadcrumb>
        )
    }

    const headers = [
        {
            header: 'Patente del Equipo',
            key: 'patent',
        },
        {
            header: 'Estado (ADAS)',
            key: 'state_adas',
            sortDirection: "DESC",
        },
        {
            header: 'Última Conexión (ADAS)',
            key: 'last_connection_adas',
            sortDirection: "DESC",
        },
        {
            header: 'Imei del Dispositivo (SYRUS)',
            key: 'imei_syrus',
        },
        {
            header: 'Ubicación del Dispositivo (SYRUS)',
            key: 'device_location_syrus',
        },
        {
            header: 'Estado de Transmisión (SYRUS)',
            key: 'transmision_status_syrus',
            sortDirection: "DESC",
        },
        {
            header: 'Última transmisión recibida (SYRUS)',
            key: 'last_received_transmission_syrus',
            sortDirection: "DESC",
            isDefaultSortable: true
        }
    ];
    const rows = [];

    return (
        <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
            <BrandLayout
                navbar={<Navbar managementMenu={<ManagementMenu current={routes.devices} />} />}
                header={<Heading title="Dispositivos" icon={<IotPlatform size={50} />} breadcrumbs={<DevicesBreadcrumbs />} />}
            >
                <Stack gap={3}>
                    <InformationContainer
                        description='Esta característica todavía se encuentra en desarrollo y podría no estar funcionando.'
                        background={g100.supportWarning}
                        color={g100.textInverse}
                    />
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <DataTable size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                DISPOSITIVOS - TABLA DE DATOS
                            </Typography>
                        </Card>
                        <InformationContainer description='Los dispositivos inactivos corresponden a la cantidad de dispositivos que no han recibido un reporte hace más de 150 minutos.' />
                        <SimpleTable headers={headers} rows={rows} headerData={headers} />
                        <Pagination
                            backwardText="Página anterior"
                            forwardText="Página siguiente"
                            itemsPerPageText=""
                            onChange={function noRefCheck() { }}
                            page={1}
                            pageSize={10}
                            pageSizes={[
                                10,
                                20,
                                30,
                                40,
                                50
                            ]}
                            size="md"
                            totalItems={100}
                        />
                    </CardComponent>
                </Stack>
            </BrandLayout>
        </ProtectBySession>
    )
}

export default DevicesPage
