import { makeActionCreator } from '../../../config/store/utils';
import { batch } from 'react-redux';
import { getSensors } from '../../../services/security';

export const GET_SENSORS = 'GET_SENSORS';
export const GET_SENSORS_ERROR = 'GET_SENSORS_ERROR';
export const GET_SENSORS_SUCCESS = 'GET_SENSORS_SUCCESS';
export const onGetSensors = makeActionCreator(GET_SENSORS);
export const onGetSensorsError = makeActionCreator(
  GET_SENSORS_ERROR,
  'payload'
);
export const onGetSensorsSuccess = makeActionCreator(
  GET_SENSORS_SUCCESS,
  'payload'
);
export const onGetSensorsThunk = date => async dispatch => {
  dispatch(onGetSensors());
  try {
    let path = 'sensors_count/';
    let endpointParams = {};
    if (date) {
      const test = date.getDate();
      endpointParams = {
        day: date
          .getDate()
          .toString()
          .padStart(2, '0'),
        month: (date.getMonth() + 1).toString().padStart(2, '0'),
        year: date.getFullYear(),
      };
    }
    path += `${endpointParams.year}/${endpointParams.month}/${
      endpointParams.day
    }/sensors_count.csv`;

    const { data } = await getSensors(path);
    let gates = JSON.parse(data);
    gates = gates.map((gate, index) => ({
      ...gate,
      id: `${index + 1}`,
    }));
    return dispatch(
      onGetSensorsSuccess({
        data: gates,
        date: date.toISOString(),
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(onGetSensorsError({ error }));
  }
};
