import React from 'react'
import { LineChart } from '@carbon/charts-react'
import PropTypes from 'prop-types';
import { toolbarOptions } from "./utils";

const LineGraph = props => {
  const { height, title, lineData, xAxisTitle, yAxisTitle } = props

  const data = lineData

  const options = {
    title: `${title}`,
    axes: {
      bottom: {
        title: xAxisTitle,
        mapsTo: 'key',
        scaleType: 'labels'
      },
      left: {
        mapsTo: 'value',
        title: yAxisTitle,
        scaleType: 'linear'
      }
    },
    height: `${height}`,
    width: "95%",
    theme: "g100",
    points: {
      enabled: false
    },
    color: {
      scale: {
        'Dataset 1': '#D12771'
      }
    },
    ...toolbarOptions
  }

  return (
    <LineChart
      data={data}
      options={options}
    />
  )
}

LineGraph.defaultProps = {
  height: "100%",
  title: "Bar Chart Graph",
  yAxisTitle: "",
  xAxisTitle: "",
  lineData: [
    {
      group: 'Dataset 1',
      key: 'Qty',
      value: 34200
    },
    {
      group: 'Dataset 1',
      key: 'More',
      value: 23500
    },
    {
      group: 'Dataset 1',
      key: 'Sold',
      value: 53100
    },
    {
      group: 'Dataset 1',
      key: 'Restocking',
      value: 42300
    },
    {
      group: 'Dataset 1',
      key: 'Misc',
      value: 12300
    }
  ]
}

LineGraph.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
}

export default LineGraph
