import styled from 'styled-components';
import { g90, g100 } from '@carbon/themes';

export const CardRoot = styled.div`
  border: ${props => `1px solid ${props.border || g100.borderTile01}`};
  display: flex;
  gap: ${props => props.gap || '5px'};
  flex: ${props => props.flex};
  flex-direction: ${props => props.flexDirection || 'row'};
  background-color: ${props => props.background || g90.background};
  justify-content: ${props => props.justifyContent};
  margin: ${props => props.margin};
  padding: ${props => props.padding || '10px'};
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: 100%;
  align-items: ${props => props.alignItems};
  opacity: ${props => props.disabled && 0.5};
  color: ${props => props.color};
`;

export const CardComponent = styled.div`
  display: flex;
  gap: ${props => props.gap || '0.5rem'};
  flex-direction: column;
  flex: ${props => props.flex || ''};
  padding: ${props => props.padding};
  width: ${props => props.width};
`;

export const CardRow = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: ${props => props.justifyContent || 'space-between'};
  max-width: 100%;
`;
