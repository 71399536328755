import React from "react";
import { SimpleBarChart } from "@carbon/charts-react";
import PropTypes from 'prop-types';
import { toolbarOptions } from "./utils";

const BarChart = props => {
  const { height, title, data, axesOptions } = props

  const state = {
    data: data,
    options: {
      "title": `${title}`,
      "axes": axesOptions,
      "height": `${height}`,
      "width": "100%",
      "theme": "g100",
      ticks: {
        min: 0
      },
      ...toolbarOptions
    }
  };

  return (
    <SimpleBarChart
      data={state.data}
      options={state.options}
    />
  )
}

BarChart.defaultProps = {
  height: "100%",
  title: "Bar Chart Graph",
  data: [
    {
      "group": "Qty",
      "key": "qty",
      "value": 65000
    },
    {
      "key": "more",
      "group": "More",
      "value": 29123
    },
    {
      "key": "sold",
      "group": "Sold",
      "value": 35213
    },
    {
      "key": "restocking",
      "group": "Restocking",
      "value": 51213
    },
    {
      "key": "misc",
      "group": "Misc",
      "value": 16932
    }
  ],
  axesOptions: {
    "left": {
      "mapsTo": "group",
      "scaleType": "labels"
    },
    "bottom": {
      "mapsTo": "value"
    }
  }
}

BarChart.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  axesOptions: PropTypes.object
}

export default BarChart
