import styled from 'styled-components';
import { g100 } from '@carbon/themes';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const Separator = styled.hr`
  background: ${props => props.color ?? g100.borderSubtle00};
  border: 0;
  box-sizing: content-box;
  height: 1px;
  margin: 0;
  overflow: visible;
  width: 100%;
`

export const VerticalSeparator = styled.div`
  background: ${g100.borderSubtle00};
  border: 0;
  height: 20px;
  margin: 0 16px;
  width: 2px;
`

export const ManualFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`
