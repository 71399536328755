import React from "react";
import { SimpleBarChart } from "@carbon/charts-react";
import PropTypes from 'prop-types';
import { toolbarOptions } from "./utils";

const VerticalBarChart = props => {
  const { height, title, data, axisOptions } = props

  const state = {
    data: data,
    options: {
      "title": `${title}`,
      "axes": axisOptions,
      "height": `${height}`,
      "width": "95%",
      "theme": "g100",
      ...toolbarOptions
    }
  };

  return (
    <SimpleBarChart
      data={state.data}
      options={state.options}
    />
  )
}

VerticalBarChart.defaultProps = {
  height: "15rem",
  title: "Bar Chart Graph",
  data: [
    {
      group: 'Dataset 1',
      key: 'Qty',
      value: 65000
    },
    {
      group: 'Dataset 1',
      key: 'More',
      value: -29123
    },
    {
      group: 'Dataset 1',
      key: 'Sold',
      value: -35213
    },
    {
      group: 'Dataset 1',
      key: 'Restocking',
      value: 51213
    },
    {
      group: 'Dataset 1',
      key: 'Misc',
      value: 16932
    },
    {
      group: 'Dataset 2',
      key: 'Qty',
      value: 32432
    },
    {
      group: 'Dataset 2',
      key: 'More',
      value: -21312
    },
    {
      group: 'Dataset 2',
      key: 'Sold',
      value: -56456
    },
    {
      group: 'Dataset 2',
      key: 'Restocking',
      value: -21312
    },
    {
      group: 'Dataset 2',
      key: 'Misc',
      value: 34234
    },
    {
      group: 'Dataset 3',
      key: 'Qty',
      value: -12312
    },
    {
      group: 'Dataset 3',
      key: 'More',
      value: 23232
    },
    {
      group: 'Dataset 3',
      key: 'Sold',
      value: 34232
    },
    {
      group: 'Dataset 3',
      key: 'Restocking',
      value: -12312
    },
    {
      group: 'Dataset 3',
      key: 'Misc',
      value: -34234
    },
    {
      group: 'Dataset 4',
      key: 'Qty',
      value: -32423
    },
    {
      group: 'Dataset 4',
      key: 'More',
      value: 21313
    },
    {
      group: 'Dataset 4',
      key: 'Sold',
      value: 64353
    },
    {
      group: 'Dataset 4',
      key: 'Restocking',
      value: 24134
    },
    {
      group: 'Dataset 4',
      key: 'Misc',
      value: 24134
    }
  ],
  axisOptions: {
    left: {
      mapsTo: 'value'
    },
    bottom: {
      scaleType: 'labels',
      mapsTo: 'key'
    }
  }
}

VerticalBarChart.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  axisOptions: PropTypes.object
}

export default VerticalBarChart
