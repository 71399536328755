import PropTypes from 'prop-types'
import Card from '../Layouts/Card';
import Typography from './Typography';
import { Information } from '@carbon/icons-react';

function InformationContainer({ description, noBorder, color, background = 'none' }) {
    return (
        <Card justifyContent='flex-start' background={background} color={color} gap="0.5rem" height="40px" border={noBorder && 'unset'}>
            <Information size={18} />
            <Typography className='cds--type-helper-text-02'>
                {description}
            </Typography>
        </Card>
    )
}

InformationContainer.propTypes = {
    description: PropTypes.string.isRequired,
    noBorder: PropTypes.bool
}

export default InformationContainer;