import React from 'react'
import { Redirect } from 'react-router-dom'
import routes from '../config/settings/routes'
import ProtectBySession from '../components/Security/ProtectBySession'

/**
 * The Home' page.
 */
const Home = () => {
  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)} redirectTo={routes.login}>
      <Redirect to={routes.root} />
    </ProtectBySession>
  )
}

export default Home
