import React from 'react'
import TitleText from '../../../components/Layouts/Title'
import Typography from '../../../components/Basics/Typography'
import CardButton from '../../../components/Basics/CardButton.container'
import { Column, Root } from './Records.styles'
import routes from '../../../config/settings/routes'
import { g100 } from '@carbon/themes';
import { Stack } from '@carbon/react';
import { Separator } from '../../Dashboard/Dashboard.styles'

/**
 * The Records's container
 * 
 * In order to disable a CardButton simply add them the `disabled` prop.
 */
const Records = () => {
  return (
    <>
      <TitleText
        title='Fichas'
        subtitle='Módulo Organizador para el Registro de Rendimiento por Conductores, Geocercas, Máquinas y Rutas.'
      />
      <Root>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Fichas</Typography>
            <Separator />
            <CardButton title='Conductores' route={routes.recordsDriver} />
            {/* <CardButton title='Geocercas' route={routes.recordsGeofenches} /> */}
            <CardButton title='Pórtico' route={routes.recordsGates} />
            <CardButton disabled title='Equipos' route={routes.recordsMachines} hidden />
            <CardButton disabled title='Rutas' route={routes.recordsInitial} hidden />
          </Stack>
        </Column>
      </Root>
    </>
  )
}

export default Records
