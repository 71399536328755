import { makeActionCreator } from '../../../config/store/utils';
import { getMonthlyReport } from '../../../services/productivityService';
import { red50, green40, yellow30, gray60 } from '@carbon/colors';

const getColor = (item, beforeItem) => {
  if (beforeItem) {
    if (item.value > beforeItem.value) {
      return green40; // Higher value
    } else if (item.value < beforeItem.value) {
      return red50; // Lower value
    } else {
      return yellow30; // Same value
    }
  } else {
    return gray60; // Default color if no corresponding item is found
  }
};

const processObject = (obj, calcCallback) => {
  if (Object.keys(obj).length === 0) {
    return { value: 0 };
  }

  return calcCallback(obj);
};

export const GET_MONTH_REPORT = 'GET_MONTH_REPORT';
export const GET_MONTH_REPORT_ERROR = 'GET_MONTH_REPORT_ERROR';
export const GET_MONTH_REPORT_SUCCESS = 'GET_MONTH_REPORT_SUCCESS';
export const onGetMonthReport = makeActionCreator(GET_MONTH_REPORT);
export const onGetMonthReportError = makeActionCreator(
  GET_MONTH_REPORT_ERROR,
  'payload'
);
export const onGetMonthReportSuccess = makeActionCreator(
  GET_MONTH_REPORT_SUCCESS,
  'payload'
);
export const onGetMonthReportThunk = date => async dispatch => {
  dispatch(onGetMonthReport());
  try {
    let endpointParams = {};
    if (date) {
      endpointParams = {
        day: date[0].getDate(),
        month: date[0].getMonth() + 1,
        year: date[0].getFullYear(),
      };
    }
    const { data } = await getMonthlyReport(endpointParams);
    const lastMonth = data.month.tablas;
    const lastMonthBefore = data.last_month;
    const url = data.report_url;

    const m3Production = lastMonth.producción_m3.filter(
      entry => entry.value !== 0
    );
    const monthlyProduction = lastMonth.produccion_total_m3.value;
    const monthlyProductionBefore = processObject(lastMonthBefore, () => {
      return lastMonthBefore.tablas.produccion_total_m3;
    });
    const cycleCount = lastMonth.heatmap_conteo_ciclos
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            lastMonth.heatmap_conteo_ciclos
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = { value: 0 };
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });
    const prodHourslastMonthDay = lastMonth.horas_productivas_sin_vel_0[0].value.map(
      (obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }
    );
    const prodHourslastMonthNight = lastMonth.horas_productivas_sin_vel_0[1].value.map(
      (obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }
    );
    const prodHours = [...prodHourslastMonthDay, ...prodHourslastMonthNight];
    const prodHoursIdlelastMonthDay = lastMonth.horas_vel_0[0].value.map(
      (obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }
    );
    const prodHoursIdlelastMonthNight = lastMonth.horas_vel_0[1].value.map(
      (obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }
    );
    const prodHoursIdle = [
      ...prodHoursIdlelastMonthDay,
      ...prodHoursIdlelastMonthNight,
    ];
    const numberTrucksDay = lastMonth.cantidad_camiones_dia.value.map(
      (obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }
    );
    const numberTrucksNight = lastMonth.cantidad_camiones_noche.value.map(
      (obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }
    );
    const numberTrucks = [...numberTrucksDay, ...numberTrucksNight];
    const discharge = lastMonth.cantidad_descargas;
    const wallTime = lastMonth.tiempo_en_muros;
    const avgSpeed = lastMonth.heatmap_velocidad_promedio
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            lastMonth.heatmap_velocidad_promedio
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = { value: 0 };
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });
    const goodStart = lastMonth.inicio_efectivo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const ending = lastMonth.fin_efectivo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const startCycle = lastMonth.inicio_primero_ciclo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const breakFastDay = lastMonth.tiempos_colacion[0].value.map(obj => {
      return { group: lastMonth.tiempos_colacion[0].group, value: obj };
    });
    const breakFastNight = lastMonth.tiempos_colacion[1].value.map(obj => {
      return { group: lastMonth.tiempos_colacion[1].group, value: obj };
    });
    const breakFast = [...breakFastDay, ...breakFastNight];
    const loadCount = lastMonth.cantidad_cargas;
    const loadTime = lastMonth.tiempo_cargas;
    const meanCyclePerTurn = lastMonth.ciclos_por_turno_promedio.flatMap((shift) =>
      shift.value.map((value, index) => ({
        group: shift.key.charAt(0).toUpperCase() + shift.key.slice(1).toLowerCase(),
        key: index + 1,
        value: value,
      }))
    );
    const queueTimes = lastMonth.tiempos_cola.filter(
      entry => entry.value !== 0
    );
    const heatmapHour = lastMonth.heatmap_m3_hora
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            lastMonth.heatmap_m3_hora
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = { value: 0 };
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });

    const cycleTime = lastMonth.heatmap_tiempos_ciclo
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            lastMonth.heatmap_tiempos_ciclo
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = processObject(lastMonthBefore, () => {
          return lastMonthBefore.tablas.heatmap_tiempos_ciclo.find(
            item2 => item2.group === item.group && item2.key === item.key
          );
        });
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });

    const m3Moved = lastMonth.media_produccion_por_turno.flatMap((shift) =>
      shift.value.map((value, index) => ({
        group: shift.key.charAt(0).toUpperCase() + shift.key.slice(1).toLowerCase(),
        key: index + 1,
        value: value,
      }))
    );

    const maintenance = lastMonth.tiempo_mantencion_por_turno.flatMap((shift) =>
      shift.value.map((value, index) => ({
        group: shift.key.charAt(0).toUpperCase() + shift.key.slice(1).toLowerCase(),
        key: index + 1,
        value: value,
      }))
    );
    return dispatch(
      onGetMonthReportSuccess({
        m3Production: m3Production,
        monthlyProduction: monthlyProduction,
        monthlyProductionBefore: monthlyProductionBefore,
        cycleCount: cycleCount,
        prodHours: prodHours,
        prodHoursIdle: prodHoursIdle,
        numberTrucks: numberTrucks,
        discharge: discharge,
        wallTime: wallTime,
        avgSpeed: avgSpeed,
        goodStart: goodStart,
        ending: ending,
        startCycle: startCycle,
        breakFast: breakFast,
        loadCount: loadCount,
        loadTime: loadTime,
        meanCyclePerTurn: meanCyclePerTurn,
        queueTimes: queueTimes,
        heatmapHour: heatmapHour,
        cycleTime: cycleTime,
        m3Moved: m3Moved,
        maintenance: maintenance,
        url: url,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetMonthReportError({
        error: error,
      })
    );
  }
};
