import styled, { keyframes, css } from 'styled-components';
import { gray100, gray90, gray80  } from '@carbon/colors'
import { Close } from '@carbon/icons-react';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 60px;
  right: 1vw;
  width: 300px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0,0,0,0.1);
  opacity: 0;
  animation: ${props => props.animate === 'in' ? css`${slideIn} 300ms ease-in forwards` :
              props.animate === 'out' ? css`${slideOut} 300ms ease-in forwards` : 'none'};
  z-index: 10000;
  visibility: hidden; /* Add this line */
  ${(props) => props.animate && 'visibility: visible;'} /* Make visible when animating */
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  right: 13px;
  top: 12px;
`;

export const Header = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${gray100};
  font-size: 1.25rem;
  font-weight: bold;
  border: 1px solid ${gray80};
`;

export const SubHeader = styled.div`
  padding: 8px 16px;
  background-color: ${gray90}; // Slightly lighter than Header
  font-size: 1rem;
  border: 1px solid ${gray80};
`;

export const ItemsContainer = styled.div`
  overflow-y: auto;
  background-color: ${gray80};
`;
