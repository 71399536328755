import React from 'react'
import { XYPlot, XAxis, YAxis, HeatmapSeries, LabelSeries } from 'react-vis';
import PropTypes from 'prop-types'
import { gray10, gray90 } from '@carbon/colors'
import { AutoSizer } from 'react-virtualized'
import Typography from '../Basics/Typography';

const FlexHeatMap = props => {
  const { title, data, changeColor } = props;
  const getColor = d => {
    const color = d.colorScale;
    if (changeColor && (color === '#fa4d56' || color === '#42be65')) {
      return color === '#fa4d56' ? '#42be65' : '#fa4d56';
    }
    return color;
  };
  const CustomAxisLabel = (props/*: {
    title: string,
    xAxis: boolean,
    // note these next two are passed down from the parent XYPlot/Flexible*XYPlot
    innerWidth: number,
    innerHeight: number
}*/) => {
    // since we rotate the y label, we have to adjust it to center
    // (ideally we'd rotate about the correct origin, but i couldn't get that working)
    const yLabelOffset = {
      y: props.innerHeight / 2 + props.title.length * 18, // '3' might be different for you depending on your font size/char width
      x: 25
    };

    const xLabelOffset = {
      x: props.innerWidth / 2,
      y: 1.3 * props.innerHeight // 1.2 was enough for me to get it below x axis. you may need a diff't #
    };
    const transform = props.xAxis
      ? `translate(${xLabelOffset.x}, ${xLabelOffset.y})`
      : `translate(${yLabelOffset.x}, ${yLabelOffset.y}) rotate(-90)`;

    return (
      <g
        transform={transform}
      >
        <text style={{ fill: gray10, font: 'IBM Plex Sans', fontWeight: 'bold', margin: 200 }}>{props.title}</text>
      </g>
    );
  };

  CustomAxisLabel.displayName = 'CustomAxisLabel';
  CustomAxisLabel.requiresSVG = true;

  return (
    <div style={{ height: '40vh', width: '100%' }}>
      <Typography margin='16px 0 0 16px' className='cds--type-heading-02'>
        {title}
      </Typography>
      <AutoSizer>
        {({ width, height }) => (
          <XYPlot
            xType="ordinal"
            yType="ordinal"
            margin={{ left: 120, bottom: 150 }}
            width={width}
            height={height}
            title='test'
          >
            <HeatmapSeries
              colorType='literal'
              getColor={getColor}
              data={data}
              style={{
                stroke: gray90,
                strokeWidth: '1px',
                rectStyle: {
                  rx: 1,
                  ry: 1
                }
              }}
            />

            <CustomAxisLabel title={'B57'} />
            <CustomAxisLabel title={'Muros'} xAxis />
            <XAxis position='middle' orientation="bottom" style={{ text: { fill: gray10, font: 'IBM Plex Sans' } }} />
            <YAxis position='middle' tickLabelAngle={-50} style={{ text: { fill: gray10, font: 'IBM Plex Sans' } }} />
            <LabelSeries
              style={{ pointerEvents: 'none' }}
              data={data}
              labelAnchorX="middle"
              labelAnchorY="middle"
              getLabel={d => `${d.color}`}
            />
          </XYPlot>
        )}
      </AutoSizer>
    </div>
  )
}

FlexHeatMap.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array
}

// Establecer el valor predeterminado de changeColor en false
FlexHeatMap.defaultProps = {
  changeColor: false
};

export default FlexHeatMap