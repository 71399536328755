import { makeActionCreator } from '../../../config/store/utils';
import { getRealTimeReports } from '../../../services/monitoringService';
import { formatDate, shortenFront } from '../../../modules/utils/formatters';

export const GET_REAL_TIME_TO = 'GET_REAL_TIME_TO';
export const GET_REAL_TIME_TO_ERROR = 'GET_REAL_TIME_TO_ERROR';
export const GET_REAL_TIME_TO_SUCCESS = 'GET_REAL_TIME_TO_SUCCESS';
export const onGetRealTimeReports = makeActionCreator(GET_REAL_TIME_TO);
export const onGetRealTimeReportsError = makeActionCreator(
  GET_REAL_TIME_TO_ERROR,
  'payload'
);
export const onGetRealTimeReportsSuccess = makeActionCreator(
  GET_REAL_TIME_TO_SUCCESS,
  'payload'
);
export const onGetRealTimeReportsThunk = () => async dispatch => {
  dispatch(onGetRealTimeReports());
  try {
    const type = 't_traslados_hacia_b57';
    const { data } = await getRealTimeReports(type);
    const currTurn = data.current_turn.tablas;

    const chargeTime = currTurn.t_traslados
      .filter(obj => obj.value.length > 0)
      .map(item => {
        const shortenedKey = shortenFront(item.key);
        return {
          group: item.key,
          value: item.value.flatMap((val, innerIndex) => ({
            hourly: `${innerIndex + 1}`,
            value: val,
            group: shortenedKey,
          })),
        };
      });
    const boxPlot = currTurn.t_traslados
      .filter(obj => obj.value.length > 0)
      .flatMap(item => {
        if (item.value.length === 0) {
          return [{ group: item.key, value: [] }];
        } else {
          return item.value.map(val => ({
            group: item.key,
            value: val,
          }));
        }
      });
    const chargeMean = currTurn.t_traslados_mean.map(item => ({
      ...item,
      value: Number(item.value).toFixed(2),
    }));
    const table = JSON.parse(currTurn.tabla_por_ruta).map((item, index) => {
      const _date = new Date(item.event_time_max);
      return {
        ...item,
        id: `${index++}`,
        origin: `${item.lugar_carga} - ${item.lugar_descarga}`,
      };
    });

    return dispatch(
      onGetRealTimeReportsSuccess({
        chargeTime: chargeTime,
        boxPlot: boxPlot,
        table: table,
        chargeMean: chargeMean,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetRealTimeReportsError({
        error: error,
      })
    );
  }
};
