import { makeActionCreator } from '../../../config/store/utils';
import { batch } from 'react-redux';
import { getGates } from '../../../services/recordService';
import { formatDate } from '../../../modules/utils/formatters';
import { format } from 'date-fns';

export const GET_GATES = 'GET_GATES';
export const GET_GATES_ERROR = 'GET_GATES_ERROR';
export const GET_GATES_SUCCESS = 'GET_GATES_SUCCESS';
export const onGetGates = makeActionCreator(GET_GATES);
export const onGetGatesError = makeActionCreator(GET_GATES_ERROR, 'payload');
export const onGetGatesSuccess = makeActionCreator(
  GET_GATES_SUCCESS,
  'payload'
);

export const onGetGatesThunk = date => async dispatch => {
  dispatch(onGetGates());
  try {
    const pattern = 'yyyy-MM-dd';
    const isoDate = formatDate(date, pattern);
    const { data } = await getGates(isoDate);
    let gates = JSON.parse(data);

    gates = gates.map((gate, index) => ({
      ...gate,
      id: `${index + 1}`,
    }));

    return dispatch(
      onGetGatesSuccess({
        data: gates,
        isoDate: isoDate,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(onGetGatesError({ error }));
  }
};
