import { makeActionCreator } from '../../../config/store/utils';
import { createQuery, getAthenaQuery } from '../../../services/databaseService';
import { batch } from 'react-redux';
import { format, parseISO } from 'date-fns';

export const SEND_QUERY = 'SEND_QUERY';
export const SEND_QUERY_ERROR = 'SEND_QUERY_ERROR';
export const SEND_QUERY_SUCCESS = 'SEND_QUERY_SUCCESS';
export const onSendQuery = makeActionCreator(SEND_QUERY);
export const onSendQueryError = makeActionCreator(SEND_QUERY_ERROR, 'payload');
export const onSendQuerySuccess = makeActionCreator(
  SEND_QUERY_SUCCESS,
  'payload'
);
export const onSendQueryThunk = params => async (dispatch, getState) => {
  dispatch(onSendQuery());
  try {
    const { data: response } = await createQuery({
      params,
    });
    return batch(() => {
      dispatch(
        onSendQuerySuccess({
          downloadUrl: response.download_url,
        })
      );
    });
  } catch (error) {
    console.log(error);
    return batch(() => {
      dispatch(onSendQueryError({ error }));
    });
  }
};

export const GET_QUERY = 'GET_QUERY';
export const GET_QUERY_ERROR = 'GET_QUERY_ERROR';
export const GET_QUERY_SUCCESS = 'GET_QUERY_SUCCESS';
export const onGetQuery = makeActionCreator(GET_QUERY);
export const onGetQueryError = makeActionCreator(GET_QUERY_ERROR, 'payload');
export const onGetQuerySuccess = makeActionCreator(
  GET_QUERY_SUCCESS,
  'payload'
);
export const onGetQueryThunk = type => async dispatch => {
  dispatch(onGetQuery());
  try {
    const { data } = await getAthenaQuery(type);

    const table = data.map((item, index) => {
      const dateCreated = parseISO(item.Created);
      let _Created;
      if (!isNaN(dateCreated.getTime())) {
        // The parsed date is a valid date
        _Created = format(dateCreated, 'dd/MM/yyyy HH:mm:ss');
        console.log(_Created);
      } else {
        // The parsed date is not valid, return the input string
        _Created = item.Created;
      }

      return {
        ...item,
        Created: _Created,
        id: `${index}`,
      };
    });

    return dispatch(
      onGetQuerySuccess({
        data: table,
      })
    );
  } catch (error) {
    return batch(() => {
      console.log(error);
      dispatch(onGetQueryError({ error }));
    });
  }
};
