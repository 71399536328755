import {
  GET_GEOFENCE,
  GET_GEOFENCE_ERROR,
  GET_GEOFENCE_SUCCESS,
} from './Geofence.actions';

const geofenceState = {
  data: [],
  loading: true,
  error: undefined,
};

/**
 * The Geofence reducer.
 */
const geofenceReducer = (state = geofenceState, { payload, type }) => {
  switch (type) {
    case GET_GEOFENCE: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_GEOFENCE_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_GEOFENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: payload.data,
      };
    }
    default:
      return state;
  }
};

export default geofenceReducer;
