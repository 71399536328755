import styled from 'styled-components';
import { g90 } from '@carbon/themes';

export const TitleDisplay = styled.div`
  display: flex;
  height: inherit;
  align-items: center;
  margin: ${props => props.margin || '0 0 30px'};

  > * {
    margin: 0 20px 0 0;
  }
`;

export const Text = styled.div`
  display: column;
`;

export const Icon = styled.div`
  background-color: ${g90.background};
  height: 106px;
  width: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
