import { Stack } from "@carbon/react";
import { CardComponent } from "../../../components/Layouts/Card.styles";
import { HeatMap_02 as HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "./GeofenceG1.styles";

const GeofenceG1 = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            PASO POR GEOCERCA G1 - TABLA DE REGISTRO
          </Typography>
        </Card>
        <Card>
          <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/e20eaf36-f9d7-42bc-b6c6-3c2100c21b97/paso-por-geocerca-g1?orgId=1&from=1722507765116&to=1722529365116&kiosk" width="100%" height="100%" />
        </Card>
      </CardComponent>
    </Stack>
  )
};

export default GeofenceG1;