import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    DataTable, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
    TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch
} from '@carbon/react';
import { Filter } from '@carbon/icons-react'

const PredictiveTable = _ => {
    const { predictive } = useSelector(state => state.maintenance)
    const headers = [
        {
            header: 'Máquina',
            key: 'machinePatent',
        },
        {
            header: 'Tipo de Mantenimiento',
            key: 'maintenanceType',
        },
        {
            header: 'Entrada a Mantenimiento',
            key: 'eventTime',
        },
        {
            header: 'Motivo',
            key: 'reason',
        },
        {
            header: 'Tiempo Transcurrido',
            key: 'timePassed',
        },
    ]
    const rows = predictive

    const Default = () => <DataTable rows={rows} headers={headers} width="100%">
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getExpandedRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange
        }) => <TableContainer {...getTableContainerProps()}>
                <TableToolbar>
                    <TableToolbarContent>
                        {/* pass in `onInputChange` change here to make filtering work */}
                        <TableToolbarSearch onChange={onInputChange} />
                        <Button renderIcon={Filter} kind="secondary" hasIconOnly iconDescription="Filter table" />
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, i) => <TableHeader key={i} {...getHeaderProps({
                                header
                            })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <React.Fragment key={row.id}>
                            <TableRow {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                            </TableRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable>;

    return (
        <Default />
    )
}

export default PredictiveTable;