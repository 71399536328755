import styled from 'styled-components';

// Estilo para texto más pequeño
export const SmallText = styled.div`
  font-size: 0.7rem;  // Ajusta el tamaño según tus necesidades
  color: ${props => props.color || 'inherit'};
`;

// Estilo para texto de títulos más grandes
export const LargeText = styled.div`
  font-size: 0.8rem;  // Tamaño para los títulos
  color: ${props => props.color || 'inherit'};
  font-weight: bold;
`;
