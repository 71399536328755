import React from 'react'
import { useSelector } from 'react-redux'
import Card from '../../../components/Layouts/Card'
import Typography from '../../../components/Basics/Typography'
import { ChartBarStacked, ChartBar, HeatMap_03 as HeatMap, BoxPlot } from '@carbon/icons-react'
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import Gauge from '../../../components/Graphs/Gauge.container'
import Histogram from '../../../components/Graphs/Histogram.container'
import HorizontalBoxPLot from '../../../components/Graphs/HorizontalBoxPlot.container'
import LineGraph from '../../../components/Graphs/LineChart.container'
import GroupBarChart from '../../../components/Graphs/GroupBarChart.container'
import FlexHeatMap from '../../../components/Graphs/FlexHeatMap.container'
import { spacing05 } from "@carbon/themes";
import InformationContainer from '../../../components/Basics/Information.container'
import { convertTo12HourFormat } from '../../../components/Graphs/utils'

export const FirstRow = () => {
  const { weeklyProduction, m3Production, weeklyProductionBefore } = useSelector(state => state.weekly)
  let weeklyProductionData = weeklyProductionBefore.value
  const change = `${weeklyProduction - weeklyProductionData}`
  const gaugeStatus = weeklyProduction > weeklyProductionData ? 'success' : 'danger'

  const axis = {
    left: {
      scaleType: 'labels',
      mapsTo: 'key'
    },
    bottom: {
      mapsTo: 'value'
    }
  }
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          PRODUCCIÓN SEMANA
        </Typography>
      </Card>
      <InformationContainer description='Solo se mostrarán los frentes que recibieron descargas durante la semana del reporte.' />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="17%" padding={spacing05}>
          <Gauge title='Producción Semanal' footer="Comparación con semana anterior" percentage={weeklyProduction} change={change} maxPercent={17000} noMetric={true} arrowStatus={gaugeStatus} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="83%" padding={spacing05}>
          <GroupBarChart title='Producción por Frentes de Descarga (total, m3)' data={m3Production} axesOptions={axis} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const SecondRow = () => {
  const { loadCount, loadTime, discharge, wallTime } = useSelector(state => state.weekly)
  const axis = {
    left: {
      mapsTo: 'value'
    },
    bottom: {
      scaleType: 'labels',
      mapsTo: 'key'
    }
  }
  return (
    <CardComponent width="100%" >
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          CARGAS
        </Typography>
      </Card>
      <InformationContainer description='Únicamente se visualizarán las plataformas que registraron cargas.' />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
          <GroupBarChart title='Cargas por Plataforma (total, cargas)' data={loadCount} axisOptions={axis} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
          <GroupBarChart title='Duración de Cargas (media, minutos)' data={loadTime} axisOptions={axis} />
        </Card>
      </CardRow>
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          DESCARGAS
        </Typography>
      </Card>
      <InformationContainer description='Solo se mostrarán los frentes que recibieron descargas durante la semana del reporte.' />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
          <GroupBarChart title='Descargas Por Frente (total, descargas)' data={discharge} axisOptions={axis} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
          <GroupBarChart title='Tiempo en Frentes de Descarga (media, minutos)' data={wallTime} axisOptions={axis} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const ThirdRow = () => {
  const { queueTimes } = useSelector(state => state.weekly)

  const axis = {
    left: {
      scaleType: 'labels',
      mapsTo: 'key'
    },
    bottom: {
      mapsTo: 'value'
    }
  }
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <ChartBar size={18} />
        <Typography className='cds--type-heading-compact-01'>
          COLAS EN MURO Y PLATAFORMAS - MINUTOS
        </Typography>
      </Card>
      <InformationContainer description='Las colas corresponden al promedio de pérdida operacional generada por la espera en la cola.' />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart title='Colas en Frentes de Descarga y Plataformas (media, minutos)' data={queueTimes} axesOptions={axis} />
        </Card>
      </CardRow>
    </CardComponent >
  )
}

export const FourthRow = () => {
  const { prodHours, numberTrucks, maintenance, meanCyclePerTurn, prodHoursIdle, weeklyProductionMeanTurn } = useSelector(state => state.weekly)
  const axis = {
    left: {
      mapsTo: 'value'
    },
    bottom: {
      scaleType: 'labels',
      mapsTo: 'key'
    }
  }

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          RENDIMIENTO DE FLOTA
        </Typography>
      </Card>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <LineGraph title='Tiempo efectivo (media, horas)' lineData={prodHours} />
        </Card>
      </CardRow>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <LineGraph title='Camiones Operativos' lineData={numberTrucks} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <LineGraph title='Tiempo en fuera de servicio (media, horas)' lineData={maintenance} />
        </Card>
      </CardRow>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <LineGraph title='Ciclos (media, ciclos)' lineData={meanCyclePerTurn} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <LineGraph title='Tiempo en ralenti (media,horas)' lineData={prodHoursIdle} />
        </Card>
      </CardRow>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <LineGraph title='M3 Movido (media, m3)' lineData={weeklyProductionMeanTurn} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const FifthRow = () => {
  const { goodStart, ending, startCycle, breakFast } = useSelector(state => state.weekly)

  const axis = {
    bottom: {
      scaleType: "linear",
      title: 'Hora',
      mapsTo: 'value',
      bins: 24,
      domain: [8, 32],
      ticks: {
        values: [8, 11, 14, 17, 20, 23, 26, 29, 32],
        formatter: (value, i) => {
          // Convert the transformed value back to the original hour
          const formattedStart = convertTo12HourFormat(value);
          
          const formattedValue = `${formattedStart}`;
          return formattedValue
        }
      }
    },
    left: {
      title: 'Valor',
      scaleType: 'lineal',
      binned: true
    }
  }

  const legend = {
    order: ["Turno Día", "Turno Noche"]
  }

  const getFillColor = (group) => {
    const turnoDia = legend.order[0];
    return group === turnoDia ? "#8a3ffc" : "#009d9a"
  }

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <BoxPlot size={18} />
        <Typography className='cds--type-heading-compact-01'>
          TIEMPOS DE JORNADA - HISTOGRAMA
        </Typography>
      </Card>
      <InformationContainer description='Cada barra en el histograma representa la cantidad de conductores en un intervalo específico de una hora.' />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <Histogram title='Hora Inicio Efectivo (total, conductores)' data={goodStart} axesOptions={axis} legendOptions={legend} getFillColor={getFillColor} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <Histogram title='Hora Término Efectivo (total, conductores)' data={ending} axesOptions={axis} legendOptions={legend} getFillColor={getFillColor} />
        </Card>
      </CardRow>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <Histogram title='Inicio Primer Ciclo (total, conductores)' data={startCycle} axesOptions={axis} legendOptions={legend} getFillColor={getFillColor} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <HorizontalBoxPLot title='Duración Colación (media, minutos)' data={breakFast} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const SixthRow = () => {
  const { cycleTime, heatmapHour, avgSpeed, cycleCount } = useSelector(state => state.weekly)
  const graphWidth = "100%"


  return (
    <>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            RUTAS DE TRANSPORTE
          </Typography>
        </Card>
        <InformationContainer description='Las rutas corresponden al rendimiento de los camiones en los ciclos registrados.' />
        <InformationContainer description='Se compara el rendimiento de la semana seleccionada con el rendimiento de la semana anterior.' />
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={cycleTime} title='Duración de Ciclos (media, minutos)' changeColor={true}/>
          </Card>
        </CardRow>
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={cycleCount} title='Cantidad de Ciclos (media, ciclos)' />
          </Card>
        </CardRow>
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={avgSpeed} title='Velocidad Promedio (media, km/h)' />
          </Card>
        </CardRow>
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={heatmapHour} title='M3 por Hora (media, m3/h)' />
          </Card>
        </CardRow>
      </CardComponent>
    </>
  )
}
