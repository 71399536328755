import {
  GET_REPORT,
  GET_REPORT_ERROR,
  GET_REPORT_SUCCESS,
  GET_TRUCK_MODAL,
  GET_TRUCK_MODAL_ERROR,
  GET_TRUCK_MODAL_SUCCESS,
} from './DailyReport.actions';

const dailyReportState = {
  data: [],
  m3Production: [],
  dailyProduction: [],
  dailyProductionBefore: [],
  cycleCount: 0,
  cycleCountBefore: 0,
  prodHours: [],
  prodHoursIdle: [],
  numberTrucks: 0,
  numberTrucksBefore: 0,
  numberTrucksNightBefore: 0,
  numberTrucksNight: 0,
  trucksDay: {},
  trucksDayBefore: {},
  trucksNight: {},
  trucksNightBefore: {},
  truckMapping: {},
  discharge: [],
  wallTime: [],
  avgSpeed: [],
  goodStart: [],
  ending: [],
  startCycle: [],
  breakFast: [],
  trucks: [],
  loadCount: [],
  loadTime: [],
  meanCyclePerTurn: [],
  dailyProductionMeanTurn: [],
  queueTimes: [],
  heatmapHour: [],
  cycleTime: [],
  m3Moved: [],
  distanceTraveled: [],
  maintenance: [],
  modalData: [],
  loadingModal: true,
  errorModal: undefined,
  url: '',
  loading: true,
  error: undefined,
  currDate: '',
  currTruck: '',
  transformedData: [],
  cycleTable: [],
};

/**
 * The daily report reducer.
 */
const dailyReportReducer = (state = dailyReportState, { payload, type }) => {
  switch (type) {
    case GET_REPORT_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_REPORT: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        m3Production: payload.m3Production,
        dailyProduction: payload.dailyProduction,
        dailyProductionBefore: payload.dailyProductionBefore,
        cycleCount: payload.cycleCount,
        cycleCountBefore: payload.cycleCountBefore,
        prodHours: payload.prodHours,
        prodHoursIdle: payload.prodHoursIdle,
        numberTrucks: payload.numberTrucks,
        numberTrucksBefore: payload.numberTrucksBefore,
        numberTrucksNight: payload.numberTrucksNight,
        numberTrucksNightBefore: payload.numberTrucksNightBefore,
        discharge: payload.discharge,
        wallTime: payload.wallTime,
        avgSpeed: payload.avgSpeed,
        goodStart: payload.goodStart,
        ending: payload.ending,
        startCycle: payload.startCycle,
        breakFast: payload.breakFast,
        trucks: payload.trucks,
        loadCount: payload.loadCount,
        loadTime: payload.loadTime,
        meanCyclePerTurn: payload.meanCyclePerTurn,
        dailyProductionMeanTurn: payload.dailyProductionMeanTurn,
        queueTimes: payload.queueTimes,
        heatmapHour: payload.heatmapHour,
        cycleTime: payload.cycleTime,
        m3Moved: payload.m3Moved,
        distanceTraveled: payload.distanceTraveled,
        maintenance: payload.maintenance,
        url: payload.url,
        currDate: payload.currDate,
        trucksDay: payload.trucksDay,
        trucksDayBefore: payload.trucksDayBefore,
        trucksNight: payload.trucksNight,
        trucksNightBefore: payload.trucksNightBefore,
        truckMapping: payload.truckMapping,
        transformedData: payload.transformedData,
        cycleTable: payload.cycleTable,
      };
    }
    case GET_TRUCK_MODAL: {
      return {
        ...state,
        loadingModal: true,
        errorModal: undefined,
      };
    }
    case GET_TRUCK_MODAL_ERROR: {
      return {
        ...state,
        loadingModal: false,
        errorModal: payload.error,
      };
    }
    case GET_TRUCK_MODAL_SUCCESS: {
      return {
        ...state,
        loadingModal: false,
        errorModal: undefined,
        modalData: payload.modalData,
        currTruck: payload.currTruck,
      };
    }
    default: {
      return state;
    }
  }
};

export default dailyReportReducer;
