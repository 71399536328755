import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { permissionsMap } from '../../config/settings/constant';
import { getUserGroup } from '../../services/authAWS';

const DisableByGroup = ({ type, children }) => {
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        let groups = JSON.parse(localStorage.getItem('userGroups'));
        if (!groups) {
          groups = await getUserGroup();
          localStorage.setItem('userGroups', JSON.stringify(groups));
        }
        setUserGroups(groups || []);
      } catch (error) {
        console.error('Error fetching user groups:', error);
        setUserGroups([]);
      }
    };

    fetchUserGroups();
  }, []);

  const hasAccess = userGroups.some(userGroup =>
    (permissionsMap[userGroup] || []).includes(type)
  );

  return children(!hasAccess);
};

DisableByGroup.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default DisableByGroup;
