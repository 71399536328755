import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../components/Layouts/Card';
import { Stack, DataTable, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, Checkbox } from "@carbon/react";
import { DataTable as ListChecked, View } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import InformationContainer from "../../../components/Basics/Information.container";
import routes from '../../../config/settings/routes';
import { Link } from 'react-router-dom';
import CarbonTableComponent from "../../../components/Basics/Table.container";
import { spacing05 } from "@carbon/themes";
import { onGetHdopThunk } from './Recomendations.actions';
import LoadingContent from "../../../components/Animations/LoadingContent";

/**
 * The recomendation's container.
 */
const RecomendationsContainer = () => {
    const [tableRows, setTableRows] = useState([
        { id: 'row-1', name: 'TBFJ46', status: "Bulldozer Caterpillar", example: 4.7 },
        { id: 'row-2', name: 'TPVB96', status: "Bus Scania", example: 3.0 },
        { id: 'row-3', name: 'RYWB34', status: "", example: 2.7 },
        { id: 'row-4', name: 'THWC32', status: "Minibus Mercedes", example: 10.0 },
        { id: 'row-5', name: 'SZBX32', status: "Camión tolva Volvo", example: 10.0 },
        { id: 'row-6', name: 'TRAO-417', status: "Bulldozer Caterpillar", example: 10.0 },
        { id: 'row-7', name: 'TBGJ10', status: "Camión tolva Volvo", example: 10.0 },
        { id: 'row-8', name: 'TKXD69', status: "Camión tolva Volvo", example: 10.0 },
    ]);

    const [isChecked, setIsChecked] = useState(false);
    const [isChecked_2, setIsChecked_2] = useState(false);
    const [isChecked_3, setIsChecked_3] = useState(false);
    const [isChecked_4, setIsChecked_4] = useState(false);
    const [isChecked_5, setIsChecked_5] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        // Llama a la acción para obtener los datos HDOP
        dispatch(onGetHdopThunk());
    }, [dispatch]);

    const { loading_hdop, hdop } = useSelector(state => state.user);
    console.log(hdop, 'vista');

    // Actualizar `tableRows` con los datos de `hdop` cuando cambien
    useEffect(() => {
        if (hdop && hdop.length > 0) {
            const updatedTableRows = hdop.map((item, index) => ({
                id: `row-${index + 1}`,
                name: item.primary_name,
                status: item.type_mode,
                example: item.p80_hdop,
            }));
            setTableRows(updatedTableRows);
        }
    }, [hdop]);

    const rows = [
        {
            id: 'a',
            name: 'Muro Norte V2',
            status: '4-11-24',
        },
        {
            id: 'b',
            name: 'Muro Oeste',
            status: '14-04-24',
        },
        {
            id: 'c',
            name: 'Pretil 2NS',
            status: '13-05-24',
        },
        {
            id: 'd',
            name: 'Pretil Cabecera 2',
            status: '13-08-24',
        },
        {
            id: 'e',
            name: 'Muro Sur Tramo 1',
            status: '08-11-24',
        },
        {
            id: 'f',
            name: 'Muro Sur Tramo 2',
            status: '08-11-24',
        },
        {
            id: 'g',
            name: 'Muro Sur Tramo 3',
            status: '08-11-24',
        },
        {
            id: 'h',
            name: 'Muro Sur Tramo 4',
            status: '08-11-24',
        },
    ];

    const headers = [
        {
            key: 'name',
            header: 'Frente',
        },
        {
            key: 'status',
            header: 'Fecha de inicio',
        },
    ];

    return (
        <>
            {loading_hdop ? (
                <LoadingContent />
            ) : (
                <Stack gap={3}>
                    <InformationContainer color='#000000' background='#FB7E50' description="Esta Checklist se reinicia al final del turno y se comparte entre todos los usuarios que sean encargados de UCO." />
                    <Card justifyContent='flex-start' >
                        <ListChecked size={18} />
                        <Typography className='cds--type-heading-compact-01'>
                            LISTA DE TAREAS Y RECOMENDACIONES
                        </Typography>
                    </Card>
                    <Card flex={1} flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
                        <Typography className='cds--type-body-01'>
                            <View size={18} style={{ marginRight: '8px' }}/> Revisar activamente las notificaciones
                        </Typography>
                        <Typography className="cds--type-label-01">
                            Puede revisar esto en{' '}
                            <Link
                                to={routes.usersInitial}
                                style={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Notificaciones
                            </Link>.
                        </Typography>
                    </Card>
                    <Card
                        flexDirection="column"
                        justifyContent="flex-start"
                        padding={spacing05}
                        style={{ width: '100%' }}
                    >
                        <div style={{ width: '100%', paddingBottom: '10px' }}>
                            <Checkbox
                                id="checkbox_1"
                                labelText="Validar Frentes y geocercas de frentes"
                                checked={isChecked}
                                onChange={(_, { checked }) => setIsChecked(checked)}
                                width="100%"
                            />
                            <Typography className="cds--type-label-01">
                                Puede revisar esto en{' '}
                                <Link
                                    to={routes.fronts}
                                    style={{
                                        color: '#FFFFFF',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Mapa de frentes
                                </Link>.
                            </Typography>
                        </div>
                        <DataTable width="100%" rows={rows} headers={headers}>
                            {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
                                <Table
                                    {...getTableProps()}
                                    style={{
                                        width: '100%',
                                        tableLayout: 'fixed',
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader
                                                    {...getHeaderProps({ header })}
                                                    style={{ textAlign: 'left' }}
                                                >
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow {...getRowProps({ row })}>
                                                {row.cells.map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        style={{ wordWrap: 'break-word' }}
                                                    >
                                                        {cell.value}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </DataTable>
                        <div style={{ width: '100%' }}>
                            <InformationContainer background='#525252'
                                description={
                                    <>
                                        En caso de haber un frente que ya no está activo o un frente ausente, enviar un correo a{' '}
                                        <a
                                            href="mailto:carlos.olaechea@terrestra.tech"
                                            style={{
                                                textDecoration: 'underline',
                                                color: '#FFFFFF',
                                            }}
                                        >
                                            carlos.olaechea@terrestra.tech
                                        </a>{' '}
                                        especificando los cambios necesarios.
                                    </>
                                }
                            />
                        </div>
                    </Card>
                    <Card justifyContent='flex-start' padding={spacing05} flexDirection="column" style={{ width: '100%' }} >
                        <div style={{ width: '100%', paddingBottom: '10px' }}>
                            <Checkbox
                                id="checkbox_2"
                                labelText="Monitorear la desviación de la señal gps de la flota a través del HDOP"
                                checked={isChecked_2}
                                onChange={(_, { checked }) => setIsChecked_2(checked)}
                                width="100%"
                            />
                            <Typography className="cds--type-label-01">
                                Puede revisar esto con mas detalle en{' '}
                                <Link
                                    to={routes.monitoringmap}
                                    style={{
                                        color: '#FFFFFF',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Mapa de flota
                                </Link>.
                            </Typography>
                        </div>
                        <CarbonTableComponent
                            firstColumnTitle="Patente"
                            secondColumnTitle="Tipo de equipo"
                            thirdColumnTitle="HDOP"
                            rows={tableRows}
                        />
                        <div style={{ width: '100%' }}>
                            <InformationContainer background='#525252' description="La tabla muestra el HDOP de los equipos con HDOP superior a 2, un valor > 2 representa un problema con la antena o el dispositivo de telemetría." />
                        </div>
                    </Card>
                    <Card justifyContent='flex-start' padding={spacing05} flexDirection="column" style={{ width: '100%' }} >
                        <div style={{ width: '100%' }}>
                            <Checkbox
                                id="checkbox_3"
                                labelText="Monitorear el estado de conexión de los equipos."
                                checked={isChecked_3}
                                onChange={(_, { checked }) => setIsChecked_3(checked)}
                                width="100%"
                            />
                            <Typography className="cds--type-label-01">
                                Puede revisar esto en{' '}
                                <Link
                                    to={routes.monitoringmap}
                                    style={{
                                        color: '#FFFFFF', // Color del enlace
                                        fontWeight: 'bold' // Opcional: enfatizar el enlace
                                    }}
                                >
                                    Mapa de flota
                                </Link>.
                            </Typography>
                        </div>
                    </Card>
                    <Card justifyContent='flex-start' padding={spacing05} flexDirection="column" style={{ width: '100%' }} >
                        <div style={{ width: '100%' }}>
                            <Checkbox
                                id="checkbox_4"
                                labelText="Revisar Vigencia de las licencias."
                                checked={isChecked_4}
                                onChange={(_, { checked }) => setIsChecked_4(checked)}
                                width="100%"
                            />
                            <Typography className="cds--type-label-01">
                                Puede revisar esto en{' '}
                                <Link
                                    to={routes.recordsDriver}
                                    style={{
                                        color: '#FFFFFF', // Color del enlace
                                        fontWeight: 'bold' // Opcional: enfatizar el enlace
                                    }}
                                >
                                    Conductores
                                </Link>.
                            </Typography>
                        </div>
                    </Card>
                    <Card justifyContent='flex-start' padding={spacing05} flexDirection="column" style={{ width: '100%' }} >
                        <div style={{ width: '100%' }}>
                            <Checkbox
                                id="checkbox_5"
                                labelText="Subir Fotos de Licencias nuevas."
                                checked={isChecked_5}
                                onChange={(_, { checked }) => setIsChecked_5(checked)}
                                width="100%"
                            />
                            <Typography className="cds--type-label-01">
                                Puede revisar esto en{' '}
                                <Link
                                    to={routes.recordsDriver}
                                    style={{
                                        color: '#FFFFFF', // Color del enlace
                                        fontWeight: 'bold' // Opcional: enfatizar el enlace
                                    }}
                                >
                                    Conductores
                                </Link>.
                            </Typography>
                        </div>
                    </Card>
                </Stack>
            )}
        </>
    )
}

export default RecomendationsContainer