import {
  GET_SPEED_HISTORIC,
  GET_SPEED_HISTORIC_ERROR,
  GET_SPEED_HISTORIC_SUCCESS,
} from './SpeedHistoric.actions';

const speedHistoricState = {
  data: [],
  loading: false,
  error: undefined,
};

const speedHistoricReducer = (
  state = speedHistoricState,
  { payload, type }
) => {
  switch (type) {
    case GET_SPEED_HISTORIC: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_SPEED_HISTORIC_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_SPEED_HISTORIC_SUCCESS: {
      return { ...state, loading: false, error: undefined, data: payload.data };
    }
    default: {
      return state;
    }
  }
};

export default speedHistoricReducer;
