import React from 'react'
import Navbar from '../containers/Navbar/Navbar.container'
import Dashboard from '../containers/Dashboard/Dashboard.container'
import ManagementMenu from '../containers/ManagementMenu/ManagementMenu.container'
import routes from '../config/settings/routes'
import { useSession } from './../modules/hooks/security'
import ProtectBySession from '../components/Security/ProtectBySession'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import { Home } from '@carbon/icons-react'
import DashboardBody from '../containers/Dashboard/DashboardBody.container'
import TitleText from '../components/Layouts/Title'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'

/**
 * The Dashboard page.
 */
const DashboardPage = () => {
  const { username } = useSession()
  const DashboardBreadcrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/#">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem href="#" isCurrentPage>Inicio</BreadcrumbItem>
      </Breadcrumb>
    )
  }

  const title = 'Inicio'

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.dashboard} />} />}
        header={<Heading title={title} icon={<Home size={50} />} breadcrumbs={<DashboardBreadcrumbs />} />}
        childrenStyles={{ paddingLeft: 0 }}
      >
        <Dashboard>
          <TitleText icon={<img src="/assets/icons/terrestra.svg" alt="Reportar Recomendación" />} prefix='UCO 2.0' title={`Bienvenido, ${username}.`} />
          <DashboardBody />
        </Dashboard>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default DashboardPage
