import React from "react";
import { GaugeChart } from "@carbon/charts-react";
import PropTypes from 'prop-types'
import Typography from "../Basics/Typography";
import { g100 } from "@carbon/themes";
import { toolbarOptions } from "./utils";
import { Div } from "../Basics/Div.styles";


const Gauge = (props) => {
  const { height, isBold, footer, title, percentage, change, noMetric, maxPercent, arrowStatus } = props
  const state = {
    data: [
      {
        "group": "value",
        "value": Math.round((percentage / maxPercent) * 100)
      },
      {
        "group": "delta",
        "value": `${change}`
      }
    ],
    options: {
      "title": `${title}`,
      "resizable": true,
      "height": `${height}`,
      "width": "100%",
      "gauge": {
        "type": "semi",
        "status": arrowStatus,
        "numberFormatter": ((value) => {
          if (typeof value === 'string') {
            return noMetric ? `${value} m3` : `${value}`
          }
          const newValue = noMetric ? `${percentage} m3` : `${percentage}`
          return (newValue)
        }),
        "valueFontSize": ((value) => {
          return isBold ? 54 : value / 4;
        }),
        "deltaFontSize": (_ => {
          return isBold ? 24 : 12;
        }),
        "showPercentageSymbol": false,
        "alignment": "center",
        "deltaArrow": {
          "enabled": !isBold
        }
      },
      "theme": "g100",
      ...toolbarOptions
    }
  };

  return (
    <Div className={isBold && "bold"} display="flex" flexDirection="column" alignItems="center">
      <GaugeChart
        data={state.data}
        options={state.options}
      />
      {footer &&
        <Typography className='cds--type-helper-text-01' color={g100.textSecondary} margin="20px 0 20px 0 !important">
          {footer}
        </Typography>
      }
    </Div>
  );
}

Gauge.defaultProps = {
  height: "15rem",
  isBold: false,
  title: 'Gauge Graph',
  footer: '',
  percentage: 0,
  change: '',
  noMetric: false,
  maxPercent: 100
}

Gauge.propTypes = {
  height: PropTypes.string,
  isBold: PropTypes.bool,
  footer: PropTypes.string,
  title: PropTypes.string,
  percentage: PropTypes.number,
  change: PropTypes.string,
  noMetric: PropTypes.bool,
  maxPercent: PropTypes.number,
  arrowStatus: PropTypes.oneOf(["danger", "success"])
}

export default Gauge
