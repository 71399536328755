import {
  GET_DRIVER,
  GET_DRIVER_ERROR,
  GET_DRIVER_SUCCESS,
  GET_ADAS,
  GET_ADAS_ERROR,
  GET_ADAS_SUCCESS,
  GET_PERDIDAS,
  GET_PERDIDAS_ERROR,
  GET_PERDIDAS_SUCCESS,
  GET_FRENOS,
  GET_FRENOS_ERROR,
  GET_FRENOS_SUCCESS,
  UPDATE_DRIVER_LICENSE,
  UPDATE_DRIVER_LICENSE_SUCCESS,
  UPDATE_DRIVER_LICENSE_ERROR,
  GET_DRIVER_LICENSE,
  GET_DRIVER_LICENSE_ERROR,
  GET_DRIVER_LICENSE_SUCCESS,
  CLEAR_DRIVER_LICENSE,
} from './Driver.actions';

const driverState = {
  data: [],
  adas: [],
  perdidas: [],
  perdidas_semana: [],
  perdidas_ranking: [],
  frenos: [],
  frenos_semana: [],
  loading_data: true,
  error_data: undefined,
  loading_adas: false,
  error_adas: undefined,
  loading_perdidas: false,
  error_perdidas: undefined,
  loading_frenos: false,
  error_frenos: undefined,
  sending: false,
  submitError: undefined,
  submitSuccess: false,
  loadingImage: true,
  errorImage: undefined,
  successImage: false,
  url: '',
};

/**
 * The Driver reducer.
 */
const driverReducer = (state = driverState, { payload, type }) => {
  switch (type) {
    case GET_DRIVER: {
      return {
        ...state,
        loading_data: true,
        error_data: undefined,
      };
    }
    case GET_DRIVER_ERROR: {
      return {
        ...state,
        loading_data: false,
        error_data: payload.error,
      };
    }
    case GET_DRIVER_SUCCESS: {
      return {
        ...state,
        loading_data: false,
        error_data: undefined,
        data: payload.data,
      };
    }
    case GET_ADAS: {
      return {
        ...state,
        loading_adas: true,
        error_adas: undefined,
      };
    }
    case GET_ADAS_ERROR: {
      return {
        ...state,
        loading_adas: false,
        error_adas: payload.error,
      };
    }
    case GET_ADAS_SUCCESS: {
      return {
        ...state,
        loading_adas: false,
        error_adas: undefined,
        adas: payload.adas,
      };
    }
    case GET_PERDIDAS: {
      return {
        ...state,
        loading_perdidas: true,
        error_perdidas: undefined,
      };
    }
    case GET_PERDIDAS_ERROR: {
      return {
        ...state,
        loading_perdidas: false,
        error_perdidas: payload.error,
      };
    }
    case GET_PERDIDAS_SUCCESS: {
      return {
        ...state,
        loading_perdidas: false,
        error_perdidas: undefined,
        perdidas: payload.perdidas,
        perdidas_semana: payload.perdidas_semana,
        perdidas_ranking : payload.perdidas_ranking,
      };
    }
    case GET_FRENOS: {
      return {
        ...state,
        loading_frenos: true,
        error_frenos: undefined,
      };
    }
    case GET_FRENOS_ERROR: {
      return {
        ...state,
        loading_frenos: false,
        error_frenos: payload.error,
      };
    }
    case GET_FRENOS_SUCCESS: {
      return {
        ...state,
        loading_frenos: false,
        error_frenos: undefined,
        frenos: payload.frenos,
        frenos_semana: payload.frenos_semana,
      };
    }
    case UPDATE_DRIVER_LICENSE: {
      return {
        ...state,
        sending: true,
        submitSuccess: false,
        submitError: undefined,
      };
    }
    case UPDATE_DRIVER_LICENSE_ERROR: {
      return {
        ...state,
        sending: false,
        submitSuccess: false,
        submitError: payload.error,
      };
    }
    case UPDATE_DRIVER_LICENSE_SUCCESS: {
      return {
        ...state,
        sending: false,
        submitSuccess: true,
        submitError: undefined,
      };
    }
    case GET_DRIVER_LICENSE: {
      return {
        ...state,
        loadingImage: true,
        successImage: false,
        errorImage: undefined,
      };
    }
    case GET_DRIVER_LICENSE_ERROR: {
      return {
        ...state,
        loadingImage: false,
        successImage: false,
        errorImage: payload.error,
      };
    }
    case GET_DRIVER_LICENSE_SUCCESS: {
      return {
        ...state,
        loadingImage: false,
        successImage: true,
        errorImage: undefined,
        url: payload.url,
      };
    }
    case CLEAR_DRIVER_LICENSE:
      return driverState;
    default:
      return state;
  }
};

export default driverReducer;
