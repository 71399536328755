import { makeActionCreator } from '../../../config/store/utils';
import { getWeeklyReport } from '../../../services/productivityService';
import { red50, green40, yellow30, gray60 } from '@carbon/colors';
import { capitalizeNames } from '../../../modules/utils/helpers';

const getColor = (item, beforeItem) => {
  if (beforeItem) {
    if (item.value > beforeItem.value) {
      return green40; // Higher value
    } else if (item.value < beforeItem.value) {
      return red50; // Lower value
    } else {
      return yellow30; // Same value
    }
  } else {
    return gray60; // Default color if no corresponding item is found
  }
};

const processObject = (obj, calcCallback) => {
  if (Object.keys(obj).length === 0) {
    return { value: 0 };
  }

  return calcCallback(obj);
};

export const GET_WEEK_REPORT = 'GET_WEEK_REPORT';
export const GET_WEEK_REPORT_ERROR = 'GET_WEEK_REPORT_ERROR';
export const GET_WEEK_REPORT_SUCCESS = 'GET_WEEK_REPORT_SUCCESS';
export const onGetWeekReport = makeActionCreator(GET_WEEK_REPORT);
export const onGetWeekReportError = makeActionCreator(
  GET_WEEK_REPORT_ERROR,
  'payload'
);
export const onGetWeekReportSuccess = makeActionCreator(
  GET_WEEK_REPORT_SUCCESS,
  'payload'
);
export const onGetWeekReportThunk = date => async dispatch => {
  dispatch(onGetWeekReport());
  try {
    let endpointParams = {};
    if (date) {
      endpointParams = {
        day: date[0].getDate(),
        month: date[0].getMonth() + 1,
        year: date[0].getFullYear(),
      };
    }
    const { data } = await getWeeklyReport(endpointParams);
    const lastWeek = data.week.tablas;
    const lastWeekBefore = data.last_week;
    const url = data.report_url;

    const defaultArray = Array.from({ length: 7 }, (_, index) => ({
      group: 'Turno Noche',
      key: index + 1,
      value: 0,
    }));

    const m3Production =
      lastWeek.producción_m3?.filter(entry => entry.value !== 0) || [];
    const weeklyProduction = lastWeek.produccion_total_m3?.value || 0;
    const weeklyProductionBefore =
      processObject(lastWeekBefore, () => {
        return lastWeekBefore.tablas.produccion_total_m3;
      }) || 0;

    const cycleCount =
      lastWeek.heatmap_conteo_ciclos
        ?.filter(obj => {
          if (obj.value >= 1) {
            return true;
          } else {
            const key = obj.key;
            const sameKeys =
              lastWeek.heatmap_conteo_ciclos
                ?.filter(_ => _.key === key)
                .filter(_inclusion => _inclusion.value >= 1).length > 0;
            return sameKeys;
          }
        })
        .map(item => {
          const beforeItem = processObject(lastWeekBefore, () => {
            return lastWeekBefore.tablas.heatmap_conteo_ciclos.find(
              item2 => item2.group === item.group && item2.key === item.key
            );
          });
          const color = Math.round(item.value);
          const colorScale = getColor(item, beforeItem);
          return {
            x: item.key,
            y: item.group,
            color: color,
            colorScale: color ? colorScale : '#292929',
          };
        }) || [];

    const prodHourslastWeekDay =
      lastWeek.horas_productivas_sin_vel_0?.[0]?.value?.map((obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }) || defaultArray;

    const prodHourslastWeekNight =
      lastWeek.horas_productivas_sin_vel_0?.[1]?.value?.map((obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }) || defaultArray;

    const prodHours = [...prodHourslastWeekDay, ...prodHourslastWeekNight];

    const prodHoursIdlelastWeekDay =
      lastWeek.horas_vel_0?.[0]?.value?.map((obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }) || defaultArray;

    const prodHoursIdlelastWeekNight =
      lastWeek.horas_vel_0?.[1]?.value?.map((obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }) || defaultArray;

    const prodHoursIdle = [
      ...prodHoursIdlelastWeekDay,
      ...prodHoursIdlelastWeekNight,
    ];

    const numberTrucksDay =
      lastWeek.cantidad_camiones_dia?.value?.map((obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }) || defaultArray;

    const numberTrucksNight =
      lastWeek.cantidad_camiones_noche?.value?.map((obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }) || defaultArray;

    const numberTrucks = [...numberTrucksDay, ...numberTrucksNight];

    const discharge =
      lastWeek.cantidad_descargas?.filter(item => item.value !== 0) || [];
    const wallTime =
      lastWeek.tiempo_en_muros?.filter(item => item.value !== 0) || [];
    const avgSpeed =
      lastWeek.heatmap_velocidad_promedio
        ?.filter(obj => {
          if (obj.value >= 1) {
            return true;
          } else {
            const key = obj.key;
            const sameKeys =
              lastWeek.heatmap_velocidad_promedio
                ?.filter(_ => _.key === key)
                .filter(_inclusion => _inclusion.value >= 1).length > 0;
            return sameKeys;
          }
        })
        .map(item => {
          const beforeItem = processObject(lastWeekBefore, () => {
            return lastWeekBefore.tablas.heatmap_velocidad_promedio.find(
              item2 => item2.group === item.group && item2.key === item.key
            );
          });
          const color = Math.round(item.value);
          const colorScale = getColor(item, beforeItem);
          return {
            x: item.key,
            y: item.group,
            color: color,
            colorScale: color ? colorScale : '#292929',
          };
        }) || [];

    const goodStart =
      lastWeek.inicio_efectivo?.value?.map(obj => {
        return {
          group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
          value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
        };
      }) || [];
    const ending =
      lastWeek.fin_efectivo?.value?.map(obj => {
        return {
          group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
          value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
        };
      }) || [];
    const startCycle =
      lastWeek.inicio_primero_ciclo?.value?.map(obj => {
        return {
          group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
          value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
        };
      }) || [];
    const breakFastDay =
      lastWeek.tiempos_colacion?.[0]?.value?.map(obj => {
        return { group: lastWeek.tiempos_colacion[0].group, value: obj };
      }) || defaultArray;
    const breakFastNight =
      lastWeek.tiempos_colacion?.[1]?.value?.map(obj => {
        return { group: lastWeek.tiempos_colacion[1].group, value: obj };
      }) || defaultArray;
    const breakFast = [...breakFastDay, ...breakFastNight];
    const loadCount = lastWeek.cantidad_cargas || [];
    const loadTime = lastWeek.tiempo_cargas || [];
    const meanCyclePerTurnlastWeekDay =
      lastWeek.ciclos_por_turno_promedio?.[0]?.value?.map((obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }) || defaultArray;
    const meanCyclePerTurnlastWeekNight =
      lastWeek.ciclos_por_turno_promedio?.[1]?.value?.map((obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }) || defaultArray;
    const meanCyclePerTurn = [
      ...meanCyclePerTurnlastWeekDay,
      ...meanCyclePerTurnlastWeekNight,
    ];
    const weeklyProductionMeanTurnDay =
      lastWeek.daily_production_turnos_promedio?.[0]?.value?.map(
        (obj, index) => {
          return { group: 'Turno Día', key: index + 1, value: obj };
        }
      ) || defaultArray;
    const weeklyProductionMeanTurnNight =
      lastWeek.daily_production_turnos_promedio?.[1]?.value?.map(
        (obj, index) => {
          return { group: 'Turno Noche', key: index + 1, value: obj };
        }
      ) || defaultArray;
    const weeklyProductionMeanTurn = [
      ...weeklyProductionMeanTurnDay,
      ...weeklyProductionMeanTurnNight,
    ];
    const queueTimes =
      lastWeek.tiempos_cola?.filter(entry => entry.value !== 0) || [];
    const heatmapHour =
      lastWeek.heatmap_m3_hora
        ?.filter(obj => {
          if (obj.value >= 1) {
            return true;
          } else {
            const key = obj.key;
            const sameKeys =
              lastWeek.heatmap_m3_hora
                ?.filter(_ => _.key === key)
                .filter(_inclusion => _inclusion.value >= 1).length > 0;
            return sameKeys;
          }
        })
        .map(item => {
          const beforeItem = processObject(lastWeekBefore, () => {
            return lastWeekBefore.tablas.heatmap_m3_hora.find(
              item2 => item2.group === item.group && item2.key === item.key
            );
          });
          const color = Math.round(item.value);
          const colorScale = getColor(item, beforeItem);
          return {
            x: item.key,
            y: item.group,
            color: color,
            colorScale: color ? colorScale : '#292929',
          };
        }) || [];

    const cycleTime =
      lastWeek.heatmap_tiempos_ciclo
        ?.filter(obj => {
          if (obj.value >= 1) {
            return true;
          } else {
            const key = obj.key;
            const sameKeys =
              lastWeek.heatmap_tiempos_ciclo
                ?.filter(_ => _.key === key)
                .filter(_inclusion => _inclusion.value >= 1).length > 0;
            return sameKeys;
          }
        })
        .map(item => {
          const beforeItem = processObject(lastWeekBefore, () => {
            return lastWeekBefore.tablas.heatmap_tiempos_ciclo.find(
              item2 => item2.group === item.group && item2.key === item.key
            );
          });
          const color = Math.round(item.value);
          const colorScale = getColor(item, beforeItem);
          return {
            x: item.key,
            y: item.group,
            color: color,
            colorScale: color ? colorScale : '#292929',
          };
        }) || [];

    const m3MovedlastWeek =
      lastWeek.media_produccion_por_turno?.map(obj => ({
        ...obj,
        group: 'Día',
      })) || [];
    const m3Moved = [...m3MovedlastWeek];

    const maintenanceDay =
      lastWeek.tiempo_mantencion_por_turno?.[0]?.value?.map((obj, index) => {
        return { group: 'Turno Día', key: index + 1, value: obj };
      }) || defaultArray;
    const maintenanceNight =
      lastWeek.tiempo_mantencion_por_turno?.[1]?.value?.map((obj, index) => {
        return { group: 'Turno Noche', key: index + 1, value: obj };
      }) || defaultArray;
    const maintenance = [...maintenanceDay, ...maintenanceNight];

    return dispatch(
      onGetWeekReportSuccess({
        m3Production: m3Production,
        weeklyProduction: weeklyProduction,
        weeklyProductionBefore: weeklyProductionBefore,
        cycleCount: cycleCount,
        prodHours: prodHours,
        prodHoursIdle: prodHoursIdle,
        numberTrucks: numberTrucks,
        discharge: discharge,
        wallTime: wallTime,
        avgSpeed: avgSpeed,
        goodStart: goodStart,
        ending: ending,
        startCycle: startCycle,
        breakFast: breakFast,
        loadCount: loadCount,
        loadTime: loadTime,
        meanCyclePerTurn: meanCyclePerTurn,
        weeklyProductionMeanTurn: weeklyProductionMeanTurn,
        queueTimes: queueTimes,
        heatmapHour: heatmapHour,
        cycleTime: cycleTime,
        m3Moved: m3Moved,
        maintenance: maintenance,
        url: url,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetWeekReportError({
        error: error,
      })
    );
  }
};
