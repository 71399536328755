import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Tooltip } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import Typography from '../Basics/Typography';
import { Root, Item, IconButton } from './Heading.styles';
import { Div } from '../Basics/Div.styles';

/**
 * The Heading's container.
 */
const Heading = ({ breadcrumbs, icon, title, subtitle, topbar = () => undefined, tooltipContent = '' }) => {
  return (
    <Root>
      <Item marginBottom="0.5rem">
        {icon && <Div padding="0 1rem 0 0">{icon}</Div>}
        <Typography className="cds--type-heading-04">
          {title}
        </Typography>
        <Tooltip label={tooltipContent} align="bottom">
          <IconButton type="button">
            <Information size={18}/>
          </IconButton>
        </Tooltip>
      </Item>
      {breadcrumbs && 
      <Item>
        <Breadcrumb>{breadcrumbs}</Breadcrumb>
      </Item>}
      {subtitle && (
        <Typography color="white">
          {subtitle}
        </Typography>
      )}
      {topbar()}
    </Root>
  );
}

Heading.defaultProps = {
  title: '',
  tooltipContent: ''
};

Heading.propTypes = {
  breadcrumbs: PropTypes.node,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  topbar: PropTypes.func,
  tooltipContent: PropTypes.string
};

export default Heading;