import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { onGetRealTimeReportsThunk } from './ChargingTime.actions'
import InfoCard from "../../../components/Basics/InfoCard.container";
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import { SummaryKpi, CheckmarkFilled, BoxPlot, DataTable } from '@carbon/icons-react'
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card'
import Sparkline from '../../../components/Graphs/Sparkline.container'
import { Stack } from "@carbon/react";
import BoxPlotContainer from "../../../components/Graphs/BoxPlot.container";
import ChargingTimeTable from "./ChargingTimeTable.container";
import { Div } from "../../../components/Basics/Div.styles";
import LoadingContent from '../../../components/Animations/LoadingContent'
import { spacing05 } from "@carbon/themes";

const ChargingTimeContainer = () => {
  const dispatch = useDispatch()
  const { error, loading, chargeTime, chargeMean, boxPlot } = useSelector(state => state.charging)
  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetRealTimeReportsThunk())
  }, [])

  const sparklineYTitle = 'Tiempos de Carga'

  return (
    <>
      {loading && !error && (
        <>
          <LoadingContent />
        </>
      )}
      {!loading && error && (
        <>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              Error al buscar data tiempo real. Estamos solucionado el problema
            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && !error && (
        <Stack gap={3}>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <SummaryKpi size={18} />
              <Typography className='cds--type-heading-compact-01'>
                TIEMPOS DE CARGA - TENDENCIA
              </Typography>
            </Card>
            <Div flexWrap="wrap" display="flex" justifyContent="space-between" gap="0.5rem">
              {chargeTime.map((item, index) => (
                <InfoCard
                  key={index}
                  icon={<CheckmarkFilled className='success' size={33} />}
                  title={item.group}
                  body={`${chargeMean.filter(_ => _.key == item.group)[0].value} min`}
                  subtitle='Tiempo de Traslado Promedio'
                  graphTitle='Tiempo de Traslado (media, minutos)'
                  graph={<Sparkline data={item.value} yAxisTitle={sparklineYTitle} />}
                />
              ))}
            </Div>
          </CardComponent>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <SummaryKpi size={18} />
              <Typography className='cds--type-heading-compact-01'>
                TIEMPOS DE CARGA - COMPARATIVO
              </Typography>
            </Card>
            <CardRow width='inherit'>
              <Card flexDirection='column' justifyContent='center' width='100%' padding={spacing05}>
                <BoxPlotContainer height='40vh' title='Tiempos de Carga (media, minutos)' data={boxPlot} />
              </Card>
            </CardRow>
          </CardComponent>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <DataTable size={18} />
              <Typography className='cds--type-heading-compact-01'>
                CARGAS REGISTRADAS - TABLA DE REGISTRO
              </Typography>
            </Card>
            <CardRow width='inherit'>
              <Card width="100%" padding="0">
                <ChargingTimeTable />
              </Card>
            </CardRow>
          </CardComponent>
        </Stack>
      )}
    </>
  )
}

export default ChargingTimeContainer
