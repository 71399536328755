import { Stack } from "@carbon/react";
import { CardComponent } from "../../../components/Layouts/Card.styles";
import { HeatMap_02 as HeatMap, Information, DataTable } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "./SpeedGeofence.styles";

const SpeedGeofence = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            VELOCIDADES POR GEOCERCA - TABLA DE REGISTRO
          </Typography>
        </Card>
        <Card>
          <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/a7b685fb-519a-4f71-b171-cbd1c93a3bf3/estadistica-velocidades?orgId=1&var-geofence_name=All&var-fecha_inicio=2024-08-18+00%3A00%3A00&var-fecha_fin=2024-08-28+00%3A00%3A00&from=1724858420144&to=1724880020144&kiosk" width="100%" height="100%" />
        </Card>
      </CardComponent>
    </Stack>
  )
};

export default SpeedGeofence;