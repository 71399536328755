import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { DataTable } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import RollerTable from "./RollerTable.container";

const Roller = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <DataTable size={18} />
          <Typography className='cds--type-heading-compact-01'>
            CONDUCTORES
          </Typography>
        </Card>
        <CardRow width='inherit'>
          <Card width='100%' padding="0">
            <RollerTable />
          </Card>
        </CardRow>
      </CardComponent>
    </Stack>
  )
};

export default Roller;