import { makeActionCreator } from '../../../config/store/utils';
import { batch } from 'react-redux';
import { getGeofences } from '../../../services/recordService';

export const GET_GEOFENCE = 'GET_GEOFENCE';
export const GET_GEOFENCE_ERROR = 'GET_GEOFENCE_ERROR';
export const GET_GEOFENCE_SUCCESS = 'GET_GEOFENCE_SUCCESS';
export const onGetGeofence = makeActionCreator(GET_GEOFENCE);
export const onGetGeofenceError = makeActionCreator(
  GET_GEOFENCE_ERROR,
  'payload'
);
export const onGetGeofenceSuccess = makeActionCreator(
  GET_GEOFENCE_SUCCESS,
  'payload'
);
export const onGetGeofenceThunk = () => async dispatch => {
  dispatch(onGetGeofence());
  try {
    const { data } = await getGeofences();
    const geofence = data.reduce((acc, current, index) => {
      let newObject = {
        id: `${index + 1}`,
        name: current.Name,
        production:
          'Production' in current
            ? parseFloat(current.Production.toFixed(2))
            : 0,
        queue: 'Queue' in current ? parseFloat(current.Queue.toFixed(2)) : 0,
      };
      acc.push(newObject);
      return acc;
    }, []);

    return batch(() => {
      dispatch(
        onGetGeofenceSuccess({
          data: geofence,
        })
      );
    });
  } catch (error) {
    console.log(error);
    return batch(() => {
      dispatch(onGetGeofenceError({ error }));
    });
  }
};
