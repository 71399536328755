import React from 'react'
import Typography from '../../../components/Basics/Typography';
import { Stack, ContainedListItem } from '@carbon/react'
import { Timer, Calendar } from '@carbon/icons-react'
import { ListItemContainer, GridContainer, GridItem, CardItemContainer, OuterContainer } from './KpiTree.styles';
import { teal80, orange60, yellow40, teal30 } from '@carbon/colors';
import { Separator } from './KpiTree.styles';

const ExtendedTree = ({ marginBottom, wordPadding, avgBull }) => {
	return (
    <Stack gap={4}>
      <GridContainer>
        <GridItem>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin={marginBottom}>Árbol de Extendido</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-02'>Producción</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgBull["Produccion diaria"]} m3/día</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={teal80}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin='0 0 20px'>Disponibilidad mecánica</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgBull["Disponibilidad Fisica"]} %</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={teal80}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01'>Promedio</Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Tiempo en Mantenimiento</Typography>
                <Typography className='cds--type-body-01'>{avgBull["Tiempo Mantenciones"]} h</Typography>
              </ListItemContainer>
            </ContainedListItem>
          </OuterContainer>
          <Separator />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={orange60}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin='0 0 20px'>Uso Operativo</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgBull["Uso Operativo"]} %</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={orange60}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio</Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Reservas Programadas</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Reservas"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Colación</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Colacion"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Cambio de turno</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Cambio de turno"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Cargas combustible</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Carga Combustible"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-body-02' margin={marginBottom}>Rendimiento Operativo</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgBull["Rendimiento Operativo"]} m3h</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Pérdidas operacionales</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Perdidas Operacionales"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Velocidad cero en frente de trabajo</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Perdidas Operacionales"]} m</Typography>
              </ListItemContainer>
              <Separator/>
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Duración del ciclo</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Duracion Ciclo"]} min</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Velocidad mayor a cero en frente de trabajo</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgBull["Duracion Ciclo"]} min</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio</Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Calendar}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Factor de carga</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>-</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={teal30}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-body-02' margin={marginBottom}>Cantidad de Bulldozer</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgBull["Bulldozer Totales"]} unidades</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
      </GridContainer>
    </Stack>
	)
}

export default ExtendedTree