import { makeActionCreator } from '../../../config/store/utils';
import { batch } from 'react-redux';
import { getSpeedHistoric } from '../../../services/security';

const transformMovingUp = data => {
  return data.map(item => {
    if (item.moving_up === true) {
      return { ...item, moving_up: 'Subiendo' };
    } else if (item.moving_up === false) {
      return { ...item, moving_up: 'Bajando' };
    } else {
      return item;
    }
  });
};

export const GET_SPEED_HISTORIC = 'GET_SPEED_HISTORIC';
export const GET_SPEED_HISTORIC_ERROR = 'GET_SPEED_HISTORIC_ERROR';
export const GET_SPEED_HISTORIC_SUCCESS = 'GET_SPEED_HISTORIC_SUCCESS';
export const onGetSpeedHistoric = makeActionCreator(GET_SPEED_HISTORIC);
export const onGetSpeedHistoricError = makeActionCreator(
  GET_SPEED_HISTORIC_ERROR,
  'payload'
);
export const onGetSpeedHistoricSuccess = makeActionCreator(
  GET_SPEED_HISTORIC_SUCCESS,
  'payload'
);
export const onGetSpeedHistoricThunk = params => async dispatch => {
  dispatch(onGetSpeedHistoric());
  try {
    const { data } = await getSpeedHistoric(params);
    const transformedData = transformMovingUp(data);
    return dispatch(
      onGetSpeedHistoricSuccess({
        data: transformedData,
      })
    );
  } catch (error) {
    console.log(error);
    let code;
    let reason;
    switch (error?.response.status) {
      case 413:
        code = 413;
        reason = 'PAYLOAD_TOO_LARGE';
        break;
      default:
        code = 500;
        reason = 'SOMETHING_WENT_WRONG_ERROR';
        break;
    }

    return dispatch(onGetSpeedHistoricError({ error: { code, reason } }));
  }
};
