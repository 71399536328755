import { API } from 'aws-amplify';
import {
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
} from '../config/settings/environments';

/**
 * To get the daily report.
 */
export const getSpeedHistoric = async params => {
  const paths = {
    talabre: '/v1/get-speed-historic',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-speed-historic';

  const body = params;

  const response = await API.post(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
    body,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the sensors.
 */
export const getSensors = async date => {
  const paths = {
    talabre: '/v1/read-files',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/read-files';
  if (date) {
    path = `${path}?file=${date}`;
  }
  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};
