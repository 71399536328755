const { Table, TableHead, TableRow, TableHeader, TableBody, TableCell, DataTable, TableContainer, TableToolbar, TableToolbarContent, TableToolbarSearch } = require("@carbon/react");

function SimpleTable({ headers, rows, headerData = headers }) {
    return (
        <DataTable rows={rows} headers={headers}>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getToolbarProps,
                onInputChange,
                getTableContainerProps,
                sortBy,
            }) => <TableContainer {...getTableContainerProps()}>
                    <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                        <TableToolbarContent>
                            <TableToolbarSearch onChange={onInputChange} />
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table size="lg" useZebraStyles={false} {...getTableProps()} aria-label="sample table">
                        <TableHead>
                            <TableRow>
                                {headers.map((header, i) => <TableHeader
                                    key={i}
                                    sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                    {...getHeaderProps({
                                        header,
                                        isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                        onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                                    })}
                                >
                                    {header.header}
                                </TableHeader>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => <TableRow key={row.id} {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </DataTable>
    )
}

export default SimpleTable;