import React from "react";
import PropTypes from 'prop-types'
import { BoxplotChart } from "@carbon/charts-react";
import { toolbarOptions } from "./utils";

const HorizontalBoxPLot = props => {
  const { height, title, xAxisTitle, yAxisTitle, data } = props
  const state = {
    data: data,
    options: {
      "title": title,
      "axes": {
        "bottom": {
          "mapsTo": "value",
          "title": yAxisTitle,
        },
        "left": {
          "scaleType": "labels",
          "mapsTo": "group",
          "title": xAxisTitle,
        }
      },
      "height": height,
      "width": "95%",
      "theme": "g100",
      ...toolbarOptions
    }
  };

  return (
    <BoxplotChart
      data={state.data}
      options={state.options}
    />
  )
}

HorizontalBoxPLot.defaultProps = {
  height: "15rem",
  title: "Boxplot Graph",
  data: [
    {
      group: 'Q1',
      key: 'Monday',
      value: 65000
    },
    {
      group: 'Q1',
      key: 'Tuesday',
      value: 29123
    },
    {
      group: 'Q1',
      key: 'Wednesday',
      value: 35213
    },
    {
      group: 'Q1',
      key: 'Thursday',
      value: 51213
    },
    {
      group: 'Q1',
      key: 'Friday',
      value: 16932
    }
  ]
}

HorizontalBoxPLot.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  data: PropTypes.array
}

export default HorizontalBoxPLot
