import {
  SEND_MAINTENANCE,
  SEND_MAINTENANCE_ERROR,
  SEND_MAINTENANCE_SUCCESS,
  GET_MAINTENANCE,
  GET_MAINTENANCE_ERROR,
  GET_MAINTENANCE_SUCCESS,
  EDIT_MAINTENANCE,
  EDIT_MAINTENANCE_SUCCESS,
  EDIT_MAINTENANCE_ERROR,
  SET_MAINTENANCE_MODAL_SUCCESS,
  SEND_MAINTENANCE_EXCEL,
  SEND_MAINTENANCE_EXCEL_ERROR,
  SEND_MAINTENANCE_EXCEL_SUCCESS,
} from './Ongoing.actions';

const maintenanceState = {
  error: undefined,
  loading: false,
  sending: false,
  sendingExcel: false, // New state for sending Excel data
  sendingEdit: false,
  submitError: undefined,
  excelSubmitError: undefined, // New error state for Excel
  editError: undefined,
  submitSuccess: false,
  excelSubmitSuccess: false, // New success state for Excel
  editSuccess: false,
  corrective: [],
  preventive: [],
  predictive: [],
  onGoing: [],
  completed: [],
  modalParams: {},
};

/**
 * The maintenance' reducer.
 */

const maintenanceReducer = (state = maintenanceState, { payload, type }) => {
  switch (type) {
    case GET_MAINTENANCE: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_MAINTENANCE_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_MAINTENANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        preventive: payload.preventive,
        predictive: payload.predictive,
        onGoing: payload.onGoing,
        completed: payload.completed,
        corrective: payload.corrective,
      };
    }
    case SEND_MAINTENANCE: {
      return {
        ...state,
        sending: true,
        submitSuccess: false,
        submitError: undefined,
      };
    }
    case SEND_MAINTENANCE_ERROR: {
      return {
        ...state,
        sending: false,
        submitSuccess: false,
        submitError: payload.error,
      };
    }
    case SEND_MAINTENANCE_SUCCESS: {
      return {
        ...state,
        sending: false,
        submitSuccess: true,
        submitError: undefined,
      };
    }
    case SEND_MAINTENANCE_EXCEL: {
      return {
        ...state,
        sendingExcel: true,
        excelSubmitSuccess: false,
        excelSubmitError: undefined,
      };
    }
    case SEND_MAINTENANCE_EXCEL_ERROR: {
      console.log(payload.error);
      return {
        ...state,
        sendingExcel: false,
        excelSubmitSuccess: false,
        excelSubmitError: payload.error,
      };
    }
    case SEND_MAINTENANCE_EXCEL_SUCCESS: {
      return {
        ...state,
        sendingExcel: false,
        excelSubmitSuccess: true,
        excelSubmitError: undefined,
      };
    }
    case SET_MAINTENANCE_MODAL_SUCCESS: {
      return {
        ...state,
        modalParams: payload.modalParams,
      };
    }
    case EDIT_MAINTENANCE: {
      return {
        ...state,
        sendingEdit: true,
        editSuccess: false,
        editError: undefined,
      };
    }
    case EDIT_MAINTENANCE_ERROR: {
      return {
        ...state,
        sendingEdit: false,
        editSuccess: false,
        editError: payload.error,
      };
    }
    case EDIT_MAINTENANCE_SUCCESS: {
      return {
        ...state,
        sendingEdit: false,
        editSuccess: true,
        editError: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default maintenanceReducer;
