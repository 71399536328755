import React from 'react'
import TitleText from '../../../components/Layouts/Title'
import Typography from '../../../components/Basics/Typography'
import CardButton from '../../../components/Basics/CardButton.container'
import { Column, Root } from './Monitoring.styles'
import routes from '../../../config/settings/routes'
import { g100 } from '@carbon/themes';
import { Stack } from "@carbon/react";
import { Separator } from '../../Dashboard/Dashboard.styles'

/**
 * The Monitoring's component
 */
const Monitoring = () => {
  return (
    <>
      <TitleText
        title='Mapa y Monitoreo del Turno en Tiempo Real'
        subtitle='Módulo para el monitoreo en tiempo real del rendimiento y la flota.'
      />
      <Root>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Monitoreo</Typography>
            <Separator />
            <CardButton title='Mapa de Flota' route={routes.monitoringmap} />
            <CardButton title='Desempeño Operación y Desperdicios' route={routes.monitoringPerf} />
            <CardButton title='Dashboard CAN Última hora' route={routes.monitoringCan} />
          </Stack>
        </Column>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Tiempos de Operación</Typography>
            <Separator />
            <CardButton title='Tiempos de Carga' route={routes.monitoringCharg} />
            <CardButton title='Tiempos de Traslado a Frentes de Descarga' route={routes.monitoringTUnload} />
            <CardButton title='Tiempos en Frente de Descarga' route={routes.monitoringDischarging} />
            <CardButton title='Tiempos Traslado a B57' route={routes.monitoringTLoad} />
            <CardButton title='Tiempo en Ralenti' route={routes.monitoringIdle} />
            <CardButton title='Conductores Operativos' route={routes.monitoringEffectiveTimeDriver} />
          </Stack>
        </Column>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Registro</Typography>
            <Separator />
            <CardButton title='Pinchazos' route={routes.monitoringPunctures} />
            <CardButton title='Composteo Combustible' route={routes.monitoringComposting} />
            <CardButton title='Paso Geocerca G1' route={routes.monitoringGeofenceG1} />
            <CardButton title='Velocidades Bajas' route={routes.monitoringLowVelocity} />
            <CardButton disabled title='Pasadas de Rodillo' route={routes.monitoringRoller} hidden />
          </Stack>
        </Column>
      </Root>
    </>
  )
}

export default Monitoring
