import React from "react";
import Typography from "./Typography";
import { Div } from "./Div.styles";
import { g100 } from "@carbon/themes";
import { Separator } from "../../containers/Dashboard/Dashboard.styles";

const Footer = _ => {
    return (
        <footer style={{ display: "flex", flexDirection: "column", padding: "80px 32px", gap: "16px" }}>
            <Div display="flex" alignItems="center" gap="96px">
                <img src="/assets/images/codelco.svg" alt="codelco" style={{ mixBlendMode: "soft-light" }} />
                <img src="/assets/images/acciona.svg" alt="acciona" style={{ mixBlendMode: "soft-light" }} />
                <img src="/assets/images/geoaustral.svg" alt="geoaustral" style={{ mixBlendMode: "soft-light", height: "1rem" }} />
            </Div>
            <Separator />
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Unidad de Control Operativo 2.0</Typography>
            <img src="/assets/images/terrestra.svg" alt="terrestra" width="105.79px" height="16px" />
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>
                2024
            </Typography>
        </footer >
    );
}

export default Footer;
