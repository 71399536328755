import React from 'react'
import { MAINTENANCE_TYPE } from "../config/settings/constant";
import routes from "../config/settings/routes";
import { usePathParam } from "../modules/hooks/route";
import ProtectBySession from "../components/Security/ProtectBySession";
import BrandLayout from "../components/Layouts/BrandLayout";
import { ToolKit } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import Navbar from "../containers/Navbar/Navbar.container";
import ManagementMenu from "../containers/ManagementMenu/ManagementMenu.container";
import Heading from "../components/Layouts/Heading";
import Maintenance from '../containers/Maintenance/Initial/Maintenance.container';
import Preventive from '../containers/Maintenance/Preventive/Preventive.container';
import Predictive from '../containers/Maintenance/Predictive/Predictive.container';
import Ongoing from '../containers/Maintenance/Ongoing/Ongoing.container';
import Completed from '../containers/Maintenance/Completed/Completed.container';
import AccessControl from '../components/Security/ProtectByGroup';
import PermanenceContainer from '../containers/Maintenance/Permanence/Permanence.container';

/**
 * The Maintenance page.
 */
const MaintenancePage = () => {
  const type = usePathParam('type').toUpperCase();

  const TypeTitle = type => {
    switch (type) {
      case MAINTENANCE_TYPE['MAINTENANCE-INITIAL']:
        return 'Mantenimientos'
      case MAINTENANCE_TYPE.PREVENTIVE:
        return 'Gestión de Mantenimientos Preventivos'
      case MAINTENANCE_TYPE.PREDICTIVE:
        return 'Gestión de Mantenimientos Predictivos'
      case MAINTENANCE_TYPE.ONGOING:
        return 'Mantenimientos en Curso'
      case MAINTENANCE_TYPE.COMPLETED:
        return 'Mantenimientos Completados'
      case MAINTENANCE_TYPE.PERMANENCE:
        return 'Registro de permanencia en talleres'
      default:
        return 'Inicio de modulo'
    }
  };

  const getTooltipContent = type => {
    switch (type) {
      case MAINTENANCE_TYPE['MAINTENANCE-INITIAL']:
        return 'Módulo mantenimiento'
      case MAINTENANCE_TYPE.PREVENTIVE:
        return 'Detalles sobre gestión de mantenimientos preventivos'
      case MAINTENANCE_TYPE.PREDICTIVE:
        return 'Información sobre gestión de mantenimientos predictivos'
      case MAINTENANCE_TYPE.ONGOING:
        return 'Tabla para gestionar y recopilar todos los mantenimientos que se encuentren en curso.'
      case MAINTENANCE_TYPE.COMPLETED:
        return 'Registro de todos los mantenimientos ingresados y completados en la plataforma con información de las fechas y duración.'
      case MAINTENANCE_TYPE.PERMANENCE:
        return 'Registro de las permanencias dentro del taller.'
      default:
        return ''
    }
  };

  const ProductivityBreadcrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={MAINTENANCE_TYPE[type] === MAINTENANCE_TYPE['MAINTENANCE-INITIAL']} href={routes.maintenanceInitial}>
          Mantenimientos
        </BreadcrumbItem>
        {MAINTENANCE_TYPE[type] !== MAINTENANCE_TYPE['MAINTENANCE-INITIAL'] && <BreadcrumbItem isCurrentPage>{TypeTitle(type)}</BreadcrumbItem>}
      </Breadcrumb>
    )
  }

  const typeToComponentMap = {
    [MAINTENANCE_TYPE['MAINTENANCE-INITIAL']]: Maintenance,
    [MAINTENANCE_TYPE.PREVENTIVE]: Preventive,
    [MAINTENANCE_TYPE.PREDICTIVE]: Predictive,
    [MAINTENANCE_TYPE.ONGOING]: Ongoing,
    [MAINTENANCE_TYPE.COMPLETED]: Completed,
    [MAINTENANCE_TYPE.PERMANENCE]: PermanenceContainer
  }
  const ComponentToRender = typeToComponentMap[type];

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.maintenance} />} />}
        header={<Heading title={TypeTitle(type)} icon={<ToolKit size={50} />} breadcrumbs={<ProductivityBreadcrumbs />} tooltipContent= {getTooltipContent(type)}/>}
      >
        <AccessControl type={type}>
          {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
        </AccessControl>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default MaintenancePage
