import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetWeekReportThunk } from './WeeklyReport.actions'
import { CardRow } from '../../../components/Layouts/Card.styles'
import { FirstRow, SecondRow, ThirdRow, FourthRow, FifthRow, SixthRow } from './WeeklyReport.row'
import { Stack } from '@carbon/react'
import { Button, DatePicker, DatePickerInput } from '@carbon/react'
import { Export } from '@carbon/icons-react'
import LoadingContent from '../../../components/Animations/LoadingContent'
import Typography from '../../../components/Basics/Typography'
import { CardComponent } from '../../../components/Layouts/Card.styles'

/**
 * The Weekly Report's container.
 */
const WeeklyReportContainer = () => {
  const dispatch = useDispatch()
  const { error, loading, url } = useSelector(state => state.weekly)
  const [date, setDate] = useState(new Date())
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)
  const dispatchReport = (date) => {
    setDate(date)
    dispatch(onGetWeekReportThunk(date))
  }

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetWeekReportThunk())
  }, [])

  return (
    <>
      {loading && !error && (
        <LoadingContent />
      )}
      {!loading && error && (
        <>
          <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} style={{ marginBottom: '40px' }} onChange={dispatchReport} value={date} dateFormat='d-m-Y'>
            <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha del Reporte" id="we-datepicker-error" size="md" />
          </DatePicker>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              No hay datos para esta semana
            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && !error && (
        <Stack gap={3}>
          <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} onChange={dispatchReport} value={date} dateFormat='d-m-Y'>
            <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha del Reporte" id="weekly-datepicker-error" size="md" />
          </DatePicker>
          <Button size='sm' renderIcon={Export} href={url}>
            Exportar Reporte Semanal
          </Button>
          <CardRow>
            <FirstRow />
          </CardRow>
          <CardRow>
            <SecondRow />
          </CardRow>
          <CardRow>
            <ThirdRow />
          </CardRow>
          <CardRow>
            <FourthRow />
          </CardRow>
          <CardRow>
            <FifthRow />
          </CardRow>
          <CardRow>
            <SixthRow />
          </CardRow>
        </Stack>
      )}
    </>
  )
}

export default WeeklyReportContainer
