import { Stack } from "@carbon/react";
import { CardComponent } from "../../../components/Layouts/Card.styles";
import { HeatMap_02 as HeatMap, Information, DataTable } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "./Idle.styles";
import InformationContainer from "../../../components/Basics/Information.container";
import NullVelocityTable from "./IdleTable.container";
import { CardRow } from "../../../components/Layouts/Card.styles";

const Idle = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            TIEMPO EN RALENTI - MAPA DE CALOR
          </Typography>
        </Card>
        <InformationContainer description="Los parámetros de los filtros 'fecha_inicio' y 'fecha_fin' utilizan el formato mm/dd/aaaa." />
        <Card>
          <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/c2cb8912-3541-4da0-a6e9-72a94d1aa081/tiempo-en-ralenti?orgId=1&from=1704985300839&to=1704985600839&var-patente=All&var-fecha_inicio=2024-01-01+00%3A00%3A00&var-fecha_fin=2024-01-02+00%3A00%3A00&kiosk" width="100%" height="100%" />
        </Card>
      </CardComponent>
    </Stack>
  )
};

export default Idle;