import styled from 'styled-components';

export const Map = styled.div`
  width: 100%;
  height: 100%;
`;

export const Iframe = styled.iframe`
  height: 100vh;
`;
