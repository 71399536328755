import React from 'react'
import Navbar from '../containers/Navbar/Navbar.container'
import ManagementMenu from '../containers/ManagementMenu/ManagementMenu.container'
import routes from '../config/settings/routes'
import ProtectBySession from '../components/Security/ProtectBySession'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import { LassoPolygon, MapBoundary } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem, Stack } from '@carbon/react'
import { Iframe } from '../containers/Map/Map.styles'
import { CardComponent } from '../components/Layouts/Card.styles'
import Card from '../components/Layouts/Card';
import Typography from '../components/Basics/Typography'
import InformationContainer from '../components/Basics/Information.container'

/**
 * The Map's Page.
 */
const Map = () => {
  const MapBreadCrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/#">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem href="#" isCurrentPage>Geocercas Limites de Velocidades</BreadcrumbItem>
      </Breadcrumb>
    )
  }

  const title = 'Geocercas Limites de Velocidades'

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.map} />} />}
        header={<Heading title={title} icon={<LassoPolygon size={50} />} breadcrumbs={<MapBreadCrumbs />} tooltipContent='Vista para visualizar las geocercas de velocidad y el registro de los puntos GPS de los equipos. Contiene información detallada de las propiedades de las geocercas.'/>}
      >
        <Stack gap={3}>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <MapBoundary size={18} />
              <Typography className='cds--type-heading-compact-01'>
                MAPA DE TALABRE
              </Typography>
            </Card>
            <InformationContainer description="Las geocercas creadas dentro del visualizador pueden demorar hasta 15 segundos en aparecer." />
            <Card>
              <Iframe src="https://experience.arcgis.com/experience/8f0de19a40fb4521b718484e3fb2096f" width="100%" height="100%" allowfullscreen>No se admiten iFrames en esta página.</Iframe>
            </Card>
          </CardComponent>
        </Stack>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default Map
