import { makeActionCreator } from '../../../config/store/utils';
import { getRealTimeReports } from '../../../services/monitoringService';
import { formatDate } from '../../../modules/utils/formatters';

export const GET_REAL_TIME_DRIVER = 'GET_REAL_TIME_DRIVER';
export const GET_REAL_TIME_DRIVER_ERROR = 'GET_REAL_TIME_DRIVER_ERROR';
export const GET_REAL_TIME_DRIVER_SUCCESS = 'GET_REAL_TIME_DRIVER_SUCCESS';
export const onGetRealTimeDriver = makeActionCreator(GET_REAL_TIME_DRIVER);
export const onGetRealTimeDriverError = makeActionCreator(
  GET_REAL_TIME_DRIVER_ERROR,
  'payload'
);
export const onGetRealTimeDriverSuccess = makeActionCreator(
  GET_REAL_TIME_DRIVER_SUCCESS,
  'payload'
);
export const onGetRealTimeDriverThunk = () => async dispatch => {
  dispatch(onGetRealTimeDriver());
  try {
    const type = 't_efectivo_conductores';
    const { data } = await getRealTimeReports(type);

    const _currTurn = JSON.parse(
      data.current_turn.tablas.tabla_tiempo_efectivo_conductores
    );
    const currTurn = _currTurn.map((obj, index) => {
      const _start = formatDate(obj.inicio_efectivo, 'yyyy-MM-dd HH:mm:ss');
      const _end = formatDate(obj.inicio_primer_ciclo, 'yyyy-MM-dd HH:mm:ss');
      const surname = obj.Apellido === null ? 'Sin Registro' : obj.Apellido;
      const name = obj.Nombre === null ? 'Sin Registro' : obj.Nombre;
      return {
        id: `${index + 1}`,
        isActive: obj['Activo/Inactivo'],
        surname: surname,
        phone: obj.Celular === null ? 'Sin Registro' : obj.Celular,
        expiry:
          obj['Fecha vencimiento licencia'] === null
            ? 'Sin Registro'
            : obj['Fecha vencimiento licencia'],
        name: name,
        licenseNumber:
          obj['N° licencia'] === null ? 'Sin Registro' : obj['N° licencia'],
        rut: obj['RUT'] === null ? 'Sin Registro' : obj['RUT'],
        turn: obj.Turno === null ? 'Sin Registro' : obj.Turno,
        diffEventTime: obj.diff_event_time.toFixed(2),
        startEffective: _start,
        startCycle: _end,
        timeDiffSum: obj.time_diff_sum.toFixed(2),
        fullName: `${name} ${surname}`,
      };
    });
    return dispatch(
      onGetRealTimeDriverSuccess({
        currTurn: currTurn,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetRealTimeDriverError({
        error: error,
      })
    );
  }
};
