import React from 'react'
import Typography from '../../../components/Basics/Typography';
import { Stack, ContainedListItem } from '@carbon/react'
import { Timer } from '@carbon/icons-react'
import { ListItemContainer, GridContainer, GridItem, CardItemContainer, OuterContainer } from './KpiTree.styles';
import { teal80, orange60, yellow40, teal30 } from '@carbon/colors';
import { Separator } from './KpiTree.styles';

const TransportTree = ({ marginBottom, wordPadding, avgLoads }) => {
  return (
    <Stack gap={4}>
      <GridContainer>
        <GridItem>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin={marginBottom}>Árbol de Transporte</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-02'>Producción</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgLoads["Produccion diaria"]} m3/día</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={teal80}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin='0 0 20px'>Disponibilidad mecánica</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgLoads["Disponibilidad Fisica"]} %</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={teal80}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Tiempo en Mantenimiento</Typography>
                <Typography className='cds--type-body-01'>{avgLoads["Tiempo Mantenciones"]} h</Typography>
              </ListItemContainer>
            </ContainedListItem>
          </OuterContainer>
          <Separator />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={orange60}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin='0 0 20px'>Uso Operativo</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio </Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgLoads["Uso Operativo"]} %</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={orange60}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Reservas Programadas</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Reservas Programadas"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Colación</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Colacion"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Cambio de turno</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Cambio de turno"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Cargas combustible</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Carga Combustible"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Traslados Colación</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Traslados Colacion"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Traslados mantenimiento</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Traslados mantenimiento"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Otros</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Otros"]} h</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-body-02' margin={marginBottom}>Rendimiento Operativo</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio </Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgLoads["Rendimiento Operativo"]} m3h</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Pérdidas operacionales</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Perdidas Operacionales"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Esperando en carguío</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Espera carga"]} min</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Esperando en descarga</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Espera descarga"]} min</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Duración del ciclo</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Duracion Ciclo"]} min</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Tiempo de carga</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Carguio"]} min</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Tiempo de Viaje</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Transporte"]} min</Typography>
              </ListItemContainer>            
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding='0 80px'>Distancia</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Distancia"]} km</Typography>
              </ListItemContainer>            
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding='0 80px'>Velocidad</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Velocidad"]} km/h</Typography>
              </ListItemContainer>            
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Tiempo de descarga</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{avgLoads["Descarga"]} min</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Factor de carga</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>-</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={teal30}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-body-02' margin={marginBottom}>Cantidad de Camiones</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio </Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{avgLoads["Camiones Totales"]}</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
      </GridContainer>
    </Stack>
  )
}

export default TransportTree