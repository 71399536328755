import { GET_GEOFENCE_ERROR } from '../Geofences/Geofence.actions';
import { GET_GATES, GET_GATES_ERROR, GET_GATES_SUCCESS } from './Gates.actions';

// Get yesterday's date
const getYTodayDate = () => {
  const today = new Date();
  return today.toISOString();
};

const gatesState = {
  data: [],
  isoDate: '',
  loading: true,
  error: undefined,
};

/**
 * The Gates reducer.
 */

const gatesReducer = (state = gatesState, { payload, type }) => {
  switch (type) {
    case GET_GATES: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_GATES_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_GATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: payload.data,
        date: payload.isoDate,
      };
    }
    default:
      return state;
  }
};

export default gatesReducer;
