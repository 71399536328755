export default function AnimationVariant5({ className }) {
    return (
        <svg id="svg4" width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g x="0.107018" y="43.4307" id="text4" className={className}>
                <rect x="0.107018" y="43.4307" width="61.0253" height="61.0253"
                    transform="rotate(-45 0.107018 43.4307)" fill="#F14405" />
            </g>
            <path
                d="M39.4385 24.8701C38.6633 24.8701 38.043 25.1285 37.5779 25.5937C37.0611 26.1105 36.8544 26.7307
    36.8544 27.4542V52.1045C36.8544 54.0166 37.2162 55.7225 38.043 57.2213C38.8182 58.7716 39.9038
    59.9602 41.299 60.8386C42.6943 61.7172 44.2445 62.1304 45.95 62.1304H46.8803C47.7588 62.1304
    48.4823 61.9237 49.0508 61.4069C49.6193 60.9418 49.9295 60.3215 49.9295 59.5463C49.9295 58.8228
    49.671 58.2031 49.2576 57.6863C48.8441 57.2212 48.327 56.9628 47.7069 56.9628H45.95C44.8131
    56.9628 43.8832 56.4976 43.1597 55.5673C42.3846 54.6371 42.0226 53.4998 42.0226
    52.1046V39.3404H46.415C47.1385 39.3404 47.7068 39.1341 48.1719 38.7206C48.5853 38.3072 48.8438
    37.7901 48.8438 37.17C48.8438 36.4981 48.5853 35.9296 48.1719 35.5162C47.7068 35.1027 47.1385
    34.8964 46.415 34.8964H42.0214V27.4542C42.0214 26.7307 41.763 26.1104 41.2979 25.5936C40.7811
    25.1285 40.1608 24.8701 39.4373 24.8701L39.4385 24.8701Z"
                fill="white" />
        </svg>
    )
}