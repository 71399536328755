import { makeActionCreator } from '../../../config/store/utils';
import { formatDate } from '../../../modules/utils/formatters';
import { getGeneralReport } from '../../../services/productivityService';

export const GET_GENERAL_REPORT = 'GET_GENERAL_REPORT';
export const GET_GENERAL_REPORT_ERROR = 'GET_GENERAL_REPORT_ERROR';
export const GET_GENERAL_REPORT_SUCCESS = 'GET_GENERAL_REPORT_SUCCESS';
export const onGetGeneralReport = makeActionCreator(GET_GENERAL_REPORT);
export const onGetGeneralReportError = makeActionCreator(
  GET_GENERAL_REPORT_ERROR,
  'payload'
);
export const onGetGeneralReportSuccess = makeActionCreator(
  GET_GENERAL_REPORT_SUCCESS,
  'payload'
);
export const onGetGeneralReportThunk = date => async dispatch => {
  dispatch(onGetGeneralReport());
  try {
    let endpointParams = {};
    if (date) {
      endpointParams = {
        day: date[0].getDate(),
        month: date[0].getMonth() + 1,
        year: date[0].getFullYear(),
      };
    }
    endpointParams['type'] = 'ralenti';
    const { data } = await getGeneralReport(endpointParams);
    const table = JSON.parse(data.current_turn).map((item, index) => {
      const _time = formatDate(item.time, 'yyyy-MM-dd');
      return {
        ...item,
        id: `${index + 1}`,
        ralenti: item.ralenti.toFixed(2),
        time: _time,
      };
    });
    return dispatch(
      onGetGeneralReportSuccess({
        data: table,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetGeneralReportError({
        error,
      })
    );
  }
};
