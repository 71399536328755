import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "../../../components/Basics/Iframe.styles";
import InformationContainer from "../../../components/Basics/Information.container";

const Brakes = () => {
    return (
        <Stack gap={3}>
            <CardComponent width="100%">
                <Card justifyContent='flex-start' >
                    <HeatMap size={18} />
                    <Typography className='cds--type-heading-compact-01'>
                        FRENOS Y RETARDADOR- MAPA DE CALOR
                    </Typography>
                </Card>
                <InformationContainer description="El umbral para detección de un freno depende de la variable que se utilice para detectarlo." />
                <CardRow width='inherit'>
                    <Card width='100%' padding="0">
                        <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/f3525662-b9e1-48e5-b429-680c3c03c6a4/frenos?orgId=1&var-intervalo=1h&var-geocerca=All&var-patente=All&var-variable=%27ecu_brake_pedal%27&from=1720089073911&to=1720110673911&kiosk" width="100%" height="100%" />
                    </Card>
                </CardRow>
            </CardComponent>
        </Stack>
    )
};

export default Brakes;