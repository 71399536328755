import { Auth } from 'aws-amplify';

/**
 * To log in into the application.
 */
export const signIn = ({ username, password }) => {
  return Auth.signIn(username, password);
};

/**
 * To log out to the application.
 */
export const signOut = () => {
  return Auth.signOut();
};

/**
 * To register a new user into the application.
 */
export const signUp = ({ username, password }) => {
  return Auth.signUp(username, password);
};

/**
 * To get the current session.
 */
export const getCurrentSession = () => {
  return Auth.getCurrentSession();
};

/**
 * To get the current user.
 */
export const getCurrentUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const getUserGroup = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const accessTokenPayload = user.signInUserSession.accessToken.payload;
    const groups = accessTokenPayload['cognito:groups'] || [];
    return groups;
  } catch (error) {
    console.error('Error fetching user attributes:', error);
    return [];
  }
};

/**
 * To recover password.
 */
export const forgotPassword = username => {
  return Auth.forgotPassword(username);
};

/**
 * To submit new password.
 */
export const forgotPasswordSubmit = (username, code, newPassword) => {
  return Auth.forgotPasswordSubmit(username, code, newPassword);
};
