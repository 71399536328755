import React from 'react'
import { HistogramChart } from '@carbon/charts-react'
import PropTypes from 'prop-types'
import '@carbon/charts-react/styles.css'
import { toolbarOptions, formatFromBaseline, convertTo12HourFormat } from "./utils";

const Histogram = props => {
  const { title, height, data, axesOptions, getFillColor, legendOptions } = props
  const state = {
    data: data,
    options: {
      title: `${title}`,
      axes: axesOptions,
      height: `${height}`,
      theme: "g100",
      width: "95%",
      legend: legendOptions,
      getFillColor: getFillColor,
      tooltip: {
        enabled: true,
        valueFormatter: (value, label) => {
          if (label !== 'Range') {
            return label, value
          }
          const [start, end] = value.split(' - ').map(parseFloat);
        
          const formattedStart = convertTo12HourFormat(start);
          
          const formattedValue = `${formattedStart}`;
          return label, formattedValue
        }
      },
      ...toolbarOptions
    }
  }

  return (
    <>
      <HistogramChart
        data={state.data}
        options={state.options}
      />
    </>
  )
}

Histogram.defaultProps = {
  height: "15rem",
  title: "Bar Chart Graph",
  data: [
    {
      group: 'Dataset 1',
      value: 240
    },
    {
      group: 'Dataset 1',
      value: 250
    },
    {
      group: 'Dataset 1',
      value: 255
    },
    {
      group: 'Dataset 1',
      value: 290
    },
    {
      group: 'Dataset 1',
      value: 300
    },
    {
      group: 'Dataset 1',
      value: 320
    },
    {
      group: 'Dataset 1',
      value: 330
    },
    {
      group: 'Dataset 1',
      value: 320
    },
    {
      group: 'Dataset 1',
      value: 330
    },
    {
      group: 'Dataset 1',
      value: 320
    },
    {
      group: 'Dataset 1',
      value: 330
    },
    {
      group: 'Dataset 1',
      value: 340
    },
    {
      group: 'Dataset 1',
      value: 350
    },
    {
      group: 'Dataset 1',
      value: 300
    },
    {
      group: 'Dataset 1',
      value: 400
    },
    {
      group: 'Dataset 1',
      value: 420
    },
    {
      group: 'Dataset 1',
      value: 430
    },
    {
      group: 'Dataset 1',
      value: 450
    }
  ],
  axesOptions: {
    bottom: {
      scaleType: "linear",
      title: 'Hora',
      mapsTo: 'value',
      bins: 24,
      ticks: {
        values: [200, 250, 300, 350, 400, 450, 500, 550]
      }
    },
    left: {
      title: 'Valor',
      scaleType: 'lineal',
      binned: true
    }
  }
}

Histogram.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  axesOptions: PropTypes.object,
  legendOptions: PropTypes.object,
}

export default Histogram