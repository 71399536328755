import Typography from "../../components/Basics/Typography";
import { Stack } from "@carbon/react";
import { CardComponent } from "../../components/Layouts/Card.styles";
import Card from '../../components/Layouts/Card';
import routes from "../../config/settings/routes";

const CompleteForm = () => {
    return <CardComponent width="100%">
        <Card justifyContent='flex-start'><Stack gap={5}>
            <Typography className="cds--type-fluid-heading-05">Como completar un mantenimiento</Typography>
            <Typography className="cds--type-fluid-heading-03">
                Como utilizar el formulario de salida de mantenimiento
            </Typography>
            <Typography className="cds--type-body-compact-02">
                Para completar y registrar los detalles de duración del mantenimiento, los mantenimientos cuentan con un formulario para marcar la salida de mantenimiento. Este formulario es una herramienta pensada en facilitar el registro de mantenimientos en un solo sistema que pueda a ayudar a conocer los mantenimientos ocurridos anteriormente.<br />
                <br />
                Pasos a Seguir:<br />
                1. Ingresar al submódulo de <a className="document-link" href={routes.maintenanceOngoing}>Mantenimientos en Curso</a>.<br />
                2. Identificar la fila en la tabla que tiene los detalles del mantenimiento que deseamos completar.<br />
                3. Expandir la fila utilizando la flecha a la izquierda.<br />
                4. Abrir el 'formulario de Salida Mantenimiento' y rellenar los campos necesarios para la salida.<br />
                5. Revisar la información y confirmar salida a mantenimiento.<br />
                6. El mantenimiento completado puede ser visto en el submódulo <a className="document-link" href={routes.maintenanceCompleted}>Mantenimientos Completados</a> en caso de ser necesario revisar los detalles, la duración o la fecha.
            </Typography>
            <video width="880px" height="437px" controls autoPlay>
                <source src="/assets/videos/complete_form.mp4" type="video/mp4" />
            </video>
        </Stack>
        </Card>
    </CardComponent>
}

export default CompleteForm;