import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  ALLOW_ONLY_NUMBERS,
  CLEAN_NUMBERS,
  CURRENCY_DOTS,
  ESCAPE_HTML,
  ESCAPE_JS,
} from '../../config/settings/regexes';
import { cleanDNI } from './helpers';
import moment from 'moment-timezone';

/**
 * To decorate the given value by the date format pattern.
 */
export const formatDate = (value, pattern = 'dd/MM/yyyy HH:mm') =>
  format(new Date(value), pattern, { locale: es });

/**
 * To decorate the given value to a percentage format.
 */
export const formatPercentage = (value, decimals = 2) => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return value;
  }

  const number = Number.parseFloat(value);
  if (decimals > 0 && number.toString().includes('.')) {
    decimals = Math.min(number.toString().split('.')[1].length, decimals);
  } else {
    decimals = 0;
  }

  return `${number.toFixed(decimals).replace('.', ',')}%`;
};

/**
 * To permit only numbers.
 */
export const formatToAllowOnlyNumbers = value =>
  ALLOW_ONLY_NUMBERS.test(value) ? value : value.replace(CLEAN_NUMBERS, '');

/**
 * Format an amount to Chilean Pesos.
 */
export const formatPrice = value => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    currency: 'CLP',
    style: 'currency',
  });

  return numberFormat.format(value);
};

/**
 * To format to HTML encode.
 */
export const formatHTMLEncode = value =>
  String(value)
    .replace(ESCAPE_JS, '')
    .replace(ESCAPE_HTML, character => `&#${character.charCodeAt(0)};`);

/**
 * To format to unicode encode.
 */
export const formatUnicodeEncode = value =>
  String(value)
    .replace(ESCAPE_JS, '')
    .replace(
      ESCAPE_HTML,
      character =>
        `\\u${`0000${character.charCodeAt(0).toString(16)}`.slice(-4)}`
    );

/**
 * To format by the given regular expression.
 */
export const formatRegEx = regex => value => {
  if (typeof value !== 'string') {
    return value;
  }

  return value.replace(regex, '');
};

/**
 * To decorate the given value to a chilean DNI format.
 * The type parameter can have the values 1 or 2.
 * Type 1 means the given value is formatted as 11.111.111-1.
 * Type 2 means the given value is formatted as 11111111-1.
 */
export const formatDNI = (value, type = 1) => {
  const updatedValue = cleanDNI(value?.toString());

  if (updatedValue.length === 0) return updatedValue;
  if (updatedValue.length === 1) return updatedValue;

  const dv = updatedValue.substring(updatedValue.length - 1);
  const dni = updatedValue.substring(0, updatedValue.length - 1);

  return type === 1
    ? `${dni.replace(CURRENCY_DOTS, '$1.')}-${dv}`
    : `${dni}-${dv}`;
};

/**
 * To decorate the given value to a number with a thousands separator.
 */
export const formatNumberWithThousandSeparator = value => {
  if (value.toString().includes('.')) {
    const integerPart = value.toString().split('.')[0];
    const decimalPart = value.toString().split('.')[1];
    return `${integerPart.replace(CURRENCY_DOTS, '$1.')},${decimalPart}`;
  }
  return value
    .toString()
    .replace('.', ',')
    .replace(CURRENCY_DOTS, '$1.');
};

/**
 * List of Trucks for now, this should be from an endpoint but we don't have all trukcs
 */
export const truckList = {
  Total: 'Sin Registro',
  '865326062139079': 'PKKH91',
  '865326062154235': 'TBGJ11',
  '865326062152387': 'PKKH92',
  '865326062139061': 'TBGJ10',
  '865326062154102': 'PKKH89',
  '865326062138949': 'TCFL74',
  '865326062154276': 'TCFL31',
  '865326062153997': 'TCFL75',
  '865326062153369': 'TCFL78',
  '865326062153013': 'TCFL76',
  '865326062152676': 'TCFL77',
  '865326062157741': 'TCFL80',
  '865326062170215': 'SWKV45',
  '865326062156354': 'SHVR87',
  '865167066830143': 'SJLY12',
  '865326062158863': 'SHVT50',
  '865167066829517': 'SHVT51',
  '865326062153674': 'SWJZ82',
  '865326062153468': 'SWJZ81',
  '865326062153112': 'TCFL67',
  '865167066828204': 'SWKV42',
  '865167062435566': 'SWKV43',
  '865326062170348': 'SWKV44',
  '865167066824674': 'SZBK46',
  '865326062159341': 'SZBK48',
  '865326062158814': 'SZBS56',
  '865167066828634': 'SZBK49',
  '865326062124717': 'SZBK47',
  '865326062158624': 'SZBS58',
  '865167066886616': 'SZBS57',
  '865326062157758': 'SZBK28',
  '865326062144798': 'SZBX31',
  '865167066829863': 'SZBX33',
  '865326062124782': 'TCSD22',
  '865326062125300': 'TCSC81',
  '865326062158905': 'TBGK79',
};

export const getValueFromList = (list, key) => {
  if (list.hasOwnProperty(key)) {
    return list[key];
  }

  return list['Total'];
};

export const convertUTCToLocalTime = utcTimestamp => {
  // Ensure the timestamp is treated as UTC by appending a 'Z' (Zulu time) if it's not present
  const timestamp = utcTimestamp.endsWith('Z')
    ? utcTimestamp
    : `${utcTimestamp}Z`;

  // Parse the UTC timestamp with moment, specifying the precision
  const date = moment(timestamp);

  // Convert to 'America/Santiago' timezone
  const localDate = date.tz('America/Santiago');

  // Format the date and time, including all fractional seconds
  // 'SSS' is the formatter for milliseconds, if you need more precision you can add more S's
  // However, moment supports only up to milliseconds (3 S's) precision
  return localDate.format('YYYY-MM-DD HH:mm:ss.SSS');
};

const keyShorteningDictionary = {
  'Estación de bombeo': 'Estación Bombeo',
  'Camino Principal Pretil': 'Principal Pretil',
  'Pretil Principal T1': 'Pretil T1',
  'Relleno MO 1': 'Relleno MO_1',
};

// Function to shorten item.key based on the dictionary
export const shortenFront = key => {
  const words = key.split(' ');
  if (words.length === 3) {
    const shortenedKey = keyShorteningDictionary[key];
    if (shortenedKey) {
      return shortenedKey;
    }
  }
  return key;
};
