import { orderBy } from 'lodash-es';

export const useSorting = (rowData, headerData) => {
  const headers = headerData.map(({ header, key }) => ({ header, key }));
  // Apply sorting algorithms beforehand because
  // Carbon doesn't have this feature by default at this moment.
  const sortable = headerData.filter(header => {
    const isSortable = (header?.sortDirection ?? 'NONE') !== 'NONE';
    const isDefaultSortable = header?.isDefaultSortable ?? false;
    return isSortable && isDefaultSortable;
  });
  const rows = orderBy(
    rowData,
    sortable.map(data => data.key),
    sortable.map(data => (data?.sortDirection ?? 'NONE').toLowerCase())
  );
  return { headers, rows };
};
