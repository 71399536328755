import { useSelector, useDispatch } from 'react-redux';
import { Button, Stack, Dropdown } from "@carbon/react";
import { CardRow, CardComponent } from "../../../components/Layouts/Card.styles";
import { DataTable, DeliveryTruck } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import ExpansionTable from "../../../components/Basics/ExpansionTable.container";
import { Div } from "../../../components/Basics/Div.styles";
import { useRef, useState, useEffect } from "react";
import { onGetTrucksThunk } from './Machine.actions';
import { TopFilter } from '../../Productivity/Idle/Idle.styles';
import MachineRecordModal from './MachineRecordModal.container';
import { useSorting } from '../../../modules/hooks/sorting';
import LoadingContent from '../../../components/Animations/LoadingContent';

const items = [{
    id: 'carguio',
    text: 'Carguío',
},
{
    id: 'transfer',
    text: 'Traslado',
},
{
    id: 'compact',
    text: 'Compactado'
},
{
    id: 'support',
    text: 'Soporte'
}];

const INITIAL_SELECTED_ITEM = items[0];

const MachinesContainer = () => {
    const dispatch = useDispatch()
    const { error, loading, data } = useSelector(state => state.machines)
    const buttonRef = useRef();
    const [open, setOpen] = useState(null);
    const [selectedItem, setSelectedItem] = useState(INITIAL_SELECTED_ITEM);

    useEffect(() => {
        if (!loading) {
            return
        }
        dispatch(onGetTrucksThunk())
    }, [dispatch, loading])

    const headerData = [
        {
            header: 'Proceso de Trabajo',
            key: 'type',
        },
        {
            header: 'Patente',
            key: 'truckPatent',
            sortDirection: "ASC",
            isDefaultSortable: true
        },
        {
            header: 'Tipo de Equipo',
            key: 'truck',
        },
        {
            header: 'Modelo',
            key: 'modelo',
        },
        {
            header: 'Marca',
            key: 'marca',
        },
        {
            header: 'Año',
            key: 'year',
        }
    ];
    const { headers, rows: prevRows } = useSorting(data, headerData);
    const rows = prevRows.filter(item => item.type === selectedItem.text);

    return (
        <>
            {loading && !error && (
                <LoadingContent />
            )}
            {!loading && error && (
                <CardComponent width="10%">
                    <Typography className='cds--type-heading-compact-01'>
                        No hay registros de camiones
                    </Typography>
                </CardComponent>
            )}
            {!loading && !error && (
                <>
                    <Stack gap={3}>
                        <TopFilter>
                            <Dropdown
                                id="default"
                                titleText="Filtrar por Proceso"
                                initialSelectedItem={INITIAL_SELECTED_ITEM}
                                label="Seleccionar Proceso"
                                items={items}
                                itemToString={item => item ? item.text : ''}
                                helperText="Los equipos son agrupados según su rol en la operación."
                                onChange={({ selectedItem }) => setSelectedItem(selectedItem)}
                            />
                        </TopFilter>
                        <Card justifyContent='flex-start' >
                            <DataTable size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                EQUIPOS - TABLA DE DATOS
                            </Typography>
                        </Card>
                        <CardRow width='inherit'>
                            <Card width='100%' padding="0">
                                <ExpansionTable rows={rows} headers={headers} headerData={headerData}
                                    detailsFunc={(row) =>
                                        <>
                                            <Div padding="4px">
                                                <span>Información adicional</span>
                                                <Card display="flex" alignItems="flex-start" width="100%">
                                                    <Button style={{ minWidth: "100%" }} ref={buttonRef} kind="danger--ghost" renderIcon={DeliveryTruck} onClick={() => setOpen(row)}>Ver Ficha Equipo</Button>
                                                    {/* <Button style={{ minWidth: "50%" }} as="a" href={routes.monitoringCan} kind="danger--ghost" renderIcon={DeliveryTruck}>Ver Máquina en Dashboard CAN</Button> */}
                                                </Card>
                                            </Div>
                                        </>
                                    } />
                            </Card>
                        </CardRow>
                    </Stack>
                    {/* <MachineDetails open={open} setOpen={setOpen} ref={buttonRef} /> */}
                    <MachineRecordModal open={open} setOpen={setOpen} ref={buttonRef} />
                </>
            )}

        </>
    )
};

export default MachinesContainer;