import styled from 'styled-components';

// Estilo para el contenedor del RadarChart
export const RadarChartContainer = styled.div`
  width: 100%;  // Usa todo el ancho disponible
  height: 100%;  // Usa todo el alto disponible
  display: flex;
  justify-content: center;
  align-items: center;

  .bx--chart {
    width: 100%;  // Asegura que el gráfico ocupe todo el ancho
    height: 100%; // Asegura que el gráfico ocupe todo el alto
  }
`;
