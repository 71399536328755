// A best practice for URL structures is to define
// long-names with dash instead of underscores:
// https://developers.google.com/search/docs/crawling-indexing/url-structure
const routes = {
  home: '/',
  root: '/dashboard',
  login: '/login',
  dashboard: '/dashboard',
  documentation: '/documentation',
  documentationCreateGeofence: '/documentation/general/create-geofence',
  documentationCollectTeamData: '/documentation/security/collect-team-data',
  documentationUpdateLicense: '/documentation/security/update-license',
  documentationEnterTruck: '/documentation/maintenance/enter-truck',
  documentationCompleteForm: '/documentation/maintenance/complete-form',
  maintenance:
    '/maintenance/:type(maintenance-initial|preventive|predictive|ongoing|completed|permanence)',
  maintenanceInitial: '/maintenance/maintenance-initial',
  maintenancePreventive: '/maintenance/preventive',
  maintenancePredictive: '/maintenance/predictive',
  maintenanceOngoing: '/maintenance/ongoing',
  maintenanceCompleted: '/maintenance/completed',
  maintenancePermanence: '/maintenance/permanence',
  monitoring:
    '/monitoring/:type(monitoring-initial|map|performance|last-shift|charging|transfer-b57-discharging|discharging|transfer-b57|idle|effective-time-driver|punctures|composting|roller|can|geofenceg1|lowvelocity)',
  monitoringInitial: '/monitoring/monitoring-initial',
  monitoringmap: '/monitoring/map',
  monitoringPerf: '/monitoring/performance',
  monitoringLastShift: '/monitoring/last-shift',
  monitoringCharg: '/monitoring/charging',
  monitoringTUnload: '/monitoring/transfer-b57-discharging',
  monitoringDischarging: '/monitoring/discharging',
  monitoringTLoad: '/monitoring/transfer-b57',
  monitoringIdle: '/monitoring/idle',
  monitoringEffectiveTimeDriver: '/monitoring/effective-time-driver',
  monitoringPunctures: '/monitoring/punctures',
  monitoringComposting: '/monitoring/composting',
  monitoringRoller: '/monitoring/roller',
  monitoringCan: '/monitoring/can',
  monitoringGeofenceG1: '/monitoring/geofenceg1',
  monitoringLowVelocity: '/monitoring/lowvelocity',
  productivity:
    '/productivity/:type(productivity-initial|historic|daily|weekly|monthly|idle|punctures|asarco|speed|kpitree|breakfast|speedgeofence|dailyturn)',
  productivityInitial: '/productivity/productivity-initial',
  productivityDaily: '/productivity/daily',
  productivityWeekly: '/productivity/weekly',
  productivityHistoric: '/productivity/historic',
  productivityMonthly: '/productivity/monthly',
  productivityIdle: '/productivity/idle',
  productivityFlatTire: '/productivity/punctures',
  productivityAsarco: '/productivity/asarco',
  productivitySpeed: '/productivity/speed',
  producivityKpiTree: '/productivity/kpitree',
  productivityBreakfast: '/productivity/breakfast',
  productivitySpeedGeofence: '/productivity/speedgeofence',
  producivityDailyTurn: '/productivity/dailyturn',
  security:
    '/security/:type(security-initial|truck-approach|velocity-violation|velocity-historic|can|fatigue|sensors-count|brake-retarder|train)',
  securityInitial: '/security/security-initial',
  securityTruckApproach: '/security/truck-approach',
  securityVelocityViolation: '/security/velocity-violation',
  securityVelocityHistoric: '/security/velocity-historic',
  securityCan: '/security/can',
  securityFatigue: '/security/fatigue',
  securitySensorsCount: '/security/sensors-count',
  securityBrake: '/security/brake-retarder',
  securityTrain: '/security/train',
  records:
    '/records/:type(records-initial|driver|machines|geofences|routes|gates)',
  recordsInitial: '/records/records-initial',
  recordsDriver: '/records/driver',
  recordsMachines: '/records/machines',
  recordsGeofenches: '/records/geofences',
  recordsRoutes: '/records/routes',
  recordsGates: '/records/gates',
  database:
    '/database/:type(database-initial|gps|canbus|productivity|hourmeter)',
  databaseInitial: '/database/database-initial',
  databaseGps: '/database/gps',
  databaseCanBus: '/database/canbus',
  databaseProductivity: '/database/productivity',
  databaseHourmeter: '/database/hourmeter',
  users: '/users/:type(users-initial|recomendations)',
  usersInitial: '/users/users-initial',
  usersRecomendations: '/users/recomendations',
  map: '/map',
  fronts: '/fronts',
  userManual: '/user-manual',
  notifications: '/notifications',
  diagram: '/diagram',
  devices: '/devices',
  passwordRecovery: '/recover-password',
};

export default routes;
