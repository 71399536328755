import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { onForgotPasswordThunk } from './Authentication.actions'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { checkErrorRequired } from '../../modules/errors/validations'
import { Modal, TextInput } from '@carbon/react'
import Typography from '../../components/Basics/Typography'

/**
 * The change password container.
 */

const ForgotPasswordModal = ({ button, open, setOpen }) => {
  const dispatch = useDispatch()
  const { loading, error, resetMessage } = useSelector(state => state.auth)
  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const canNotSubmit = useCallback(() => {
    return usernameError !== ''
  }, [usernameError])
  const disabledSubmit = canNotSubmit()

  const submitEmail = () => {
    return dispatch(onForgotPasswordThunk(usernameValue))
  }
  return (
    <Modal onRequestSubmit={submitEmail} danger modalHeading='Por favor escriba su correo' launcherButtonRef={button} primaryButtonText="Ingresar" open={open} onRequestClose={() => setOpen(false)}>
      <TextInput labelText='Correo Electronico' placeholder='Correo' id='reset-mail' onChange={handleUsernameChange} value={usernameValue} invalid={Boolean(usernameError)} invalidText={usernameError} />
      {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
        <Typography>
          Nombre de usuario no encontrado
        </Typography>
      )}

      {!loading && error && error.reason === 'USERNAME_CANNOT_BE_EMPTY' && (
        <Typography>
          Por favor ingrese su nombre de usuario
        </Typography>
      )}

      {!loading && error && error.reason === 'LIMIT_EXCEEDED_EXCEPTION' && (
        <Typography>
          Limite excedido, por favor inténtelo de nuevo más tarde
        </Typography>
      )}

      {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
        <Typography>
          Lo sentimos, estamos presentando problemas con nuestro servidor
        </Typography>
      )}
      {!loading && !error && (
        <Typography>
          {resetMessage}
        </Typography>
      )}
    </Modal>
  )
}

export default ForgotPasswordModal