import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "../../../components/Basics/Iframe.styles";
import InformationContainer from "../../../components/Basics/Information.container";

const Fatigue = () => {
    return (
        <Stack gap={3}>
            <CardComponent width="100%">
                <Card justifyContent='flex-start' >
                    <HeatMap size={18} />
                    <Typography className='cds--type-heading-compact-01'>
                        FATIGA Y SOMNOLENCIA - MAPA DE CALOR
                    </Typography>
                </Card>
                <CardRow width='inherit'>
                    <Card width='100%' padding="0">
                        <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/c356ef3b-9172-495a-ba56-c8f4b4a2593a/fatiga-y-somnolencia?orgId=1&var-patente=All&var-intervalo=1d&var-advertencia=All&var-geocerca=All&from=1725547788857&to=1725569388858&kiosk" width="100%" height="100%" />
                    </Card>
                </CardRow>
            </CardComponent>
        </Stack>
    )
};

export default Fatigue;