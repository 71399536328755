import Typography from "../../components/Basics/Typography";
import { Stack } from "@carbon/react";
import { CardComponent } from "../../components/Layouts/Card.styles";
import Card from '../../components/Layouts/Card';
import routes from "../../config/settings/routes";

const EnterTruck = () => {
    return <CardComponent width="100%">
        <Card justifyContent='flex-start'><Stack gap={5}>
            <Typography className="cds--type-fluid-heading-05">Como ingresar un equipo a mantenimiento</Typography>
            <Typography className="cds--type-fluid-heading-03">
                Como utilizar el formulario de ingreso a mantenimiento
            </Typography>
            <Typography className="cds--type-body-compact-02">
                Para simplificar la gestión de mantenimientos en la plataforma, el módulo de <a className="document-link" href={routes.maintenanceInitial}>Mantenimientos</a> incluye un formulario específico para el ingreso a mantenimiento. Este formulario permite a los usuarios especificar detalles del mantenimiento.<br />
                <br />
                Pasos a Seguir:<br />
                1. Ingresar al módulo de Mantenimientos.<br />
                2. Abrir el 'Formulario Ingreso a mantenimiento'.<br />
                3. Rellenar los campos del formulario.<br />
                4. Verificar la información e ingresar a mantenimiento.
            </Typography>
            <video width="880px" height="437px" controls autoPlay>
                <source src="/assets/videos/enter_truck.mp4" type="video/mp4" />
            </video>
        </Stack>
        </Card>
    </CardComponent>
}

export default EnterTruck;