import { makeActionCreator } from '../../../config/store/utils';
import { batch } from 'react-redux';
import { getTrucks } from '../../../services/recordService';

export const GET_TRUCKS = 'GET_TRUCKS';
export const GET_TRUCKS_ERROR = 'GET_TRUCKS_ERROR';
export const GET_TRUCKS_SUCCESS = 'GET_TRUCKS_SUCCESS';
export const onGetTrucks = makeActionCreator(GET_TRUCKS);
export const onGetTrucksError = makeActionCreator(GET_TRUCKS_ERROR, 'payload');
export const onGetTrucksSuccess = makeActionCreator(
  GET_TRUCKS_SUCCESS,
  'payload'
);
export const onGetTrucksThunk = () => async dispatch => {
  dispatch(onGetTrucks());
  try {
    const { data } = await getTrucks();
    const trucks = data.reduce((acc, current, index) => {
      let newObject = { ...current, id: `${index + 1}`, year: current['año'] };
      acc.push(newObject);
      return acc;
    }, []);

    return batch(() => {
      dispatch(
        onGetTrucksSuccess({
          data: trucks,
        })
      );
    });
  } catch (error) {
    return batch(() => {
      dispatch(onGetTrucksError({ error }));
    });
  }
};
