import React from 'react'
import Card from '../../components/Basics/InfoCard.container'
import Link from '../../components/Basics/Link.container';
import { Root, Separator } from './Dashboard.styles'
import routes from '../../config/settings/routes';
import { SatelliteRadar, Security, ToolKit, ScisControlTower, Identification, DataBase, Book, MapBoundary, IotPlatform, GatewayUserAccess } from '@carbon/icons-react';
import Typography from '../../components/Basics/Typography';
import { Stack } from "@carbon/react";
import { g100 } from '@carbon/themes';
import DisableByGroup from '../../components/Security/DisableByGroup';

const DashboardBody = () => {
  return (
    <Stack gap={3}>
      <Typography
        className='cds--type-body-01'
        color={g100.textHelper}
        justify='space-between'
        display='flex'
        margin="8px 0 0 0"
      >
        Módulos Principales
      </Typography>
      <Separator />
      <Root>
        <DisableByGroup type="USERS-INITIAL">
          {(isDisabled) => (
            <Link route={routes.usersInitial} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<GatewayUserAccess size={32} />}
                title='Dashboard Usuario'
                footer='Módulo para la revisión del estado de la plataforma, monitoreo de tareas y registro de notificaciones.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <DisableByGroup type="MONITORING-INITIAL">
          {(isDisabled) => (
            <Link route={routes.monitoringInitial} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<SatelliteRadar size={32} />}
                title='Mapa y Monitoreo en Tiempo Real'
                footer='Módulo para el monitoreo en tiempo real del rendimiento y la flota.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <DisableByGroup type="SECURITY-INITIAL">
          {(isDisabled) => (
            <Link route={routes.securityInitial} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<Security size={32} />}
                title={<>Seguridad<br /><br /></>}
                footer='Módulo para el registro de transgresiones de velocidad.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <DisableByGroup type="MAINTENANCE-INITIAL">
          {(isDisabled) => (
            <Link route={routes.maintenanceInitial} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<ToolKit size={32} />}
                title={<>Mantenimiento<br /><br /></>}
                footer='Gestiona los mantenimientos de la flota, registra el ingreso y salida de mantenimiento y consulta mantenimientos preventivos.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <DisableByGroup type="PRODUCTIVITY-INITIAL">
          {(isDisabled) => (
            <Link route={routes.productivityInitial} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<ScisControlTower size={32} />}
                title='Producción y Reportabilidad'
                footer='Módulo Histórico para el registro del rendimiento productivo y generación de reportes.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <DisableByGroup type="RECORDS-INITIAL">
          {(isDisabled) => (
            <Link route={routes.recordsInitial} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<Identification size={32} />}
                title={<>Fichas<br /><br /></>}
                footer='Módulo Organizador para el Registro de Rendimiento por Conductores, Geocercas, Máquinas y Rutas.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <DisableByGroup type="RECORDS-INITIAL">
          {(isDisabled) => (
            <Link route={routes.databaseInitial} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<DataBase size={32} />}
                title={<>Bases de Datos<br /><br /></>}
                footer='Módulo de Acceso a Bases de Datos de GPS, Productividad, CANbus y Horómetro.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
      </Root>
      <Typography
        className='cds--type-body-01'
        color={g100.textHelper}
        justify='space-between'
        display='flex'
        margin="8px 0 0 0"
      >
        Herramientas
      </Typography>
      <Separator />
      <Root>
        <DisableByGroup type="GEOFENCE-MAP">
          {(isDisabled) => (
            <Link route={routes.map} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<MapBoundary size={32} />}
                title='Geocercas Limites de Velocidad'
                footer='Visualizador que permite a los usuarios ver de manera clara y visual las geocercas en un mapa y añadir geocercas a través de un formulario.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <DisableByGroup type="GEOFENCE-MAP">
          {(isDisabled) => (
            <Link route={routes.fronts} display='inline-flex' disabled={isDisabled}>
              <Card
                icon={<MapBoundary size={32} />}
                title='Geocercas Frentes de Carga y Descarga'
                footer='Visualizador que permite a los usuarios ver de manera clara y visual las geocercas en un mapa y añadir geocercas a través de un formulario.'
                disabled={isDisabled}
              />
            </Link>
          )}
        </DisableByGroup>
        <Link route={routes.userManual} display='inline-flex'>
          <Card
            icon={<Book size={32} />}
            title={<>Manual de Usuario<br /><br /></>}
            footer='El Manual de Usuario proporciona una guía detallada sobre cómo utilizar todas las funciones y características de la plataforma.'
          />
        </Link>
      </Root>
    </Stack>
  )
}

export default DashboardBody
