import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DatePicker, DatePickerInput, Modal, Select, SelectItem, Stack, TextInput, TimePicker, ComboBox, MultiSelect, FilterableMultiSelect, RadioButtonGroup, RadioButton, SelectSkeleton, Tabs, Tab, TabList, TabPanels, TabPanel, FormItem, FileUploaderDropContainer, FileUploader, FileUploaderItem, TableContainer, TableHead, TableRow, TableHeader, TableBody, TableCell, Table, DataTable, DataTableSkeleton } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { DataTable as IconTable, Pen, Information, Xls } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import OngoingTable from "./OngoingTable.container";
import { useInput, useDatePicker, useSelectComboBox, useMultiSelect, useRadio } from '../../../components/Inputs/Inputs.hooks'
import { Div } from "../../../components/Basics/Div.styles";
import {
  checkErrorRequired
} from '../../../modules/errors/validations'
import { formatDate } from '../../../modules/utils/formatters';
import { onGetMaintenanceThunk, onSendMaintenanceThunk, onSendMaintenanceExcelThunk } from './Ongoing.actions';
import LoadingContent from '../../../components/Animations/LoadingContent';
import { onGetTrucksThunk } from '../../Records/Machines/Machine.actions';
import FilterComboBox from '../../../components/Basics/ComboBox';
import AreaText from '../../../components/Basics/AreaText.container';
import { MultiSelectStyled } from '../../../components/Inputs/FilterableMultiSelect.styles';
import { StyledComboBox } from '../../../components/Basics/ComboBox.styles';
import * as XLSX from 'xlsx';
import InformationContainer from '../../../components/Basics/Information.container';

export const EntranceForm = ({ button, open, setOpen }) => {
  const dispatch = useDispatch()
  const [timeIn, setTimeIn] = useState('');
  const [isValidTimeIn, setIsValidTimeIn] = useState(false);
  const inPattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/
  const [timeOut, setTimeOut] = useState('');
  const [isValidTimeOut, setIsValidTimeOut] = useState(false);
  const outPattern = /^\d{1,}:[0-5]\d$/
  const { sending, submitError, submitSuccess, sendingExcel, excelSubmitError, excelSubmitSuccess } = useSelector(state => state.maintenance);
  const { error, loading, data } = useSelector(state => state.machines)
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [truckType, setTruckType] = useState('');

  const talleres = [
    { id: 'taller_talabre', text: 'TALLER TALABRE IFF' },
    { id: 'trek_rental', text: 'TREK RENTAL' },
    { id: 'volvo', text: 'VOLVO' },
    { id: 'tassara', text: 'TASSARA' },
    { id: 'cbs', text: 'CBS' },
    { id: 'valko', text: 'VALKO' },
    { id: 'getram', text: 'GETRAM' },
    { id: 'anterra', text: 'ANTERRA' },
    { id: 'conpax', text: 'CONPAX' },
    { id: 'salfa_rental', text: 'SALFA RENTAL' },
    { id: 'verschaerental', text: 'VERSCHAERENTAL' },
    { id: 'maestranza', text: 'MAESTRANZA' }
  ];

  const entryReasons = [
    { id: 'mantenimiento_programado', text: 'MANTENCIÓN PROGRAMADA' },
    { id: 'engrases_y_lubricaciones', text: 'ENGRASES Y LUBRICACIONES' },
    { id: 'relleno_de_adblue', text: 'RELLENO DE ADBLUE' },
    { id: 'retorque_de_neumaticos', text: 'RETORQUE DE NEUMÁTICOS' },
    { id: 'pinchazos', text: 'PINCHAZOS' },
    { id: 'electricos_menores', text: 'ELÉCTRICOS MENORES' },
    { id: 'parachoques', text: 'PARACHOQUES' },
    { id: 'elementos_de_desgaste', text: 'ELEMENTOS DE DESGASTE' },
  ];

  const resetForm = () => {
    setTimeIn('');
    setIsValidTimeIn(false);
    handleTypeReset();
    handleTruckReset();
    handleNotTruckReset();
    handleManualTruckChange('');
    handleTallerReset();
    handleCommentChange('');
    handleReasonReset();
  };

  const handleChangeTruckSelect = (selectedItem) => {
    // If no truck is selected (i.e., clear action)
    if (!selectedItem) {
      handleTypeChange({
        "selectedItem": {
          "id": "",
          "text": ""
        }
      });
      return;
    }
    // Look for the selected truck in the data array
    const truck = data.find(truck => truck.truckPatent === selectedItem?.id);
    handleTypeChange({
      "selectedItem": {
        "id": truck.type,
        "text": truck.type
      }
    });
  };
  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetTrucksThunk())
  }, [])

  useEffect(() => {
    if (submitSuccess) {
      resetForm();
    }
  }, [submitSuccess]);

  const truckList = data.map(item => ({
    id: item.truckPatent,
    text: item.truckPatent
  }));

  const additionalTypes = [
    "Cama baja",
    "Camion de servicio",
    "Camion ampliroll",
    "Camion articulado",
    "Rampa plana 12mts"
  ];

  const uniqueTypes = new Set();
  const truckListType = data
    .filter(item => {
      if (!uniqueTypes.has(item.type)) {
        uniqueTypes.add(item.type);
        return true;
      }
      return false;
    })
    .map(item => ({
      id: item.type,
      text: item.type
    }));

  // Extend the list with additional types
  const extendedTruckListType = truckListType.concat(
    additionalTypes.map(type => ({
      id: type,
      text: type
    }))
  );
  // Date and Clock
  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: new Date(),
    errorCallbacks: [checkErrorRequired()]
  })

  const handleChangeTimeIn = (e) => {
    const value = e.target.value;
    setTimeIn(value);

    // Check if the input matches the regex pattern
    if (inPattern.test(value)) {
      setIsValidTimeIn(true);
    } else {
      setIsValidTimeIn(false);
    }
  };

  // Type and Truck patent
  const { error: truckError, value: truckValue, onChange: handleTruckChange, onReset: handleTruckReset } = useSelectComboBox({
    changeCallback: handleChangeTruckSelect,
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: typeError, value: typeValue, onChange: handleTypeChange, onReset: handleTypeReset } = useSelectComboBox({
  })

  // Type and Truck patent not registered
  const { error: truckNotError, value: truckNotValue, onChange: handleTruckNotChange, onReset: handleNotTruckReset } = useSelectComboBox({
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: tallerError, value: tallerValue, onChange: handleTallerChange, onReset: handleTallerReset } = useSelectComboBox({
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: reasonError, selectedItems: reasonValue, onChange: handleReasonChange, onReset: handleReasonReset } = useMultiSelect({
    errorCallbacks: [checkErrorRequired()]
  });

  const { error: commentError, value: commentValue, onChange: handleCommentChange } = useInput({
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: manualTruckError, value: manualTruckValue, onChange: handleManualTruckChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const { value: radioValue, onChange: handleRadioChange } = useRadio()

  const { value: radioValueTruck, onChange: handleRadioChangeTruck } = useRadio({
    initialValue: 'registered'
  })

  const handleRadioChangeReset = (value) => {
    handleRadioChangeTruck(value)
    setSelectedTruck(null);
    setTruckType('');
    handleTruckReset();
    handleNotTruckReset();
  }

  const handleSubmit = () => {
    const _date = formatDate(dateValue, 'yyyy-MM-dd') + `T${timeIn}:00Z`;

    const params = {
      machinePatent: radioValueTruck === 'registered' ? truckValue.text : manualTruckValue, // Add truck String
      eventTime: _date, // Add date string
      timeIn: timeIn, // Add time string
      reasons: reasonValue, // Add reasons array
      workshop: tallerValue.text, // Add workshop value
      maintenanceType: radioValue, // Add the radio value for type
      comment: commentValue && commentValue.trim() ? commentValue : 'Sin Comentarios', // Optional comment
      truckType: radioValueTruck === 'registered' ? typeValue.text : truckNotValue.text
    };
    console.log(params)
    dispatch(onSendMaintenanceThunk(params));
  };

  const canNotSubmit = useCallback(() => {
    if (!dateValue) {
      return true;
    }

    // Check if truckValue is valid when 'registered'
    if (radioValueTruck === 'registered') {
      if (!truckValue || !truckValue.text || !truckValue.text.trim()) {
        return true;
      }
    }

    // Validation for 'notRegistered' trucks
    if (radioValueTruck === 'notRegistered') {
      // Ensure manual truck value is a valid string
      if (!manualTruckValue || typeof manualTruckValue !== 'string' || !manualTruckValue.trim()) {
        return true;
      }
      // Ensure truckNotValue is a valid string
      if (!truckNotValue || typeof truckNotValue.text !== 'string' || !truckNotValue.text.trim()) {
        return true;
      }
    }

    // Ensure tallerValue is a string and trim it
    if (!tallerValue || (typeof tallerValue === 'string' && !tallerValue.trim())) {
      return true;
    }

    if (!isValidTimeIn) {
      return true;
    }

    if (!Array.isArray(reasonValue) || reasonValue.length === 0) {
      return true;
    }

    if (!radioValue || !radioValue.trim()) {
      return true;
    }

    return false;
  }, [dateValue, truckValue, manualTruckValue, truckNotValue, isValidTimeIn, tallerValue, reasonValue, radioValue]);

  const disabledSubmit = canNotSubmit()

  const getLoadingStatus = () => {
    if (submitError) {
      return 'error'
    }
    if (sending) {
      return 'active'
    }
    return 'inactive'
  }

  const getLoadingStatusV2 = () => {
    if (submitError || excelSubmitError) {
      return 'error';
    }
    if (sending || sendingExcel) {
      return 'active';
    }
    return 'inactive';
  };

  const bottomMargin = '0 0 20px'
  const filterItems = menu => {
    return menu?.item?.text
      ?.toLowerCase()
      .includes(menu?.inputValue?.toLowerCase());
  };
  const [file, setFile] = useState(null);
  const [fileError, setError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true); // Track loading state for the table
  const [fileUploaded, setFileUploaded] = useState(false);

  const convertExcelDate = (serial) => {
    if (!serial || typeof serial !== 'number') return "No Fecha"; // Handle non-number cases gracefully

    const excelStartDate = new Date(Date.UTC(1899, 11, 30)); // Excel's start date
    const date = new Date(excelStartDate.getTime() + serial * 86400000); // Add days in milliseconds
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getUTCFullYear().toString().slice(-2); // Take the last 2 digits for YY format

    return `${day}-${month}-${year}`;
  };

  const convertExcelTime = (serial) => {
    if (serial === undefined || serial === "No Hora" || serial === "") return ""; // Handle missing or invalid times
    const hours = Math.floor(serial * 24);
    const minutes = Math.floor((serial * 24 - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`; // Returns HH:MM
  };

  const readExcelFile = async (uploadedFile) => {
    setFileUploaded(true);
    setLoadingTable(true);
  
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array', cellStyles: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
  
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const rowsMeta = worksheet['!rows'] || [];
  
      const firstVisibleRow = jsonData.findIndex((row, index) => {
        return !(rowsMeta[index] && rowsMeta[index].hidden);
      });
  
      let filteredData = jsonData.slice(firstVisibleRow).filter((row, index) => {
        const isRowHidden = !!(rowsMeta[index + firstVisibleRow] && rowsMeta[index + firstVisibleRow].hidden);
  
        // Check if row is empty without adding any default values
        const isRowEmpty = row.slice(1).every(cell => !cell || cell.toString().trim() === "");
  
        return !isRowHidden && !isRowEmpty;
      });
  
      // Remove header if it exists in the first row of filteredData
      if (filteredData.length && filteredData[0][0] === "N° ORDEN DE TRABAJO ") {
        filteredData = filteredData.slice(1);
      }
  
      // Date validation function for FECHA INGRESO
      const isValidDate = (date) => {
        if (!date) return false; // Exclude empty cells
        const parsedDate = new Date(date);
        return !isNaN(parsedDate.getTime()); // Ensure the date is valid
      };
  
      // Map the filtered data, skipping rows with invalid FECHA INGRESO
      const validData = filteredData
        .filter((row) => isValidDate(row[6])) // Only include rows with valid FECHA INGRESO
        .map((row, index) => ({
          id: `${index + 1}`,
          "N° ORDEN DE TRABAJO": row[0] || "No N° ORDEN DE TRABAJO",
          "LUGAR INTERVENCIÓN": row[1] || "No Lugar de Intervención",
          "TIPO OT": row[2] || "No Tipo OT",
          "CATEGORÍA DE EQUIPO": row[3] || "No Categoría de Equipo",
          "N° INTERNO EQUIPO": row[4] || "No N° Interno de Equipo",
          "HORÓMETRO": row[5] || "No Horómetro",
          "FECHA INGRESO": convertExcelDate(row[6]), // No transformation applied, raw date value used
          "HORA INGRESO": convertExcelTime(row[7]) || "No Hora de Ingreso",
          "FECHA ENTREGA": convertExcelDate(row[8]) || "No Fecha de Entrega",
          "HORA ENTREGA": convertExcelTime(row[9]) || "No Hora de Entrega",
          "TURNO": row[10] || "No Turno",
          "DURACION INTERVENCIÓN": convertExcelTime(row[11]) || "No Duración de Intervención",
          "SISTEMA UBICACIÓN": row[12] || "No Sistema de Ubicación",
          "DESCRIPCION UBICACIÓN TECNICA": row[13] || "No Descripción de Ubicación Técnica",
          "ACTIVIDAD": row[14] || "No Actividad",
          "FALLA": row[15] || "No Falla",
          "ESTADO": row[16] || "No Estado",
          "COMPONENTE": row[17] || "No Componente"
        }));
  
      setTableData(validData);
      setLoadingTable(false);
    };
  
    reader.readAsArrayBuffer(uploadedFile);
  };
  

  const handleAddFile = (evt, { addedFiles }) => {
    if (addedFiles.length > 0) {
      const newFile = addedFiles[0];

      // Check if the file type is .xlsx
      if (newFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setError('Invalid file type. Please upload an .xlsx file.');
        return;
      }

      setFile({
        uuid: newFile.name,
        name: newFile.name,
        status: 'edit',
        file: newFile,
      });
      setError('');
      readExcelFile(newFile);  // Read the Excel file
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    setError('');
    setTableData([]); // Clear table data when file is deleted
  };

  const headers = [
    { header: 'N° ORDEN DE TRABAJO', key: 'N° ORDEN DE TRABAJO' },
    { header: 'LUGAR INTERVENCIÓN', key: 'LUGAR INTERVENCIÓN' },
    { header: 'TIPO OT', key: 'TIPO OT' },
    { header: 'CATEGORÍA DE EQUIPO', key: 'CATEGORÍA DE EQUIPO' },
    { header: 'N° INTERNO EQUIPO', key: 'N° INTERNO EQUIPO' },
    { header: 'HORÓMETRO', key: 'HORÓMETRO' },
    { header: 'FECHA INGRESO', key: 'FECHA INGRESO' },
    { header: 'HORA INGRESO', key: 'HORA INGRESO' },
    { header: 'FECHA ENTREGA', key: 'FECHA ENTREGA' },
    { header: 'HORA ENTREGA', key: 'HORA ENTREGA' },
    { header: 'TURNO', key: 'TURNO' },
    { header: 'DURACION INTERVENCIÓN', key: 'DURACION INTERVENCIÓN' },
    { header: 'SISTEMA UBICACIÓN', key: 'SISTEMA UBICACIÓN' },
    { header: 'DESCRIPCION UBICACIÓN TECNICA', key: 'DESCRIPCION UBICACIÓN TECNICA' },
    { header: 'ACTIVIDAD', key: 'ACTIVIDAD' },
    { header: 'FALLA', key: 'FALLA' },
    { header: 'ESTADO', key: 'ESTADO' },
    { header: 'COMPONENTE', key: 'COMPONENTE' },
  ];

  const handleSubmitExcelData = () => {
    const formattedData = tableData.map(row => ({
      orderNumber: row["N° ORDEN DE TRABAJO"] ? String(row["N° ORDEN DE TRABAJO"]) : "N/A",  // Ensure it's a string
      interventionLocation: row["LUGAR INTERVENCIÓN"] || "N/A",
      maintenanceType: row["TIPO OT"] || "N/A",
      equipmentCategory: row["CATEGORÍA DE EQUIPO"] || "N/A",
      internalEquipmentNumber: row["N° INTERNO EQUIPO"] || "N/A",
      horometer: row["HORÓMETRO"] ? String(row["HORÓMETRO"]) : "No Horómetro",  // Ensure it's a string
      entryDate: row["FECHA INGRESO"], // Convert Excel date serial to YYYY-MM-DD
      entryTime: row["HORA INGRESO"],  // Convert Excel time serial to HH:MM
      deliveryDate: row["FECHA ENTREGA"], // Convert Excel date serial to YYYY-MM-DD
      deliveryTime: row["HORA ENTREGA"], // Convert Excel time serial to HH:MM
      shift: row["TURNO"] || "N/A",
      interventionDuration: String(row["DURACION INTERVENCIÓN"] || "N/A"),
      locationSystem: row["SISTEMA UBICACIÓN"] || "N/A",
      technicalLocationDescription: row["DESCRIPCION UBICACIÓN TECNICA"] || "N/A",
      activity: row["ACTIVIDAD"] || "N/A",
      fault: row["FALLA"] || "N/A",
      status: row["ESTADO"] || "N/A",
      component: row["COMPONENTE"] || "N/A"
    }));
    dispatch(onSendMaintenanceExcelThunk({ maintenanceLogs: formattedData }));
  };

  return (
    <Modal
      danger
      primaryButtonDisabled={tableData.length === 0}
      launcherButtonRef={button}
      modalHeading="Ingreso a Mantenimiento"
      modalLabel="Formulario" primaryButtonText="Completar"
      open={open}
      onRequestSubmit={handleSubmitExcelData}
      onRequestClose={() => setOpen(false)}
      loadingStatus={getLoadingStatusV2()}
    >
      <p style={{
        marginBottom: '1rem'
      }}>
        Formulario para registrar el ingreso de una máquina a mantenimiento, para cálculo de disponibilidad en sistema.
      </p>
      <Tabs>
        <TabList activation="manual" aria-label="List of tabs">
          <Tab renderIcon={Xls}>Excel</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div>
              <InformationContainer description='Los filtros aplicados en Excel son también aplicados en esta vista, cualquier fila oculta de forma manual o filtro no será registrada.' />
              <p className="cds--file--label">Cargar Archivo</p>
              <p className="cds--label-description">Tipo de archivos permitidos: .xlsx.</p>

              {fileError && <p style={{ color: 'red' }}>{fileError}</p>}

              <div style={{ display: 'inline-block' }}>
                {!file ? (
                  <FileUploaderDropContainer
                    accept={['.xlsx']}
                    labelText="Arrastra y suelta un archivo .xlsx aquí o haz clic para cargarlo"
                    name=""
                    onAddFiles={handleAddFile}
                  />
                ) : (
                  <div>
                    <p>{file.name}</p>
                    <Button onClick={handleDeleteFile} kind="secondary">Remover Archivo</Button>
                  </div>
                )}
              </div>

              {/* Check for error */}
              {excelSubmitError ? (
                // Display error details if there's an error
                <div style={{ color: '#ff8389', marginTop: '20px' }}>
                  <h3>Error en la carga de datos</h3>
                  <p>Los siguientes detalles tienen errores y necesitan ser corregidos:</p>
                  <p>{excelSubmitError.response.data.error}</p>
                  <p>fila: {excelSubmitError.response.data.row}</p>
                  <ul>
                    {Object.entries(excelSubmitError.response.data.data || {}).map(([key, value]) => (
                      <li key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                // Display table or loading state if no error
                fileUploaded && loadingTable ? (
                  <DataTableSkeleton headers={headers} width="100%" />
                ) : (
                  tableData.length > 0 && (
                    <DataTable rows={tableData} headers={headers} width="100%">
                      {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        getTableContainerProps,
                      }) => (
                        <TableContainer {...getTableContainerProps()}>
                          <Table {...getTableProps()} aria-label="Excel Data Table">
                            <TableHead>
                              <TableRow>
                                {headers.map((header, i) => (
                                  <TableHeader key={i} {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map(row => (
                                <TableRow key={row.id} {...getRowProps({ row })}>
                                  {row.cells.map(cell => (
                                    <TableCell key={cell.id}>
                                      {cell.value}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </DataTable>
                  )
                )
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <p style={{
              marginBottom: '1rem'
            }}>
              Fecha y Hora de ingreso
            </p>
            <Div display="inline-flex" gap="16px">
              <DatePicker datePickerType="single" value={dateValue} invalid={true} invalidText={dateError} onChange={handleDateChange} margin='0 0'>
                <DatePickerInput
                  placeholder="dd/mm/yyyy"
                  labelText="Fecha de Ingreso"
                  id="date-picker-single"
                  size="md"
                  style={{ backgroundColor: '#262626' }}
                />
              </DatePicker>
              <TimePicker style={{ backgroundColor: '#262626' }} id="entrada" labelText="Hora de Ingreso" pattern='^(?:[01]\d|2[0-3]):[0-5]\d$' value={timeIn} invalid={!isValidTimeIn} onChange={handleChangeTimeIn} />
            </Div>
            <Div display="flex" margin="1rem 0 1rem 0" gap="8px" alignItems="center">
              <RadioButtonGroup legendText="Selección del equipo" name="radio-button-default-group-2" onChange={handleRadioChangeReset} defaultSelected={'registered'}>
                <RadioButton labelText="Registrado en la plataforma" value="registered" id="registeredTruck" />
                <RadioButton labelText="No registrado en la plataforma" value="notRegistered" id="notRegisteredTruck" />
              </RadioButtonGroup>
            </Div>
            {radioValueTruck === 'registered' ? (
              <>
                <Div display="flex" gap="8px" margin={bottomMargin}>
                  <StyledComboBox
                    items={extendedTruckListType}
                    id='truckSelect1'
                    titleText='Tipo de Equipo'
                    placeholder='Tipo de Equipo'
                    selectedItem={typeValue} // Always pass an object or null
                    itemToString={item => item ? item.text : ''}
                    onChange={handleTypeChange}
                    disabled
                  />
                </Div>
                <Div display="flex" gap="8px" margin={bottomMargin}>
                  {loading && !error && (
                    <SelectSkeleton style={{ width: '100%' }} />
                  )}
                  {!loading && error && (
                    <CardComponent width="10%">
                      <Typography className='cds--type-heading-compact-01'>
                        Error al buscar los camiones.
                      </Typography>
                    </CardComponent>
                  )}
                  {!loading && !error && (
                    <StyledComboBox
                      items={truckList}
                      id='truckSelect2'
                      titleText='Equipo que ingresa a mantenimiento'
                      onChange={handleTruckChange} // Handle truck selection
                      placeholder='Seleccionar la patente del equipo'
                      selectedItem={truckValue}
                      shouldFilterItem={filterItems}
                      itemToString={item => item ? item.text : ''}
                      warn={Boolean(truckError)}
                      warnText={truckError}
                    />
                  )}
                </Div>
              </>
            ) : (
              <>
                <Div display="flex" gap="8px" margin={bottomMargin}>
                  {loading && !error && (
                    <SelectSkeleton style={{ width: '100%' }} />
                  )}
                  {!loading && error && (
                    <CardComponent width="10%">
                      <Typography className='cds--type-heading-compact-01'>
                        Error al buscar los camiones.
                      </Typography>
                    </CardComponent>
                  )}
                  {!loading && !error && (
                    <StyledComboBox
                      items={extendedTruckListType}
                      id='truckSelect1'
                      titleText='Tipo de Equipo'
                      onChange={handleTruckNotChange}
                      placeholder='Seleccione el tipo de equipo'
                      warn={Boolean(truckNotError)}
                      warnText={truckNotError}
                      itemToString={item => item ? item.text : ''}
                      selectedItem={truckNotValue}
                    />
                  )}
                </Div>
                <Div display="flex" gap="8px" margin={bottomMargin}>
                  <TextInput data-modal-primary-focus id="text-input-3" labelText="Equipo que ingresa a mantenimiento" placeholder="Ingrese la patente de forma manual" value={manualTruckValue} onChange={handleManualTruckChange} invalid={Boolean(manualTruckError)} invalidText={manualTruckError} style={{
                    marginBottom: '1rem', backgroundColor: '#262626'
                  }} />
                </Div>
              </>
            )}
            <p style={{
              marginBottom: '1rem'
            }}>
              Detalles del mantenimiento
            </p>
            <Div display="flex" gap="8px" margin={bottomMargin}>
              <StyledComboBox
                items={talleres}
                id='taller'
                titleText='Seleccionar el Taller al que ingresa el equipo'
                placeholder='Selecciona un taller'
                onChange={handleTallerChange}
                warn={Boolean(tallerError)}
                warnText={tallerError}
                itemToString={item => item ? item.text : ''}
                selectedItem={tallerValue}
              />
            </Div>
            <Div display="flex" gap="8px" margin={bottomMargin}>
              <MultiSelectStyled
                id="carbon-multiselect-example-controlled"
                titleText="Motivos de ingreso"
                label='Seleccione motivo(s)'
                items={entryReasons}
                selectedItems={reasonValue}
                onChange={handleReasonChange}
                itemToString={(item) => (item ? item.text : '')}
                helperText='Si no encuentra un motivo en la lista, puede incluirlo en el comentario de mantenimiento'
                style={{ width: '100%' }}
                warn={Boolean(reasonError)}
                warnText={reasonError}
              />
            </Div>
            <Div display="flex" gap="8px" margin={bottomMargin}>
              <AreaText
                labelText='Comentario del mantenimiento'
                enableCounter
                maxCount={100}
                placeholder='Aquí puede ingresar un resumen del mantenimiento, este puede ser actualizado para luego incluir el estado del mantenimiento.'
                onChange={handleCommentChange}
                value={commentValue}
              />
            </Div>
            <Div display="flex" margin="1rem 0 1rem 0" gap="8px" alignItems="center">
              <RadioButtonGroup legendText="Seleccione el tipo de mantenimiento" name="radio-button-default-group" onChange={handleRadioChange}>
                <RadioButton labelText="Mantenimiento Preventivo" value="preventivo" id="preventivo" />
                <RadioButton labelText="Mantenimiento Correctivo" value="correctivo" id="correctivo" />
              </RadioButtonGroup>
            </Div>
            {submitError ? 'Error al tratar de enviar, favor pongase en contacto' : ''}
            {submitSuccess ? 'Mantenimiento registrado' : ''}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Modal>
  )
}

const Ongoing = () => {
  const button = useRef();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const { loading, error } = useSelector(state => state.maintenance)

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetMaintenanceThunk())
  }, [])

  const handleDateChangeDispatch = value => {
    handleDateChange(value)
    if (value.length === 2) {
      dispatch(onGetMaintenanceThunk(value[0], value[1]))
    }
  }

  return (
    <>
      {loading && !error && (
        <>
          <LoadingContent />
        </>
      )}
      {!loading && error && (
        <>
          <Div display="flex" gap="24px">
            <DatePicker
              datePickerType="range"
              value={dateValue}
              invalidText={dateError}
              onChange={handleDateChangeDispatch}
              dateFormat='d-m-Y'
            >
              <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText="Fecha de Inicio" size="md" />
              <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText="Fecha de Término" size="md" />
            </DatePicker>
          </Div>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              No hay registros de mantenimientos
            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && !error && (
        <>
          <Stack gap={3}>
            <Button size='sm' ref={button} renderIcon={Pen} onClick={() => setOpen(true)}>
              Formulario Ingreso a Mantenimiento
            </Button>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <DataTable size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  MANTENIMIENTOS EN CURSO - TABLA DE REGISTRO
                </Typography>
              </Card>
              <CardRow width='inherit'>
                <Card width='100%' padding="0">
                  <Div display="flex" gap="24px">
                    <DatePicker
                      datePickerType="range"
                      value={dateValue}
                      invalidText={dateError}
                      onChange={handleDateChangeDispatch}
                      dateFormat='d-m-Y'
                    >
                      <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText="Fecha de Inicio" size="md" />
                      <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText="Fecha de Término" size="md" />
                    </DatePicker>
                  </Div>
                  <OngoingTable />
                </Card>
              </CardRow>
            </CardComponent>
          </Stack>
          <EntranceForm button={button} open={open} setOpen={setOpen} />
        </>
      )}
    </>
  )
};

export default Ongoing;