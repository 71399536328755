import React from "react";
import PropTypes from 'prop-types'
import Typography from "../Basics/Typography";
import { Icon, Text, TitleDisplay } from "./Title.styles";
import { g100 } from "@carbon/themes";

/**
 * The Page Title's component
 */
const TitleText = props => {
  const { icon, margin, prefix, subtitle, title } = props

  return (
    <TitleDisplay margin={margin}>
      {icon && <Icon>
        {icon}
      </Icon>}
      {prefix && (
        <Typography className='cds--type-fluid-heading-06'>
          {prefix}
        </Typography>
      )}
      <Text>
        <Typography className='cds--type-fluid-heading-04' color={g100.textPrimary}>
          {title}
        </Typography>
        <Typography className='cds--type-fluid-heading-03' color={g100.textSecondary}>
          {subtitle}
        </Typography>
      </Text>
    </TitleDisplay>
  )
}

TitleText.propTypes = {
  icon: PropTypes.node,
  margin: PropTypes.string,
  prefix: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default TitleText