import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetReportThunk } from './DailyReport.actions'
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import { DataTable } from '@carbon/icons-react'
import Card from '../../../components/Layouts/Card'
import Typography from '../../../components/Basics/Typography'
import { FirstRow, SecondRow, ThirdRow, FourthRow, FifthRow, SixthRow, SeventhRow, EighthRow } from './DailyReport.row'
import { Stack } from '@carbon/react'
import { Button, DatePicker, DatePickerInput } from '@carbon/react'
import { Export } from '@carbon/icons-react'
import DailyReportTable from './DailyReportTable.container'
import LoadingContent from '../../../components/Animations/LoadingContent'
import { useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import { checkErrorRequired } from '../../../modules/errors/validations';
import DailyCycleReportTable from './DailyCycleTable.container'

/**
 * The Performance's container.
 */
const DailyReportContainer = () => {
  const dispatch = useDispatch()
  const { error, loading, url } = useSelector(state => state.daily)
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: [yesterday],
    errorCallbacks: [checkErrorRequired()]
  })

  const dispatchReport = (date) => {
    handleDateChange(date)
    dispatch(onGetReportThunk(date))
  }

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetReportThunk())
  }, [])

  return (
    <>
      {loading && !error && (
        <LoadingContent />
      )}
      {!loading && error && (
        <>
          <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} style={{ marginBottom: '40px' }} onChange={dispatchReport} value={dateValue} dateFormat='d-m-Y'>
            <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha del Reporte" id="daily-datepicker-error" size="md" />
          </DatePicker>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              No hay datos para este dia
            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && !error && (
        <Stack gap={3}>
          <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} onChange={dispatchReport} value={dateValue} dateFormat='d-m-Y'>
            <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha del Reporte" id="daily-datepicker" size="md" />
          </DatePicker>
          <Button size='sm' renderIcon={Export} href={url}>
            Exportar Reporte Diario
          </Button>
          <CardRow>
            <FirstRow />
          </CardRow>
          <CardRow>
            <SecondRow />
          </CardRow>
          <CardRow>
            <ThirdRow />
          </CardRow>
          <CardRow>
            <FourthRow />
          </CardRow>
          <CardRow>
            <FifthRow />
          </CardRow>
          <CardRow>
            <SixthRow />
          </CardRow>
          <CardRow>
            <SeventhRow />
          </CardRow>
          <CardRow>
            <EighthRow />
          </CardRow>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <DataTable size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  RESUMEN - TIPOS DE CICLOS
                </Typography>
              </Card>
              <CardRow width='inherit'>
                <Card width='100%' padding="0">
                  <DailyCycleReportTable />
                </Card>
              </CardRow>
            </CardComponent>
          </CardRow>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <DataTable size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  CAMIONES OPERATIVOS - TABLA DE DATOS
                </Typography>
              </Card>
              <CardRow width='inherit'>
                <Card width='100%' padding="0">
                  <DailyReportTable />
                </Card>
              </CardRow>
            </CardComponent>
          </CardRow>
        </Stack>
      )}

    </>
  )
}

export default DailyReportContainer
