import { makeActionCreator } from '../../../config/store/utils';
import { formatDate } from '../../../modules/utils/formatters';
import { getGeneralReport } from '../../../services/productivityService';

const reformatString = str => {
  return (
    str
      // Replace 'Axle' with 'Axis'
      .replace('Axle', 'Eje')
      // Split the string into parts based on underscores
      .split('_')
      // Join the parts back together, inserting a comma and space before 'Position'
      .join(' ')
      .replace('Position', ', Posición')
  );
};

export const GET_GENERAL_REPORT_FLAT = 'GET_GENERAL_REPORT_FLAT';
export const GET_GENERAL_REPORT_FLAT_ERROR = 'GET_GENERAL_REPORT_FLAT_ERROR';
export const GET_GENERAL_REPORT_FLAT_SUCCESS =
  'GET_GENERAL_REPORT_FLAT_SUCCESS';
export const onGetGeneralReport = makeActionCreator(GET_GENERAL_REPORT_FLAT);
export const onGetGeneralReportError = makeActionCreator(
  GET_GENERAL_REPORT_FLAT_ERROR,
  'payload'
);
export const onGetGeneralReportSuccess = makeActionCreator(
  GET_GENERAL_REPORT_FLAT_SUCCESS,
  'payload'
);
export const onGetGeneralReportThunk = date => async dispatch => {
  dispatch(onGetGeneralReport());
  try {
    let endpointParams = {};
    if (date) {
      endpointParams = {
        day: date[0].getDate(),
        month: date[0].getMonth() + 1,
        year: date[0].getFullYear(),
      };
    }
    endpointParams['type'] = 'pinchazos';
    const { data } = await getGeneralReport(endpointParams);
    const table = JSON.parse(data.current_turn).map((item, index) => {
      const _time = formatDate(item.time, 'yyyy-MM-dd');
      const _inicio = formatDate(item.inicio, 'yyyy-MM-dd HH:mm:ss');
      const _termino = formatDate(item.termino, 'yyyy-MM-dd HH:mm:ss');
      console.log(item.patente);
      const _patente = item.patente === null ? 'Sin Patente' : item.patente;
      const _flat_tires = item['neumaticos_pinchados']
        .map(reformatString)
        .join(' - ');

      return {
        ...item,
        id: `${index + 1}`,
        duration: item.duracion,
        time: _time,
        start: _inicio,
        end: _termino,
        truck: _patente,
        tires: _flat_tires,
      };
    });
    return dispatch(
      onGetGeneralReportSuccess({
        data: table,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetGeneralReportError({
        error,
      })
    );
  }
};
