import React from 'react'
import { USERS_TYPE } from "../config/settings/constant";
import routes from "../config/settings/routes";
import { usePathParam } from "../modules/hooks/route";
import ProtectBySession from "../components/Security/ProtectBySession";
import BrandLayout from "../components/Layouts/BrandLayout";
import { GatewayUserAccess } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import Navbar from "../containers/Navbar/Navbar.container";
import ManagementMenu from "../containers/ManagementMenu/ManagementMenu.container";
import Heading from "../components/Layouts/Heading";
import UsersContainer from '../containers/User/Initial/Users.container';
import RecomendationsContainer from '../containers/User/Recomendations/Recomendations.container';
import AccessControl from '../components/Security/ProtectByGroup';


/**
 * The Records page.
 */
const Users = () => {
    const type = usePathParam('type').toUpperCase();

    const TypeTitle = type => {
        switch (type) {
            case USERS_TYPE['USERS-INITIAL']:
                return 'Usuarios'
            case USERS_TYPE.RECOMENDATIONS:
                return 'Recomendaciones'
            default:
                return 'Inicio de modulo'
        }
    };

    const getTooltipContent = type => {
        switch (type) {
            case USERS_TYPE['USERS-INITIAL']:
                return 'Módulo de usuarios'
            case USERS_TYPE.RECOMENDATIONS:
                return 'Recomendaciones'    
            default:
                return ''
        }
    };

    const UsersBreadcrumbs = () => {
        return (
            <Breadcrumb noTrailingSlash>
                <BreadcrumbItem>
                    <a href="/">UCO</a>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage={USERS_TYPE[type] === USERS_TYPE['USERS-INITIAL']} href={routes.usersInitial}>
                    {TypeTitle(USERS_TYPE['USERS-INITIAL'])}
                </BreadcrumbItem>
                {USERS_TYPE[type] !== USERS_TYPE['USERS-INITIAL'] && <BreadcrumbItem isCurrentPage>{TypeTitle(type)}</BreadcrumbItem>}
            </Breadcrumb>
        )
    }

    const typeToComponentMap = {
        [USERS_TYPE['USERS-INITIAL']]: UsersContainer,
        [USERS_TYPE.RECOMENDATIONS]: RecomendationsContainer
    }
    const ComponentToRender = typeToComponentMap[type];
    console.log(typeToComponentMap[type]);
    

    return (
        <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
            <BrandLayout
                navbar={<Navbar managementMenu={<ManagementMenu current={routes.users} />} />}
                header={<Heading title={TypeTitle(type)} icon={<GatewayUserAccess size={50} />} breadcrumbs={<UsersBreadcrumbs />} tooltipContent={getTooltipContent(type)} />}
            >
                <AccessControl type={type}>
                    {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
                </AccessControl>
            </BrandLayout>
        </ProtectBySession>
    )
}

export default Users