import styled from 'styled-components';
import { g100 } from '@carbon/themes';

export const Logo = styled.img`
  max-height: 6rem;
  max-width: 100%;
`;

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
`;

export const BorderRoot = styled.div`
  align-items: center;
  border-left: 1px solid ${g100.borderTile01};
  border-right: 1px solid ${g100.borderTile01};
  display: flex;
  flex-grow: 1;
  padding: 1rem;
`;

export const Root = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
`;
