import {
  GET_REAL_TIME_DRIVER,
  GET_REAL_TIME_DRIVER_ERROR,
  GET_REAL_TIME_DRIVER_SUCCESS,
} from './Effective.actions';

const realTimeDrivers = {
  currTurn: [],
  loading: true,
  error: undefined,
};

/**
 * The Real Time Driver Reducer
 */
const realTimeDriverReducer = (state = realTimeDrivers, { payload, type }) => {
  switch (type) {
    case GET_REAL_TIME_DRIVER: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_REAL_TIME_DRIVER_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_REAL_TIME_DRIVER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        currTurn: payload.currTurn,
      };
    }
    default: {
      return state;
    }
  }
};

export default realTimeDriverReducer;
