export default function AnimationVariant3({ className }) {
    return (
        <svg id="svg2" width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g x="86.112" y="43.4512" id="text2" className={className}>
                <rect x="86.112" y="43.4512" width="60.0252" height="60.0252"
                    transform="rotate(135 86.112 43.4512)" fill="#393939" stroke="#6F6F6F" />
            </g>
            <path d="M59.0769 30.6914L55.585 34.1857L57.2316 35.8311L60.7235 32.3367L59.0769 30.6914Z"
                fill="white" />
            <path
                d="M44.4228 48.7398L38.6009 42.9633L40.4522 41.1352L44.4228 45.0709L53.0509 36.5138L54.9022 38.3524L44.4228 48.7398Z"
                fill="white" />
            <path
                d="M30.4502 57.4727V54.4606L37.9186 46.9933L36.2721 45.3456L30.4502 51.1676V27.1987H28.1214V57.4727C28.1214 58.0903 28.3668 58.6827 28.8035 59.1194C29.2402 59.5561 29.8325 59.8015 30.4502 59.8015H60.7241V57.4727H30.4502Z"
                fill="white" />
        </svg>
    )
}