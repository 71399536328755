import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LoadingContent from "../../../components/Animations/LoadingContent";
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import Typography from '../../../components/Basics/Typography';
import { DatePicker, DatePickerInput, Stack, Tabs, TabList, Tab, TabPanels, TabPanel, Button } from '@carbon/react'
import Card from '../../../components/Layouts/Card'
import { MapBoundary, Upload } from '@carbon/icons-react'
import { g100 } from '@carbon/themes';
import { Tree } from "@carbon/icons-react";
import LoadTree from './KpiTreeLoads.container';
import { onGetKpiThunk } from './KpiTree.actions';
import TransportTree from './KpiTreeTransport.container';
import { useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import { checkErrorRequired } from '../../../modules/errors/validations';
import ExtendedTree from './KpiTreeExtended.container';
import CompactedTree from './KpiTreeCompacted.container';

const KpiTreeContainer = () => {
  const dispatch = useDispatch()
  const marginBottom = '0 0 20px'
  const wordPadding = '0 40px'
  const { avgLoads, averageExca, avgBull, avgRoller, loading, error } = useSelector(state => state.kpiTree)
  const today = new Date();
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  const beforeYesterday = new Date()
  beforeYesterday.setDate(today.getDate() - 2)

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: [beforeYesterday, yesterday],
    errorCallbacks: [checkErrorRequired()]
  })

  const handleChange = _ => {
    if (dateValue.length == 2) {
      dispatch(onGetKpiThunk(dateValue[0], dateValue[1]))
    }
  }

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetKpiThunk())
  }, [])

  return (
    <>
      {loading && !error && (
        <>
          <LoadingContent />
        </>
      )}
      {!loading && error && (
        <Stack gap={6}>
          <DatePicker datePickerType="range" value={dateValue} invalidText={dateError} onClose={handleDateChange} dateFormat='d/m/Y'>
            <DatePickerInput id={`date-picker-input-id-start`} placeholder="dd/mm/aaaa" labelText="Fecha de Inicio" size="md" />
            <DatePickerInput id={`date-picker-input-id-finish`} placeholder="dd/mm/aaaa" labelText="Fecha de Término" size="md" />
          </DatePicker>
          <Button renderIcon={Upload} iconDescription="Cargar datos al arbol" size='sm' onClick={handleChange}>Cargar datos al árbol</Button>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              Error al obtener la data
            </Typography>
          </CardComponent>
        </Stack>
      )}
      {!loading && !error && (
        <Stack gap={3}>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='left-start'>
                <MapBoundary size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  ÁRBOLES DE VALOR - TABLA DE DATOS
                </Typography>
              </Card>
              <Card alignItems='flex-start' padding="4px">
                <Card width="100%" height="100%" background={g100.background} flexDirection='column' alignItems='flex-start' padding='30px'>
                  <DatePicker datePickerType="range" value={dateValue} invalidText={dateError} onChange={handleDateChange} dateFormat='d/m/Y'>
                    <DatePickerInput id={`date-picker-input-id-start`} placeholder="dd/mm/aaaa" labelText="Fecha de Inicio" size="md" />
                    <DatePickerInput id={`date-picker-input-id-finish`} placeholder="dd/mm/aaaa" labelText="Fecha de Término" size="md" />
                  </DatePicker>
                  <Button renderIcon={Upload} iconDescription="Cargar datos al arbol" size='sm' onClick={handleChange}>Cargar datos al árbol</Button>
                  <Tabs>
                    <TabList activation="manual" aria-label="List of tabs">
                      <Tab renderIcon={Tree}>Árbol de Carguío</Tab>
                      <Tab renderIcon={Tree}>Árbol de Transporte</Tab>
                      <Tab renderIcon={Tree}>Árbol de Extendido</Tab>
                      <Tab renderIcon={Tree}>Árbol de Compactado</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel style={{ width: '100%' }}>
                        <LoadTree marginBottom={marginBottom} wordPadding={wordPadding} averageExca={averageExca} />
                      </TabPanel>
                      <TabPanel style={{ width: '100%' }}>
                        <TransportTree marginBottom={marginBottom} wordPadding={wordPadding} avgLoads={avgLoads} />
                      </TabPanel>
                      <TabPanel style={{ width: '100%' }}>
                        <ExtendedTree marginBottom={marginBottom} wordPadding={wordPadding} avgBull={avgBull} />
                      </TabPanel>
                      <TabPanel style={{ width: '100%' }}>
                        <CompactedTree marginBottom={marginBottom} wordPadding={wordPadding} avgRoller={avgRoller} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Card>
              </Card>
            </CardComponent>
          </CardRow>
        </Stack>
      )}
    </>
  )
}

export default KpiTreeContainer