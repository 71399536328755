import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import WaterfallContainer from "../../../components/Graphs/Waterfall.container";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { DatePicker, DatePickerInput, Select, SelectItem } from '@carbon/react';
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { TopFilter } from "../Idle/Idle.styles";
import { onGetAsarcoThunk } from "./Asarco.actions";
import { spacing05 } from "@carbon/themes";
import LoadingContent from "../../../components/Animations/LoadingContent";

const AsarcoContainer = () => {
  const dispatch = useDispatch();
  const { error, loading, asarco, date, truck, truckList } = useSelector(state => state.asarco);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [selectedTruck, setSelectedTruck] = useState(truck);
  const [selectedShift, setSelectedShift] = useState("dia");
  const [selectedDate, setSelectedDate] = useState(date || yesterday);

  const dispatchAsarco = (_date) => {
    dispatch(onGetAsarcoThunk(_date[0], selectedTruck, selectedShift));
  };

  const dispatchTruck = (truck) => {
    const currDate = new Date(selectedDate);
    dispatch(onGetAsarcoThunk(currDate, truck.selectedItem, selectedShift));
    setSelectedTruck(truck.selectedItem);
  };

  const handleShiftChange = (e) => {
    const shiftValue = e.target.value;
    setSelectedShift(shiftValue);
    const currDate = new Date(selectedDate);
    dispatch(onGetAsarcoThunk(currDate, selectedTruck, shiftValue));
  };

  const handleDateChange = (_date) => {
    const newDate = _date[0];
    setSelectedDate(newDate);
    dispatch(onGetAsarcoThunk(newDate, selectedTruck, selectedShift));
  };

  useEffect(() => {
    if (!loading) {
      return;
    }
    dispatch(onGetAsarcoThunk());
  }, []);

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const tiempoNominalValue = 12;

  const waterfallOptions = {
    autoSize: true,
    height: 800,
    background: {
      fill: 'rgb(38, 38, 38)',
    },
    data: [
      { financials: "Tiempo\nNominal", amount: tiempoNominalValue },
      { financials: "Tiempo Fuera\nde Servicio", amount: asarco[0]?.amount ? asarco[0].amount * -1 : 0 },
      { financials: "Reservas", amount: asarco[1]?.amount ? asarco[1].amount * -1 : 0 },
      { financials: "Perdidas", amount: asarco[2]?.amount ? asarco[2].amount * -1 : 0 },
    ],
    title: {
      text: selectedTruck?.text || '',
      fontSize: 20,
      fontFamily: 'Arial, sans-serif',
      fontWeight: 'bold',
      color: '#ffffff',
      align: 'center',
    },
    legend: { enabled: false },
    series: [
      {
        type: "waterfall",
        xKey: "financials",
        xName: "Concepto",
        yKey: "amount",
        yName: "Horas",
        totals: [
          { totalType: 'total', index: 1, axisLabel: 'Tiempo\nDisponible' },
          { totalType: 'total', index: 2, axisLabel: 'Tiempo\nOperativo' },
          { totalType: 'total', index: 3, axisLabel: 'Tiempo\nEfectivo' },
        ],
        direction: 'vertical',
        item: {
          positive: { fill: '#1192e8', stroke: '#333333' },
          negative: { fill: '#DA1E28', stroke: '#333333' },
          total: { fill: '#6F6F6F', stroke: '#333333' },
        },
        line: { stroke: '#bbbbbb', strokeWidth: 1 },
      },
    ],
    axes: [
      { type: 'category', position: 'bottom', label: { color: '#ffffff' } },
      { type: 'number', position: 'left', label: { color: '#ffffff' } },
    ],
  };

  return (
    <>
      {loading && !error && <LoadingContent />}
      {!loading && error && (
        <>
          <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} style={{ marginBottom: '40px' }} onChange={handleDateChange} value={selectedDate} dateFormat='d-m-Y'>
            <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha" id="daily-datepicker-error" size="md" />
          </DatePicker>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>No hay datos para esta fecha, por favor seleccione otra fecha.</Typography>
          </CardComponent>
        </>
      )}
      {!isEmpty(asarco) && !error && (
        <>
          <TopFilter>
            <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} style={{ marginBottom: '40px' }} onChange={handleDateChange} value={selectedDate} dateFormat='d-m-Y'>
              <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha" id="daily-datepicker" size="md" />
            </DatePicker>
            <Select id="shift-select" labelText="Turno" defaultValue="dia" onChange={handleShiftChange}>
              <SelectItem text="Día" value="dia" />
              <SelectItem text="Noche" value="noche" />
            </Select>
            <Select
              id="truck-select"
              labelText="Equipo"
              defaultValue={selectedTruck?.id}
              onChange={(e) => {
                const selectedTruckId = e.target.value;
                const truck = truckList.find(t => t.id === selectedTruckId);
                dispatchTruck({ selectedItem: truck });
              }}
            >
              {/* Ordenar la lista de camiones alfabéticamente */}
              {[...truckList]
                .sort((a, b) => a.text.localeCompare(b.text)) // Ordena alfabéticamente por el campo `text`
                .map(truck => (
                  <SelectItem key={truck.id} text={truck.text} value={truck.id} />
                ))}
            </Select>
          </TopFilter>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <Typography className='cds--type-heading-compact-01' style={{ color: '#ffffff' }}>
                ASARCO - GRÁFICO DE CASCADA
              </Typography>
            </Card>
            <CardRow width='inherit'>
              <Card flexDirection='column' justifyContent='center' height='800px' width='100%' padding={spacing05}>
                <WaterfallContainer height='800px' options={waterfallOptions} />
              </Card>
            </CardRow>
          </CardComponent>
        </>
      )}
    </>
  );
};

export default AsarcoContainer;
