import React from 'react';
import { HeatmapChart } from "@carbon/charts-react";
import PropTypes from 'prop-types'
import { toolbarOptions } from "./utils";

/**
 * They Heatmap Diagram's container
 */

const Heatmap = props => {
  const { height, title, data, axesOptions } = props

  const state = {
    data: data,
    options: {
      "title": `${title}`,
      "color": {
        "gradient": {
          "enabled": "true",
          "colors": ["#42BE65", "#F1C21B", " #Fa4D56"]
        }
      },
      "axes": axesOptions,
      "heatmap": {
        "colorLegend": {
          "title": "Legend title",
          "type": "quantize"
        }
      },
      "height": `${height}`,
      "width": "95%",
      "theme": "g100",
      ...toolbarOptions
    }
  }
  return (
    <>
      <HeatmapChart data={state.data} options={state.options} />
    </>
  )
}

Heatmap.defaultProps = {
  height: "15rem",
  title: "Heatmap Graph",
  data: [
    {
      "letter": "A",
      "month": "January",
      "value": 41
    },
    {
      "letter": "B",
      "month": "January",
      "value": 7
    },
    {
      "letter": "C",
      "month": "January",
      "value": 66
    },
    {
      "letter": "D",
      "month": "January",
      "value": 85
    },
    {
      "letter": "E",
      "month": "January",
      "value": 70
    },
    {
      "letter": "F",
      "month": "January",
      "value": 98
    },
    {
      "letter": "G",
      "month": "January",
      "value": 90
    },
    {
      "letter": "H",
      "month": "January",
      "value": 66
    },
    {
      "letter": "I",
      "month": "January",
      "value": 0
    },
    {
      "letter": "J",
      "month": "January",
      "value": 13
    },
    {
      "letter": "A",
      "month": "February",
      "value": 16
    },
    {
      "letter": "B",
      "month": "February",
      "value": 5
    },
    {
      "letter": "C",
      "month": "February",
      "value": 6
    },
    {
      "letter": "C",
      "month": "February",
      "value": 48
    },
    {
      "letter": "F",
      "month": "February",
      "value": 26
    },
    {
      "letter": "G",
      "month": "February",
      "value": 70
    },
    {
      "letter": "H",
      "month": "February",
      "value": 99
    },
    {
      "letter": "I",
      "month": "February",
      "value": 79
    },
    {
      "letter": "J",
      "month": "February",
      "value": 83
    },
    {
      "letter": "A",
      "month": "March",
      "value": 62
    },
    {
      "letter": "B",
      "month": "March",
      "value": 57
    },
    {
      "letter": "C",
      "month": "March",
      "value": 90
    },
    {
      "letter": "D",
      "month": "March",
      "value": 68
    },
    {
      "letter": "E",
      "month": "March",
      "value": 84
    },
    {
      "letter": "F",
      "month": "March",
      "value": 21
    },
    {
      "letter": "G",
      "month": "March",
      "value": 54
    },
    {
      "letter": "H",
      "month": "March",
      "value": 25
    },
    {
      "letter": "I",
      "month": "March",
      "value": 42
    },
    {
      "letter": "J",
      "month": "March",
      "value": 62
    },
    {
      "letter": "A",
      "month": "April",
      "value": 15
    },
    {
      "letter": "B",
      "month": "April",
      "value": 52
    },
    {
      "letter": "C",
      "month": "April",
      "value": 15
    },
    {
      "letter": "D",
      "month": "April",
      "value": 22
    },
    {
      "letter": "E",
      "month": "April",
      "value": 59
    },
    {
      "letter": "F",
      "month": "April",
      "value": 36
    },
    {
      "letter": "G",
      "month": "April",
      "value": 5
    },
    {
      "letter": "H",
      "month": "April",
      "value": 18
    },
    {
      "letter": "I",
      "month": "April",
      "value": 42
    },
    {
      "letter": "J",
      "month": "April",
      "value": 72
    },
    {
      "letter": "A",
      "month": "May",
      "value": 30
    },
    {
      "letter": "B",
      "month": "May",
      "value": 39
    },
    {
      "letter": "C",
      "month": "May",
      "value": 69
    },
    {
      "letter": "D",
      "month": "May",
      "value": 73
    },
    {
      "letter": "E",
      "month": "May",
      "value": 2
    },
    {
      "letter": "F",
      "month": "May",
      "value": 15
    },
    {
      "letter": "G",
      "month": "May",
      "value": 86
    },
    {
      "letter": "H",
      "month": "May",
      "value": 23
    },
    {
      "letter": "I",
      "month": "May",
      "value": 65
    },
    {
      "letter": "J",
      "month": "May",
      "value": 0
    },
    {
      "letter": "A",
      "month": "June",
      "value": 51
    },
    {
      "letter": "B",
      "month": "June",
      "value": 30
    },
    {
      "letter": "C",
      "month": "June",
      "value": 7
    },
    {
      "letter": "D",
      "month": "June",
      "value": 74
    },
    {
      "letter": "E",
      "month": "June",
      "value": 44
    },
    {
      "letter": "F",
      "month": "June",
      "value": 62
    },
    {
      "letter": "G",
      "month": "June",
      "value": 65
    },
    {
      "letter": "H",
      "month": "June",
      "value": 35
    },
    {
      "letter": "I",
      "month": "June",
      "value": 95
    },
    {
      "letter": "J",
      "month": "June",
      "value": 59
    },
    {
      "letter": "A",
      "month": "July",
      "value": 89
    },
    {
      "letter": "B",
      "month": "July",
      "value": 50
    },
    {
      "letter": "C",
      "month": "July",
      "value": 35
    },
    {
      "letter": "D",
      "month": "July",
      "value": 45
    },
    {
      "letter": "E",
      "month": "July",
      "value": 93
    },
    {
      "letter": "F",
      "month": "July",
      "value": 19
    },
    {
      "letter": "G",
      "month": "July",
      "value": 52
    },
    {
      "letter": "H",
      "month": "July",
      "value": 81
    },
    {
      "letter": "I",
      "month": "July",
      "value": 72
    },
    {
      "letter": "J",
      "month": "July",
      "value": 99
    },
    {
      "letter": "A",
      "month": "August",
      "value": 54
    },
    {
      "letter": "B",
      "month": "August",
      "value": 41
    },
    {
      "letter": "C",
      "month": "August",
      "value": 75
    },
    {
      "letter": "D",
      "month": "August",
      "value": 10
    },
    {
      "letter": "E",
      "month": "August",
      "value": 0
    },
    {
      "letter": "F",
      "month": "August",
      "value": 93
    },
    {
      "letter": "G",
      "month": "August",
      "value": 3
    },
    {
      "letter": "H",
      "month": "August",
      "value": 80
    },
    {
      "letter": "I",
      "month": "August",
      "value": 88
    },
    {
      "letter": "J",
      "month": "August",
      "value": 27
    },
    {
      "letter": "A",
      "month": "September",
      "value": 81
    },
    {
      "letter": "B",
      "month": "September",
      "value": 36
    },
    {
      "letter": "C",
      "month": "September",
      "value": 77
    },
    {
      "letter": "D",
      "month": "September",
      "value": 1
    },
    {
      "letter": "E",
      "month": "September",
      "value": 45
    },
    {
      "letter": "F",
      "month": "September",
      "value": 23
    },
    {
      "letter": "G",
      "month": "September",
      "value": 1
    },
    {
      "letter": "H",
      "month": "September",
      "value": 13
    },
    {
      "letter": "I",
      "month": "September",
      "value": 61
    },
    {
      "letter": "J",
      "month": "September",
      "value": 87
    },
    {
      "letter": "A",
      "month": "October",
      "value": 5
    },
    {
      "letter": "B",
      "month": "October",
      "value": 29
    },
    {
      "letter": "C",
      "month": "October",
      "value": 49
    },
    {
      "letter": "D",
      "month": "October",
      "value": 81
    },
    {
      "letter": "E",
      "month": "October",
      "value": 5
    },
    {
      "letter": "F",
      "month": "October",
      "value": 6
    },
    {
      "letter": "G",
      "month": "October",
      "value": 3
    },
    {
      "letter": "H",
      "month": "October",
      "value": 72
    },
    {
      "letter": "I",
      "month": "October",
      "value": 27
    },
    {
      "letter": "J",
      "month": "October",
      "value": 99
    },
    {
      "letter": "A",
      "month": "November",
      "value": 25
    },
    {
      "letter": "B",
      "month": "November",
      "value": 11
    },
    {
      "letter": "C",
      "month": "November",
      "value": 54
    },
    {
      "letter": "D",
      "month": "November",
      "value": 90
    },
    {
      "letter": "E",
      "month": "November",
      "value": 21
    },
    {
      "letter": "F",
      "month": "November",
      "value": 5
    },
    {
      "letter": "G",
      "month": "November",
      "value": 41
    },
    {
      "letter": "H",
      "month": "November",
      "value": 4
    },
    {
      "letter": "I",
      "month": "November",
      "value": 31
    },
    {
      "letter": "J",
      "month": "November",
      "value": 22
    },
    {
      "letter": "A",
      "month": "December",
      "value": 99
    },
    {
      "letter": "B",
      "month": "December",
      "value": 54
    },
    {
      "letter": "C",
      "month": "December",
      "value": 85
    },
    {
      "letter": "D",
      "month": "December",
      "value": 39
    },
    {
      "letter": "E",
      "month": "December",
      "value": 45
    },
    {
      "letter": "F",
      "month": "December",
      "value": 24
    },
    {
      "letter": "G",
      "month": "December",
      "value": 87
    },
    {
      "letter": "H",
      "month": "December",
      "value": 69
    },
    {
      "letter": "I",
      "month": "December",
      "value": 59
    },
    {
      "letter": "J",
      "month": "December",
      "value": 44
    }
  ],
  axesOptions: {
    "bottom": {
      "title": "Muros",
      "mapsTo": "letter",
      "scaleType": "labels"
    },
    "left": {
      "title": "B57",
      "mapsTo": "month",
      "scaleType": "labels"
    }
  }
}

Heatmap.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  axesOptions: PropTypes.object
}

export default Heatmap