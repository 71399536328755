import React from "react";
import {
    Button,
    DataTable, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
    TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch
} from '@carbon/react';
import { Calendar } from '@carbon/icons-react'

const RollerTable = _ => {
    const headers = [
        {
            header: 'Frente de Descarga',
            key: 'wall',
        },
        {
            header: 'Velocidad Promedio',
            key: 'mean_velocity',
        },
        {
            header: 'Pasada',
            key: 'frequency',
        },
        {
            header: 'Rodillo',
            key: 'roller',
        },
        {
            header: 'Conductor',
            key: 'driver',
        }
    ]

    const rows = Array(20).fill().map((_, index) => ({
        id: `${index}`,
        wall: 'id_geocerca',
        mean_velocity: '0',
        frequency: '0 pasadas',
        roller: 'id_rodillo',
        driver: 'id_conductor'
    }));

    const Default = () => <DataTable rows={rows} headers={headers} width="100%">
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getExpandedRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange
        }) => <TableContainer {...getTableContainerProps()}>
                <TableToolbar>
                    <TableToolbarContent>
                        {/* pass in `onInputChange` change here to make filtering work */}
                        <TableToolbarSearch onChange={onInputChange} />
                        <Button renderIcon={Calendar} kind="ghost" iconDescription="Fecha Específica">Fecha Específica</Button>
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            <TableExpandHeader aria-label="expand row" />
                            {headers.map((header, i) => <TableHeader key={i} {...getHeaderProps({
                                header
                            })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <React.Fragment key={row.id}>
                            <TableExpandRow {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                            </TableExpandRow>
                            <TableExpandedRow colSpan={headers.length + 1} className="demo-expanded-td" {...getExpandedRowProps({
                                row
                            })}>
                                <h6>Información adicional</h6>
                                <div>Description here</div>
                            </TableExpandedRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable>;

    return (
        <Default />
    )
}

export default RollerTable;