import React from 'react'
import Navbar from '../containers/Navbar/Navbar.container'
import ManagementMenu from '../containers/ManagementMenu/ManagementMenu.container'
import routes from '../config/settings/routes'
import ProtectBySession from '../components/Security/ProtectBySession'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import { Book } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem, Stack } from '@carbon/react'
import { CardComponent } from '../components/Layouts/Card.styles'
import Card from '../components/Layouts/Card';
import Typography from '../components/Basics/Typography'

/**
 * The Front's Page.
 */
const UserManual = () => {
    const UserManualCrumbs = () => {
        return (
            <Breadcrumb noTrailingSlash>
                <BreadcrumbItem>
                    <a href="/#">UCO</a>
                </BreadcrumbItem>
                <BreadcrumbItem href="#" isCurrentPage>Manual de Usuario</BreadcrumbItem>
            </Breadcrumb>
        )
    }

    const title = 'Manual de Usuario'

    return (
        <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
            <BrandLayout
                navbar={<Navbar managementMenu={<ManagementMenu current={routes.userManual} />} />}
                header={<Heading title={title} icon={<Book size={50} />} breadcrumbs={<UserManualCrumbs />} />}
            >
                <Stack gap={3}>
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start'>
                            <Book size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                MANUAL DE USUARIO
                            </Typography>
                        </Card>
                        <Card height="80vw">
                            <iframe
                                title="user-manual"
                                src="https://www.figma.com/proto/tRoH1e8mwckWjbWDrYOPV8/Manual-de-Usuario?embed_host=share&kind=proto&node-id=22-14681&page-id=0%3A1&scaling=min-zoom&t=pP5yaRokBWOsoAKf-1&type=design&viewport=-1011%2C127%2C0.39&mode=design"
                                width="100%"
                                height="100%"
                            >
                                No se admiten iFrames en esta página.
                            </iframe>
                        </Card>
                    </CardComponent>
                </Stack>
            </BrandLayout>
        </ProtectBySession>
    )
}

export default UserManual
