import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import routes from '../../config/settings/routes';
import { Link } from 'react-router-dom'
import {
  Content, Header, HeaderContainer, HeaderMenuButton, HeaderName, HeaderNavigation, HeaderMenu,
  HeaderMenuItem, HeaderGlobalBar, HeaderGlobalAction, HeaderPanel, HeaderSideNavItems, SkipToContent, SideNav, SideNavDivider, SideNavItems, SideNavLink, SideNavMenu, SideNavMenuItem,
  Switcher, SwitcherItem, SwitcherDivider, Button, Modal, Select, SelectItem, Stack, DatePicker, DatePickerInput, Section, Heading, Dropdown, Tag, ActionableNotification
} from '@carbon/react';
import { Dashboard, MapIdentify, ReportData, ToolBox, UserProfile, UserAvatar, CircleFill, Help, WarningFilled } from '@carbon/icons-react';
import cx from 'classnames';
import { HeaderMenu as HeaderMenuNative } from '@carbon/react';
import { Search, Notification, Fade, Switcher as SwitcherIcon } from '@carbon/icons-react';
import Sankey from '../../components/Graphs/Sankey.container';
import ManagementMenu from '../ManagementMenu/ManagementMenu.container';
import { onLogOutThunk } from '../Authentication/Authentication.actions'
import Typography from '../../components/Basics/Typography';
import LogoutModal from '../Authentication/Logout.container';
import NotificationHolder from '../../components/Layouts/NotificationHolder.container';
import { NotificationPopup, NormalActionable } from '../../components/Layouts/Notifications.styles';
import { NotificationStatus } from '../../components/Layouts/Notification.container';
import { onRemoveNotificationThunk, onRemoveAllNotificationThunk } from './Navbar.actions';

/**
 * The Navbar's component
 */
export const Navbar = props => {
  const [open, setOpen] = useState(false);
  const button = useRef();
  const { managementMenu } = props
  const dispatch = useDispatch()
  const history = useHistory();
  const { numberOfNotifications, notifications } = useSelector(state => state.navbar)
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [animationState, setAnimationState] = useState('');
  const [shouldRender, setShouldRender] = useState(false);

  const removeNotification = (notification) => {
    return dispatch(onRemoveNotificationThunk(notification))
  }

  const items = [
    {
      id: 'anomalias',
      text: 'Anomalías'
    }, {
      id: 'produccion',
      text: 'Producción'
    }
  ];

  const handleLogout = () => {
    dispatch(
      onLogOutThunk({
        onCallback: () => history.push(routes.login)
      })
    )
  }

  const RemoveAllNotifications = () => {
    return dispatch(onRemoveAllNotificationThunk())
  }

  const Title = () => {
    return (
      <>
        <Typography>Notificaciones</Typography>
        <Typography color='#f14405' onClick={RemoveAllNotifications}>Marcar Leídas</Typography>
      </>
    )
  }
  const SubHeader = () => {
    const countByTitle = notifications.reduce((acc, item) => {
      if (item.status !== NotificationStatus.REMOVED) {
        acc[item.title] = (acc[item.title] || 0) + 1;
      }
      return acc;
    }, {});
    const isEmpty = Object.keys(countByTitle).length === 0;
    return (
      <>
        {isEmpty ? (
          <p>Sin notificaciones</p>
        ) : (
          Object.entries(countByTitle).map(([title, count]) => (
            <Typography className={'cds--type-body-compact-01'} key={title}>{`${title} (${count})`}</Typography>
          ))
        )}
      </>
    )
  }

  useEffect(() => {
    if (isPopupOpen) {
      setAnimationState('in')
      setShouldRender(true)
    } else if (!isPopupOpen) {
      setAnimationState('out')
      // Delay the removal or hiding of the popup to allow the slideOut animation to complete
      const timeoutId = setTimeout(() => {
        setShouldRender(false)
      }, 300)

      return () => clearTimeout(timeoutId)
    }
  }, [isPopupOpen]);

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <>
          <Header aria-label="Talabre">
            <SkipToContent />
            <HeaderName href="/" prefix="UCO 2.0">
              <Typography className='cds--type-heading-compact-01 header'>
                TALABRE IX
              </Typography>
            </HeaderName>
            {managementMenu && (
              <>{managementMenu}</>
            )}
            <HeaderGlobalBar>
              <HeaderGlobalAction aria-label="Notificaciones" tooltipAlignment="end" onClick={() => setPopupOpen(!isPopupOpen)}>
                {numberOfNotifications ? <WarningFilled size={20} style={{ marginRight: "0.5rem" }} /> : null}
                <Tag
                  size="md"
                  title="Número de notificaciones"
                >
                  {numberOfNotifications}
                </Tag>
              </HeaderGlobalAction>
              <HeaderGlobalAction aria-label="Cerrar Sesión" tooltipAlignment="end" onClick={() => setOpen(true)}>
                <UserAvatar size={20} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
          </Header>
          <LogoutModal button={button} open={open} setOpen={setOpen} />
          {shouldRender && (
            <NotificationHolder animate={animationState} isOpen={isPopupOpen} onClose={() => setPopupOpen(false)} header={<Title />} subheader={<SubHeader />}>
              {notifications.length === 0 ? (
                ''
              ) : (
                <>
                  {notifications.filter(notification => (notification.status === NotificationStatus.SHOW | notification.status === NotificationStatus.HIDDEN)).map(notification => (
                    <ActionableNotification key={notification.id}
                      role="status"
                      actionButtonLabel="Más información"
                      onActionButtonClick={() => history.push(notification.route)}
                      title={notification.title}
                      subtitle={<span>{notification.subtitle}<br />{notification.caption}</span>}
                      onClose={() => removeNotification(notification)}
                      kind={notification.kind}
                    />
                  ))}
                </>
              )}
            </NotificationHolder>
          )}
        </>
      )}
    />
  )
}

Navbar.defaultProps = {
  managementMenu: null,
  subHeaderItems: null
}

Navbar.propTypes = {
  managementMenu: PropTypes.node
}

export default Navbar
