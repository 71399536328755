import React from 'react'
import { DATABASE_TYPE } from "../config/settings/constant";
import routes from "../config/settings/routes";
import { usePathParam } from "../modules/hooks/route";
import ProtectBySession from "../components/Security/ProtectBySession";
import BrandLayout from "../components/Layouts/BrandLayout";
import { DataBase as DatabaseIcon } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import Navbar from "../containers/Navbar/Navbar.container";
import ManagementMenu from "../containers/ManagementMenu/ManagementMenu.container";
import Heading from "../components/Layouts/Heading";
import Database from '../containers/Database/Initial/Database.container';
import DatabaseContainer from '../containers/Database/DatabasePage';
import CanHistoric from '../containers/Database/Can/Can.container';
import AccessControl from '../components/Security/ProtectByGroup'

/**
 * The Database page.
 */
const DatabasePage = () => {
  const type = usePathParam('type').toUpperCase();
  console.log(type)
  const TypeTitle = type => {
    switch (type) {
      case DATABASE_TYPE['DATABASE-INITIAL']:
        return 'Bases de Datos'
      case DATABASE_TYPE.GPS:
        return 'Gps'
      case DATABASE_TYPE.CANBUS:
        return 'CanBus'
      case DATABASE_TYPE.PRODUCTIVITY:
        return 'Cuadro Mando de Productividad'
      case DATABASE_TYPE.HOURMETER:
        return 'Horómetro'
      default:
        return 'Inicio de modulo'
    }
  };

  const getTooltipContent = type => {
    switch (type) {
      case DATABASE_TYPE['DATABASE-INITIAL']:
        return 'Formulario para la generación de informes de datos con parámetros específicos.'
      case DATABASE_TYPE.GPS:
        return 'Detalles sobre el sistema GPS'
      case DATABASE_TYPE.CANBUS:
        return 'Información sobre el sistema CANBus'
      case DATABASE_TYPE.PRODUCTIVITY:
        return 'Cuadro de mando de productividad'
      case DATABASE_TYPE.HOURMETER:
        return 'Detalles sobre el horómetro'
      default:
        return ''
    }
  };

  const DatabaseBreadcrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={DATABASE_TYPE[type] === DATABASE_TYPE['DATABASE-INITIAL']} href={routes.databaseInitial}>
          Bases de Datos
        </BreadcrumbItem>
        {DATABASE_TYPE[type] !== DATABASE_TYPE['DATABASE-INITIAL'] && <BreadcrumbItem isCurrentPage>{TypeTitle(type)}</BreadcrumbItem>}
      </Breadcrumb>
    )
  }

  const typeToComponentMap = {
    [DATABASE_TYPE['DATABASE-INITIAL']]: Database,
    [DATABASE_TYPE.GPS]: <DatabaseContainer driveLink="https://drive.google.com/embeddedfolderview?id=10n4jkZW6YKHlJtVMDJj-q-kSgwMRdmN2&amp;A_RESOURCE_KEY&amp;usp=sharing" title="GPS" />,
    [DATABASE_TYPE.CANBUS]: <DatabaseContainer driveLink="https://drive.google.com/embeddedfolderview?id=1R1ZIfRZkjVJKqpjlbIDHq0T7N4Qip84d&amp;A_RESOURCE_KEY&amp;usp=sharing" title="CANBUS" />,
    [DATABASE_TYPE.PRODUCTIVITY]: <DatabaseContainer driveLink="https://drive.google.com/embeddedfolderview?id=1-22w0TFkuekw8X3Ma6RYAczz585didpY&amp;A_RESOURCE_KEY&amp;usp=sharing" title="CUADRO MANDO DE PRODUCTIVIDAD" />,
    [DATABASE_TYPE.HOURMETER]:  <DatabaseContainer driveLink="https://drive.google.com/embeddedfolderview?id=10p3yV1luQGM99HJjDrf_EKeNWFWsgHlE&amp;A_RESOURCE_KEY&amp;usp=sharing" title="HORÓMETRO" />,
  }
  const ComponentToRender = typeToComponentMap[type];

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.database} />} />}
        header={<Heading title={TypeTitle(type)} icon={<DatabaseIcon size={50} />} breadcrumbs={<DatabaseBreadcrumbs />} tooltipContent= {getTooltipContent(type)}/>}
      >
        <AccessControl type={type}>
          {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
        </AccessControl>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default DatabasePage
