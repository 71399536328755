import {
  GET_HISTORIC,
  GET_HISTORIC_ERROR,
  GET_HISTORIC_SUCCESS,
} from './Historic.actions';

const historicReportState = {
  lastre: [],
  walls: {},
  wallsList: [],
  trucks: [],
  productivity: [],
  cycleTime: [],
  loading: true,
  error: undefined,
  average10: {},
  average20: {},
};

/**
 * The Historic report reducer.
 */
const historicReportReducer = (
  state = historicReportState,
  { payload, type }
) => {
  switch (type) {
    case GET_HISTORIC: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_HISTORIC_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_HISTORIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        lastre: payload.lastre,
        walls: payload.walls,
        wallsList: payload.wallsList,
        trucks: payload.trucks,
        productivity: payload.productivity,
        cycleTime: payload.cycleTime,
        average10: payload.average10,
        average20: payload.average20,
      };
    }
    default:
      return state;
  }
};

export default historicReportReducer;
