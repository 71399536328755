import React from 'react';
import { LineChart } from '@carbon/charts-react';
import '@carbon/charts-react/styles.css';

const LineChartComponent = ({ chartData, chartTitle }) => {

  const options = {
    title: chartTitle + '  (horas)',
    axes: {
      bottom: {
        mapsTo: 'date',
        scaleType: 'time',
      },
      left: {
        mapsTo: 'value',
        scaleType: 'linear',
      },
    },
    color: {
      scale: {
        'Dataset 1': '#FB7E50',  // Cambiar el color de la línea a FB7E50
      },
    },
    height: '500px',
    theme: 'g100',  // Modo oscuro
    points: {
      enabled: true, // Habilitar puntos para depuración
    },
    legend: {
      enabled: false,  // Deshabilitar la leyenda
    },
  };

  return <LineChart data={chartData} options={options} />;
};

export default LineChartComponent;
