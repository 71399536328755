import React from "react";
import { StyledTextArea } from "./AreaText.styles";

const AreaText = ({
  labelText,
  enableCounter,
  maxCount,
  placeholder,
  ...rest
}) => {
  return (
    <StyledTextArea 
      labelText={labelText}
      enableCounter={enableCounter}
      maxCount={maxCount}
      placeholder={placeholder}
      {...rest}
    />
  )
}

export default AreaText