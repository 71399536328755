import React from 'react'
import { Root } from './Typograhpy.styles'
import PropTypes from 'prop-types'

/**
 * The Typography' component.
 */
const Typography = props => {
  const { children, color, cursor, display, italic, margin, padding, width, className, justify, alignItems, noWrap, onClick } = props
  return (
    <Root
      color={color}
      display={display}
      italic={italic}
      margin={margin}
      padding={padding}
      width={width}
      justify={justify}
      alignItems={alignItems}
      noWrap={noWrap}
      className={className}
      onClick={onClick}
      cursor={cursor}
    >
      {children}
    </Root>
  )
}

Typography.defaultProps = {
  display: 'block',
  italic: false,
  onClick: () => undefined
}

Typography.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  display: PropTypes.oneOf(['block', 'initial', 'inline', 'flex']),
  italic: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noWrap: PropTypes.bool,
  justify: PropTypes.string,
  alignItems: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default Typography
