import { Button, Stack } from "@carbon/react";
import { CardRow } from "../../../components/Layouts/Card.styles";
import { DataTable, CalendarHeatMap, Time, ArrowUpLeft, Movement } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import ExpansionTable from "../../../components/Basics/ExpansionTable.container";
import { Div } from "../../../components/Basics/Div.styles";
import { useRef, useState } from "react";
import RoutesDetails from "./RoutesDetails.container";
import TabsContainer from "../../../components/Basics/Tabs.container";
import { spacing05 } from "@carbon/themes";

const RoutesContainer = () => {
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);

    const headers = [
        {
            header: 'Origen',
            key: 'origin',
        },
        {
            header: 'Destino',
            key: 'destination',
        },
        {
            header: 'Tiempo de Traslado Promedio - Hoy',
            key: 'average_transfer_time_today',
        },
        {
            header: 'Tiempo de Traslado Promedio - Mes',
            key: 'average_transfer_time_month',
        },
        {
            header: 'Mejor Tiempo de Traslado - Turno',
            key: 'best_transfer_time_turn',
        },
        {
            header: 'Distancia Ruta',
            key: 'route_distance',
        }
    ]

    const rows = Array(20).fill().map((_, index) => ({
        id: `${index}`,
        origin: 'id_plataforma',
        destination: 'id_punto_descarga',
        average_transfer_time_today: '00:00:00',
        average_transfer_time_month: '00:00:00',
        best_transfer_time_turn: '00:00:00',
        route_distance: '00 km',
    }));

    const tabs = [{
        title: 'Desde Plataforma (25 rutas)',
        icon: ArrowUpLeft,
    }, {
        title: 'Desde Plataformas',
        icon: ArrowUpLeft
    }]

    return (
        <Stack gap={3}>
            <TabsContainer tabs={tabs} />
            <Card justifyContent='flex-start' >
                <DataTable size={18} />
                <Typography className='cds--type-heading-compact-01'>
                    RUTAS - TABLA DE DATOS
                </Typography>
            </Card>
            <CardRow width='inherit'>
                <Card width='100%' padding="0">
                    <ExpansionTable rows={rows} headers={headers}
                        additionalToolbarComponents={
                            <>
                                <Button renderIcon={CalendarHeatMap} kind="ghost" iconDescription="Mes en curso">Mes en Curso</Button>
                                <Button renderIcon={Time} kind="ghost" iconDescription="Histórico">Histórico</Button>
                            </>
                        }
                        detailsFunc={() =>
                            <>
                                <Div display="flex" flexDirection="column" padding="4px" gap={spacing05}>
                                    <span>Información adicional</span>
                                    <Card display="flex" justifyContent="space-between" width="inherit">
                                        <Button style={{ minWidth: "100%" }} ref={buttonRef} kind="danger--ghost" renderIcon={Movement} onClick={() => setOpen(true)}>Ver Ficha Ruta</Button>
                                    </Card>
                                </Div>
                            </>
                        } />
                </Card>
            </CardRow>
            <RoutesDetails open={open} setOpen={setOpen} ref={buttonRef} />
        </Stack>
    )
};

export default RoutesContainer;