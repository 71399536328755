import { makeActionCreator } from '../../../config/store/utils';
import { getRealTimeReports } from '../../../services/monitoringService';
import { formatDate, shortenFront } from '../../../modules/utils/formatters';

export const GET_REAL_TIME_DISCHARGING = 'GET_REAL_TIME_DISCHARGING';
export const GET_REAL_TIME_DISCHARGING_ERROR =
  'GET_REAL_TIME_DISCHARGING_ERROR';
export const GET_REAL_TIME_DISCHARGING_SUCCESS =
  'GET_REAL_TIME_DISCHARGING_SUCCESS';
export const onGetRealTimeReports = makeActionCreator(
  GET_REAL_TIME_DISCHARGING
);
export const onGetRealTimeReportsError = makeActionCreator(
  GET_REAL_TIME_DISCHARGING_ERROR,
  'payload'
);
export const onGetRealTimeReportsSuccess = makeActionCreator(
  GET_REAL_TIME_DISCHARGING_SUCCESS,
  'payload'
);
export const onGetRealTimeReportsThunk = () => async dispatch => {
  dispatch(onGetRealTimeReports());
  try {
    const type = 't_frente_descarga';
    const { data } = await getRealTimeReports(type);
    const currTurn = data.current_turn.tablas;

    const chargeTime = currTurn.t_descargas
      .filter(obj => obj.value.length > 0)
      .map(item => {
        const shortenedKey = shortenFront(item.key);
        return {
          group: item.key,
          value: item.value.flatMap((val, innerIndex) => ({
            hourly: `${innerIndex + 1}`,
            value: val,
            group: shortenedKey,
          })),
        };
      });
    const boxPlot = currTurn.t_descargas
      .filter(obj => obj.value.length > 0)
      .flatMap(item => {
        if (item.value.length === 0) {
          return [{ group: item.key, value: [] }];
        } else {
          return item.value.map(val => ({
            group: item.key,
            value: val,
          }));
        }
      });
    const chargeMean = currTurn.t_promedios.map(item => ({
      ...item,
      value: Number(item.value).toFixed(2),
    }));
    const table = JSON.parse(currTurn.tabla_por_descargas).map(
      (item, index) => {
        const _date = new Date(item.event_time_max);
        return {
          ...item,
          id: `${index++}`,
          event_time_max: formatDate(_date, 'yyyy-MM-dd HH:mm:ss'),
        };
      }
    );

    return dispatch(
      onGetRealTimeReportsSuccess({
        chargeTime: chargeTime,
        boxPlot: boxPlot,
        table: table,
        chargeMean: chargeMean,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetRealTimeReportsError({
        error: error,
      })
    );
  }
};
