import React, { useEffect } from "react";
import { useSelector, useDispatch, batch } from 'react-redux';
import {
    Button, Stack, DataTable, Table, TableBody, TableCell, TableContainer,
    TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch, DatePicker, DatePickerInput
} from "@carbon/react";
import { CardRow, CardComponent } from "../../../components/Layouts/Card.styles";
import { Filter, Identification } from '@carbon/icons-react'
import { DataTable as DataTableIcon } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { useRef, useState } from "react";
import ExpansionTable from "../../../components/Basics/ExpansionTable.container";
import { useSorting } from "../../../modules/hooks/sorting";
import LoadingContent from "../../../components/Animations/LoadingContent";
import { onGetGatesThunk } from "./Gates.actions";
import { useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import { checkErrorRequired } from '../../../modules/errors/validations';

const GatesContainer = () => {
    const dispatch = useDispatch()
    const today = new Date()
    const { error, loading, data } = useSelector(state => state.gates)
    useEffect(() => {
        if (!loading) {
            return
        }
        dispatch(onGetGatesThunk(today))
    }, [])

    const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
        initialValue: [today],
        errorCallbacks: [checkErrorRequired()]
    })

    const handleChange = _ => {
        handleDateChange(_)
        dispatch(onGetGatesThunk(_))
    }

    const headerData = [
        {
            header: 'Registro',
            key: 'Registro',
        },
        {
            header: 'Hora',
            key: 'Hora',
            sortDirection: "DESC",
            isDefaultSortable: true
        },
        {
            header: 'Camion ID',
            key: 'Camion_ID',
        },
        {
            header: 'Patente',
            key: 'truckPatent'
        },
        {
            header: 'Tipo',
            key: 'type'
        },
        {
            header: 'Marca',
            key: 'marca'
        },
        {
            header: 'Camion Carga (m3)',
            key: 'Camion_Volumen_m3',
        },
        {
            header: 'Total',
            key: 'Total_m3',
        },
        {
            header: 'Pórtico',
            key: 'sid',
        }
    ];
    console.log(data)
    const { rows, headers } = useSorting(data, headerData);
    const convertToCSV = (data) => {
        // Define the new headers and their mapping to the old ones
        const headerMapping = {
            'Registro': 'Registro',
            'Hora': 'Sensores Hora',
            'Camion_ID': 'Camion ID',
            'truckPatent': 'Patente',
            'type': 'Tipo',
            'marca': 'Marca',
            'Camion_Volumen_m3': 'Camion Carga (m3)',
            'Total_m3': 'Total',
            'sid': 'Pórtico',
        };

        if (data.length === 0) {
            return '';
        }

        const headers = Object.values(headerMapping).join(',') + '\n';

        const rows = data.map(row => {
            return Object.keys(headerMapping).map(key => {
                let value = row[key];
        
                // Check if the value is null or undefined
                if (value === null || value === undefined) {
                    value = ''; // Set a default value for null or undefined (empty string or any placeholder you want)
                } else if (!isNaN(value) && value.toString().indexOf('.') !== -1) {
                    // If it's a number with a dot, replace the dot with a comma
                    value = value.toString().replace('.', ',');
                }
        
                return `"${value}"`; // Apply text formatting to all fields
            }).join(',');
        }).join('\n');
        
        return headers + rows;
    };

    const downloadCSV = (csvContent, fileName = 'download.csv') => {
        // Create a Blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a hidden link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownload = () => {
        const csv = convertToCSV(data);

        downloadCSV(csv, 'Registro_Pórticos.csv');
    };
    const Default = () => <DataTable rows={rows} headers={headers} width="100%">
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getExpandedRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange,
            sortBy
        }) => <TableContainer {...getTableContainerProps()}>
                <TableToolbar>
                    <TableToolbarContent>
                        {/* pass in `onInputChange` change here to make filtering work */}
                        <TableToolbarSearch onChange={onInputChange} />
                        <Button renderIcon={Filter} kind="secondary" hasIconOnly iconDescription="Filter table" />
                        <Button onClick={handleDownload} >Descargar</Button>
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, i) => <TableHeader
                                key={i}
                                sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                {...getHeaderProps({
                                    header,
                                    isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                    onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                                })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <React.Fragment key={row.id}>
                            <TableRow {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                            </TableRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable>;

    return (
        <>
            {loading && !error && (
                <LoadingContent />
            )}
            {!loading && error && (
                <>
                    <DatePicker datePickerType="single" maxDate={today.toISOString()} value={dateValue} invalidText={dateError} onChange={handleChange} dateFormat='d/m/Y'>
                        <DatePickerInput id={`date-picker-input-id-start`} placeholder="dd/mm/aaaa" labelText="Fecha de Inicio" size="md" />
                    </DatePicker>
                    <CardComponent width="10%">
                        <Typography className='cds--type-heading-compact-01'>
                            No hay datos para esta fecha, por favor seleccione otra fecha.
                        </Typography>
                    </CardComponent>
                </>
            )}
            {!loading && !error && (
                <Stack gap={3}>
                    <DatePicker datePickerType="single" maxDate={today.toISOString()} value={dateValue} invalidText={dateError} onChange={handleChange} dateFormat='d/m/Y'>
                        <DatePickerInput id={`date-picker-input-id-start`} placeholder="dd/mm/aaaa" labelText="Fecha de Inicio" size="md" />
                    </DatePicker>
                    <Card justifyContent='flex-start' >
                        <DataTableIcon size={18} />
                        <Typography className='cds--type-heading-compact-01'>
                            PÓRTICO - TABLA DE REGISTRO
                        </Typography>
                    </Card>
                    <CardRow width='inherit'>
                        <Card width='100%' padding="0">
                            <Default />
                        </Card>
                    </CardRow>
                </Stack>
            )}
        </>

    )
};

export default GatesContainer;