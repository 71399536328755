import styled from 'styled-components';

export const Subtitle = styled.span`
  font-weight: 100;
`;

export const DriverLicense = styled.img`
  max-width: 800px;
  max-height: 1200px;
  width: auto,
  height: auto
` 