import React from "react";
import Sankey from "../../components/Graphs/Sankey.container";

const Diagram = () => {
  return (
    <Sankey />
  )
}

export default Diagram
