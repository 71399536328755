import { Stack } from "@carbon/react";
import { CardComponent } from "../../../components/Layouts/Card.styles";
import { HeatMap_02 as HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "./Permanence.styles";

const PermanenceContainer = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            INGRESO A TALLER - TABLA DE REGISTRO
          </Typography>
        </Card>
        <Card>
          <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/f78fb0d3-f903-4091-8b29-0222424463fe/registro-mantenciones-diarias?orgId=1&var-patente=All&var-fecha_entrada=2024-07-31&from=1722496828133&to=1722518428133&kiosk" width="100%" height="100%" />
        </Card>
      </CardComponent>
    </Stack>
  )
};

export default PermanenceContainer;