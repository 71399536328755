import React from "react";
import { useSelector } from 'react-redux';
import {
    DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch, Button, DataTableSkeleton
} from '@carbon/react';
import { CardComponent } from "../../../components/Layouts/Card.styles";
import Typography from "../../../components/Basics/Typography";
import { useSorting } from "../../../modules/hooks/sorting";

const CompletedTable = _ => {
    const { completed } = useSelector(state => state.maintenance);
    const convertToCSV = (data) => {
        // Define the new headers and their mapping to the old ones
        const headerMapping = {
          'machinePatent': 'Camión',
          'reason': 'Motivo',
          'eventTime': 'Entrada a Mantenimiento',
          'outTime': 'Salida de Mantenimiento',
          'timePassed': 'Duración',
          'maintenanceType': 'Tipo de Mantención'
        };
    
        if (data.length === 0) {
          return '';
        }
    
        const headers = Object.values(headerMapping).join(',') + '\n';
    
        const rows = data.map(row => {
          return Object.keys(headerMapping).map(key => {
            let value = row[key];
            // Check if the value is a number
            if (!isNaN(value) && value.toString().indexOf('.') !== -1) {
              // If it's a number with a dot, replace the dot with a comma
              value = value.toString().replace('.', ',');
            }
            return `"${value}"`; // Apply text formatting to all fields
          }).join(',');
        }).join('\n');
    
        return headers + rows;
      };

    const downloadCSV = (csvContent, fileName = 'download.csv') => {
        // Create a Blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
    
        // Create a hidden link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
    
        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownload = () => {
        const csv = convertToCSV(completed);
    
        downloadCSV(csv, 'Mantenciones_historicas.csv');
      };

      const headerData = [
        { header: 'N° Orden de Trabajo', key: 'orderNumber', sortDirection: "ASC" },
        { header: 'Lugar de Intervención', key: 'interventionLocation' },
        { header: 'Tipo de Mantención', key: 'maintenanceType' },
        { header: 'Actividad', key: 'activity' },
        { header: 'Componente', key: 'component' },
        { header: 'Fecha de Entrega', key: 'deliveryDate', sortDirection: "DESC" },
        { header: 'Hora de Entrega', key: 'deliveryTime', sortDirection: "DESC" },
        { header: 'Fecha de Ingreso', key: 'entryDate', sortDirection: "ASC" },
        { header: 'Hora de Ingreso', key: 'entryTime' },
        { header: 'Categoría de Equipo', key: 'equipmentCategory' },
        { header: 'Falla', key: 'fault' },
        { header: 'Horómetro', key: 'horometer' },
        { header: 'N° Interno de Equipo', key: 'internalEquipmentNumber' },
        { header: 'Duración de Intervención', key: 'interventionDuration' },
        { header: 'Sistema de Ubicación', key: 'locationSystem' },
        { header: 'Turno', key: 'shift' },
        { header: 'Estado', key: 'status' },
        { header: 'Descripción Ubicación Técnica', key: 'technicalLocationDescription' }
    ];
    
    const { rows, headers } = useSorting(completed, headerData);


    const Default = () => <DataTable rows={rows} headers={headers} width="100%">
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange,
            sortBy
        }) => <TableContainer {...getTableContainerProps()}>
                <TableToolbar>
                    <TableToolbarContent>
                        {/* pass in `onInputChange` change here to make filtering work */}
                        <Button onClick={handleDownload} >Descargar</Button>
                        <TableToolbarSearch onChange={onInputChange} />
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, i) => <TableHeader
                                key={i}
                                sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                {...getHeaderProps({
                                    header,
                                    isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                    onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                                })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <React.Fragment key={row.id}>
                            <TableRow {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                            </TableRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable>;

    return (
        <>
            {(completed.length === 0) ? (
                <DataTableSkeleton />
            ) : (
                <>
                    <Default />
                </>
            )}
        </>

    )
}

export default CompletedTable;