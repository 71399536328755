import Typography from "../../components/Basics/Typography";
import { Stack } from "@carbon/react"
import { CardComponent } from "../../components/Layouts/Card.styles";
import Card from '../../components/Layouts/Card';
import routes from "../../config/settings/routes";
import './documentation.styles.scss';

const CreateGeofence = () => {
    return <CardComponent width="100%">
        <Card justifyContent='flex-start'>
            <Stack gap={5}>
                <Typography className="cds--type-fluid-heading-05">Como crear una geocerca</Typography>
                <Typography className="cds--type-fluid-heading-03">
                    Como utilizar el Creador de geocercas en la plataforma
                </Typography>
                <Typography className="cds--type-body-compact-02">
                    La plataforma cuenta con una herramienta que permite la creación de geocercas mediante polígonos.
                    Estos polígonos son recibidos por el equipo de Terrestra,
                    quienes se encargan de configurarlos y utilizarlos en la medición de transgresiones de velocidad o
                    en la medición de producción. Esta herramienta está disponible dentro de <a className="document-link" href={routes.map}>Geocerca Velocidades</a> y <a className="document-link" href={routes.fronts}>Geocerca Frentes</a>.
                    Según el tipo de geocerca que se que se vaya a crear (de velocidad o de frente de trabajo)
                    ingresamos al sub-módulo correspondiente.
                    <br /><br />
                    Pasos a Seguir:<br />
                    1. Ingresar a la herramienta <a className="document-link" href={routes.map}>Geocerca Velocidades</a> o <a className="document-link" href={routes.fronts}>Geocerca Frentes</a><br />
                    2. Activar la herramienta de 'Crear Geocerca' en la esquina inferior derecha.<br />
                    3. Rellenar los campos de nombre, tipo de geocerca y límite de velocidad con las indicaciones.<br />
                    4. Armar el polígono utilizando el mapa al final del formulario.<br />
                    5. Incluir información adicional si es necesario.<br />
                    6. Verificar el polígono y enviar.<br />
                    <br /><br />
                    Consejos<br />
                    <br /><br />
                    Para lograr una geocerca más precisa, puedes ajustar la ventana de la herramienta "Crear Geocerca" para ampliar la visualización del mapa. De esta manera, podrás trabajar con mayor detalle y precisión en la delimitación de la geocerca deseada.
                    <br /><br />
                    Utiliza la huella (puntos en el mapa) para generar geocercas que se acomoden a el recorrido real de los equipos.
                </Typography>
                <video width="880px" height="352px" controls autoPlay>
                    <source src="/assets/videos/create_geofence.mp4" type="video/mp4" />
                </video>
            </Stack>
        </Card>
    </CardComponent>
}

export default CreateGeofence;