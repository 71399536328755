import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Card from '../../../components/Layouts/Card'
import Typography from '../../../components/Basics/Typography'
import { QOperationGauge } from '@carbon/icons-react'
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import Gauge from '../../../components/Graphs/Gauge.container'
import BarChart from '../../../components/Graphs/BarChart.container'
import BoxPlotContainer from '../../../components/Graphs/BoxPlot.container'
import { spacing05 } from '@carbon/themes';
import FlexHeatMap from '../../../components/Graphs/FlexHeatMap.container'
import InformationContainer from '../../../components/Basics/Information.container'
import { TopFilter } from '../../Productivity/Idle/Idle.styles'
import { Dropdown } from '@carbon/react'
import { Div } from '../../../components/Basics/Div.styles'

export const FirstRow = () => {
  const { dailyProduction, m3Production } = useSelector(state => state.performance)
  const barChart = m3Production
  const axes = {
    "left": {
      "mapsTo": "key",
      "scaleType": "labels"
    },
    "bottom": {
      "mapsTo": "value"
    }
  }
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          PRODUCCIÓN DEL TURNO
        </Typography>
      </Card>
      <InformationContainer description='El total del medidor corresponde a la producción del turno anterior.' />
      <CardRow width="inherit">
        <Card flexDirection='column' justifyContent='center' height="100%" padding={spacing05} width="33%" alignItems="stretch">
          <Gauge title='Producción del turno en tiempo real (total, turno)' percentage={dailyProduction} maxPercent={9000} noMetric={true} />
        </Card>
        <Card height="100%" padding={spacing05} width="77%">
          <BarChart title='Producción por Frente de descarga (total, m3)' data={barChart} axesOptions={axes} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const TRUCK_DROPDOWN_ITEMS = values => ({
  'trucks': {
    'text': 'Camiones',
    'subitems': {
      'tolva': { 'text': "Camión Tolva", 'value': values.tolva },
      'aljibe': { 'text': "Camión Aljibe", 'value': values.aljibe },
      'fuel': { 'text': "Camión Combustible", 'value': values.fuel },
      'pluma': { 'text': "Camión Pluma", 'value': values.pluma },
    }
  },
  'heavy': {
    'text': 'Equipos Pesados',
    'subitems': {
      'rodillo': { 'text': 'Rodillo', 'value': values.rodillo },
      'front': { 'text': 'Cargadora Frontal', 'value': values.front },
      'retro': { 'text': 'Retroexcavadora', 'value': values.retro },
      'level': { 'text': 'Motoniveladora', 'value': values.level },
      'dig': { 'text': 'Excavadora', 'value': values.dig },
      'bulldozer': { 'text': 'Bulldozer', 'value': values.bulldozer },
    }
  },
  'support': {
    'text': 'Equipos de Apoyo',
    'subitems': {
      'bus': { 'text': 'Bus', 'value': values.bus },
      'minibus': { 'text': 'Mini Bus', 'value': values.minibus },
    }
  }
})

export const formatTruckItems = (items) => Object.entries(items).map(([key, value]) => ({
  id: key,
  text: value.text
}))

export const SecondRow = () => {
  const { totalTrucks } = useSelector(state => state.performance);
  const items = TRUCK_DROPDOWN_ITEMS({
    tolva: 0,
    aljibe: 0,
    fuel: 0,
    pluma: 0,
    rodillo: 0,
    front: 0,
    retro: 0,
    level: 0,
    dig: 0,
    bulldozer: 0,
    bus: 0,
    minibus: 0,
  });
  const formattedItems = formatTruckItems(items)
  const [selectedItem, setSelectedItem] = useState(formattedItems[0]);

  const graphHeight = "20vh"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          UTILIZACIÓN DE RECURSOS
        </Typography>
      </Card>
      <InformationContainer description='El total del medidor corresponde al total de conductores/máquinas disponibles' />
      <CardRow width='inherit' justifyContent="flex-start">
        <Card padding={spacing05} alignItems="flex-end">
          <Gauge title='Conductores' percentage={totalTrucks} change="100" isBold maxPercent={100} />
        </Card>
        <Div width="100%">
          <TopFilter>
            <Dropdown
              id="default"
              titleText="Grupo de Equipos"
              label="Grupo de Equipos"
              initialSelectedItem={formattedItems[0]}
              items={formattedItems}
              itemToString={item => item ? item.text : ''}
              onChange={({ selectedItem }) => setSelectedItem(selectedItem)}
            />
          </TopFilter>
          <Card flexDirection='row' justifyContent='space-between' padding={spacing05} gap="1.5rem">
            {Object.values(items[selectedItem.id].subitems).map(({ text, value }) =>
              <Gauge key={text} height={graphHeight} title={text} percentage={value} change="100" isBold />
            )
            }
          </Card>
        </Div>
      </CardRow>
    </CardComponent>
  )
}

export const ThirdRow = () => {
  const { loadTimes } = useSelector(state => state.performance)

  const graphWidth = "100%"
  const graphHeight = "40vh"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          TIEMPOS CARGUÍO POR PLATAFORMA
        </Typography>
      </Card>
      <CardRow width='inherit' padding={spacing05}>
        <Card flexDirection='column' justifyContent='center' width={graphWidth}>
          <BoxPlotContainer height={graphHeight} title='Tiempos de Carguío (minutos)' data={loadTimes} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const FourthRow = () => {
  const { queueTimes } = useSelector(state => state.performance)
  const axes = {
    "left": {
      "mapsTo": "key",
      "scaleType": "labels"
    },
    "bottom": {
      "mapsTo": "value"
    }
  }
  const graphHeight = "40vh"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          COLAS EN FRENTES
        </Typography>
      </Card>
      <InformationContainer description='Las colas corresponden al total de camiones que experimentaron pérdida operacional debido a la espera en la cola.' />
      <Card flexDirection='column' justifyContent='center' padding={spacing05}>
        <BarChart title='Tiempos de Cola (media, minutos)' height={graphHeight} data={queueTimes} axesOptions={axes} />
      </Card>
    </CardComponent>
  )
}

export const FifthRow = () => {
  const { cycleTime, heatmapHour, avgSpeed, cycleCount } = useSelector(state => state.performance)
  const graphWidth = "100%"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          RUTAS DE TRANSPORTE
        </Typography>
      </Card>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={cycleTime} title='Tiempos de Ciclo (media, minutos)' />
        </Card>
      </CardRow>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={cycleCount} title='Cantidad de Ciclos (media, ciclos)' />
        </Card>
      </CardRow>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={avgSpeed} title='Velocidad Promedio (media, km/h)' />
        </Card>
      </CardRow>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={heatmapHour} title='M3 por Hora (media, m3/h)' />
        </Card>
      </CardRow>
    </CardComponent>
  )
}
