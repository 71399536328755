import React from "react";
import { useSelector } from "react-redux";
import {
    Button,
    DataTable, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
    TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch
} from "@carbon/react";
import { Filter } from "@carbon/icons-react";
import { useSorting } from "../../modules/hooks/sorting";

const FlatTiresTable = () => {
    // Get flat tires data from the Redux state
    const { flat } = useSelector((state) => state.notification);

    // Define table headers
    const headerData = [
        {
            header: "Fecha",
            key: "timestamp",
            sortDirection: "DESC",
            isDefaultSortable: true,
        },
        {
            header: "Patente",
            key: "patente",
        },
        {
            header: "Geocerca",
            key: "geocerca",
        },
    ];

    // Use sorting hook for table data
    const { headers, rows } = useSorting(flat, headerData);

    // Define the table structure
    const Default = () => (
        <DataTable rows={rows} headers={headers} width="100%">
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getExpandedRowProps,
                getTableProps,
                getTableContainerProps,
                onInputChange,
                sortBy,
            }) => (
                <TableContainer {...getTableContainerProps()}>
                    <TableToolbar>
                        <TableToolbarContent>
                            {/* Search functionality */}
                            <TableToolbarSearch onChange={onInputChange} />
                            <Button
                                renderIcon={Filter}
                                kind="secondary"
                                hasIconOnly
                                iconDescription="Filter table"
                            />
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()} aria-label="Flat Tires Table">
                        <TableHead>
                            <TableRow>
                                {headers.map((header, i) => (
                                    <TableHeader
                                        key={i}
                                        sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                        {...getHeaderProps({
                                            header,
                                            isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                            onClick: (_, sortState) => sortBy(sortState.sortHeaderKey),
                                        })}
                                    >
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <React.Fragment key={row.id}>
                                    <TableRow
                                        {...getRowProps({
                                            row,
                                        })}
                                    >
                                        {row.cells.map((cell) => (
                                            <TableCell key={cell.id}>{cell.value}</TableCell>
                                        ))}
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </DataTable>
    );

    return <Default />;
};

export default FlatTiresTable;
