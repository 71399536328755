import React from 'react'
import TitleText from '../../../components/Layouts/Title'
import Typography from '../../../components/Basics/Typography'
import CardButton from '../../../components/Basics/CardButton.container'
import { Column, Root } from './Security.styles'
import routes from '../../../config/settings/routes'
import { g100 } from '@carbon/themes';
import { Stack } from "@carbon/react";
import { Separator } from '../../Dashboard/Dashboard.styles'

/**
 * The Security's component
 */
const Security = () => {
  console.log(routes.securityCan)
  return (
    <>
      <TitleText
        title='Seguridad'
        subtitle='Módulo para el registro de transgresiones de velocidad.'
      />
      <Root>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Conducción</Typography>
            <Separator />
            <CardButton title='Transgresiones de Velocidad' route={routes.securityVelocityViolation} />
            <CardButton title='Transgresiones de Velocidad Histórico' route={routes.securityVelocityHistoric} />
            <CardButton title='Acercamientos entre camiones' route={routes.securityTruckApproach} />
            <CardButton title='Fatiga y somnolencia' route={routes.securityFatigue} />
            <CardButton title='Dashboard CAN Histórico' route={routes.securityCan} />
            <CardButton title='Sensores presión' route={routes.securitySensorsCount} />
            <CardButton title='Frenos y Retardador' route={routes.securityBrake} />
            <CardButton title='Registro Cruce Tren' route={routes.securityTrain} />
          </Stack>
        </Column>
      </Root>
    </>
  )
}

export default Security
