import React from 'react'
import { PRODUCTIVITY_TYPE } from "../config/settings/constant";
import routes from "../config/settings/routes";
import { usePathParam } from "../modules/hooks/route";
import ProtectBySession from "../components/Security/ProtectBySession";
import BrandLayout from "../components/Layouts/BrandLayout";
import { ScisControlTower } from '@carbon/icons-react'
import Productivity from "../containers/Productivity/Initial/Productivity.container";
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import Navbar from "../containers/Navbar/Navbar.container";
import ManagementMenu from "../containers/ManagementMenu/ManagementMenu.container";
import Heading from "../components/Layouts/Heading";
import DailyReportContainer from "../containers/Productivity/DailyReports/DailyReport.container";
import HistoricReportContainer from '../containers/Productivity/HistoricReports/HistoricReport.container';
import MonthlyReportContainer from '../containers/Productivity/MonthlyReports/MonthlyReport.container';
import Idle from '../containers/Productivity/Idle/Idle.container';
import FlatTire from '../containers/Productivity/FlatTire/FlatTire.container';
import WeeklyReportContainer from '../containers/Productivity/WeeklyReports/WeeklyReport.container';
import AsarcoContainer from '../containers/Productivity/Asarco/Asarco.container';
import Speed from '../containers/Productivity/SpeedReports/Speed.container';
import AccessControl from '../components/Security/ProtectByGroup';
import KpiTreeContainer from '../containers/Productivity/KpiTree/KpiTree.container';
import BreakfastContainer from '../containers/Productivity/Breakfast/Breakfast.container';
import SpeedGeofence from '../containers/Productivity/SpeedGeofence/SpeedGeofence.container';
import DailyReportContainerV2 from '../containers/Productivity/DailyReportsV2/DailyReportV2.container';
/**
 * The Productivity page.
 */
const ProductivityPage = () => {
  const type = usePathParam('type').toUpperCase();
  console.log(type)
  const TypeTitle = type => {
    switch (type) {
      case PRODUCTIVITY_TYPE.HISTORIC:
        return 'Producción Histórica'
      case PRODUCTIVITY_TYPE.DAILY:
        return 'Reportes Diarios'
      case PRODUCTIVITY_TYPE.WEEKLY:
        return 'Reportes Semanales'
      case PRODUCTIVITY_TYPE.MONTHLY:
        return 'Reportes Mensuales'
      case PRODUCTIVITY_TYPE.IDLE:
        return 'Tiempo en Ralentí'
      case PRODUCTIVITY_TYPE.PUNCTURES:
        return 'Registro de Pinchazos'
      case PRODUCTIVITY_TYPE.ASARCO:
        return 'ASARCO'
      case PRODUCTIVITY_TYPE.SPEED:
        return 'Registro de Velocidades'
      case PRODUCTIVITY_TYPE.KPITREE:
        return 'Árbol de Kpi'
      case PRODUCTIVITY_TYPE.BREAKFAST:
        return 'Duración Colación'
      case PRODUCTIVITY_TYPE.SPEEDGEOFENCE:
        return 'Velocidad Geocerca'
      case PRODUCTIVITY_TYPE.DAILYTURN:
        return 'Reporte Turno'
      default:
        return 'Productividad'
    }
  };

  const getTooltipContent = type => {
    switch (type) {
      case PRODUCTIVITY_TYPE.HISTORIC:
        return 'Vista general para el análisis de la producción histórica y su evolución en el tiempo.'
      case PRODUCTIVITY_TYPE.DAILY:
        return 'Vista para consultar reportes completos de la producción por día, la producción de los frentes y rendimiento de los operadores.'
      case PRODUCTIVITY_TYPE.WEEKLY:
        return 'Vista para consultar reportes completos de la producción por semana, la producción de los frentes y rendimiento de los operadores.'
      case PRODUCTIVITY_TYPE.MONTHLY:
        return 'Vista para consultar reportes completos de la producción por mes, la producción de los frentes y rendimiento de los operadores.'
      case PRODUCTIVITY_TYPE.IDLE:
        return 'Vista histórica con eventos de ralentí para consultas en ventanas de tiempo especificas.'
      case PRODUCTIVITY_TYPE.PUNCTURES:
        return 'Vista histórica con eventos de Pinchazos para consultas en ventanas de tiempo especificas.'
      case PRODUCTIVITY_TYPE.ASARCO:
        return 'Vista para consultar tiempos de operación utilizando la norma ASARCO.'
      case PRODUCTIVITY_TYPE.SPEED:
        return 'Detalles sobre el registro de velocidades'
      case PRODUCTIVITY_TYPE.KPITREE:
        return 'Vista para consultar información del rendimiento a través de indicadores claves de desempeño según el tipo de equipo.'
      case PRODUCTIVITY_TYPE.BREAKFAST:
        return 'Vista para consultar las horas de duración de colación de los operadores.'
      case PRODUCTIVITY_TYPE.SPEEDGEOFENCE:
        return 'Vista para consultar las estadisticas de velocidades por geocerca.'
      case PRODUCTIVITY_TYPE.DAILYTURN:
        return 'Vista para consultar reportes de producción por turno'
      default:
        return 'Módulo de productividad'
    }
  };

  const ProductivityBreadcrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={PRODUCTIVITY_TYPE[type] === PRODUCTIVITY_TYPE['PRODUCTIVITY-INITIAL']} href={routes.productivityInitial}>
          Producción y Reportabilidad
        </BreadcrumbItem>
        {PRODUCTIVITY_TYPE[type] !== PRODUCTIVITY_TYPE['PRODUCTIVITY-INITIAL'] && <BreadcrumbItem isCurrentPage>{TypeTitle(type)}</BreadcrumbItem>}
      </Breadcrumb>
    )
  }
  const typeToComponentMap = {
    [PRODUCTIVITY_TYPE['PRODUCTIVITY-INITIAL']]: Productivity,
    [PRODUCTIVITY_TYPE.HISTORIC]: HistoricReportContainer,
    [PRODUCTIVITY_TYPE.DAILY]: DailyReportContainer,
    [PRODUCTIVITY_TYPE.WEEKLY]: WeeklyReportContainer,
    [PRODUCTIVITY_TYPE.MONTHLY]: MonthlyReportContainer,
    [PRODUCTIVITY_TYPE.IDLE]: Idle,
    [PRODUCTIVITY_TYPE.PUNCTURES]: FlatTire,
    [PRODUCTIVITY_TYPE.ASARCO]: AsarcoContainer,
    [PRODUCTIVITY_TYPE.SPEED]: Speed,
    [PRODUCTIVITY_TYPE.KPITREE]: KpiTreeContainer,
    [PRODUCTIVITY_TYPE.BREAKFAST]: BreakfastContainer,
    [PRODUCTIVITY_TYPE.SPEEDGEOFENCE]: SpeedGeofence,
    [PRODUCTIVITY_TYPE.DAILYTURN]: DailyReportContainerV2
  }
  const ComponentToRender = typeToComponentMap[type];

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.productivity} />} />}
        header={<Heading title={TypeTitle(type)} icon={<ScisControlTower size={50} />} breadcrumbs={<ProductivityBreadcrumbs />} tooltipContent= {getTooltipContent(type)}/>}
      >
        <AccessControl type={type}>
          {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
        </AccessControl>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default ProductivityPage
