import styled from "styled-components";

export const TableDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
`;

export const Div = styled.div`
  align-items: ${props => props.alignItems};
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection};
  flex-wrap: ${props => props.flexWrap};
  gap: ${props => props.gap};
  grid-template-columns: ${props => props.gridTemplateColumns};
  justify-content: ${props => props.justifyContent};
  margin: ${props => props.margin};
  padding: ${props => props.padding || 0};
  width: ${props => props.width};
`;

export const FilterDiv = styled.div`
  width: inherit;
  min-width: 10%;
  width: ${props => props.width};
  align-items: ${props => props.alignItems};
  display: ${props => props.display};
  justify-content: ${props => props.justifyContent};
`
