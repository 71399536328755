import { API } from 'aws-amplify';
import {
    REACT_APP_BASE_KEY,
    REACT_APP_BASE_NAME,
    REACT_APP_CLIENT_NAME,
} from '../config/settings/environments';

export const getHdopTrucks = async ({ year, month, day }) => {
    const paths = {
        talabre: '/v1/get-hdop-trucks',
    };
    let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-hdop-trucks';
    if (year && month && day) {
        path = `${path}?year=${year}&month=${month}&day=${day}`;
    }

    const response = await API.get(REACT_APP_BASE_NAME, path, {
        headers: { 'x-api-key': REACT_APP_BASE_KEY },
        response: true,
    });

    return {
        data: response.data,
        headers: response.headers,
        request: response.config,
        status: response.status,
    };
};