import styled from 'styled-components';
import { g90, g100 } from '@carbon/themes';

export const Root = styled.div`
  align-items: left;
  background-color: ${g90.background};
  border: 1px solid ${g100.borderTile01};
  mix-blend-mode: ${props => props.mixBlendMode && 'soft-light'};
  height: ${props => props.height || 'inherit'};
  max-width: 21.25rem;
  min-width: 21.25rem;
  padding: 15px;
  display: ${props => (props.hidden ? 'none' : 'block')};
`;
