import { makeActionCreator } from '../../../config/store/utils';
import { formatDate } from '../../../modules/utils/formatters';
import { getHistoricReport } from '../../../services/productivityService';

export const GET_HISTORIC = 'GET_HISTORIC';
export const GET_HISTORIC_ERROR = 'GET_HISTORIC_ERROR';
export const GET_HISTORIC_SUCCESS = 'GET_HISTORIC_SUCCESS';
export const onGetHistoric = makeActionCreator(GET_HISTORIC);
export const onGetHistoricError = makeActionCreator(
  GET_HISTORIC_ERROR,
  'payload'
);
export const onGetHistoricSuccess = makeActionCreator(
  GET_HISTORIC_SUCCESS,
  'payload'
);
export const onGetHistoricThunk = (initial, end) => async dispatch => {
  dispatch(onGetHistoric());
  try {
    let endpointParams = {};
    const pattern = 'yyyy-MM-dd';
    if (initial & end) {
      const _dateInitial = formatDate(initial, pattern);
      const _dateFinal = formatDate(end, pattern);
      endpointParams = {
        initial: _dateInitial,
        end: _dateFinal,
      };
    }
    const { data } = await getHistoricReport(endpointParams);

    const lastre = data.data.lastre;
    const walls = data.data.muros;
    const _wallsList = Object.keys(walls);
    const wallsList = _wallsList.map(obj => {
      return { id: obj, text: obj };
    });
    const trucks = data.data.camiones;
    const productivity = data.data.productividad.map(obj => ({
      ...obj,
      group: 'Tiempo Efectivo',
    }));
    const cycleTime = data.data.tiempo_ciclo.map(obj => ({
      ...obj,
      group: 'Duración de Ciclos',
    }));

    return dispatch(
      onGetHistoricSuccess({
        lastre: lastre,
        walls: walls,
        wallsList: wallsList,
        trucks: trucks,
        productivity: productivity,
        cycleTime: cycleTime,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(onGetHistoricError({ error: error }));
  }
};
