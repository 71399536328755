import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from '../../../components/Layouts/Card'
import Typography from '../../../components/Basics/Typography'
import { ChartBarStacked, Group, QOperationGauge } from '@carbon/icons-react'
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import Gauge from '../../../components/Graphs/Gauge.container'
import BarChart from '../../../components/Graphs/BarChart.container'
import Heatmap from '../../../components/Graphs/Heatmap.container'
import VerticalBarChart from '../../../components/Graphs/VerticalBarChart.container'
import HorizontalBoxPLot from '../../../components/Graphs/HorizontalBoxPlot.container'
import GroupBarChart from '../../../components/Graphs/GroupBarChart.container'
import Histogram from '../../../components/Graphs/Histogram.container'
import { scaleLinear } from 'd3-scale';
import { XYPlot, XAxis, YAxis, HeatmapSeries, LabelSeries, makeVisFlexible } from 'react-vis';
import FlexHeatMap from '../../../components/Graphs/FlexHeatMap.container'
import { spacing05 } from "@carbon/themes"
import InformationContainer from '../../../components/Basics/Information.container'
import { Div } from '../../../components/Basics/Div.styles'
import { TopFilter } from '../Idle/Idle.styles'
import { Dropdown } from '@carbon/react'
import { convertTo12HourFormat } from '../../../components/Graphs/utils'
import { TRUCK_DROPDOWN_ITEMS, formatTruckItems } from '../../Monitoring/Performance/Performance.row'

export const FirstRow = () => {
  const { dailyProduction, m3Production, dailyProductionBefore } = useSelector(state => state.daily)

  const change = `${dailyProduction - dailyProductionBefore}`
  const barChart = m3Production
  const gaugeStatus = dailyProduction > dailyProductionBefore ? 'success' : 'danger'

  const axis = {
    left: {
      scaleType: 'labels',
      mapsTo: 'key'
    },
    bottom: {
      mapsTo: 'value'
    }
  }
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          PRODUCCIÓN DIA
        </Typography>
      </Card>
      <InformationContainer description="Solo se mostrarán los frentes que recibieron descargas durante el día del reporte." />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="17%" padding={spacing05}>
          <Gauge title='Producción Diaria' footer="Comparación con día anterior" percentage={dailyProduction} change={change} maxPercent={17000} noMetric={true} arrowStatus={gaugeStatus} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="83%" padding={spacing05}>
          <GroupBarChart title='Producción por Frentes de Descarga (total, m3)' data={barChart} axesOptions={axis} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const SecondRow = () => {
  const { loadCount, loadTime, discharge, wallTime, transformedData } = useSelector(state => state.daily)
  const axis = {
    left: {
      mapsTo: 'value'
    },
    bottom: {
      scaleType: 'labels',
      mapsTo: 'key'
    }
  }
  return (
    <>
      <CardComponent width="100%" >
        <Card justifyContent='flex-start' >
          <ChartBarStacked size={18} />
          <Typography className='cds--type-heading-compact-01'>
            CARGAS
          </Typography>
        </Card>
        <InformationContainer description="Únicamente se visualizarán las plataformas que registraron cargas." />
        <CardRow width='inherit'>
          <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
            <GroupBarChart title='Cargas por Plataforma (total, cargas)' data={loadCount} axisOptions={axis} />
          </Card>
          <Card flexDirection='column' justifyConten='center' width="50%" padding={spacing05}>
            <GroupBarChart title='Duración de Cargas (media, minutos)' data={loadTime} axisOptions={axis} />
          </Card>
        </CardRow>
        <Card justifyContent='flex-start' >
          <ChartBarStacked size={18} />
          <Typography className='cds--type-heading-compact-01'>
            DESCARGAS
          </Typography>
        </Card>
        <InformationContainer description="Solo se mostrarán los frentes que recibieron descargas durante el día del reporte." />
        <CardRow width='inherit'>
          <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
            <GroupBarChart title='Descargas Por Frente (total, descargas)' data={discharge} axisOptions={axis} />
          </Card>
          <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
            <GroupBarChart title='Tiempos en Frentes de Descarga (media, minutos)' data={wallTime} axisOptions={axis} />
          </Card>
          {transformedData.length > 0 && (
            <Card flexDirection='column' justifyContent='center' width="50%" padding={spacing05}>
              <GroupBarChart title='Equipos cargados al final del turno por ubicación (camiones, total)' data={transformedData} axisOptions={axis} />
            </Card>
          )}
        </CardRow>
      </CardComponent>
    </>
  )
}

export const ThirdRow = () => {
  const { queueTimes } = useSelector(state => state.daily)
  const graphHeight = "40vh";

  const axis = {
    left: {
      title: "Geocerca",
      scaleType: 'labels',
      mapsTo: 'key'
    },
    bottom: {
      title: "Minutos",
      mapsTo: 'value'
    }
  };

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          COLAS EN CARGA Y DESCARGA - MINUTOS
        </Typography>
      </Card>
      <InformationContainer description="Las colas corresponden al total de pérdida operacional generada por la espera en la cola." />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart height={graphHeight} title='Colas en Frentes de Descarga y Plataformas (media, minutos)' data={queueTimes} axesOptions={axis} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const FourthRow = () => {
  const { numberTrucks, trucksDay, trucksDayBefore, truckMapping } = useSelector(state => state.daily);
  const items = TRUCK_DROPDOWN_ITEMS(trucksDay);
  const formattedItems = formatTruckItems(items)
  const [selectedItem, setSelectedItem] = useState(formattedItems[0]);

  const graphHeight = "20vh"

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          UTILIZACIÓN DE RECURSOS - DIA
        </Typography>
      </Card>
      <InformationContainer description='El total del medidor corresponde al total de conductores/máquinas disponibles' />
      <CardRow width='inherit' justifyContent="flex-start">
        <Card padding={spacing05} alignItems="flex-end">
          <Gauge title='Conductores' percentage={numberTrucks} isBold />
        </Card>
        <Div width="100%">
          <TopFilter>
            <Dropdown
              id="default"
              titleText="Grupo de Equipos"
              label="Grupo de Equipos"
              initialSelectedItem={formattedItems[0]}
              items={formattedItems}
              itemToString={item => item ? item.text : ''}
              onChange={({ selectedItem }) => setSelectedItem(selectedItem)}
            />
          </TopFilter>
          <Card flexDirection='row' justifyContent='space-between' padding={spacing05} gap="1.5rem">
            {Object.values(items[selectedItem.id].subitems).map(({ text, value }) => {
              const key = truckMapping[text]
              const previousValue = trucksDayBefore[key]
              const change = previousValue !== undefined ? value : 'N/A'
              return <Gauge key={text} height={graphHeight} title={text} percentage={value} isBold />
            })
            }
          </Card>
        </Div>
      </CardRow>
    </CardComponent>
  )
}

export const FifthRow = () => {
  const { numberTrucksNight, trucksNight, trucksNightBefore, truckMapping } = useSelector(state => state.daily)

  const items = TRUCK_DROPDOWN_ITEMS(trucksNight);
  const formattedItems = formatTruckItems(items)
  const [selectedItem, setSelectedItem] = useState(formattedItems[0]);

  const graphHeight = "20vh"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          UTILIZACIÓN DE RECURSOS - NOCHE
        </Typography>
      </Card>
      <InformationContainer description='El total del medidor corresponde al total de conductores/máquinas disponibles' />
      <CardRow width='inherit' justifyContent="flex-start">
        <Card padding={spacing05} alignItems="flex-end">
          <Gauge title='Conductores' percentage={numberTrucksNight} isBold />
        </Card>
        <Div width="100%">
          <TopFilter>
            <Dropdown
              id="default"
              titleText="Grupo de Equipos"
              label="Grupo de Equipos"
              initialSelectedItem={formattedItems[0]}
              items={formattedItems}
              itemToString={item => item ? item.text : ''}
              onChange={({ selectedItem }) => setSelectedItem(selectedItem)}
            />
          </TopFilter>
          <Card flexDirection='row' justifyContent='space-between' padding={spacing05} gap="1.5rem">
            {Object.values(items[selectedItem.id].subitems).map(({ text, value }) => {
              const key = truckMapping[text]
              const previousValue = trucksNight[key]
              const change = previousValue !== undefined ? value : 'N/A'
              return <Gauge key={text} height={graphHeight} title={text} percentage={value} isBold />
            })
            }
          </Card>
        </Div>
      </CardRow>
    </CardComponent>
  )
}

export const SixthRow = () => {
  const { prodHours, prodHoursIdle, meanCyclePerTurn, distanceTraveled, m3Moved, maintenance } = useSelector(state => state.daily)
  const axis = {
    left: {
      mapsTo: 'value'
    },
    bottom: {
      scaleType: 'labels',
      mapsTo: 'key'
    }
  }

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          RENDIMIENTO DE TRANSPORTE
        </Typography>
      </Card>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart title='Tiempo Efectivo (media, horas)' data={prodHours} axisOptions={axis} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart title='Tiempo Fuera de Servicio (total, minutos)' data={maintenance} axisOptions={axis} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart title='Tiempo en Ralenti (media, horas)' data={prodHoursIdle} axisOptions={axis} />
        </Card>
      </CardRow>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart title='Ciclos por Camión Tolva (media, ciclos)' data={meanCyclePerTurn} axisOptions={axis} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart title='Distancia Recorrida (media, km)' data={distanceTraveled} axisOptions={axis} />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
          <GroupBarChart title='M3 Movido (media, m3)' data={m3Moved} axisOptions={axis} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const SeventhRow = () => {
  const { goodStart, ending, startCycle, breakFast } = useSelector(state => state.daily)
  const axis = {
    bottom: {
      scaleType: "linear",
      title: 'Hora',
      mapsTo: 'value',
      bins: 24,
      domain: [8, 32],
      ticks: {
        values: [8, 11, 14, 17, 20, 23, 26, 29, 32],
        formatter: (value, i) => {
          // Convert the transformed value back to the original hour
          const formattedStart = convertTo12HourFormat(value);

          const formattedValue = `${formattedStart}`;
          return formattedValue
        }
      }
    },
    left: {
      title: 'Valor',
      scaleType: 'lineal',
      binned: true
    }
  }

  const legend = {
    order: ["Turno Día", "Turno Noche"]
  }

  const getFillColor = (group) => {
    const turnoDia = legend.order[0];
    return group === turnoDia ? "#8a3ffc" : "#009d9a"
  }

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <ChartBarStacked size={18} />
        <Typography className='cds--type-heading-compact-01'>
          TIEMPOS DE JORNADA - HISTOGRAMA
        </Typography>
      </Card>
      <InformationContainer description="Cada barra en el histograma representa la cantidad de conductores en un intervalo específico de una hora." />
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05} gap="1.5rem">
          <Histogram title='Hora Inicio Efectivo (total, conductores)' data={goodStart} axesOptions={axis} legendOptions={legend} getFillColor={getFillColor} />
          <InformationContainer noBorder description="La Hora de Inicio Efectivo se refiere al momento en el que el conductor enciende el camión por primera vez." />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05} gap="1.5rem">
          <Histogram title='Hora Término Efectivo (total, conductores)' data={ending} axesOptions={axis} legendOptions={legend} getFillColor={getFillColor} />
          <InformationContainer noBorder description="La Hora de Inicio Efectivo se refiere al momento en el en el cual el conductor baja del camión al producirse el cambio de turno." />
        </Card>
      </CardRow>
      <CardRow width='inherit'>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05} gap="1.5rem">
          <Histogram title='Inicio Primer Ciclo (total, conductores)' data={startCycle} axesOptions={axis} legendOptions={legend} getFillColor={getFillColor} />
          <InformationContainer noBorder description="El inicio del primer Ciclo corresponde en el momento en el que el conductor inicia su primera carga." />
        </Card>
        <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05} gap="1.5rem">
          <HorizontalBoxPLot title='Duración Colación (media, minutos)' data={breakFast} />
          <InformationContainer noBorder description="El gráfico de Boxplot le permiten visualizar y comparar la distribución y la tendencia central de las duraciones de colación." />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const EighthRow = () => {
  const { cycleTime, heatmapHour, avgSpeed, cycleCount } = useSelector(state => state.daily)
  const graphWidth = "100%"

  return (
    <>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <ChartBarStacked size={18} />
          <Typography className='cds--type-heading-compact-01'>
            RUTAS DE TRANSPORTE
          </Typography>
        </Card>
        <InformationContainer description="Las rutas corresponden al rendimiento de los camiones en los ciclos registrados." />
        <InformationContainer description="Se compara el rendimiento del día con el del día anterior." />
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={cycleTime} title='Duración de Ciclos (media, minutos)' changeColor={true} />
          </Card>
        </CardRow>
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={cycleCount} title='Cantidad de Ciclos (total, ciclos)' />
          </Card>
        </CardRow>
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={avgSpeed} title='Velocidad Promedio (media, km/h)' />
          </Card>
        </CardRow>
        <CardRow>
          <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
            <FlexHeatMap data={heatmapHour} title='M3 por Hora (media, m3/h)' />
          </Card>
        </CardRow>
      </CardComponent>
    </>
  )
}
