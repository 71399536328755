import {
  GET_TRUCKS,
  GET_TRUCKS_ERROR,
  GET_TRUCKS_SUCCESS,
} from './Machine.actions';

const machinesState = {
  data: [],
  loading: true,
  error: undefined,
};

/**
 * The Machines reducer.
 */
const machinesReducer = (state = machinesState, { payload, type }) => {
  switch (type) {
    case GET_TRUCKS: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_TRUCKS_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_TRUCKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: payload.data,
      };
    }
    default:
      return state;
  }
};

export default machinesReducer;
