export default function AnimationVariant4({ className }) {
    return (
        <svg id="svg3" width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g x="45.2585" y="89.2891" id="text3" className={className}>
                <rect x="45.2585" y="89.2891" width="62.0253" height="62.0253"
                    transform="rotate(-135 45.2585 89.2891)" fill="#525252" stroke="#8D8D8D" />
            </g>
            <path
                d="M59.2311 36.1845H54.5735V31.527C54.5728 30.9096 54.3272 30.3177 53.8906 29.8812C53.4541 29.4446 52.8622 29.199 52.2448 29.1982H31.2859C30.6685 29.199 30.0766 29.4446 29.64 29.8812C29.2035 30.3177 28.9579 30.9096 28.9571 31.527V52.4859C28.9579 53.1033 29.2035 53.6952 29.64 54.1318C30.0766 54.5683 30.6685 54.8139 31.2859 54.8147H35.9434V59.4722C35.9442 60.0896 36.1898 60.6815 36.6263 61.1181C37.0629 61.5546 37.6548 61.8002 38.2722 61.801H59.2311C59.8485 61.8002 60.4404 61.5546 60.8769 61.1181C61.3135 60.6815 61.5591 60.0896 61.5598 59.4722V38.5133C61.5591 37.8959 61.3135 37.304 60.8769 36.8675C60.4404 36.4309 59.8485 36.1853 59.2311 36.1845ZM52.2448 52.4859H42.9297V46.664H52.2448V52.4859ZM52.2448 44.3352H42.9297V38.5133H52.2448V44.3352ZM40.6009 44.3352H31.2859V38.5133H40.6009V44.3352ZM52.2448 31.527V36.1845H31.2859V31.527H52.2448ZM31.2859 52.4859V46.664H40.6009V52.4859H31.2859ZM59.2311 59.4722H38.2722V54.8147H52.2448C52.8622 54.8139 53.4541 54.5683 53.8906 54.1318C54.3272 53.6952 54.5728 53.1033 54.5735 52.4859V38.5133H59.2311V59.4722Z"
                fill="white" />
        </svg>
    )
}