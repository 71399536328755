import { API } from 'aws-amplify';
import {
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
} from '../config/settings/environments';

/**
 * To get notifications.
 */
export const getNotificationReport = async ({ date, currTurn }) => {
  const paths = {
    talabre: '/v1/get-notifications',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-notifications';
  if (date && currTurn) {
    path = `${path}?date=${date}&current_turn=${currTurn}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};
