import { makeActionCreator } from '../../../config/store/utils';
import {
  createMaintenance,
  getMaintenance,
  editMaintenance,
  createMaintenanceV2,
  getMaintenanceV2,
} from '../../../services/maintenanceService';
import { batch } from 'react-redux';
import { getTimeDifference } from '../../../modules/utils/helpers';
import { getTime } from 'date-fns';
import { format } from 'date-fns';

// Old action constants and creators for individual maintenance submissions
export const SEND_MAINTENANCE = 'SEND_MAINTENANCE';
export const SEND_MAINTENANCE_ERROR = 'SEND_MAINTENANCE_ERROR';
export const SEND_MAINTENANCE_SUCCESS = 'SEND_MAINTENANCE_SUCCESS';
export const onSendMaintenance = makeActionCreator(SEND_MAINTENANCE);
export const onSendMaintenanceError = makeActionCreator(
  SEND_MAINTENANCE_ERROR,
  'payload'
);
export const onSendMaintenanceSuccess = makeActionCreator(
  SEND_MAINTENANCE_SUCCESS,
  'payload'
);
export const onSendMaintenanceThunk = params => async (dispatch, getState) => {
  dispatch(onSendMaintenance());

  try {
    const { data: response } = await createMaintenance({
      params,
    });

    return batch(() => {
      dispatch(onSendMaintenanceSuccess());
    });
  } catch (error) {
    return batch(() => {
      dispatch(onSendMaintenanceError({ error }));
    });
  }
};

// New action constants and creators for Excel maintenance submissions
export const SEND_MAINTENANCE_EXCEL = 'SEND_MAINTENANCE_EXCEL';
export const SEND_MAINTENANCE_EXCEL_ERROR = 'SEND_MAINTENANCE_EXCEL_ERROR';
export const SEND_MAINTENANCE_EXCEL_SUCCESS = 'SEND_MAINTENANCE_EXCEL_SUCCESS';
export const onSendMaintenanceExcel = makeActionCreator(SEND_MAINTENANCE_EXCEL);
export const onSendMaintenanceExcelError = makeActionCreator(
  SEND_MAINTENANCE_EXCEL_ERROR,
  'payload'
);
export const onSendMaintenanceExcelSuccess = makeActionCreator(
  SEND_MAINTENANCE_EXCEL_SUCCESS,
  'payload'
);

// Thunk for handling Excel data submission
export const onSendMaintenanceExcelThunk = data => async (
  dispatch,
  getState
) => {
  dispatch(onSendMaintenanceExcel());

  try {
    // Pass data directly, as it’s already wrapped in the correct structure
    const { data: response } = await createMaintenanceV2(data);

    return batch(() => {
      dispatch(onSendMaintenanceExcelSuccess(response));
    });
  } catch (error) {
    return batch(() => {
      dispatch(onSendMaintenanceExcelError({ error }));
    });
  }
};
// Utility function to format date to 'YYYY-MM-DD' and handle invalid dates
const formatDate = date => {
  if (!date) return null; // Return null if date is invalid
  try {
    return format(new Date(date), 'yyyy-MM-dd');
  } catch (e) {
    console.error('Invalid date provided:', date);
    return null;
  }
};
// Original maintenance retrieval and editing actions remain unchanged
export const GET_MAINTENANCE = 'GET_MAINTENANCE';
export const GET_MAINTENANCE_ERROR = 'GET_MAINTENANCE_ERROR';
export const GET_MAINTENANCE_SUCCESS = 'GET_MAINTENANCE_SUCCESS';
export const onGetMaintenance = makeActionCreator(GET_MAINTENANCE);
export const onGetMaintenanceError = makeActionCreator(
  GET_MAINTENANCE_ERROR,
  'payload'
);
export const onGetMaintenanceSuccess = makeActionCreator(
  GET_MAINTENANCE_SUCCESS,
  'payload'
);
export const onGetMaintenanceThunk = (startDate, endDate) => async dispatch => {
  dispatch(onGetMaintenance());
  try {
    // If dates are empty, assign default values
    const formattedStartDate =
      formatDate(startDate) || format(new Date().setDate(1), 'yyyy-MM-dd');
    const formattedEndDate =
      formatDate(endDate) || format(new Date(), 'yyyy-MM-dd');

    // Pass formatted dates to getMaintenance function
    const { data } = await getMaintenanceV2(
      formattedStartDate,
      formattedEndDate
    );
    let idCounter = 1;
    // Add a unique key to each item in data
    const completedWithKeys = data.items.map(item => ({
      ...item,
      id: `item-${idCounter++}`, // Add unique key to each object
    }));
    const onGoing = [];
    const completed = [];

    return dispatch(
      onGetMaintenanceSuccess({
        completed: completedWithKeys,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetMaintenanceError({
        error: error,
      })
    );
  }
};

export const SET_MAINTENANCE_MODAL = 'SET_MAINTENANCE_MODAL';
export const SET_MAINTENANCE_MODAL_ERROR = 'SET_MAINTENANCE_MODAL_ERROR';
export const SET_MAINTENANCE_MODAL_SUCCESS = 'SET_MAINTENANCE_MODAL_SUCCESS';
export const onSetMaintenanceModal = makeActionCreator(SET_MAINTENANCE_MODAL);
export const onSetMaintenanceModalError = makeActionCreator(
  SET_MAINTENANCE_MODAL_ERROR,
  'payload'
);
export const onSetMaintenanceModalSuccess = makeActionCreator(
  SET_MAINTENANCE_MODAL_SUCCESS,
  'payload'
);
export const onSetMaintenanceModalThunk = params => async (
  dispatch,
  getState
) => {
  return batch(() => {
    dispatch(
      onSetMaintenanceModalSuccess({
        modalParams: params,
      })
    );
  });
};

export const EDIT_MAINTENANCE = 'EDIT_MAINTENANCE';
export const EDIT_MAINTENANCE_ERROR = 'EDIT_MAINTENANCE_ERROR';
export const EDIT_MAINTENANCE_SUCCESS = 'EDIT_MAINTENANCE_SUCCESS';
export const onEditMaintenance = makeActionCreator(EDIT_MAINTENANCE);
export const onEditMaintenanceError = makeActionCreator(
  EDIT_MAINTENANCE_ERROR,
  'payload'
);
export const onEditMaintenanceSuccess = makeActionCreator(
  EDIT_MAINTENANCE_SUCCESS,
  'payload'
);
export const onEditMaintenanceThunk = params => async (dispatch, getState) => {
  dispatch(onEditMaintenance());
  try {
    const { data: response } = await editMaintenance({
      params,
    });

    return batch(() => {
      dispatch(onEditMaintenanceSuccess());
    });
  } catch (error) {
    return batch(() => {
      dispatch(onEditMaintenanceError({ error }));
    });
  }
};
