import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { PATH_NAMES } from '../config/settings/constant';
/**
 * The History's page
 */
const MAX_VISITED_PATHS = 10;

const History = props => {
  const { children } = props
  const [visitedPaths, setVisitedPaths] = useState(() => {
    const storedPaths = localStorage.getItem('visitedPaths');
    return storedPaths ? JSON.parse(storedPaths) : [];
  });

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const currentPath = location.pathname;
      // Add the current path to the list of visited paths
      setVisitedPaths((prevPaths) => {
        const pathName = PATH_NAMES[currentPath]
        const newPath = {
          name: pathName,
          link: currentPath
        }
        const newPaths = [...prevPaths, newPath].slice(-MAX_VISITED_PATHS);
        // Save the updated paths to localStorage
        localStorage.setItem('visitedPaths', JSON.stringify(newPaths));
        return newPaths;
      });
    });

    // Cleanup the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      {children}
    </>
  )
}

History.propTypes = {
  children: PropTypes.node.isRequired
}

export default History