import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@carbon/react'
import { Root } from './InfoCard.styles'
import Typography from './Typography'

/**
* The Card's component
*/
const Card = props => {
  const { icon, title, subtitle, body, footer, graph, height, disabled, hidden } = props

  return (
    <Root mixBlendMode={disabled} height={height} hidden={hidden}>
      <Stack gap={3}>
        {icon && (
          <>
            {icon}
          </>
        )}
        <Typography className='cds--type-heading-04'>{title}</Typography>
        <Typography className='cds--type-body-01' padding={body ? '0' : '0 0 54px'} >{subtitle}</Typography>
        <Typography className='cds--type-heading-07'>{body}</Typography>
        <Typography className='cds--type-body-01' padding={graph ? '0' : '0 0 30px'}>{footer}</Typography>
        {graph && (
          <>
            {graph}
          </>
        )}
      </Stack>
    </Root>
  )
}

Card.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
  subtitle: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
  graph: PropTypes.node,
  hidden: PropTypes.bool
}

export default Card