import React from "react";
import PropTypes from 'prop-types'
import { Root, CardFooter } from "./CardButton.styles";
import { ArrowRight, Error } from '@carbon/icons-react'
import Link from '../Basics/Link.container'
import Typography from "./Typography";
import { g100 } from "@carbon/themes";


/**
* The Card's component
*/
const CardButton = props => {
  const { route, title, disabled, hidden } = props
  return (
    <Root disabled={disabled} hidden={hidden}>
      <Link route={route} disabled={disabled}>
        <Typography color={g100.textPrimary}>{title}</Typography>
        <CardFooter>
          {disabled ? <Error size={20} /> : <ArrowRight size={20} />}
        </CardFooter>
      </Link>
    </Root>

  )
}

CardButton.propTypes = {
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default CardButton
