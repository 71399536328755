import React from 'react';
import Card from "../../components/Layouts/Card";
import { SmallText, LargeText } from "./Alertas.styles";

const AlertDashboard = ({ alertData }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {alertData.map((item, index) => (
                <div key={index} style={{ flex: '0 1 33%', marginBottom: '0.1rem' }}>
                    <Card style={{ padding: '1rem', backgroundColor: '#2e2e2e', color: 'white', border: '1px solid #444', height: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                            <LargeText style={{ marginBottom: '0.5rem', width: '100%' }}>{item.title}</LargeText>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem', width: '100%' }}>
                                <SmallText style={{ textAlign: 'left' }}>Total</SmallText>
                                <SmallText style={{ textAlign: 'right' }}>Promedio por turno</SmallText>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <SmallText style={{ textAlign: 'left' }}>{item.total}</SmallText>
                                <SmallText style={{ textAlign: 'right' }}>{item.promedio}</SmallText>
                            </div>
                        </div>
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default AlertDashboard;
