import React, { useEffect, useRef } from 'react';
import { ProgressBar, Stack, Checkbox } from '@carbon/react';
import Typography from "../../components/Basics/Typography";

const CustomVelocityComponent = () => {
    const progressBarRef = useRef(null);

    useEffect(() => {
        // Cambiar el color de la barra de progreso a amarillo
        if (progressBarRef.current) {
            const progressBarElement = progressBarRef.current.querySelector('.cds--progress-bar__bar');
            if (progressBarElement) {
                progressBarElement.style.backgroundColor = '#FFCC00'; // Color amarillo
            }
        }
    }, []);

    return (
        <div style={{ backgroundColor: '#333333', padding: '1rem', color: 'white', borderRadius: '4px', width: '100%' }}>
            <Stack gap={2}>
                <Typography className="cds--type-heading-compact-02">Velocidad promedio fuera de frentes de carga</Typography>
                <Typography className="cds--type-heading-compact-01">Velocidad promedio conductor vs Velocidad promedio optima</Typography>
                <Typography className="cds--type-heading-compact-01">
                    Claudio Enrique Ortega Saavedra <span style={{ fontWeight: 'lighter', color: '#999' }}>45 km/h</span>
                </Typography>

                <div style={{ width: '100%' }} ref={progressBarRef}>
                    <ProgressBar
                        label="Velocidad promedio"
                        value={45}
                        max={60}
                        size="sm"
                        style={{ width: '100%' }}
                    />
                </div>

                {/* Nueva disposición flexbox */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {/* Deja el contenido a la izquierda */}
                    <div style={{ flex: 1 }}>
                        {/* Puedes agregar más contenido o dejarlo vacío */}
                    </div>

                    {/* Los checkboxes alineados a la derecha */}
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'flex-end' }}>
                        <Checkbox
                            id="optimal-speed"
                            labelText="Velocidad promedio optima: 45 km/h"
                            checked
                            readOnly
                        />
                        <Checkbox
                            id="max-speed"
                            labelText="Velocidad máxima permitida: 60 km/h"
                            readOnly
                        />
                    </div>
                </div>
            </Stack>
        </div>
    );
};

export default CustomVelocityComponent;
