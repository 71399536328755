import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DatePicker, DatePickerInput, Modal, Select, SelectItem, Stack, TextInput, Loading } from "@carbon/react";
import { StyledStack } from '../../../components/Basics/Stack.styles';
import InformationContainer from '../../../components/Basics/Information.container';
import Typography from '../../../components/Basics/Typography';
import { Camera } from '@carbon/icons-react';
import { onGetDriverLicenseThunk } from "./Driver.actions";
import { DriverLicense } from './Driver.styles';

const LicenseUpdateModal = forwardRef(({ open, setOpen }, ref) => {
    const dispatch = useDispatch()
    const [retrievedData, setRetrievedData] = useState({});
    const [hasPhoto, setHasPhoto] = useState(false)

    const name = open ? open.cells[0].value : 'Nombre Apellido';
    const rut = open ? open.cells[1].value : '0000000-0';
    const expiry_date = open ? open.cells[3].value : 'dd/mm/aaaa';
    const { data, loadingImage, errorImage, successImage, url } = useSelector(state => state.driver)
    useEffect(() => {
        const foundObject = data.find(item => item.fullRut === rut);
        const toSetData = foundObject ? foundObject : { license_number: '00000000-0' }
        setRetrievedData(toSetData)
        const hasPhoto = toSetData.imagePath || ''
        setHasPhoto(Boolean(hasPhoto))
        if (hasPhoto) {
            dispatch(onGetDriverLicenseThunk(hasPhoto))
        }
    }, [open]);
    return (
        <Modal
            danger
            launcherButtonRef={ref}
            modalHeading={name}
            modalLabel="Ficha de Operador"
            open={!!open}
            onRequestClose={() => setOpen(null)}
            passiveModal
        >
            <Stack gap={6}>
                <StyledStack gap={6}>
                    <Typography className='cds--type-heading-compact-01'>Información básica</Typography>
                    <TextInput labelText="Nombre Apellido" placeholder={name} id="name-license" disabled />
                    <TextInput labelText='Rut' placeholder={rut} id='rut-number' disabled />
                    <TextInput labelText='N° de Licencia' placeholder={retrievedData.license_number} id='licence-number' disabled />
                    <DatePicker datePickerType="single" dateFormat='d-m-Y'>
                        <DatePickerInput id="expiry-license" placeholder={expiry_date} labelText="Fecha de Expiración" size="md" />
                    </DatePicker>
                    <Typography className='cds--type-heading-compact-01'>Utilidad</Typography>
                    <Button disabled={!Boolean(url)} renderIcon={Camera} href={url}>Ver Licencia</Button>
                    {!hasPhoto && (
                        <Typography>Sin Foto Licencia</Typography>
                    )}
                    {loadingImage && hasPhoto && (
                        <Loading className='loading-icon' withOverlay={false} />
                    )}
                    {errorImage && (
                        <Typography>Error al buscar la imagen</Typography>
                    )}
                    {successImage && url && (
                        <DriverLicense src={url}></DriverLicense>
                    )}
                </StyledStack>
            </Stack>
        </Modal>
    )
})

export default LicenseUpdateModal
