import React from 'react'
import { GroupedBarChart } from '@carbon/charts-react'
import PropTypes from 'prop-types';
import { toolbarOptions } from "./utils";

const GroupBarChart = props => {
  const { title, height, data, axesOptions } = props
  const state = {
    data: data,
    options: {
      title: `${title}`,
      axes: axesOptions,
      height: `${height}`,
      theme: "g100",
      width: "95%",
      ...toolbarOptions
    }
  }

  return (
    <>
      <GroupedBarChart
        data={state.data}
        options={state.options}
      />
    </>
  )
}


GroupBarChart.defaultProps = {
  height: "15rem",
  title: "Bar Chart Graph",
  data: [
    {
      group: 'Dataset 1',
      key: 'Qty',
      value: 65000
    },
    {
      group: 'Dataset 1',
      key: 'More',
      value: 29123
    },
    {
      group: 'Dataset 1',
      key: 'Sold',
      value: 35213
    },
    {
      group: 'Dataset 2',
      key: 'Qty',
      value: 32432
    },
    {
      group: 'Dataset 2',
      key: 'More',
      value: 21312
    },
    {
      group: 'Dataset 2',
      key: 'Sold',
      value: 56456
    },
    {
      group: 'Dataset 2',
      key: 'Restocking',
      value: 21312
    },
    {
      group: 'Dataset 2',
      key: 'Misc',
      value: 34234
    }
  ],
  axesOptions: {
    left: {
      mapsTo: 'value'
    },
    bottom: {
      scaleType: 'labels',
      mapsTo: 'key'
    }
  }
}

GroupBarChart.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  axesOptions: PropTypes.object
}

export default GroupBarChart
