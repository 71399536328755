import React, { useEffect } from "react";
import { Stack } from "@carbon/react";
import { useSelector, useDispatch } from 'react-redux';
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { DataTable } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import PredictiveTable from "./PredictiveTable.container";
import { onGetMaintenanceThunk } from "../Ongoing/Ongoing.actions";
import LoadingContent from "../../../components/Animations/LoadingContent";

const Predictive = () => {
  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => state.maintenance)

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetMaintenanceThunk())
  }, [])

  return (
    <>
      {loading && !error && (
        <>
          <LoadingContent />
        </>
      )}
      {!loading && error && (
        <CardComponent width="10%">
          <Typography className='cds--type-heading-compact-01'>
            No hay registros de mantenimientos
          </Typography>
        </CardComponent>
      )}
      {!loading && !error && (
        <Stack gap={3}>
          <CardComponent width="100%">
            <Card justifyContent='flex-start' >
              <DataTable size={18} />
              <Typography className='cds--type-heading-compact-01'>
                MANTENIMIENTOS PREDICTIVOS - TABLA DE DATOS
              </Typography>
            </Card>
            <CardRow width='inherit'>
              <Card width='100%' padding="0">
                <PredictiveTable />
              </Card>
            </CardRow>
          </CardComponent>
        </Stack>
      )}
    </>

  )
};

export default Predictive;