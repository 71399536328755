import {
  GET_FROMB57_FROM,
  GET_FROMB57_FROM_ERROR,
  GET_FROMB57_FROM_SUCCESS,
} from './FromB57.actions';

const realTimeFrom = {
  loading: true,
  error: undefined,
  chargeTime: [],
  graphBoxPlot: [],
  table: [],
  chargeMean: [],
};

/**
 * The real time From B57 reducer.
 */
const realTimeFromB57Reducer = (state = realTimeFrom, { payload, type }) => {
  switch (type) {
    case GET_FROMB57_FROM: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_FROMB57_FROM_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_FROMB57_FROM_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        chargeTime: payload.chargeTime,
        table: payload.table,
        graphBoxPlot: payload.boxPlot,
        chargeMean: payload.chargeMean,
      };
    }
    default: {
      return state;
    }
  }
};

export default realTimeFromB57Reducer;
