import React from "react";
import PropTypes from 'prop-types'
import { CardRoot } from "./Card.styles";

const CardTitle = props => {
  const { children, border, color, flex, justifyContent, alignItems, flexDirection, gap, margin, padding, width, height, background, disabled } = props

  return (
    <CardRoot
      border={border}
      flex={flex}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      gap={gap}
      alignItems={alignItems || "center"}
      padding={padding}
      margin={margin}
      width={width}
      height={height}
      background={background}
      color={color}
      disabled={disabled}
    >
      {children}
    </CardRoot>
  )
}

CardTitle.propTypes = {
  children: PropTypes.node,
  border: PropTypes.string,
  flex: PropTypes.number,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  flexDirection: PropTypes.string,
  gap: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  background: PropTypes.string,
  disabled: PropTypes.bool
}

export default CardTitle
