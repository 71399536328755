import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '../Basics/Typography';
import { permissionsMap } from '../../config/settings/constant';
import { getUserGroup } from '../../services/authAWS';

const AccessControl = ({ type, children, AccessDeniedComponent }) => {
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        let groups = JSON.parse(localStorage.getItem('userGroups'));
        if (!groups) {
          groups = await getUserGroup();
          localStorage.setItem('userGroups', JSON.stringify(groups));
        }
        setUserGroups(groups || []);
      } catch (error) {
        console.error('Error fetching user groups:', error);
        setUserGroups([]);
      }
    };

    fetchUserGroups();
  }, []);
  const hasAccess = userGroups.some(userGroup => permissionsMap[userGroup]?.includes(type));
  return hasAccess ? children : <AccessDeniedComponent />;
};
// TODO: Move this to a single component
const NoAccessView = () => (
  <div>
    <Typography>Accesso Denegado</Typography>
    <Typography>No tiene permisos para ver este contenido.</Typography>
  </div>
);

AccessControl.defaultProps = {
  AccessDeniedComponent: NoAccessView
};

AccessControl.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  AccessDeniedComponent: PropTypes.elementType
};

export default AccessControl;
