import React from "react";
import {
    DataTable, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
    TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch
} from '@carbon/react';

const ExpansionTable = ({ headers, rows, headerData = headers, detailsFunc = () => null, hasSearch = true, additionalToolbarComponents = <></> }) => {
    return (
        <DataTable rows={rows} headers={headers} width="100%">
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getExpandedRowProps,
                getTableProps,
                getTableContainerProps,
                onInputChange,
                sortBy
            }) => <TableContainer {...getTableContainerProps()}>
                    <TableToolbar>
                        <TableToolbarContent>
                            {/* pass in `onInputChange` change here to make filtering work */}
                            {hasSearch && <TableToolbarSearch onChange={onInputChange} />}
                            {additionalToolbarComponents}
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()} aria-label="sample table">
                        <TableHead>
                            <TableRow>
                                <TableExpandHeader aria-label="expand row" />
                                {headers.map((header, i) => <TableHeader
                                    key={i}
                                    sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                    {...getHeaderProps({
                                        header,
                                        isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                        onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                                    })}>
                                    {header.header}
                                </TableHeader>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => <React.Fragment key={row.id}>
                                <TableExpandRow {...getRowProps({
                                    row
                                })}>
                                    {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                                </TableExpandRow>
                                <TableExpandedRow colSpan={headers.length + 1} className="demo-expanded-td" {...getExpandedRowProps({
                                    row
                                })}>
                                    {detailsFunc(row)}
                                </TableExpandedRow>
                            </React.Fragment>)}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </DataTable>)
}

export default ExpansionTable;