import React, { useRef, useState } from 'react'
import { Pen } from '@carbon/icons-react'
import Typography from '../../../components/Basics/Typography'
import { Button, Stack, Pagination } from '@carbon/react';
import { DataTable } from '@carbon/icons-react';
import DatabaseForm from './Database.modal';
import { g100 } from '@carbon/themes'
import Card from '../../../components/Layouts/Card';
import { CardComponent } from '../../../components/Layouts/Card.styles'
import SimpleTable from '../../../components/Basics/SimpleTable.container';
import InformationContainer from '../../../components/Basics/Information.container';

/**
 * The Database's component
 */
const Database = () => {
  const button = useRef();
  const [open, setOpen] = useState(false);
  const headers = [
    {
      header: 'Tipo de Informe',
      key: 'type',
    },
    {
      header: 'Fecha de Inicio',
      key: 'start_date',
    },
    {
      header: 'Fecha de Término',
      key: 'end_date',
    },
    {
      header: 'Fecha de Solicitud del Informe',
      key: 'report_creation',
      sortDirection: "DESC",
      isDefaultSortable: true
    },
    {
      header: 'Solicitando del Informe',
      key: 'report',
    }];
  const rows = [];
  return (
    <>
      <Stack gap={3}>
        <Button size="sm" ref={button} kind="primary" renderIcon={Pen} onClick={() => setOpen(true)}>Formulario de Solicitud de Informes</Button>
      </Stack>
      <DatabaseForm button={button} open={open} setOpen={setOpen} />
    </>
  )
};

export default Database;
