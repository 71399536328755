import styled from 'styled-components';

export const Root = styled.div`
  align-items: end;
  width: 100%;
`;
export const Item = styled.div`
  align-items: end;
  display: flex;
  margin-bottom: ${props => props.marginBottom || '20px'};
`;
export const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: white;  // Añade color blanco al contenido del botón
  margin-left: 0.3rem;
  margin-bottom: 0.2rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background: none;
  }

  &:active {
    background: none;
  }

  svg {
    fill: white;  // Asegura que los íconos SVG dentro del botón sean blancos
  }
`;