import React, { forwardRef, useState, useCallback } from 'react';
import { DatePicker, DatePickerInput, FileUploader, Modal, Stack, TextInput, ComboBox } from "@carbon/react";
import { StyledStack } from '../../../components/Basics/Stack.styles';
import InformationContainer from '../../../components/Basics/Information.container';
import { useSelector, useDispatch } from 'react-redux';
import { useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import { checkErrorRequired } from '../../../modules/errors/validations';
import { clearDriverLicense, onUpdateDriverLicenseThunk } from './Driver.actions';

const EMPTY_DRIVER = { license_number: '', expiry: '' };

const LicenseEditModal = forwardRef(({ open, setOpen }, ref) => {
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.driver)
    const [selectedFile, setSelectedFile] = useState(null);
    const { sending, submitError, submitSuccess } = useSelector(state => state.driver)
    const [driver, setDriver] = useState(EMPTY_DRIVER)

    const handleDriverSet = (value) => {
        return setDriver(value.selectedItem === null ? EMPTY_DRIVER : value.selectedItem)
    }

    const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
        errorCallbacks: [checkErrorRequired()]
    })

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleSubmit = () => {
        const params = {
            "driverId": driver.driverId,
            'imageBase64': selectedFile
        }
        const driverExpiryDate = `${driver.expiry}T00:00:00`
        const date = new Date(driverExpiryDate);
        const newExpiry = dateValue[0] ?? date
        params['updateValue'] = newExpiry
        return dispatch(onUpdateDriverLicenseThunk(params))
    }

    const canNotSubmit = useCallback(() => {
        if (!dateValue) {
            return true
        }
        if (!selectedFile) {
            return true
        }
    }, [dateValue, selectedFile])
    const disabledSubmit = canNotSubmit()

    const getLoadingStatus = () => {
        if (submitError) {
            return 'error'
        }
        if (sending) {
            return 'active'
        }
        if (submitSuccess) {
            return 'finished'
        }
        return 'inactive'
    }

    const handleCloseModal = () => {
        setOpen(false);
        // Empty form fields
        setSelectedFile(null);
        setDriver(EMPTY_DRIVER);
        dispatch(clearDriverLicense());
    };

    return (
        <Modal
            danger
            launcherButtonRef={ref}
            modalHeading="Actualizar estado de licencia"
            modalLabel="Formulario"
            primaryButtonText="Actualizar"
            secondaryButtonText="Cancelar"
            open={open}
            onRequestClose={handleCloseModal}
            onRequestSubmit={handleSubmit}
            loadingStatus={getLoadingStatus()}
            primaryButtonDisabled={disabledSubmit}
        >
            <Stack gap={6}>
                <p>Formulario de Actualización del Estado de la Licencia de un conductor.</p>
                {/* <InformationContainer description='Esta característica todavía se encuentra en desarrollo y podría no estar funcionando.' /> */}
                <StyledStack gap={6}>
                    <ComboBox
                        id='driver'
                        items={data}
                        onChange={handleDriverSet}
                        itemToString={(item) => item ? item.fullName : ''}
                        placeholder='Seleccionar Conductor'
                        titleText="Seleccionar Conductor"
                    />
                    <TextInput labelText='N° de Licencia' placeholder='0000000-0' id='licence-number' value={driver.license_number} disabled />
                    <DatePicker datePickerType="single" dateFormat='Y-m-d' value={driver.expiry} invalidText={dateError} onChange={handleDateChange}>
                        <DatePickerInput id="date-picker-license" placeholder="aaaa/mm/dd" labelText="Fecha de Vencimiento" size="md" />
                    </DatePicker>
                    <FileUploader
                        accept={['.jpg', '.png']}
                        buttonLabel='Subir'
                        filenameStatus='edit'
                        labelDescription='El tamaño máximo del archivo es de 500Kb. Los tipos de archivo admitidos son .jpg y .png.'
                        labelTitle='Subir Foto de Licencia'
                        onChange={handleFileUpload}
                    />
                </StyledStack>
            </Stack>
        </Modal>
    )
})

export default LicenseEditModal
