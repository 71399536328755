import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Card from '../../../components/Layouts/Card'
import Typography from '../../../components/Basics/Typography'
import { DashboardReference, DeliveryTruck, QOperationGauge, Restart, SummaryKpi, Location } from '@carbon/icons-react'
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import Gauge from '../../../components/Graphs/Gauge.container'
import BarChart from '../../../components/Graphs/BarChart.container'
import BoxPlotContainer from '../../../components/Graphs/BoxPlot.container'
import { spacing05 } from '@carbon/themes';
import FlexHeatMap from '../../../components/Graphs/FlexHeatMap.container'
import InformationContainer from '../../../components/Basics/Information.container'
import { TopFilter } from '../../Productivity/Idle/Idle.styles'
import { Button, Dropdown } from '@carbon/react'
import { Div } from '../../../components/Basics/Div.styles'
import routes from '../../../config/settings/routes'
import Histogram from '../../../components/Graphs/Histogram.container'
import LineGraph from '../../../components/Graphs/LineChart.container'
import { convertTo12HourFormat, isDaytime, hasDataInTimeRange } from '../../../components/Graphs/utils'
import { Separator } from '../../Dashboard/Dashboard.styles'
import VerticalBarChart from '../../../components/Graphs/VerticalBarChart.container'

export const Header = () => {
  const { lastTrucks } = useSelector(state => state.performance)
  console.log(lastTrucks)
  return (
    <>
      <CardComponent width="100%">
        <Card justifyContent="space-between" alignItems="center" gap="0.5rem" height="40px" background="none">
          {/* Left Section */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Location size={18} />
            <Typography className="cds--type-heading-compact-01">
              EQUIPOS CARGADOS AL FINAL DEL TURNO ANTERIOR
            </Typography>
          </div>

          {/* Right Section */}
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            {Object.entries(lastTrucks).map(([key, value], index) => (
              <>
                <Location size={18} />
                <Typography
                  className="cds--type-heading-compact-01"
                  key={index}
                  component="span"
                >
                  {key}: {value}{index < Object.entries(lastTrucks).length - 1 && ' '}
                </Typography>
              </>
            ))}
          </div>
        </Card>
      </CardComponent>
      {/* The following code was commented out but it could be relevant so it was not deleted */}
      {/* <Button disabled renderIcon={DashboardReference} size='sm' href={routes.monitoringLastShift}>
        Desempeño Turno Anterior
      </Button>
      <Card disabled justifyContent='flex-start' flex={1}>
        <SummaryKpi size={18} />
        <Typography className='cds--type-heading-01'>
          Producción Turno Anterior
        </Typography>
        <Typography className='cds--type-heading-01'>
          0000 m3
        </Typography>
        <Typography className='cds--type-body-compact-01'>
          en 4 frentes, con 00 camiones Tolva.
        </Typography>
      </Card>
      <Card disabled justifyContent='flex-start' >
        <Restart size={18} />
        <Typography className='cds--type-heading-01'>
          01:01:00
        </Typography>
        <Typography className='cds--type-body-compact-01'>
          Tiempo para cambio de turno en Plataforma.
        </Typography>
      </Card> */}
    </>
  )
}
export const FirstRow = () => {
  const { dailyProduction, m3Production, unloadsPerHour, productionPerHour } = useSelector(state => state.performance)

  const currentHour = new Date().getHours();
  const daytime = isDaytime(currentHour);
  const dayTimeRange = { start: 8, end: 19 };
  const nightTimeRange = { start: 20, end: 7 };

  const hasDaytimeData = hasDataInTimeRange(unloadsPerHour, dayTimeRange.start, dayTimeRange.end);
  const hasNighttimeData = hasDataInTimeRange(unloadsPerHour, nightTimeRange.start, nightTimeRange.end);


  const barChart = m3Production
  const axes = {
    "left": {
      "mapsTo": "key",
      "scaleType": "labels"
    },
    "bottom": {
      "mapsTo": "value"
    }
  }

  const axis = {

    bottom: {
      scaleType: "linear",
      title: 'Hora',
      mapsTo: 'value',
      bins: 24,
      domain: daytime ? [8, 20] : [20, 32],
      ticks: {
        values: daytime ? [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        formatter: (value, i) => {
          // Convert the transformed value back to the original hour
          const formattedStart = convertTo12HourFormat(value);
          
          const formattedValue = `${formattedStart}`;
          return formattedValue
        }
      }
    },
    left: {
      title: 'M3/hr',
      scaleType: 'lineal',
      binned: true
    }
  }

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          PRODUCCIÓN DEL TURNO
        </Typography>
      </Card>
      <InformationContainer description='El total del medidor corresponde a la producción del turno anterior.' />
      <CardRow width="inherit">
        <Card flexDirection='column' justifyContent='center' height="100%" padding={spacing05} width="25%" alignItems="stretch">
          <Gauge title='Producción del turno en tiempo real (total, turno)' percentage={dailyProduction} maxPercent={9000} noMetric={true} />
        </Card>
        <Card flexDirection="column" justifyContent="center" height="100%" padding={spacing05} width="25%" alignItems="stretch">
          <Typography className='cds--type-heading-compact-01'>Producción por hora</Typography>
          <Typography className='cds--type-heading-07'>{productionPerHour} m3/h</Typography>
          <Separator />
          {daytime ? (
            hasDaytimeData ? (
              <Histogram title="Producción por hora de la obra (m3/h, hora)" data={unloadsPerHour} axesOptions={axis} />
            ) : (
              <LineGraph title="Calculando Producción" lineData={[]} />
            )
          ) : (
            hasNighttimeData ? (
              <Histogram title="Producción por hora de la obra (m3/h, hora)" data={unloadsPerHour} axesOptions={axis} />
            ) : (
              <LineGraph title="Calculando Producción" lineData={[]} />
            )
          )}
        </Card>
        <Card height="100%" padding={spacing05} width="50%">
          <BarChart title='Producción por Frente de descarga (total, m3)' data={barChart} axesOptions={axes} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const TRUCK_DROPDOWN_ITEMS = values => ({
  'trucks': {
    'text': 'Camiones',
    'subitems': {
      'tolva': { 'text': "Camión Tolva", 'value': values.tolva },
      'aljibe': { 'text': "Camión Aljibe", 'value': values.aljibe },
      'fuel': { 'text': "Camión Combustible", 'value': values.fuel },
      'pluma': { 'text': "Camión Pluma", 'value': values.pluma },
    }
  },
  'heavy': {
    'text': 'Equipos Pesados',
    'subitems': {
      'rodillo': { 'text': 'Rodillo', 'value': values.rodillo },
      'front': { 'text': 'Cargadora Frontal', 'value': values.front },
      'retro': { 'text': 'Retroexcavadora', 'value': values.retro },
      'level': { 'text': 'Motoniveladora', 'value': values.level },
      'dig': { 'text': 'Excavadora', 'value': values.dig },
      'bulldozer': { 'text': 'Bulldozer', 'value': values.bulldozer },
    }
  },
  'support': {
    'text': 'Equipos de Apoyo',
    'subitems': {
      'bus': { 'text': 'Bus', 'value': values.bus },
      'minibus': { 'text': 'Mini Bus', 'value': values.minibus },
    }
  }
})

export const formatTruckItems = (items) => Object.entries(items).map(([key, value]) => ({
  id: key,
  text: value.text
}))

export const SecondRow = () => {
  const { numberOfTrucksToReturn, trucksTurn, textToKeyMap } = useSelector(state => state.performance);
  const items = TRUCK_DROPDOWN_ITEMS(trucksTurn);
  const formattedItems = formatTruckItems(items)
  const [selectedItem, setSelectedItem] = useState(formattedItems[0]);

  const graphHeight = "20vh"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          UTILIZACIÓN DE RECURSOS
        </Typography>
      </Card>
      <InformationContainer description='El total del medidor corresponde al total de conductores/máquinas disponibles' />
      <CardRow width='inherit' justifyContent="flex-start">
        <Card padding={spacing05} alignItems="flex-end">
          <Gauge title='Conductores' percentage={numberOfTrucksToReturn} isBold maxPercent={100} />
        </Card>
        <Div width="100%">
          <TopFilter>
            <Dropdown
              id="default"
              titleText="Grupo de Equipos"
              label="Grupo de Equipos"
              initialSelectedItem={formattedItems[0]}
              items={formattedItems}
              itemToString={item => item ? item.text : ''}
              onChange={({ selectedItem }) => setSelectedItem(selectedItem)}
            />
          </TopFilter>
          <Card flexDirection='row' justifyContent='space-between' padding={spacing05} gap="1.5rem">
            {Object.values(items[selectedItem.id].subitems).map(({ text, value }) => {
              const key = textToKeyMap[text]
              return <Gauge key={text} height={graphHeight} title={text} percentage={value} isBold />
            })
            }
          </Card>
        </Div>
      </CardRow>
    </CardComponent>
  )
}

export const ThirdRow = () => {
  const { loadTimes } = useSelector(state => state.performance)

  const graphWidth = "100%"
  const graphHeight = "40vh"

  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          TIEMPOS CARGUÍO POR PLATAFORMA
        </Typography>
      </Card>
      <CardRow width='inherit' padding={spacing05}>
        <Card flexDirection='column' justifyContent='center' width={graphWidth}>
          <VerticalBarChart height={graphHeight} title='Tiempos de Carguío (minutos)' data={loadTimes} />
        </Card>
      </CardRow>
    </CardComponent>
  )
}

export const FourthRow = () => {
  const { queueTimes } = useSelector(state => state.performance)
  const axes = {
    "left": {
      "mapsTo": "key",
      "scaleType": "labels"
    },
    "bottom": {
      "mapsTo": "value"
    }
  }
  const graphHeight = "40vh"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          COLAS EN FRENTES
        </Typography>
      </Card>
      <InformationContainer description='Las colas corresponden al total de camiones que experimentaron pérdida operacional debido a la espera en la cola.' />
      <Card flexDirection='column' justifyContent='center' padding={spacing05}>
        <BarChart title='Tiempos de Cola (media, minutos)' height={graphHeight} data={queueTimes} axesOptions={axes} />
      </Card>
    </CardComponent>
  )
}

export const FifthRow = () => {
  const { cycleTime, heatmapHour, avgSpeed, cycleCount } = useSelector(state => state.performance)
  const graphWidth = "100%"
  return (
    <CardComponent width="100%">
      <Card justifyContent='flex-start' >
        <QOperationGauge size={18} />
        <Typography className='cds--type-heading-compact-01'>
          RUTAS DE TRANSPORTE
        </Typography>
      </Card>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={cycleTime} title='Duración de Ciclos (media, minutos)' />
        </Card>
      </CardRow>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={cycleCount} title='Cantidad de Ciclos (media, ciclos)' />
        </Card>
      </CardRow>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={avgSpeed} title='Velocidad Promedio (media, km/h)' />
        </Card>
      </CardRow>
      <CardRow>
        <Card flexDirection='column' justifyContent='center' width={graphWidth} padding={spacing05}>
          <FlexHeatMap data={heatmapHour} title='M3 por Hora (media, m3/h)' />
        </Card>
      </CardRow>
    </CardComponent>
  )
}
