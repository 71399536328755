import { API, Storage } from 'aws-amplify';
import {
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
} from '../config/settings/environments';

/**
 * To get the trucks.
 */
export const getTrucks = async () => {
  const paths = {
    talabre: '/v1/get-trucks',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-trucks';

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the geofences.
 */
export const getGeofences = async () => {
  const paths = {
    talabre: '/v1/get-geofences',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-geofences';

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the gates.
 */
export const getGates = async date => {
  const paths = {
    talabre: '/v1/get-gates',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-gates';
  if (date) {
    path = `${path}?date=${date}`;
  }
  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the drivers.
 */
export const getDrivers = async () => {
  const paths = {
    talabre: '/v1/get-drivers',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-drivers';

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To modify a driver.
 */
export const updateDrivers = async params => {
  const paths = {
    talabre: '/v1/update-driver',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/update-driver';

  const body = params;
  const response = await API.post(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
    body,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

export const uploadFileToS3 = async (file, id) => {
  try {
    const fileName = `${id}-carnet`;

    const storageResult = await Storage.put('shared-images/' + fileName, file, {
      contentType: file.type, // Recommended to set the content type
    });
    return {
      key: storageResult.key,
      status: 'Success',
    };
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    return {
      error: error,
      status: 'Failed',
    };
  }
};

/**
 * Retrieves a private image from S3 and returns a signed URL for viewing.
 * @param {string} fileKey The key of the file in the S3 bucket.
 * @return {Promise<string>} The signed URL of the image.
 */
export const fetchImageFromS3 = async fileKey => {
  try {
    // Get the signed URL for the image
    const signedUrl = await Storage.get(fileKey);

    return signedUrl;
  } catch (error) {
    console.error('Error fetching image from S3:', error);
    throw new Error('Failed to fetch image');
  }
};

/**
 * To get the Adas.
 */
export const getAdasfatiga = async ({ year, month, day, turno, rut }) => {
  const paths = {
    talabre: '/v1/get-adas-driving',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-adas-driving';
  if (year && month && day && turno) {
    path = `${path}?year=${year}&month=${month}&day=${day}&turno=${turno}`;
  }
  if (rut) {
    path = `${path}&rut=${rut}`; // Incluir el turno en la llamada a la API
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the Perdidas.
 */
export const getPerdidasDrivers = async ({ year, month, day, turno = null, rut = null }) => {
  const paths = {
    talabre: '/v1/get-perdidas-drivers',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-perdidas-drivers';
  if (year && month && day && turno) {
    path = `${path}?year=${year}&month=${month}&day=${day}&turno=${turno}`;
  }
  if (year && month && day) {
    path = `${path}?year=${year}&month=${month}&day=${day}`;
  }
  if (rut) {
    path = `${path}&rut=${rut}`; // Incluir el turno en la llamada a la API
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the Frenos.
 */
export const getFrenosDrivers = async ({ year, month, day, turno, rut }) => {
  const paths = {
    talabre: '/v1/get-frenos-drivers',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-frenos-drivers';
  if (year && month && day && turno) {
    path = `${path}?year=${year}&month=${month}&day=${day}&turno=${turno}`;
  }
  if (rut) {
    path = `${path}&rut=${rut}`; // Incluir el turno en la llamada a la API
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};
