import React, { useRef, useState } from 'react'
import TitleText from '../../../components/Layouts/Title'
import { Pen } from '@carbon/icons-react'
import Typography from '../../../components/Basics/Typography'
import CardButton from '../../../components/Basics/CardButton.container'
import { Root } from './Maintenance.styles'
import routes from '../../../config/settings/routes'
import { Button, Stack } from '@carbon/react'
import { EntranceForm } from '../Ongoing/Ongoing.container'
import { g100 } from '@carbon/themes'
import { Separator } from '../../Dashboard/Dashboard.styles'

/**
 * The Maintenance's component
 */
const Maintenance = () => {
    const button = useRef();
    const [open, setOpen] = useState(false);
    return (
        <>
            <TitleText
                title='Mantenimientos'
                subtitle='Gestiona los mantenimientos de la flota, registra el ingreso y salida de mantenimiento y consulta mantenimientos preventivos.'
            />
            <Root>
                <Stack gap={6}>
                    <Stack gap={3}>
                        <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Funcionalidades</Typography>
                        <Separator />
                        <Button size='sm' style={{ minWidth: "100%" }} ref={button} kind="primary" renderIcon={Pen} onClick={() => setOpen(true)}>Formulario Ingreso a Mantenimiento</Button>
                    </Stack>
                    <Stack gap={3}>
                        <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Tipo de Mantenimiento</Typography>
                        <Separator />
                        <CardButton title='Mantenimientos en Curso' route={routes.maintenanceOngoing} hidden />
                        <CardButton title='Mantenimientos Completados' route={routes.maintenanceCompleted} />
                        <CardButton title='Registro de permanencia en talleres' route={routes.maintenancePermanence} />
                        <CardButton disabled title='Mantenimientos Preventivos' route={routes.maintenancePreventive} hidden />
                        <CardButton disabled title='Mantenimientos Predictivos' route={routes.maintenancePredictive} hidden />
                    </Stack>
                </Stack>
            </Root>
            <EntranceForm button={button} open={open} setOpen={setOpen} />
        </>
    )
};

export default Maintenance;
