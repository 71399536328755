import React from 'react'
import Navbar from '../containers/Navbar/Navbar.container'
import ManagementMenu from '../containers/ManagementMenu/ManagementMenu.container'
import routes from '../config/settings/routes'
import ProtectBySession from '../components/Security/ProtectBySession'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import { SatelliteRadar } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import Monitoring from '../containers/Monitoring/Initial/Monitoring.container'
import MonitoringMap from '../containers/Monitoring/Map/MonitoringMap.container'
import MonitoringPerformance from '../containers/Monitoring/Performance/Performance.container'
import ChargingTimeContainer from '../containers/Monitoring/ChargingTime/ChargingTime.container'
import { usePathParam } from '../modules/hooks/route'
import { MONITORING_TYPE } from '../config/settings/constant'
import TransferB57ToDischargingPoint from '../containers/Monitoring/TransferB57ToDischargingPoint/TransferB57ToDischargingPoint.container'
import DischargeTimeContainer from '../containers/Monitoring/DischargeTime/DischargeTime.container'
import TransferToB57 from '../containers/Monitoring/TransferToB57/TransferToB57.container'
import NullVelocity from '../containers/Monitoring/NullVelocity/NullVelocity.container'
import EffectiveTimeDriver from '../containers/Monitoring/EffectiveTimeDriver/EffectiveTimeDriver.container'
import Punctures from '../containers/Monitoring/Punctures/Punctures.container'
import Composting from '../containers/Monitoring/Composting/Composting.container'
import Roller from '../containers/Monitoring/Roller/Roller.container'
import DashboardCan from '../containers/Monitoring/CanDashboard/CanDashboard.container'
import LastShiftContainer from '../containers/Monitoring/LastShift/LastShift.container'
import AccessControl from '../components/Security/ProtectByGroup'
import GeofenceG1 from '../containers/Monitoring/GeofenceG1/GeofenceG1.container'
import LowVelocity from '../containers/Monitoring/LowVelocity/LowVelocity'

/**
 * The Map and Monitoring page.
 */
const MonitoringPage = () => {
  const type = usePathParam('type').toUpperCase()

  const TypeTitle = type => {
    switch (type) {
      case MONITORING_TYPE.MAP:
        return 'Mapa de Flota'
      case MONITORING_TYPE.PERFORMANCE:
        return 'Desempeño Operación y Desperdicios'
      case MONITORING_TYPE['LAST-SHIFT']:
        return 'Desempeño Turno Anterior'
      case MONITORING_TYPE.CHARGING:
        return 'Tiempos de Carga'
      case MONITORING_TYPE['TRANSFER-B57-DISCHARGING']:
        return 'Tiempos de Traslado desde el B57 a Frentes de Descarga'
      case MONITORING_TYPE.DISCHARGING:
        return 'Tiempos en Frentes de Descarga'
      case MONITORING_TYPE['TRANSFER-B57']:
        return 'Tiempos Traslado a B57'
      case MONITORING_TYPE.IDLE:
        return 'Tiempo en Ralenti'
      case MONITORING_TYPE['EFFECTIVE-TIME-DRIVER']:
        return 'Conductores Operativos'
      case MONITORING_TYPE.PUNCTURES:
        return 'Pinchazos'
      case MONITORING_TYPE.COMPOSTING:
        return 'Composteo Combustible'
      case MONITORING_TYPE.ROLLER:
        return 'Pasadas de Rodillo'
      case MONITORING_TYPE.CAN:
        return 'Dashboard CAN Última Hora'
      case MONITORING_TYPE.GEOFENCEG1:
        return 'Paso por Geocerca G1'
      case MONITORING_TYPE.LOWVELOCITY:
        return 'Velocidades Bajas'
      default:
        return 'Tiempo Real'
    }
  }
  const getTooltipContent = type => {
    switch (type) {
      case MONITORING_TYPE.MAP:
        return 'Monitorea la ubicación y el estado de conexión de los equipos en tiempo real.';
      case MONITORING_TYPE.PERFORMANCE:
        return 'Principal sub-módulo para visualizar la producción en tiempo real y rendimiento de los frentes.';
      case MONITORING_TYPE['LAST-SHIFT']:
        return 'Información sobre el desempeño del turno anterior';
      case MONITORING_TYPE.CHARGING:
        return 'Registro en tiempo real de las cargas y su rendimiento comparativo.';
      case MONITORING_TYPE['TRANSFER-B57-DISCHARGING']:
        return 'Registro en tiempo real del rendimiento de los viajes a frentes desde el botadero 57.';
      case MONITORING_TYPE.DISCHARGING:
        return 'Registro en tiempo real del tiempo  de descarga en los frentes de trabajo.';
      case MONITORING_TYPE['TRANSFER-B57']:
        return 'Registro en tiempo real del rendimiento de los viajes a botadero 57 desde los frentes de descarga.';
      case MONITORING_TYPE.IDLE:
        return 'Mapa con eventos de ralentí detectados durante el día. También contiene información de tiempo acumulado por geocerca.';
      case MONITORING_TYPE['EFFECTIVE-TIME-DRIVER']:
        return 'Registro de los conductores que están participando el turno actual con información sobre tiempos de inicio.';
      case MONITORING_TYPE.PUNCTURES:
        return 'Mapa de pinchazos detectados durante el día con información de la ubicación del neumático y la ubicación de pinchazo.';
      case MONITORING_TYPE.COMPOSTING:
        return 'Mapa de abastecimientos durante el día con información del abastecimiento y su ubicación.';
      case MONITORING_TYPE.ROLLER:
        return 'Detalles sobre pasadas de rodillo';
      case MONITORING_TYPE.CAN:
        return 'Panel con la información sobre conducción y el estado de las variables de los equipos tolva y buses.';
      case MONITORING_TYPE.GEOFENCEG1:
        return 'Panel con información de los pasos por la geocerca G1';
      case MONITORING_TYPE.LOWVELOCITY:
        return 'Panel con información de las alertas de velocidades bajas';
      default:
        return 'Módulo tiempo real';
    }
  }
  const MonitoringBreadcrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/#">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={MONITORING_TYPE[type] === MONITORING_TYPE['MONITORING-INITIAL']} href={routes.monitoringInitial}>
          Mapa y Monitoreo del Turno en Tiempo Real
        </BreadcrumbItem>
        {MONITORING_TYPE[type] !== MONITORING_TYPE['MONITORING-INITIAL'] && <BreadcrumbItem isCurrentPage>{TypeTitle(type)}</BreadcrumbItem>}
      </Breadcrumb>
    )
  }

  const typeToComponentMap = {
    [MONITORING_TYPE['MONITORING-INITIAL']]: Monitoring,
    [MONITORING_TYPE.MAP]: MonitoringMap,
    [MONITORING_TYPE.PERFORMANCE]: MonitoringPerformance,
    [MONITORING_TYPE['LAST-SHIFT']]: LastShiftContainer,
    [MONITORING_TYPE.CHARGING]: ChargingTimeContainer,
    [MONITORING_TYPE['TRANSFER-B57-DISCHARGING']]: TransferB57ToDischargingPoint,
    [MONITORING_TYPE.DISCHARGING]: DischargeTimeContainer,
    [MONITORING_TYPE['TRANSFER-B57']]: TransferToB57,
    [MONITORING_TYPE.IDLE]: NullVelocity,
    [MONITORING_TYPE['EFFECTIVE-TIME-DRIVER']]: EffectiveTimeDriver,
    [MONITORING_TYPE.PUNCTURES]: Punctures,
    [MONITORING_TYPE.COMPOSTING]: Composting,
    [MONITORING_TYPE.ROLLER]: Roller,
    [MONITORING_TYPE.CAN]: DashboardCan,
    [MONITORING_TYPE.GEOFENCEG1]: GeofenceG1,
    [MONITORING_TYPE.LOWVELOCITY]: LowVelocity
  };

  const ComponentToRender = typeToComponentMap[type];
  

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.monitoring} />} />}
        header={<Heading title={TypeTitle(type)} icon={<SatelliteRadar size={50} />} breadcrumbs={<MonitoringBreadcrumbs />} tooltipContent= {getTooltipContent(type)}/>}
      >
        <AccessControl type={type}>
          {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
        </AccessControl>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default MonitoringPage
