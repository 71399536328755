import React from 'react'
import { RECORDS_TYPE } from "../config/settings/constant";
import routes from "../config/settings/routes";
import { usePathParam } from "../modules/hooks/route";
import ProtectBySession from "../components/Security/ProtectBySession";
import BrandLayout from "../components/Layouts/BrandLayout";
import { Identification } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import Navbar from "../containers/Navbar/Navbar.container";
import ManagementMenu from "../containers/ManagementMenu/ManagementMenu.container";
import Heading from "../components/Layouts/Heading";
import RecordsContainer from '../containers/Records/Initial/Records.container';
import DriverContainer from '../containers/Records/Drivers/Driver.container';
import MachinesContainer from '../containers/Records/Machines/Machines.container';
// import GeofencesContainer from '../containers/Records/Geofences/Geofences.container';
import RoutesContainer from '../containers/Records/Routes/Routes.container';
import AccessControl from '../components/Security/ProtectByGroup';
import GatesContainer from '../containers/Records/Gates/Gates.container';

/**
 * The Records page.
 */
const Records = () => {
  const type = usePathParam('type').toUpperCase();

  const TypeTitle = type => {
    switch (type) {
      case RECORDS_TYPE['RECORDS-INITIAL']:
        return 'Fichas'
      case RECORDS_TYPE.DRIVER:
        return 'Conductores'
      case RECORDS_TYPE.MACHINES:
        return 'Equipos'
      // case RECORDS_TYPE.GEOFENCES:
      //   return 'Geocercas'
      case RECORDS_TYPE.ROUTES:
        return 'Rutas'
      case RECORDS_TYPE.GATES:
        return 'Pórtico'
      default:
        return 'Inicio de modulo'
    }
  };

  const getTooltipContent = type => {
    switch (type) {
      case RECORDS_TYPE['RECORDS-INITIAL']:
        return 'Módulo de fichas'
      case RECORDS_TYPE.DRIVER:
        return 'Base de datos de los conductores con información básica y estado de licencia.'
      case RECORDS_TYPE.MACHINES:
        return 'Información sobre equipos'
      case RECORDS_TYPE.GEOFENCES:
        return 'Base de datos con la producción y cola generada por geocerca.'
      case RECORDS_TYPE.ROUTES:
        return 'Información sobre rutas'
      case RECORDS_TYPE.GATES:
        return 'Registro de cargas detectadas por el pórtico con información del volumen y equipo asociado.'
      default:
        return ''
    }
  };

  const RecordsBreadcrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={RECORDS_TYPE[type] === RECORDS_TYPE['RECORDS-INITIAL']} href={routes.recordsInitial}>
          {TypeTitle(RECORDS_TYPE['RECORDS-INITIAL'])}
        </BreadcrumbItem>
        {RECORDS_TYPE[type] !== RECORDS_TYPE['RECORDS-INITIAL'] && <BreadcrumbItem isCurrentPage>{TypeTitle(type)}</BreadcrumbItem>}
      </Breadcrumb>
    )
  }

  const typeToComponentMap = {
    [RECORDS_TYPE['RECORDS-INITIAL']]: RecordsContainer,
    [RECORDS_TYPE.DRIVER]: DriverContainer,
    [RECORDS_TYPE.MACHINES]: MachinesContainer,
    // [RECORDS_TYPE.GEOFENCES]: GeofencesContainer,
    [RECORDS_TYPE.ROUTES]: RoutesContainer,
    [RECORDS_TYPE.GATES]: GatesContainer
  }
  const ComponentToRender = typeToComponentMap[type];

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.records} />} />}
        header={<Heading title={TypeTitle(type)} icon={<Identification size={50} />} breadcrumbs={<RecordsBreadcrumbs />} tooltipContent= {getTooltipContent(type)}/>}
      >
        <AccessControl type={type}>
          {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
        </AccessControl>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default Records
