import { Tabs, TabList, Tab } from "@carbon/react";
import Typography from "./Typography";


function TabsContainer({ tabs }) {
    return (
        <Tabs>
            <TabList aria-label="List of tabs" contained fullWidth>
                {tabs.map(({ icon, title }, index) => (
                    <Tab key={index} renderIcon={icon}><Typography className='cds--type-heading-compact-01'>
                        {title}
                    </Typography>
                    </Tab>
                ))}
            </TabList>
        </Tabs>
    )
}

export default TabsContainer;