// TODO: Rename this file and set the appropriate cursor for all elements.
import styled from 'styled-components'

export const Root = styled.p`
  margin: ${props => props.margin || '0'};
  padding: ${props => props.padding || '0'};
  width: ${props => props.width};
  color: ${props => props.color};
  display: ${props => props.display};
  font-style: ${props => (props.italic ? 'italic' : 'initial')};
  justify-content: ${props => props.justify};
  align-items: ${props => props.alignItems};
  white-space: ${props => props.noWrap && 'nowrap'};
  cursor: ${props => !isNaN(props.onClick) ? 'pointer' : (props.cursor ? props.cursor : 'auto')};
`
