import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  DataTable, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
  TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
  TableToolbarSearch
} from '@carbon/react';
import { Filter } from '@carbon/icons-react'
import DailyReportModal from "./DailyReportModal.container";
import { onGetTruckModalThunk } from "./DailyReport.actions";
import { useSorting } from "../../../modules/hooks/sorting";

const DailyCycleReportTable = _ => {
  const dispatch = useDispatch()
  const button = useRef();
  const [open, setOpen] = useState(false);
  const { cycleTable } = useSelector(state => state.daily)
  console.log(cycleTable)
  const headerData = [
    {
      header: 'Tipo Ciclo',
      key: 'tipo_ciclo',
    },
    {
      header: 'M3 Transportados Promedio (Pórtico)',
      key: 'Camion_Volumen_m3',
    },
    {
      header: 'Distancia Recorrida Promedio',
      key: 'distancia_recorrida (km)',
    },
    {
      header: 'Velocidad Promedio',
      key: 'kph_mean',
    },
    {
      header: 'M3 por Km movido',
      key: 'm3/km'
    },
    {
      header: 'Cantidad de Ciclos',
      key: 'ciclo'
    }
  ];
  const { rows, headers } = useSorting(cycleTable, headerData);

  const Default = () => <DataTable rows={rows} headers={headers} width="100%">
    {({
      rows,
      headers,
      getHeaderProps,
      getRowProps,
      getExpandedRowProps,
      getTableProps,
      getTableContainerProps,
      onInputChange,
      sortBy
    }) => <TableContainer {...getTableContainerProps()}>
        <TableToolbar>
          <TableToolbarContent>
            {/* pass in `onInputChange` change here to make filtering work */}
            <TableToolbarSearch onChange={onInputChange} />
            <Button renderIcon={Filter} kind="secondary" hasIconOnly iconDescription="Filter table" />
          </TableToolbarContent>
        </TableToolbar>
        <Table {...getTableProps()} aria-label="sample table">
          <TableHead>
            <TableRow>
              {headers.map((header, i) => <TableHeader
                key={i}
                sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                {...getHeaderProps({
                  header,
                  isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                  onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                })}
              >
                {header.header}
              </TableHeader>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => <React.Fragment key={row.id}>
              <TableRow {...getRowProps({
                row
              })}>
                {row.cells.map(cell => <TableCell key={cell.id}>
                  {cell.value}
                </TableCell>)}
              </TableRow>
            </React.Fragment>)}
          </TableBody>
        </Table>
      </TableContainer>}
  </DataTable>;

  return (
    <>
      <Default />
    </>

  )
}

DailyCycleReportTable.defaultProps = {
  rows: Array(20).fill().map((_, index) => ({
    id: `${index}`,
    Camion_Volumen_m3: 'Camion_Volumen_m3',
    kph_mean: 'kph_mean',
    tipo_ciclo: 'tipo_ciclo',
    "distancia_recorrida (km)": 'distancia_recorrida (km)'
  }))
}

export default DailyCycleReportTable;