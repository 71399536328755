import { Stack } from "@carbon/react";
import { CardComponent } from "../../../components/Layouts/Card.styles";
import { HeatMap_02 as HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "./TruckApproach.styles";

const TruckApproachContainer = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            REGISTRO DE ACERCAMIENTOS ENTRE CAMIONES - MAPA DE CALOR
          </Typography>
        </Card>
        <Card>
          <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/e0d192ef-4776-438f-8462-d9c48e3ea048/adas?orgId=1&var-patente=All&var-intervalo=1d&var-advertencia=All&var-geocerca=All&from=1712728977303&to=1712750577303&kiosk" width="100%" height="100%" />
        </Card>
      </CardComponent>
    </Stack>
  )
};

export default TruckApproachContainer;