import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  DataTable, Table, TableBody, TableCell, TableContainer,
  TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent, SkeletonPlaceholder, MultiSelect,
  TableToolbarSearch, DatePicker, DatePickerInput, DataTableSkeleton, Stack, Pagination, TimePicker
} from '@carbon/react';
import { checkErrorRequired } from '../../../modules/errors/validations';
import { Filter, DataTable as DataTableIcon } from '@carbon/icons-react'
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import Typography from "../../../components/Basics/Typography";
import { onGetSpeedHistoricThunk } from "./SpeedHistoric.actions";
import Card from '../../../components/Layouts/Card';
import { formatDate } from '../../../modules/utils/formatters';
import { TableDiv, Div, FilterDiv } from "./SpeedHistoric.styles";
import { onGetTrucksThunk } from '../../Records/Machines/Machine.actions';
import { useSorting } from "../../../modules/hooks/sorting";

const SpeedHistoric = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { error, loading, data } = useSelector(state => state.historicSpeed)
  const { error: machinesError, loading: machineLoading, data: machineData } = useSelector(state => state.machines)

  const [timeIn, setTimeIn] = useState('');
  const [isValidTimeIn, setIsValidTimeIn] = useState(false);
  const inPattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/
  const [timeOut, setTimeOut] = useState('');
  const [isValidTimeOut, setIsValidTimeOut] = useState(false);
  const outPattern = /^\d{1,}:[0-5]\d$/
  const [selectedTrucks, setSelectedTrucks] = useState([]);

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const handleChangeTimeIn = (e) => {
    const value = e.target.value;
    setTimeIn(value);

    // Check if the input matches the regex pattern
    if (inPattern.test(value)) {
      setIsValidTimeIn(true);
    } else {
      setIsValidTimeIn(false);
    }
  };

  const handleChangeTimeOut = (e) => {
    const value = e.target.value;
    setTimeOut(value);

    // Check if the input matches the regex pattern
    if (outPattern.test(value)) {
      setIsValidTimeOut(true);
    } else {
      setIsValidTimeOut(false);
    }
  };

  useEffect(() => {
    if (!machineLoading) {
      return
    }
    dispatch(onGetTrucksThunk())
  }, [machineLoading, dispatch])

  const handleSubmit = event => {
    const pattern = 'yyyy-MM-dd'
    const _dateInitial = formatDate(event[0], pattern)
    const _dateFinal = formatDate(event[1], pattern)
    dispatch(onGetSpeedHistoricThunk(_dateInitial, _dateFinal))
  }

  const changePaginationState = (pageInfo) => {
    if (page !== pageInfo.page) {
      setPage(pageInfo.page)
    }
    if (pageSize !== pageInfo.pageSize) {
      setPageSize(pageInfo.pageSize)
    }
  }

  const headerData = [
    {
      header: 'Camión',
      key: 'patente',
    },
    {
      header: 'Velocidad_Gps',
      key: 'velocidad',
      sortDirection: "DESC",
    },
    {
      header: 'Exceso de velocidad',
      key: 'violation_value',
      sortDirection: "DESC",
    },
    {
      header: 'Velocidad_Ecu',
      key: 'ecu_speed',
      sortDirection: "DESC",
      isDefaultSortable: true,
    },
    {
      header: 'Geocerca',
      key: 'geofence_name',
    },
    {
      header: 'Dirección',
      key: 'moving_up',
    },
    {
      header: 'Fecha / Hora',
      key: 'time',
      sortDirection: "DESC",
      isDefaultSortable: true,
    }
  ];
  const { rows, headers } = useSorting(data, headerData);

  const convertToCSV = (data) => {
    // Define the new headers and their mapping to the old ones
    const headerMapping = {
      'time': 'fecha_hora',
      'patente': 'patente',
      'truck_type': 'marca',
      'firstname': 'nombre',
      'surname': 'apellido',
      'device_id': 'device_id',
      'velocidad': 'velocidad_kmh',
      'violation_value': 'exceso_velocidad_kmh',
      'geofence_name': 'geocerca',
      'speed_limit_up': 'limite_subida',
      'speed_limit_down': 'limite_bajada',
      'lon': 'lon',
      'lat': 'lat',
      'head': 'direccion',
      'ecu_speed': 'Velocidad_Ecu',
      'moving_up': 'Dirección'
    };

    if (data.length === 0) {
      return '';
    }

    const headers = Object.values(headerMapping).join(',') + '\n';

    const rows = data.map(row => {
      return Object.keys(headerMapping).map(key => {
        let value = row[key];
        // Check if the value is a number
        if (value === null || value === undefined) {
          return `""`;
        }
        else if (!isNaN(value) && value.toString().indexOf('.') !== -1) {

          // If it's a number with a dot, replace the dot with a comma
          value = value.toString().replace('.', ',');
        }
        return `"${value}"`; // Apply text formatting to all fields
      }).join(',');
    }).join('\n');

    return headers + rows;
  };

  const downloadCSV = (csvContent, fileName = 'download.csv') => {
    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
  
    // Create a hidden link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
  
    // Append link to the body, click it, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleDownload = () => {
    const csv = convertToCSV(data);
  
    downloadCSV(csv, 'Transgresiones_historicas.csv');
  };

  const transformedTrucks = machineData.map(truck => ({
    id: truck.id,
    text: `${truck.truckPatent}`
  }));

  // Add a new item at the first index
  transformedTrucks.unshift({
    id: '0',
    // Text has a leading white space so it appears first on the rendered list
    // which is then automatically trimmed.
    text: ' Todos'
  });

  /** Trim selected trucks because Todos was added a leading white space to appear first on the list. */
  const handleSelectedTrucks = (selectedItems) => {
    const trimmedItems = selectedItems.map(item => ({ ...item, text: item.text.trim() }))
    setSelectedTrucks(trimmedItems);
  }

  const handleSubmitFilter = () => {
    const pattern = 'yyyy-MM-dd'
    const _dateInitial = formatDate(dateValue[0], pattern)
    const _dateFinal = formatDate(dateValue[1], pattern)
    const dateInitial = `${_dateInitial} ${timeIn}:00`
    const dateFinal = `${_dateFinal} ${timeOut}:00`
    const params = {
      "trucksSelected": selectedTrucks,
      "initialDate": dateInitial,
      "finalDate": dateFinal,
    }

    dispatch(onGetSpeedHistoricThunk(params))
  }

  const canNotSubmit = useCallback(() => {
    if (!(dateValue.length === 2)) {
      return true
    }
    if (!isValidTimeIn) {
      return true
    }
    if (!isValidTimeOut) {
      return true
    }
    return false
  }, [dateValue, isValidTimeIn, isValidTimeOut])


  const Default = () => {
    return (
      <TableDiv>
        <DataTable rows={rows} headers={headers} width="100%">
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange,
            sortBy
          }) => <TableContainer {...getTableContainerProps()}>
              <TableToolbar>
                <TableToolbarContent>
                  {/* pass in `onInputChange` change here to make filtering work */}
                  <TableToolbarSearch onChange={onInputChange} />
                  <Button onClick={handleDownload} >Descargar</Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()} aria-label="sample table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => <TableHeader
                      key={i}
                      sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                      {...getHeaderProps({
                        header,
                        isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                        onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                      })}>
                      {header.header}
                    </TableHeader>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice((page - 1) * pageSize).slice(0, pageSize).map(row => <React.Fragment key={row.id}>
                    <TableRow {...getRowProps({
                      row
                    })}>
                      {row.cells.map(cell => <TableCell key={cell.id}>
                        {cell.value}
                      </TableCell>)}
                    </TableRow>
                  </React.Fragment>)}
                </TableBody>
              </Table>
            </TableContainer>}
        </DataTable>
        <Pagination onChange={changePaginationState} page={page} pageSize={pageSize} pageSizes={[10, 20, 100]} totalItems={data.length} />
      </TableDiv>)
  }

  return (
    <Stack gap={3}>
      <Div display="flex" gap="24px">
        <DatePicker datePickerType="range" value={dateValue} onChange={handleDateChange} dateFormat='d-m-Y' flex='0'>
          <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText="Fecha de Inicio" size="md" />
          <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText="Fecha de Término" size="md" />
        </DatePicker>
        <Div display="flex" gap="8px" width="100%">
          <Div>
            <TimePicker
              id="entrada"
              labelText="Hora Inicio"
              pattern='^(?:[01]\d|2[0-3]):[0-5]\d$'
              value={timeIn}
              invalid={!isValidTimeIn}
              invalidText="Hora Inválida"
              onChange={handleChangeTimeIn}
            />
          </Div>
          <Div>
            <TimePicker
              id="salida"
              labelText="Hora Fin"
              pattern='/^\d{1,}:[0-5]\d$/'
              value={timeOut}
              invalid={!isValidTimeOut}
              invalidText="Hora Inválida"
              onChange={handleChangeTimeOut}
            />
          </Div>
        </Div>
      </Div>

      <FilterDiv width="15%">
        {machineLoading && !machinesError && (
          <SkeletonPlaceholder />
        )}
        {!machineLoading && !machinesError && (
          <MultiSelect label="" id="carbon-multiselect-example" titleText="Seleccionar Equipos" items={transformedTrucks} itemToString={item => item ? item.text : ''} selectionFeedback="top-after-reopen" onChange={(event) => handleSelectedTrucks(event.selectedItems)} initialSelectedItems={[]} />
        )}
      </FilterDiv>
      <FilterDiv display='flex' alignItems='center'>
        <Button size='md' renderIcon={Filter} onClick={handleSubmitFilter} disabled={canNotSubmit()}>Cargar Datos a Tabla</Button>
      </FilterDiv>

      {loading && !error && (
        <>
          <DataTableSkeleton />
        </>
      )}
      {!loading && error && error.reason === "SOMETHING_WENT_WRONG_ERROR" && (
        <>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              No hay datos para este dia
            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && error && error.reason === "PAYLOAD_TOO_LARGE" && (
        <>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              Demasiados datos solicitados, reducir el rango de fechas o ponerse en contacto con Talabre9@terrestra.tech            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && !error && (
        <>
          <Card justifyContent='flex-start' >
            <DataTableIcon size={18} />
            <Typography className='cds--type-heading-compact-01'>
              TRANSGRESIONES DE VELOCIDAD - TABLA DE REGISTRO
            </Typography>
          </Card>
          <CardRow width='inherit'>
            <Card width='100%' padding="0">
              <Default />
            </Card>
          </CardRow>
        </>
      )}
    </Stack>
  )
}

export default SpeedHistoric
