import React from 'react'
import PropTypes from 'prop-types'
import {
  Content, Dropdown
} from '@carbon/react';
import { Help } from '@carbon/icons-react';
import Footer from '../Basics/Footer.container';
import Notification from './Notification.container';

/**
 * The BrandLayout's component.
 */
const BrandLayout = props => {
  const { children, childrenStyles, header, subNavbar, navbar } = props

  return (
    <>
      {navbar && (
        <>
          {navbar}
        </>
      )}
      {subNavbar && (
        <Content className="sub-navbar">
          <Dropdown id="inline" initialSelectedItem={subNavbar[0]} label="Option 1" items={subNavbar} itemToString={item => item ? item.text : ''} className='tab-selector' />
          <Help size={30} />
        </Content>
      )}
      {header && (
        <>
          <Content className="sub-header">
            {header}
            <Notification />
          </Content>
        </>
      )}
      <Content className="page-content" style={childrenStyles}>
        {children}
      </Content>
      <Footer />
    </>
  )
}

BrandLayout.defaultProps = {
  header: null,
  navbar: null,
  subNavbar: null
}

BrandLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  navbar: PropTypes.node,
  subNavbar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  )
}

export default BrandLayout
