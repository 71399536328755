import { makeActionCreator } from '../../../config/store/utils';
import { getHdopTrucks } from '../../../services/userService';

export const GET_HDOP = 'GET_HDOP';
export const GET_HDOP_ERROR = 'GET_HDOP_ERROR';
export const GET_HDOP_SUCCESS = 'GET_HDOP_SUCCESS';
export const onGetHdop = makeActionCreator(GET_HDOP);
export const onGetHdopError = makeActionCreator(GET_HDOP_ERROR, 'payload');
export const onGetHdopSuccess = makeActionCreator(
    GET_HDOP_SUCCESS,
    'payload'
);

// Modificar el `onGetAsarcoThunk` para recibir el parámetro `shift`.
export const onGetHdopThunk = (date) => async dispatch => {
    dispatch(onGetHdop());
    try {
        let endpointParams = {};
        if (date) {
            endpointParams = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            };
        } else {
            const today = new Date();
            const yesterday = new Date(today);
            date = new Date(yesterday.setDate(yesterday.getDate() - 1));
            endpointParams = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            };
        }

        // Llamar a la API y obtener los datos
        const { data } = await getHdopTrucks(endpointParams);

        // Verifica la estructura de los datos recibidos
        let hdop = JSON.parse(data);
        
        hdop = hdop.map((hdop, index) => ({
            ...hdop,
            id: `${index + 1}`,
        }));

        return dispatch(
            onGetHdopSuccess({
                hdop: hdop, 
            })
        );
    } catch (error) {
        console.log(error);
        return dispatch(
            onGetHdopError({
                error: error,
            })
        );
    }
};