import React from 'react'
import Typography from '../../../components/Basics/Typography';
import { Stack, ContainedListItem } from '@carbon/react'
import { Timer, Calendar } from '@carbon/icons-react'
import { ListItemContainer, GridContainer, GridItem, CardItemContainer, OuterContainer } from './KpiTree.styles';
import { teal80, orange60, yellow40, teal30 } from '@carbon/colors';
import { Separator } from './KpiTree.styles';

const LoadTree = ({ marginBottom, wordPadding, averageExca }) => {
	return (
    <Stack gap={4}>
      <GridContainer>
        <GridItem>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin={marginBottom}>Árbol de Carguío</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-02'>Producción</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{averageExca["Produccion diaria"]} m3/día</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={teal80}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin='0 0 20px'>Disponibilidad mecánica</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{averageExca["Disponibilidad Fisica"]} %</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={teal80}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01'>Promedio</Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Tiempo en Mantenimiento</Typography>
                <Typography className='cds--type-body-01'>{averageExca["Tiempo Mantenciones"]} h</Typography>
              </ListItemContainer>
            </ContainedListItem>
          </OuterContainer>
          <Separator />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={orange60}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-heading-compact-02' margin='0 0 20px'>Uso Operativo</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{averageExca["Uso Operativo"]} %</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={orange60}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio</Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Reservas Programadas</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{averageExca["Reservas"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Colación</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{averageExca["Colacion"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Cambio de turno</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{averageExca["Cambio de turno"]} h</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Cargas combustible</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>-</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-body-02' margin={marginBottom}>Rendimiento Operativo</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{averageExca["Rendimiento Operativo"]} m3h</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Pérdidas operacionales</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>-</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Esperando por camiones</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>-</Typography>
              </ListItemContainer>
              <Separator/>
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio </Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Timer}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Duración del ciclo</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{averageExca["Duracion Ciclo"]} min</Typography>
              </ListItemContainer>
              <Separator margin={marginBottom} />
              <ListItemContainer>
                <Typography className='cds--type-body-01' padding={wordPadding}>Tiempo de carga</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>{averageExca["Duracion Ciclo"]} min</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem isVisible={false} />
        <GridItem borderTopColor={yellow40}>
          <ContainedListItem>
            <ListItemContainer>
              <Typography className='cds--type-body-01'></Typography>
              <Typography className='cds--type-body-01' margin={marginBottom}>Promedio</Typography>
            </ListItemContainer>
          </ContainedListItem>
          <Separator />
          <OuterContainer>
            <ContainedListItem renderIcon={Calendar}>
              <ListItemContainer>
                <Typography className='cds--type-body-01'>Factor de carga</Typography>
                <Typography className='cds--type-body-01' margin={marginBottom}>-</Typography>
              </ListItemContainer>
              <Separator />
            </ContainedListItem>
          </OuterContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem isVisible={false} />
        <GridItem borderTopColor={teal30}>
          <ContainedListItem>
            <CardItemContainer>
              <Typography className='cds--type-body-02' margin={marginBottom}>Cantidad de Excavadoras</Typography>
            </CardItemContainer>
            <CardItemContainer>
              <Typography className='cds--type-body-01'>Promedio</Typography>
              <Typography className='cds--type-heading-04' margin={marginBottom}>{averageExca["Excavadoras Totales"]} unidades</Typography>
            </CardItemContainer>
          </ContainedListItem>
          <ContainedListItem />
        </GridItem>
      </GridContainer>
    </Stack>
	)
}

export default LoadTree