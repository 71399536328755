import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, Stack, DataTable, Table, TableBody, TableCell, TableContainer,
    TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch, DatePicker, DatePickerInput
} from "@carbon/react";
import { CardRow, CardComponent } from "../../../components/Layouts/Card.styles";
import { Filter, Identification } from '@carbon/icons-react'
import { DataTable as DataTableIcon } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { useRef, useState } from "react";
import ExpansionTable from "../../../components/Basics/ExpansionTable.container";
import { useSorting } from "../../../modules/hooks/sorting";
import LoadingContent from "../../../components/Animations/LoadingContent";
import { onGetSensorsThunk } from "./SensorsDetector.actions";

const SensorsCountContainer = () => {
    const dispatch = useDispatch()
    const { error, loading, data, date } = useSelector(state => state.sensorDetector)
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)

    const dispatchReport = (date) => {
        dispatch(onGetSensorsThunk(date[0]))
    }

    useEffect(() => {
        if (!loading) {
            return
        }
        dispatch(onGetSensorsThunk(yesterday))
    }, [])

    const headerData = [
        {
            header: 'Camión',
            key: 'truckPatent',
        },
        {
            header: 'Sensores instalados',
            key: 'sensor_names_list',
        },
        {
            header: 'Total Sensores',
            key: 'unique_sensor_count',
            sortDirection: "DESC",
            isDefaultSortable: true
        }
    ];

    const { rows, headers } = useSorting(data, headerData);

    const convertToCSV = (data) => {
        // Define the new headers and their mapping to the old ones
        const headerMapping = {
          'truckPatent': 'Camión',
          'sensor_names_list': 'Sensores instalados',
          'unique_sensor_count': 'Total Sensores',
        };
    
        if (data.length === 0) {
          return '';
        }
    
        const headers = Object.values(headerMapping).join(',') + '\n';
    
        const rows = data.map(row => {
          return Object.keys(headerMapping).map(key => {
            let value = row[key];
            // Check if the value is a number
            if (!isNaN(value) && value.toString().indexOf('.') !== -1) {
              // If it's a number with a dot, replace the dot with a comma
              value = value.toString().replace('.', ',');
            }
            return `"${value}"`; // Apply text formatting to all fields
          }).join(',');
        }).join('\n');
    
        return headers + rows;
      };
    
      const downloadCSV = (csvContent, fileName = 'download.csv') => {
        // Create a Blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
    
        // Create a hidden link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
    
        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
      const handleDownload = () => {
        const csv = convertToCSV(data);
    
        downloadCSV(csv, 'Sensores_presion_instalados.csv');
      };

    const Default = () => <DataTable rows={rows} headers={headers} width="100%">
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getExpandedRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange,
            sortBy
        }) => <TableContainer {...getTableContainerProps()}>
                <TableToolbar>
                    <TableToolbarContent>
                        {/* pass in `onInputChange` change here to make filtering work */}
                        <TableToolbarSearch onChange={onInputChange} />
                        <Button renderIcon={Filter} kind="secondary" hasIconOnly iconDescription="Filter table" />
                        <Button onClick={handleDownload} >Descargar</Button>
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                <TableHead>
                        <TableRow>
                            {headers.map((header, i) => <TableHeader
                                key={i}
                                sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                {...getHeaderProps({
                                    header,
                                    isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                    onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                                })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <React.Fragment key={row.id}>
                            <TableRow {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                            </TableRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable>;

    return (
        <>
            {loading && !error && (
                <LoadingContent />
            )}
            {!loading && error && (
                <>
                    <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} style={{ marginBottom: '40px' }} onChange={dispatchReport} value={date} dateFormat='d-m-Y'>
                        <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha del Reporte" id="daily-datepicker-error" size="md" />
                    </DatePicker>
                    <CardComponent width="10%">
                        <Typography className='cds--type-heading-compact-01'>
                            No hay datos para este dia
                        </Typography>
                    </CardComponent>
                </>
            )}
            {!loading && !error && (
                <Stack gap={3}>
                    <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} onChange={dispatchReport} value={date} dateFormat='d-m-Y'>
                        <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha del Reporte" id="daily-datepicker" size="md" />
                    </DatePicker>
                    <Card justifyContent='flex-start' >
                        <DataTableIcon size={18} />
                        <Typography className='cds--type-heading-compact-01'>
                            Pórtico - TABLA DE DATOS
                        </Typography>
                    </Card>
                    <CardRow width='inherit'>
                        <Card width='100%' padding="0">
                            <Default />
                        </Card>
                    </CardRow>
                </Stack>
            )}
        </>

    )
};

export default SensorsCountContainer;