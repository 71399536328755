import React from 'react'
import AnimationDefault from './AnimationDefault.jsx'
import AnimationVariant2 from './AnimationVariant2.jsx'
import AnimationVariant3 from './AnimationVariant3.jsx'
import AnimationVariant4 from './AnimationVariant4.jsx'
import AnimationVariant5 from './AnimationVariant5.jsx'
import '../Animations/Loading.styles.scss'
import Typography from '../Basics/Typography.jsx'

/**
 * The Loading Content' component.
 */
const LoadingContent = () => {
  return (
    <div className="full">
      <div className="animation-container">
        <AnimationDefault />
        <AnimationVariant2 className="animation-rect" />
        <AnimationVariant3 className="animation-rect" />
        <AnimationVariant4 className="animation-rect" />
        <AnimationVariant5 className="animation-rect" />
      </div>
      <Typography className="cds--type-legal-01">Obteniendo Datos</Typography>
    </div>
  )
}

export default LoadingContent