import {
  EMAIL_CHECK,
  ONLY_NUMBERS_CHECK,
  PASSWORD_CHECK,
} from '../../config/settings/regexes';

/**
 * Check if the given string value is a valid email.
 */
export const checkErrorEmail = customMessage => value =>
  EMAIL_CHECK.test(value)
    ? ''
    : customMessage || 'Formato de email incorrecto.';

/**
 * Check if the given value satisfy the maximum length allowed.
 */
export const checkErrorMaxLength = (maxLength, customMessage) => value => {
  const isString = typeof value === 'string';
  const result = isString
    ? value?.length > maxLength
    : value.toString().length > maxLength;

  if (result && isString) {
    return (
      customMessage ||
      `Este campo excede el máximo de ${maxLength} caracteres permitido.`
    );
  }

  if (result && !isString) {
    return customMessage || `Este campo permite hasta ${maxLength} dígitos.`;
  }

  return '';
};

/**
 * Check if the given value satisfy the minimum length allowed.
 */
export const checkErrorMinLength = (minLength, customMessage) => value => {
  const isString = typeof value === 'string';
  const result = isString
    ? value?.length < minLength
    : value.toString().length < minLength;

  if (result && isString) {
    return (
      customMessage ||
      `Este campo debe tener un mínimo de ${minLength} caracteres.`
    );
  }

  if (result && !isString) {
    return (
      customMessage ||
      `Este campo debe tener un mínimo de ${minLength} dígitos.`
    );
  }

  return '';
};

/**
 * Check if the given string value contain only numbers.
 */
export const checkErrorOnlyNumbers = customMessage => value =>
  ONLY_NUMBERS_CHECK.test(value)
    ? ''
    : customMessage || 'Este campo sólo permite números.';

/**
 * Check if number is within range.
 */
export const checkNumberWithinRange = (min, max, customMessage) => value =>
  value >= min && value <= max
    ? ''
    : customMessage || `Número no puede ser menor a ${min} ni mayor a ${max}`;

/**
 * Check if the given string value is empty.
 */
export const checkErrorRequired = customMessage => value => {
  const isString = typeof value === 'string';
  const result = isString ? value?.length > 0 : value.toString().length > 0;

  return result ? '' : customMessage || 'Campo obligatorio';
};

/**
 * Check if the passwords meet the requirements.
 */
export const checkErrorPassword = customMessage => value =>
  PASSWORD_CHECK.test(value)
    ? ''
    : customMessage || 'Tu contraseña no cumple con los requisitos mínimos.';

/**
 * Check if the passwords matchs.
 */
export const checkErrorSamePasswords = (
  passwordToCompare,
  customMessage
) => value =>
  value === passwordToCompare
    ? ''
    : customMessage || 'Esta contraseña no coincide con la anterior.';

/**
 * Check if the value meet the requirements by the given regular expression.
 */
export const checkErrorRegEx = (regex, customMessage) => value =>
  value.match(regex)
    ? ''
    : customMessage || 'El campo no cumple con los requerimientos mínimos';
