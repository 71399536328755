import { GET_KPI, GET_KPI_ERROR, GET_KPI_SUCCESS } from './KpiTree.actions';

const kpiTreeState = {
  loading: true,
  error: undefined,
  avgLoads: {},
  averageExca: {},
  avgBull: {},
  avgRoller: {},
};

/**
 * The Historic report reducer.
 */
const kpiTreeReducer = (state = kpiTreeState, { payload, type }) => {
  switch (type) {
    case GET_KPI: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_KPI_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_KPI_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        avgLoads: payload.avgLoads,
        averageExca: payload.averageExca,
        avgBull: payload.avgBull,
        avgRoller: payload.avgRoller,
      };
    }
    default:
      return state;
  }
};

export default kpiTreeReducer;
