import { makeActionCreator } from '../../../config/store/utils';
import { formatDate } from '../../../modules/utils/formatters';
import { getKpiTree } from '../../../services/productivityService';

export const GET_KPI = 'GET_KPI';
export const GET_KPI_ERROR = 'GET_KPI_ERROR';
export const GET_KPI_SUCCESS = 'GET_KPI_SUCCESS';
export const onGetKpi = makeActionCreator(GET_KPI);
export const onGetKpiError = makeActionCreator(GET_KPI_ERROR, 'payload');
export const onGetKpiSuccess = makeActionCreator(GET_KPI_SUCCESS, 'payload');
export const onGetKpiThunk = (initial, end) => async dispatch => {
  dispatch(onGetKpi());
  try {
    let endpointParams = {};
    const pattern = 'yyyy-MM-dd';
    if (initial & end) {
      const _dateInitial = formatDate(initial, pattern);
      const _dateFinal = formatDate(end, pattern);
      endpointParams = {
        initial: _dateInitial,
        end: _dateFinal,
      };
    }
    const { data } = await getKpiTree(endpointParams);

    const avgLoads = data.data.avg_days;
    const avgExcavator = data.data.avg_exca;
    const avgBull = data.data.avg_bull;
    const avgRoller = data.data.avg_rodi;

    return dispatch(
      onGetKpiSuccess({
        avgLoads: avgLoads,
        averageExca: avgExcavator,
        avgBull: avgBull,
        avgRoller: avgRoller,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(onGetKpiError({ error: error }));
  }
};
