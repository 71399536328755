import { API } from 'aws-amplify';
import {
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
} from '../config/settings/environments';

/**
 * To get the daily report.
 */
export const getDailyReport = async ({ year, month, day }) => {
  const paths = {
    talabre: '/v1/daily-reports',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/daily-reports';
  if (year && month && day) {
    path = `${path}?year=${year}&month=${month}&day=${day}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the daily report.
 */
export const getDailyTurnReport = async ({ year, month, day, turn }) => {
  const paths = {
    talabre: '/v1/daily-reports-turn',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/daily-reports-turn';
  if (year && month && day) {
    path = `${path}?year=${year}&month=${month}&day=${day}&turn=${turn}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the daily Asarco.
 */
export const getDailyAsarco = async ({ year, month, day, truck, shift }) => {
  const paths = {
    talabre: '/v1/daily-asarco',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/daily-asarco';
  if (year && month && day) {
    path = `${path}?year=${year}&month=${month}&day=${day}`;
  }
  if (truck) {
    path = `${path}&truck=${truck}`;
  }
  if (shift) {
    path = `${path}&shift=${shift}`; // Incluir el turno en la llamada a la API
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get daily truck info modal
 */
export const getDailyTruckInfo = async ({ year, month, day, truck }) => {
  const paths = {
    talabre: '/v1/get-truck-modal',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-truck-modal';
  if (year && month && day && truck) {
    path = `${path}?year=${year}&month=${month}&day=${day}&truck=${truck}`;
  }
  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get daily truck info modal
 */
export const getDailyTruckInfoTurn = async ({
  year,
  month,
  day,
  truck,
  turn,
}) => {
  const paths = {
    talabre: '/v1/get-truck-modal',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-truck-modal-turn';
  if (year && month && day && truck) {
    path = `${path}?year=${year}&month=${month}&day=${day}&truck=${truck}&turn=${turn}`;
  }
  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get weekly report.
 */
export const getWeeklyReport = async ({ year, month, day }) => {
  const paths = {
    talabre: '/v1/weekly-reports',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/weekly-reports';
  if (year && month && day) {
    path = `${path}?year=${year}&month=${month}&day=${day}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get monthly report.
 */
export const getMonthlyReport = async ({ year, month, day }) => {
  const paths = {
    talabre: '/v1/monthly-reports',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/monthly-reports';
  if (year && month && day) {
    path = `${path}?year=${year}&month=${month}&day=${day}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get historic report.
 */
export const getHistoricReport = async ({ initial, end }) => {
  const paths = {
    talabre: '/v1/historic-reports',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/historic-reports';
  if (initial && end) {
    path = `${path}?initial_date=${initial}&final_date=${end}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get Kpi report.
 */
export const getKpiTree = async ({ initial, end }) => {
  const paths = {
    talabre: '/v1/kpi-tree',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/kpi-tree';
  if (initial && end) {
    path = `${path}?initial_date=${initial}&final_date=${end}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get the daily report.
 */
export const getGeneralReport = async ({ type, year, month, day }) => {
  const paths = {
    talabre: '/v1/get-report-general',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-report-general';
  if (year && month && day) {
    path = `${path}?year=${year}&month=${month}&day=${day}&report_type=${type}`;
  } else {
    path = `${path}?report_type=${type}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};
