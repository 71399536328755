import { Modal, Stack } from "@carbon/react";
import { CardRow } from "../../../components/Layouts/Card.styles";
import { forwardRef } from "react";
import { g90 } from "@carbon/themes";
import { FirstRow, SecondRow, ThirdRow } from "./RouteDetails.row";

const RouteDetails = forwardRef(({ open, setOpen }, ref) => {
    // Averages Table Data
    const rows = [{
        id: 'load-balancer-1',
        title: 'Tiempo de Traslado Promedio - Hoy',
        geofence: '00:00:00',
    }, {
        id: 'load-balancer-2',
        title: 'Tiempo de Traslado Promedio - Semana',
        geofence: '00:00:00',
    }, {
        id: 'load-balancer-3',
        title: 'Tiempo de Traslado Promedio - Mes',
        geofence: '00:00:00',
    }];
    const headers = [
        {
            header: '',
            key: 'title',
        },
        {
            header: 'Geocerca',
            key: 'geofence',
        }
    ];

    // Styles applied to every card inside the modal
    const styles = {
        background: g90.backgroundActive,
    };

    return (
        <Modal size="lg" modalHeading="id_Ruta" modalLabel="Ficha de Ruta" launcherButtonRef={ref} isFullWidth passiveModal open={open} onRequestClose={() => setOpen(false)}>
            <Stack gap={3}>
                <CardRow>
                    <FirstRow styles={styles} />
                </CardRow>
                <CardRow>
                    <SecondRow styles={styles} />
                </CardRow>
                <CardRow>
                    <ThirdRow headers={headers} rows={rows} styles={styles} />
                </CardRow>
            </Stack>
        </Modal>
    )
})

export default RouteDetails;