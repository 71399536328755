import { Identification, SummaryKpi } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { Div } from "../../../components/Basics/Div.styles";
import SimpleTable from "../../../components/Basics/SimpleTable.container";
import Histogram from "../../../components/Graphs/Histogram.container";

export function FirstRow({ styles }) {
    return (
        <CardComponent width="100%">
            <Typography>
                Tiempo de traslado promedio: 00:00:00.
            </Typography>
            <br />
            <Card justifyContent='flex-start' background={styles.background}>
                <Identification size={18} />
                <Typography className='cds--type-heading-compact-01'>
                    DATOS
                </Typography>
            </Card>
            <CardRow display="flex" width='inherit'>
                <Div display="grid" gap="12px" gridTemplateColumns="repeat(2, 1fr)" padding="20px" width="100%">
                    <Typography className='cds--type-heading-compact-01'>
                        Origen
                    </Typography>
                    <span>id_Geocerca</span>
                    <Typography className='cds--type-heading-compact-01'>
                        Destino
                    </Typography>
                    <span>tipo_geocerca</span>
                    <Typography className='cds--type-heading-compact-01'>
                        Distancia
                    </Typography>
                    <span>00 km</span>
                </Div>
            </CardRow>
        </CardComponent>
    )
}

export function SecondRow({ styles }) {
    return (
        <CardComponent width="100%">
            <Card justifyContent='flex-start' background={styles.background}>
                <SummaryKpi size={18} />
                <Typography className='cds--type-heading-compact-01'>
                    RENDIMIENTO MES
                </Typography>
            </Card>
            <CardRow width='inherit'>
                <Histogram title='Tiempo de Traslado (minutos)' />
            </CardRow>
        </CardComponent>
    )
}

export function ThirdRow({ headers, rows, styles }) {
    return (
        <CardComponent width="100%">
            <Card justifyContent='flex-start' background={styles.background}>
                <SummaryKpi size={18} />
                <Typography className='cds--type-heading-compact-01'>
                    PROMEDIOS
                </Typography>
            </Card>
            <SimpleTable headers={headers} rows={rows} />
        </CardComponent>
    )
}
