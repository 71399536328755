import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import routes from './config/settings/routes'
import { Amplify } from 'aws-amplify'
import {
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_IDENTITY_POOL_ID
} from './config/settings/environments'
import LoginPage from './pages/Login';
import Diagram from './containers/Diagram/Diagram.container';
import HomePage from './pages/Home';
import DashboardPage from './pages/Dashboard';
import MaintenancePage from './pages/Maintenance';
import MonitoringPage from './pages/Monitoring';
import History from './pages/History';
import ProductivityPage from './pages/Productivity';
import SecurityPage from './pages/Security';
import RecordsPage from './pages/Records';
import DatabasePage from './pages/Database';
import UsersPage from './pages/Users';
import PasswordRecovery from './pages/PasswordRecovery';
import Map from './pages/Map';
import Fronts from './pages/Fronts';
import UserManual from './pages/UserManual';
import DevicesPage from './pages/Devices';
import DocumentationPage from './pages/Documentation';
import Notifications from './pages/Notifications';

/**
 * The Route's component.
 */

const AppRoutes = () => {
  const manualAWSconfig = {
    Auth: {
      identityPoolId: REACT_APP_USER_IDENTITY_POOL_ID,
      userPoolId: REACT_APP_USER_POOL_ID,
      region: 'us-east-1',
      userPoolWebClientId: REACT_APP_USER_POOL_CLIENT_ID
    }
  }
  Amplify.configure(manualAWSconfig)

  return (
    <BrowserRouter>
      <History>
        <Switch>
          <Route exact path={routes.home} component={HomePage} />
          <Route exact path={routes.login} component={LoginPage} />
          <Route exact path={routes.dashboard} component={DashboardPage} />
          <Route path={routes.documentation} component={DocumentationPage} />
          <Route exact path={routes.maintenance} component={MaintenancePage} />
          <Route exact path={routes.monitoring} component={MonitoringPage} />
          <Route exact path={routes.productivity} component={ProductivityPage} />
          <Route exact path={routes.security} component={SecurityPage} />
          <Route exact path={routes.records} component={RecordsPage} />
          <Route exact path={routes.diagram} component={Diagram} />
          <Route exact path={routes.database} component={DatabasePage} />
          <Route exact path={routes.users} component={UsersPage} />
          <Route exact path={routes.passwordRecovery} component={PasswordRecovery} />
          <Route exact path={routes.map} component={Map} />
          <Route exact path={routes.fronts} component={Fronts} />
          <Route exact path={routes.userManual} component={UserManual} />
          <Route exact path={routes.devices} component={DevicesPage} />
          <Route exact path={routes.notifications} component={Notifications} />
        </Switch>
      </History>
    </BrowserRouter>
  )
}

export default AppRoutes
