import React from "react";
import { Stack } from "@carbon/react";
import Card from '../../../components/Layouts/Card';
import Typography from "../../../components/Basics/Typography";
import { Map } from "@carbon/icons-react";
import { Iframe } from "./MonitoringMap.styles";
import { Div } from "../../../components/Basics/Div.styles";

const TextWithIcon = ({ imageSrc, text }) => {
  return (
    <Div display="flex" gap="8px" alignItems="center">
      <img width="32" height="18" src={imageSrc} alt={text} />
      <Typography className='cds--type-helper-text-01'>{text}</Typography>
    </Div>
  )
}

const MonitoringMap = () => {
  return (
    <Stack gap={3}>
      <Card justifyContent='flex-start' >
        <Map size={18} />
        <Typography className='cds--type-heading-compact-01'>
          MAPA - TIEMPO REAL
        </Typography>
      </Card>
      <Card background="none">
        <Div display="flex" justifyContent="space-between" alignItems="center" gap="8px">
          <Typography className='cds--type-label-02'>Tipo de Máquinas:</Typography>
          <TextWithIcon imageSrc="/assets/icons/icono_camión_tolva_white.svg" text="Camión Tolva" />
          <TextWithIcon imageSrc="/assets/icons/icono_camión_aljibe_v1.svg" text="Camión Aljibe" />
          <TextWithIcon imageSrc="/assets/icons/icono_camión_combustible.svg" text="Camión Combustible/Camión Lubricador" />
          <TextWithIcon imageSrc="/assets/icons/icono_camión_pluma.svg" text="Camión Pluma/Tracto Camión" />
          <TextWithIcon imageSrc="/assets/icons/icono_rodillo.svg" text="Rodillo" />
          <TextWithIcon imageSrc="/assets/icons/icono_cargadora_frontal.svg" text="Cargadora Frontal" />
          <TextWithIcon imageSrc="/assets/icons/icono_retroexcavadora.svg" imageColor="red" text="Retroexcavadora" />
          <TextWithIcon imageSrc="/assets/icons/icono_motoniveladora.svg" text="Motoniveladora" />
          <TextWithIcon imageSrc="/assets/icons/icono_excavadora.svg" imageColor="red" text="Excavadora" />
          <TextWithIcon imageSrc="/assets/icons/icono_bulldozer.svg" text="Bulldozer" />
          <TextWithIcon imageSrc="/assets/icons/icono_bus.svg" text="Bus" />
          <TextWithIcon imageSrc="/assets/icons/icono_minibus.svg" text="Mini bus" />
        </Div>
      </Card>
      <Card>
        <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/f95f1456-333a-454c-b37d-7a4fd5914ebc/mapa-talabre-v3?orgId=1&refresh=30s&from=1708023678869&to=1708045278870&kiosk" width="100%" height="100%" />
      </Card>
    </Stack>
  )
}

export default MonitoringMap