import { ToolbarControlTypes } from '@carbon/charts';

const ENABLED_TOOLBAR_CONTROL_TYPES = [
  ToolbarControlTypes.SHOW_AS_DATATABLE,
  ToolbarControlTypes.MAKE_FULLSCREEN,
];

// Set of toolbar options shared across all carbon graphs
export const toolbarOptions = {
  toolbar: {
    controls: ENABLED_TOOLBAR_CONTROL_TYPES.map((type, i) => ({
      type,
      shouldBeDisabled: () => true,
    })),
  },
};

// Function to adjust and format hours from the 8 AM baseline
export const formatFromBaseline = hour => {
  // Calculate the actual hour from 8 AM
  let actualHour = 8 + hour;
  if (actualHour >= 24) actualHour -= 24;

  const isPM = actualHour >= 12;
  const adjustedHour = actualHour % 12 || 12;
  const minutes = Math.round((hour % 1) * 60);
  const formattedMinutes =
    minutes > 0 ? `:${minutes < 10 ? '0' : ''}${minutes}` : '';

  return `${adjustedHour}${formattedMinutes} ${isPM ? 'PM' : 'AM'}`;
};

// Convert to 12-hour format with AM/PM notation for labeling
export const convertTo12HourFormat = value => {
  let hour = value;
  if (hour >= 24) hour -= 24;

  const suffix = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour === 0 ? 12 : hour; // Convert 0 hour to 12 for display

  return `${hour} ${suffix}`;
};

// Determine if it's currently daytime or nighttime
export const isDaytime = hour => hour >= 8 && hour < 20;

// Check for data presence in the specified time range
export const hasDataInTimeRange = (data, start, end) => {
  return data.some(item => {
    const hour = item.value; // assuming 'value' is the hour
    return hour >= start && (hour < end || end < start); // Account for overnight wrap-around
  });
};
