export default function AnimationVariant2({ className }) {
    return (
        <svg id="svg1" width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g x="44.6677" y="1.5927" id="text1" className={className}>
                <rect x="44.6677" y="1.5927" width="62.0252" height="62.0252"
                    transform="rotate(45 44.6677 1.5927)" fill="#262626" stroke="#525252" />
            </g>
            <path
                d="M60.0494 31.6924L50.7344 29.3636C50.4968 29.3046 50.2466 29.3215 50.0191 29.412L38.7247 33.9297L29.7756 31.6924C29.604 31.6494 29.4248 31.6462 29.2517 31.6829C29.0786 31.7196 28.9161 31.7952 28.7766 31.9041C28.6371 32.013 28.5243 32.1522 28.4466 32.3112C28.369 32.4703 28.3287 32.6449 28.3287 32.8218V58.4381C28.3287 58.6977 28.4155 58.9499 28.5752 59.1545C28.735 59.3591 28.9586 59.5045 29.2104 59.5675L38.5254 61.8963C38.6179 61.9192 38.7128 61.9309 38.808 61.9312C38.9563 61.9313 39.1032 61.9029 39.2407 61.8476L50.5352 57.3298L59.4845 59.5676C59.6562 59.6105 59.8354 59.6137 60.0084 59.577C60.1815 59.5403 60.3439 59.4646 60.4834 59.3557C60.6228 59.2469 60.7356 59.1076 60.8132 58.9486C60.8908 58.7896 60.9311 58.615 60.9312 58.4381V32.8218C60.9311 32.5622 60.8443 32.3101 60.6846 32.1054C60.5248 31.9008 60.3012 31.7554 60.0494 31.6924ZM58.6024 39.8081H51.6162V31.9842L58.6024 33.7307V39.8081ZM37.6437 49.1231H30.6574V42.1368H37.6437V49.1231ZM39.9724 39.8081V35.939L49.2874 32.213V39.8081H39.9724ZM49.2874 42.1368V49.1231H39.9724V42.1368H49.2874ZM39.9724 51.4518H49.2874V55.3207L39.9724 59.0467V51.4518ZM51.6162 42.1368H58.6024V49.1231H51.6162V42.1368ZM37.6437 36.0595V39.8081H30.6574V34.3129L37.6437 36.0595ZM30.6574 51.4518H37.6437V59.2755L30.6574 57.5289V51.4518ZM51.6162 55.1996V51.4518H58.6024V56.9468L51.6162 55.1996Z"
                fill="white" />
        </svg>
    )
}