import React from 'react'
import Navbar from '../containers/Navbar/Navbar.container'
import ManagementMenu from '../containers/ManagementMenu/ManagementMenu.container'
import routes from '../config/settings/routes'
import ProtectBySession from '../components/Security/ProtectBySession'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import { Notification } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem, Stack } from '@carbon/react'
import { CardComponent } from '../components/Layouts/Card.styles'
import Card from '../components/Layouts/Card';
import Typography from '../components/Basics/Typography'
import NotificationContainer from '../containers/Notifications/Notifications.container'

/**
 * The Front's Page.
 */
const Notifications = () => {
  const NotificationsCrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/#">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem href="#" isCurrentPage>Notificaciones</BreadcrumbItem>
      </Breadcrumb>
    )
  }

  const title = 'Notificaciones'

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.notifications} />} />}
        header={<Heading title={title} icon={<Notification size={50} />} breadcrumbs={<NotificationsCrumbs />} tooltipContent='La vista de notificaciones registra las notificaciones durante el día.' />}
      >
        <NotificationContainer />
      </BrandLayout>
    </ProtectBySession>
  )
}

export default Notifications
