import React from "react";
import PropTypes from 'prop-types';
import { StyledLink } from "./Link.styles";

/**
* The Link's component
*/
const Link = props => {
  const { children, route, display, disabled } = props
  return (
    <StyledLink display={display} to={route} disabled={disabled}>
      {children}
    </StyledLink>

  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string.isRequired,
  display: PropTypes.string
}

export default Link