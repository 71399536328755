import { makeActionCreator } from '../../../config/store/utils';
import { getRealTimeReports } from '../../../services/monitoringService';
import { formatDate, shortenFront } from '../../../modules/utils/formatters';

export const GET_FROMB57_FROM = 'GET_FROMB57_FROM';
export const GET_FROMB57_FROM_ERROR = 'GET_FROMB57_FROM_ERROR';
export const GET_FROMB57_FROM_SUCCESS = 'GET_FROMB57_FROM_SUCCESS';
export const onGetRealTimeReports = makeActionCreator(GET_FROMB57_FROM);
export const onGetRealTimeReportsError = makeActionCreator(
  GET_FROMB57_FROM_ERROR,
  'payload'
);
export const onGetRealTimeReportsSuccess = makeActionCreator(
  GET_FROMB57_FROM_SUCCESS,
  'payload'
);
export const onGetRealTimeReportsThunk = () => async dispatch => {
  dispatch(onGetRealTimeReports());
  try {
    const type = 't_traslados_desde_b57';
    const { data } = await getRealTimeReports(type);
    const currTurn = data.current_turn.tablas;
    const chargeTime = currTurn.t_traslados
      .filter(obj => obj.value.length > 0)
      .map(item => {
        const shortenedKey = shortenFront(item.key);
        return {
          group: item.key,
          value: item.value.flatMap((val, innerIndex) => ({
            hourly: `${innerIndex + 1}`,
            value: val,
            group: shortenedKey,
          })),
        };
      });
    console.log(currTurn.t_traslados);
    const boxPlot = currTurn.t_traslados
      .filter(obj => obj.value.length > 0)
      .flatMap(item => {
        if (item.value.length === 0) {
          return [{ group: item.key, value: [] }];
        } else {
          return item.value.map(val => ({
            group: item.key,
            value: val,
          }));
        }
      });
    const chargeMean = currTurn.t_traslados_mean.map(item => ({
      ...item,
      value: Number(item.value).toFixed(2),
    }));
    const table = JSON.parse(currTurn.tabla_por_ruta).map((item, index) => {
      return {
        ...item,
        id: `${index++}`,
        origin: `${item.lugar_carga} - ${item.lugar_descarga}`,
      };
    });

    return dispatch(
      onGetRealTimeReportsSuccess({
        chargeTime: chargeTime,
        boxPlot: boxPlot,
        table: table,
        chargeMean: chargeMean,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetRealTimeReportsError({
        error: error,
      })
    );
  }
};
