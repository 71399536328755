import React, { useEffect } from "react";
import { DatePicker, DatePickerInput, Button, Stack, Select, SelectItem, Tabs, Tab, TabList, TabPanels, TabPanel } from "@carbon/react";
import { CardComponent } from '../../components/Layouts/Card.styles'
import Card from '../../components/Layouts/Card';
import { Calendar } from "@carbon/icons-react";
import Typography from "../../components/Basics/Typography";
import { g90, g100 } from '@carbon/themes';
import { useDispatch, useSelector } from 'react-redux'
import { useDatePicker, useSelect } from "../../components/Inputs/Inputs.hooks";
import { checkErrorRequired } from "../../modules/errors/validations";
import { onGetNotificationThunk } from "./Notifications.actions";
import LoadingContent from "../../components/Animations/LoadingContent";
import VelocityTable from "./VelocityTable.container";
import { InputWrapper } from "./Notifications.styles";
import UnifiedNotificationsTable from "./AllDataTable.container";
import ADASTable from "./AdasTable.container";
import FatigueAndYawnTable from "./FatigueTable.container";
import FlatTiresTable from "./FlatTiresTable.container";

const NotificationContainer = () => {
  const dispatch = useDispatch()
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)
  const { error, loading } = useSelector(state => state.notification)

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: [yesterday],
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: typeError, value: typeValue, onChange: handleTypeChange } = useSelect({
    initialValue: 'turno_dia',
  })

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetNotificationThunk(dateValue, typeValue))
  }, [])

  const dispatchNotifications = (date) => {
    handleDateChange(date)
    dispatch(onGetNotificationThunk(date, typeValue))
  }

  const dispatchNotificationsType = (type) => {
    handleTypeChange(type)
    dispatch(onGetNotificationThunk(dateValue, type?.target?.value || typeValue))
  }

  return (
    <>
      <Stack gap={3}>
        <CardComponent width="100%">
          <Card justifyContent='flex-start'>
            <Calendar size={18} />
            <Typography className='cds--type-heading-compact-01'>
              NOTIFICACIONES
            </Typography>
          </Card>
          {loading && !error && (
            <LoadingContent />
          )}
          {!loading && error && (
            <>
              <InputWrapper>
                <DatePicker datePickerType="single" maxDate={yesterday.toISOString()} value={dateValue} dateFormat='d-m-Y' onChange={dispatchNotifications}>
                  <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha de las notificaciones" id="daily-datepicker" size="md" />
                </DatePicker>
                <Select id="select-1" labelText="Tipo de Informe" value={typeValue} invalid={Boolean(typeError)} invalidText={typeError} onChange={dispatchNotificationsType}>
                  <SelectItem value="turno_dia" text="Turno día" />
                  <SelectItem value="turno_noche" text="Turno noche" />
                </Select>
                <CardComponent width="10%">
                  <Typography className='cds--type-heading-compact-01'>
                    No hay datos para este dia
                  </Typography>
                </CardComponent>
              </InputWrapper>
            </>
          )}
          {!loading && !error && (
            <Card>
              <Card background={g100.background} width='100%' flexDirection='column' alignItems='left' gap='20px'>
                <InputWrapper>
                  <DatePicker
                    datePickerType="single"
                    maxDate={yesterday.toISOString()}
                    value={dateValue}
                    dateFormat="d-m-Y"
                    onChange={dispatchNotifications}
                  >
                    <DatePickerInput
                      placeholder="dd/mm/yyyy"
                      labelText="Fecha de las notificaciones"
                      id="daily-datepicker"
                      size="md"
                      style={{ width: '15vw' }}
                    />
                  </DatePicker>
                  <Select
                    id="select-1"
                    labelText="Tipo de Informe"
                    value={typeValue}
                    invalid={Boolean(typeError)}
                    invalidText={typeError}
                    onChange={dispatchNotificationsType}
                    style={{ flex: 1 }}
                  >
                    <SelectItem value="turno_dia" text="Turno día" />
                    <SelectItem value="turno_noche" text="Turno noche" />
                  </Select>
                </InputWrapper>
                <Tabs>
                  <TabList activation="manual" aria-label='List of tabs'>
                    <Tab renderIcon={Calendar}>Ver todas</Tab>
                    <Tab renderIcon={Calendar}>T. de velocidad</Tab>
                    <Tab renderIcon={Calendar}>Pinchazos</Tab>
                    <Tab renderIcon={Calendar}>ADAS</Tab>
                    <Tab renderIcon={Calendar}>Fatiga y Somnolencia</Tab>
                  </TabList>
                  <TabPanels style={{ width: '100%' }}>
                    <TabPanel>
                      <UnifiedNotificationsTable />
                    </TabPanel>
                    <TabPanel>
                      <VelocityTable />
                    </TabPanel>
                    <TabPanel>
                      <FlatTiresTable />
                    </TabPanel>
                    <TabPanel>
                      <ADASTable />
                    </TabPanel>
                    <TabPanel>
                      <FatigueAndYawnTable />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Card>
            </Card>
          )}
        </CardComponent>
      </Stack>
    </>
  )
};

export default NotificationContainer;