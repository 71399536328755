import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    DataTable, Modal, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
    TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch,
    TextInput,
    DatePicker, DatePickerInput, TimePicker,
} from '@carbon/react';
import { Pen, Information } from '@carbon/icons-react';
import { Div } from "../../../components/Basics/Div.styles";
import { onSetMaintenanceModalThunk, onEditMaintenanceThunk } from "./Ongoing.actions";
import {
    checkErrorRequired
} from '../../../modules/errors/validations'
import { formatDate } from '../../../modules/utils/formatters';
import { useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import { useSorting } from "../../../modules/hooks/sorting";


const ExitForm = ({ button, open, setOpen }) => {
    const { modalParams, sendingEdit, editError, editSuccess } = useSelector(state => state.maintenance)
    const [timeOut, setTimeOut] = useState('');
    const dispatch = useDispatch()
    const [isValidTimeOut, setIsValidTimeOut] = useState(false);
    const outPattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/

    const handleChangeTimeOut = (e) => {
        const value = e.target.value;
        setTimeOut(value);

        // Check if the input matches the regex pattern
        if (outPattern.test(value)) {
            setIsValidTimeOut(true);
        } else {
            setIsValidTimeOut(false);
        }
    };

    const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
        initialValue: new Date(),
        errorCallbacks: [checkErrorRequired()]
    })

    const canNotSubmit = useCallback(() => {
        if (!dateValue) {
            return true
        }
        if (!isValidTimeOut) {
            return true
        }
        if (sendingEdit) {
            return true
        }
        if (editError) {
            return true
        }
        if (editSuccess) {
            return true
        }
    }, [dateValue, isValidTimeOut])

    const disabledSubmit = canNotSubmit()

    const getLoadingStatus = () => {
        if (editError) {
            return 'error'
        }
        if (sendingEdit) {
            return 'active'
        }
        if (editSuccess) {
            return 'finished'
        }
        return 'inactive'
    }

    const handleSubmit = () => {
        const _date = formatDate(dateValue, 'yyyy-MM-dd') + `T${timeOut}:00Z`
        const params = {
            "machinePatent": modalParams.machinePatent,
            "eventTime": modalParams.eventTime,
            "outTime": _date
        }
        dispatch(onEditMaintenanceThunk(params))
    }

    return (
        <Modal danger loadingStatus={getLoadingStatus()} onRequestSubmit={handleSubmit} launcherButtonRef={button} modalHeading="Salida de Mantenimiento" modalLabel="Formulario" primaryButtonText="Completar" open={open} onRequestClose={() => setOpen(false)} primaryButtonDisabled={disabledSubmit}>
            <p style={{
                marginBottom: '1rem'
            }}>
                Formulario para registrar la salida de una máquina de la zona de taller, para cálculo de disponibilidad en sistema.
            </p>

            <TextInput disabled id="select-1" labelText="Taller" value={modalParams.workshopId} />
            <Div display="flex" gap="8px">
                <TextInput disabled id="select-2" labelText="Conductor" value={modalParams.driverId} />
                <TextInput disabled id="select-3" labelText="Máquina" value={modalParams.machinePatent} />
            </Div>
            <TextInput disabled id="select-4" labelText="Tipo de Mantenimiento" value={modalParams.maintenanceType} />
            <TextInput disabled id="select-5" labelText="Motivo" value={modalParams.reason} />
            <Div display="flex" gap="8px">
                <TextInput disabled data-modal-primary-focus id="text-input-1" labelText="Fecha de Ingreso" value={modalParams.eventTime} style={{
                    marginBottom: '1rem'
                }} />
                <TextInput disabled data-modal-primary-focus id="text-input-1" labelText="Hora Estimada de salida" value={modalParams.estimatedTime} style={{
                    marginBottom: '1rem'
                }} />
            </Div>
            <Div display="flex" gap="8px">
                <DatePicker datePickerType="single" value={dateValue} invalid={true} invalidText={dateError} onChange={handleDateChange}>
                    <DatePickerInput placeholder="dd/mm/yyyy" labelText="Fecha de Salida" id="date-picker-single" size="md" />
                </DatePicker>
                <TimePicker id="salida" labelText="Hora de Salida" pattern='/^(?:[01]\d|2[0-3]):[0-5]\d$/' value={timeOut} invalid={!isValidTimeOut} onChange={handleChangeTimeOut} />
            </Div>

            <Div display="flex" margin="1rem 0 0 0" gap="8px" alignItems="center">
                <Information size={24} />
                <p>Recuerda que los mantenimientos ingresados/terminados pueden demorar hasta 15 minutos en aparecer en mantenimientos en curso.</p>
            </Div>
            {editError ? 'Error al tratar de enviar, favor pongase en contacto' : ''}
            {editSuccess ? 'Mantenimiento completado' : ''}
        </Modal>
    )
}

const OngoingTable = _ => {
    const button = useRef();
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const { onGoing } = useSelector(state => state.maintenance)

    const handleOutMaintenance = _row => () => {
        const params = _row.cells.reduce((acc, _row) => {
            const key = _row.info.header;
            const value = _row.value;

            acc[key] = value

            return acc;
        }, {});

        dispatch(onSetMaintenanceModalThunk(params))
        setOpen(true)
    }

    const headerData = [
        {
            header: 'Patente',
            key: 'machinePatent',
        },
        {
            header: 'Tipo de equipo',
            key: 'truckType',
        },
        {
            header: 'Taller',
            key: 'workshopId',
        },
        {
            header: 'Tipo de Mantenimiento',
            key: 'maintenanceType',
        },
        {
            header: 'Motivo(s)',
            key: 'reasonsText',
        },
        {
            header: 'Fecha de ingreso',
            key: 'eventTime',
            sortDirection: "DESC",
            isDefaultSortable: true
        },
        {
            header: 'Tiempo Transcurrido',
            key: 'timePassed',
            sortDirection: "DESC",

        },
    ];
    const { rows, headers } = useSorting(onGoing, headerData);

    const Default = () => <DataTable rows={rows} headers={headers} width="100%">
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getExpandedRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange,
            sortBy
        }) => <TableContainer {...getTableContainerProps()}>
                <TableToolbar>
                    <TableToolbarContent>
                        {/* pass in `onInputChange` change here to make filtering work */}
                        <TableToolbarSearch onChange={onInputChange} />
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            <TableExpandHeader aria-label="expand row" />
                            {headers.map((header, i) => <TableHeader
                                key={i}
                                sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                {...getHeaderProps({
                                    header,
                                    isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                    onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                                })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <React.Fragment key={row.id}>
                            <TableExpandRow {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                            </TableExpandRow>
                            <TableExpandedRow colSpan={headers.length + 1} className="demo-expanded-td" {...getExpandedRowProps({
                                row
                            })}>
                                <Button style={{ minWidth: '100%' }} ref={button} kind="danger--ghost" renderIcon={Pen} onClick={handleOutMaintenance(row)}>
                                    Formulario Salida de Mantenimiento
                                </Button>
                                <ExitForm button={button} open={open} setOpen={setOpen} />
                            </TableExpandedRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable>;

    return (
        <Default />
    )
}

export default OngoingTable;