import { API } from 'aws-amplify';
import {
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
  REACT_APP_BASE_KEY,
} from '../config/settings/environments';

/**
 * To create a Maintenance.
 */
export const createMaintenance = async params => {
  const paths = {
    talabre: '/v1/maintenance',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/maintenance';

  const body = params;

  const response = await API.post(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
    body,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To create a Maintenance.
 */
export const createMaintenanceV2 = async maintenanceLogs => {
  const paths = {
    talabre: '/v1/maintenance-v2',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/maintenance-v2';

  const body = maintenanceLogs; // No "params" wrapper here

  const response = await API.post(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
    body,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get a Maintenance.
 */
export const getMaintenance = async () => {
  const paths = {
    talabre: '/v1/get-maintenance',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-maintenance';

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To get a Maintenance V2.
 */
export const getMaintenanceV2 = async (startDate, endDate) => {
  const paths = {
    talabre: '/v1/get-maintenance-v2',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/get-maintenance-v2';

  // Append query parameters to the URL if they are provided
  const queryParams = [];
  if (startDate)
    queryParams.push(`start_date=${encodeURIComponent(startDate)}`);
  if (endDate) queryParams.push(`end_date=${encodeURIComponent(endDate)}`);

  // If there are query parameters, append them to the path
  if (queryParams.length > 0) {
    path += `?${queryParams.join('&')}`;
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};

/**
 * To edit a Maintenance.
 */
export const editMaintenance = async params => {
  const paths = {
    talabre: '/v1/edit-maintenance',
  };
  let path = paths[REACT_APP_CLIENT_NAME] || '/v1/edit-maintenance';

  const body = params;

  const response = await API.post(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true,
    body,
  });

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status,
  };
};
