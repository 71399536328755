import { combineReducers } from 'redux';
import authenticationReducer from '../../containers/Authentication/Authentication.reducer';
import userReducer from '../../containers/User/Recomendations/Recomendations.reducer';
import dailyReportReducer from '../../containers/Productivity/DailyReports/DailyReport.reducer';
import dailyAsarcoReducer from '../../containers/Productivity/Asarco/Asarco.reducer';
import maintenanceReducer from '../../containers/Maintenance/Ongoing/Ongoing.reducer';
import databaseReducer from '../../containers/Database/Initial/Database.reducer';
import weeklyReportReducer from '../../containers/Productivity/WeeklyReports/WeeklyReport.reducer';
import monthlyReportReducer from '../../containers/Productivity/MonthlyReports/MonthlyReport.reducer';
import historicReportReducer from '../../containers/Productivity/HistoricReports/Historic.reducer';
import machinesReducer from '../../containers/Records/Machines/Machine.reducer';
import realTimeReducer from '../../containers/Monitoring/Performance/Performance.reducer';
import realTimeChargingReducer from '../../containers/Monitoring/ChargingTime/ChargingTime.reducer';
import realTimeDischargeReducer from '../../containers/Monitoring/DischargeTime/DischargeTime.reducer';
import realTimeFromB57Reducer from '../../containers/Monitoring/TransferB57ToDischargingPoint/FromB57.reducer';
import realTimeToB57Reducer from '../../containers/Monitoring/TransferToB57/TransferToB57.reducer';
import idleReportReducer from '../../containers/Productivity/Idle/Idle.reducer';
import flatReportReducer from '../../containers/Productivity/FlatTire/FlatTire.reducer';
import geofenceReducer from '../../containers/Records/Geofences/Geofence.reducer';
import driverReducer from '../../containers/Records/Drivers/Driver.reducer';
import realTimeDriverReducer from '../../containers/Monitoring/EffectiveTimeDriver/Effective.reducer';
import speedHistoricReducer from '../../containers/Security/SpeedHistoric/SpeedHistoric.reducer';
import navbarReducer from '../../containers/Navbar/Navbar.reducer';
import kpiTreeReducer from '../../containers/Productivity/KpiTree/KpiTree.reducer';
import gatesReducer from '../../containers/Records/Gates/Gates.reducer';
import sensorsDetectorReducer from '../../containers/Security/SensorsDetector/SensorsDetector.reducer';
import dailyReportTurnReducer from '../../containers/Productivity/DailyReportsV2/DailyReport.reducer';
import notificationReducer from '../../containers/Notifications/Notifications.reducer';

export default combineReducers({
  auth: authenticationReducer,
  user: userReducer,
  daily: dailyReportReducer,
  asarco: dailyAsarcoReducer,
  maintenance: maintenanceReducer,
  database: databaseReducer,
  weekly: weeklyReportReducer,
  monthly: monthlyReportReducer,
  historic: historicReportReducer,
  machines: machinesReducer,
  performance: realTimeReducer,
  charging: realTimeChargingReducer,
  discharge: realTimeDischargeReducer,
  FromB57: realTimeFromB57Reducer,
  ToB57: realTimeToB57Reducer,
  idle: idleReportReducer,
  flat: flatReportReducer,
  geofence: geofenceReducer,
  driver: driverReducer,
  realTimeDriver: realTimeDriverReducer,
  historicSpeed: speedHistoricReducer,
  navbar: navbarReducer,
  kpiTree: kpiTreeReducer,
  gates: gatesReducer,
  sensorDetector: sensorsDetectorReducer,
  dailyTurn: dailyReportTurnReducer,
  notification: notificationReducer,
});