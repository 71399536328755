import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "../../../components/Basics/Iframe.styles";
import InformationContainer from "../../../components/Basics/Information.container";

const Composting = () => {
    return (
        <Stack gap={3}>
            <CardComponent width="100%">
                <Card justifyContent='flex-start' >
                    <HeatMap size={18} />
                    <Typography className='cds--type-heading-compact-01'>
                        COMPOSTEO DE COMBUSTIBLE - MAPA DE CALOR
                    </Typography>
                </Card>
                <InformationContainer description="La cantidad de combustible cargado depende directamente de la marca del vehículo." />
                <CardRow width='inherit'>
                    <Card width='100%' padding="0">
                        <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/d856096c-4573-4674-ab11-9a7d7e56f20c/composteo-combustible?orgId=1&from=1721727272857&to=1721748872857&kiosk" width="100%" height="100%" />
                    </Card>
                </CardRow>
            </CardComponent>
        </Stack>
    )
};

export default Composting;