import styled from 'styled-components';

export const Column = styled.div`
  margin-right: 30px;

  > * {
    margin-bottom: 5px;
  }
`

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
