import styled from "styled-components";

export const TopFilter = styled.div`
  display: flex;
  flex-direction: row;
  width: 50vw;
  > * {
    width: 50%;
    margin: 0 10px 10px 0;
  }
`;

export const Iframe = styled.iframe`
  height: 140vh;
`
