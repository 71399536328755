import { makeActionCreator } from '../../../config/store/utils';
import { getRealTime } from '../../../services/monitoringService';
import { red50, green40, yellow30, gray60 } from '@carbon/colors';
import { capitalizeNames } from '../../../modules/utils/helpers';
import { truckList, getValueFromList } from '../../../modules/utils/formatters';

// Get yesterday's date
const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString();
};

const getColor = (item, beforeItem) => {
  if (beforeItem) {
    if (item.value > beforeItem.value) {
      return green40; // Higher value
    } else if (item.value < beforeItem.value) {
      return red50; // Lower value
    } else {
      return yellow30; // Same value
    }
  } else {
    return gray60; // Default color if no corresponding item is found
  }
};

const getCurrentShift = () => {
  const now = new Date();
  const hours = now.getHours();

  // Turn A is from 08:00 to 19:59
  if (hours >= 8 && hours < 20) {
    return 'Turno Día';
  }

  // Turn B is from 20:00 to 07:59
  return 'Turno Noche';
};

export const GET_REAL_TIME = 'GET_REAL_TIME';
export const GET_REAL_TIME_ERROR = 'GET_REAL_TIME_ERROR';
export const GET_REAL_TIME_SUCCESS = 'GET_REAL_TIME_SUCCESS';
export const onGetRealTime = makeActionCreator(GET_REAL_TIME);
export const onGetRealTimeError = makeActionCreator(
  GET_REAL_TIME_ERROR,
  'payload'
);
export const onGetRealTimeSuccess = makeActionCreator(
  GET_REAL_TIME_SUCCESS,
  'payload'
);
export const onGetRealTimeThunk = () => async dispatch => {
  dispatch(onGetRealTime());
  try {
    const { data } = await getRealTime();
    const currentTurn = data.current_turn.tablas;

    const m3Production = currentTurn.producción_m3;
    const dailyProduction = currentTurn.produccion_total_m3.value;
    const _loadTimes = currentTurn.tiempo_cargas;
    const currShift = getCurrentShift();
    const loadTimes = _loadTimes.flatMap(item => {
      if (item.group !== currShift) {
        return [];
      }
      if (item.value.length === 0) {
        return [{ group: item.key, value: 0 }];
      } else {
        return item.value.map(val => ({
          group: item.key,
          value: val,
        }));
      }
    });
    const queueTimes = currentTurn.tiempos_cola;
    const totalTrucks = currentTurn.cantidad_camiones.value;

    const heatmapHour = currentTurn.heatmap_m3_hora.map(item => {
      const color = Math.round(item.value);
      return {
        x: item.key,
        y: item.group,
        color: color,
        // Overwrite color values for zero cycle time.
        colorScale: color ? yellow30 : '#292929',
      };
    });

    const cycleTime = currentTurn.heatmap_tiempos_ciclo.map(item => {
      const color = Math.round(item.value);
      return {
        x: item.key,
        y: item.group,
        color: color,
        // Overwrite color values for zero cycle time.
        colorScale: color ? yellow30 : '#292929',
      };
    });

    const avgSpeed = currentTurn.heatmap_velocidad_promedio.map(item => {
      const color = Math.round(item.value);
      return {
        x: item.key,
        y: item.group,
        color: color,
        // Overwrite color values for zero cycle time.
        colorScale: color ? yellow30 : '#292929',
      };
    });

    const cycleCount = currentTurn.heatmap_conteo_ciclos.map(item => {
      const color = Math.round(item.value);
      return {
        x: item.key,
        y: item.group,
        color: color,
        // Overwrite color values for zero cycle time.
        colorScale: color ? yellow30 : '#292929',
      };
    });

    console.log(cycleCount);

    return dispatch(
      onGetRealTimeSuccess({
        m3Production: m3Production,
        dailyProduction: dailyProduction,
        loadTimes: loadTimes,
        queueTimes: queueTimes,
        cycleCount: cycleCount,
        avgSpeed: avgSpeed,
        cycleTime: cycleTime,
        heatmapHour: heatmapHour,
        totalTrucks: totalTrucks,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetRealTimeError({
        error: error,
      })
    );
  }
};
