import { Modal, Stack } from "@carbon/react";
import { CardRow } from "../../../components/Layouts/Card.styles";
import { forwardRef } from "react";
import { FirstRow, SecondRow, ThirdRow, FourthRow } from "./DriverDetails.row";
import { g90 } from "@carbon/themes";

const DriverDetails = forwardRef(({ open, setOpen }, ref) => {
    // Averages Table Data
    const rows = [{
        id: 'load-balancer-1',
        title: 'Hora de Inicio Efectivo',
        average: '5.8',
        best: '5.9',
    },
    {
        id: 'load-balancer-2',
        title: 'Hora de Término Efectivo',
        average: '6.5',
        best: '5.0',
    }, {
        id: 'load-balancer-3',
        title: 'Inicio Primer Ciclo',
        average: '6.0',
        best: '5.0',
    }, {
        id: 'load-balancer-4',
        title: 'Duración de Colación',
        average: '4.5',
        best: '6.5',
    }, {
        id: 'load-balancer-5',
        title: 'Horas Efectivas',
        average: '3.5',
        best: '5.0',
    }];
    const headers = [
        {
            header: '',
            key: 'title',
        },
        {
            header: 'Promedio Conductor',
            key: 'average',
        },
        {
            header: 'Mejor Conductor',
            key: 'best',
        },
    ];

    // Registro de turnos data table
    const headersDetails = [
        {
            header: 'Fecha',
            key: 'date',
        },
        {
            header: 'Turno',
            key: 'appointment',
        },
        {
            header: 'Horas Totales',
            key: 'total_hours',
        },
        {
            header: 'Horas Efectivas',
            key: 'effective_hours',
        },
        {
            header: 'M3 Trabajados',
            key: 'm3_worked',
        },
    ]

    const rowsDetails = Array(20).fill().map((_, index) => ({
        id: `${index}`,
        date: '00/00/0000',
        appointment: 'Noche/Dia',
        total_hours: '00 horas',
        effective_hours: '00 horas',
        m3_worked: '000 M3',
    }));

    // Styles applied to every card inside the modal
    const styles = {
        background: g90.backgroundActive,
    };

    return (
        <Modal size="lg" modalHeading="id_operador" modalLabel="Ficha de Operador" launcherButtonRef={ref} isFullWidth passiveModal open={open} onRequestClose={() => setOpen(false)}>
            <Stack gap={3}>
                <CardRow>
                    <FirstRow styles={styles} />
                </CardRow>
                <CardRow>
                    <SecondRow styles={styles} headers={headers} rows={rows} />
                </CardRow>
                <CardRow>
                    <ThirdRow styles={styles} />
                </CardRow>
                <CardRow>
                    <FourthRow styles={styles} rows={rowsDetails} headers={headersDetails} />
                </CardRow>
            </Stack>
        </Modal>
    )
})

export default DriverDetails;