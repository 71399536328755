import {
  SEND_QUERY,
  SEND_QUERY_ERROR,
  SEND_QUERY_SUCCESS,
  GET_QUERY,
  GET_QUERY_ERROR,
  GET_QUERY_SUCCESS,
} from './Database.actions';

const databaseState = {
  data: [],
  loading: true,
  error: undefined,
  sending: false,
  submitError: undefined,
  submitSuccess: false,
  downloadUrl: '',
};

/**
 * The Database reducer
 */

const databaseReducer = (state = databaseState, { payload, type }) => {
  switch (type) {
    case GET_QUERY: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_QUERY_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_QUERY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: payload.data,
      };
    }
    case SEND_QUERY: {
      return {
        ...state,
        sending: true,
        submitSuccess: false,
        submitError: undefined,
      };
    }
    case SEND_QUERY_ERROR: {
      return {
        ...state,
        sending: false,
        submitSuccess: false,
        submitError: payload.error,
      };
    }
    case SEND_QUERY_SUCCESS: {
      return {
        ...state,
        sending: false,
        submitSuccess: true,
        error: undefined,
        downloadUrl: payload.downloadUrl,
      };
    }
    default: {
      return state;
    }
  }
};

export default databaseReducer;
