import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker, DatePickerInput, Button, Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { DataTable } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import CompletedTable from "./CompletedTable.container";
import { onGetMaintenanceThunk } from "../Ongoing/Ongoing.actions";
import LoadingContent from "../../../components/Animations/LoadingContent";
import { Div } from "../../../components/Basics/Div.styles";
import { useInput, useDatePicker, useSelectComboBox, useMultiSelect, useRadio } from '../../../components/Inputs/Inputs.hooks'
import {
    checkErrorRequired
} from '../../../modules/errors/validations'
import InformationContainer from "../../../components/Basics/Information.container";

const Completed = () => {
    const dispatch = useDispatch()
    const { loading, error } = useSelector(state => state.maintenance)


    const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
        initialValue: '',
        errorCallbacks: [checkErrorRequired()]
    })

    const handleDateChangeDispatch = () => {
        if (dateValue.length === 2) {
            dispatch(onGetMaintenanceThunk(dateValue[0], dateValue[1]))
        }
    }

    return (
        <>
            <Stack gap={3}>
                <Div display="flex" gap="16px" alignItems="center" justifyContent="flex-start" style={{ maxWidth: '400px' }}>
                    <DatePicker
                        datePickerType="range"
                        value={dateValue}
                        invalidText={dateError}
                        onChange={handleDateChange}
                        dateFormat='d-m-Y'
                    >
                        <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText="Fecha de Inicio" size="md" />
                        <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText="Fecha de Término" size="md" />
                    </DatePicker>
                    <Button size='sm' kind="primary" onClick={handleDateChangeDispatch}>
                        Buscar
                    </Button>
                </Div>
                <InformationContainer description="Seleccione un rango de fechas y presione 'Buscar' para cargar datos en la tabla" />
                {loading && !error && (
                    <>
                        <LoadingContent />
                    </>
                )}
                {!loading && error && (
                    <>
                        <CardComponent width="10%">
                            <Typography className='cds--type-heading-compact-01'>
                                No hay registros de mantenimientos
                            </Typography>
                        </CardComponent>
                    </>
                )}
                {!loading && !error && (
                    <>
                        <Stack gap={3}>
                            {/* <DatePicker datePickerType="range" dateFormat='d-m-Y'>
                            <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText="Fecha de Inicio" size="md" />
                            <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText="Fecha de Término" size="md" />
                        </DatePicker> */}
                            <CardComponent width="100%">
                                <Card justifyContent='flex-start' >
                                    <DataTable size={18} />
                                    <Typography className='cds--type-heading-compact-01'>
                                        MANTENIMIENTOS COMPLETADOS - TABLA DE REGISTRO
                                    </Typography>
                                </Card>
                                <CardRow width='inherit'>
                                    <Card width='100%' padding="0">
                                        <CompletedTable />
                                    </Card>
                                </CardRow>
                            </CardComponent>
                        </Stack>
                    </>
                )}
            </Stack>

        </>
    )
};

export default Completed;