import React from 'react'
import { Grid, Column, ContainedList, ContainedListItem, Stack } from '@carbon/react'
import { ArrowRight, Book, Link } from '@carbon/icons-react'
import Typography from '../../components/Basics/Typography'
import { getDataLocalStorage } from '../../config/store/utils'
import LinkComponent from '../../components/Basics/Link.container'
import Card from './../../components/Layouts/Card'
import { CardComponent, CardRow } from '../../components/Layouts/Card.styles'
import { spacing05 } from "@carbon/themes";
import routes from '../../config/settings/routes'

const CustomLinkComponent = ({ title, route }) => {
  return (
    <LinkComponent route={route ?? "#"}>
      <ContainedListItem>
        <Typography
          className='cds--type-helper-text-01 link contained-list-text'
          justify='flex-start'
          display='flex'
          cursor='pointer'
        >
          {title}
          <ArrowRight size={16} />
        </Typography>
      </ContainedListItem>
    </LinkComponent>
  )
}

/**
 * The Dashboard's component
 */
const Dashboard = ({ children }) => {
  const storedPaths = getDataLocalStorage('visitedPaths').reverse()

  return (
    <Grid fullWidth narrow>
      <Column sm={4}>
        <Stack gap={3}>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <Book size={18} />
              <Typography className='cds--type-heading-compact-01'>
                DOCUMENTACIÓN Y RECURSOS
              </Typography>
            </Card>
            <CardRow width='inherit'>
              <Card flexDirection='column' justifyContent='center' alignItems='flex-start' width='100%' padding={spacing05} gap="0.5rem">
                <ContainedList className="contained-list" label="General" size="sm">
                  <CustomLinkComponent title="Cómo crear una geocerca" route={routes.documentationCreateGeofence} />
                </ContainedList>
                <ContainedList className="contained-list" label="Seguridad" size="sm">
                  <CustomLinkComponent title="Cómo recolectar datos de un equipo" route={routes.documentationCollectTeamData} />
                  <CustomLinkComponent title="Cómo actualizar una licencia" route={routes.documentationUpdateLicense} />
                </ContainedList>
                <ContainedList className="contained-list" label="Mantenimiento" size="sm">
                  <CustomLinkComponent title="Cómo ingresar un camión a mantenimiento" route={routes.documentationEnterTruck} />
                  <CustomLinkComponent title="Cómo completar un mantenimiento" route={routes.documentationCompleteForm} />
                </ContainedList>
              </Card>
            </CardRow>
          </CardComponent>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <Link size={18} />
              <Typography className='cds--type-heading-compact-01'>
                VISITADOS RECIENTEMENTE
              </Typography>
            </Card>
            <CardRow width='inherit'>
              <Card flexDirection='column' justifyContent='center' alignItems='flex-start' width='100%' padding={spacing05} gap="0.5rem">
                {storedPaths.map((link, index) =>
                  <CustomLinkComponent route={link.link} key={`link-${index}`} title={link.name} />
                )}
              </Card>
            </CardRow>
          </CardComponent>
        </Stack>
      </Column>
      <Column lg={12}>
        {children}
      </Column>
    </Grid>
  )
}

export default Dashboard
