import styled from 'styled-components';
import { TextArea } from '@carbon/react';

// Create a styled TextArea
export const StyledTextArea = styled(TextArea)`
  width: 100%; /* Full width of the container */
  & .cds--text-area {
    background-color: #262626 !important;
  }
  /* Additional styles can be added here */
`;
