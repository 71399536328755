import { Stack } from "@carbon/react";
import { CardComponent } from "../../../components/Layouts/Card.styles";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { HeatMap } from "@carbon/icons-react";
import { Iframe } from "./Idle.styles";
import FlatTireTable from "./FlatTireTable.container";
import InformationContainer from "../../../components/Basics/Information.container";
const FlatTire = () => {

  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            PINCHAZOS - MAPA DE CALOR
          </Typography>
        </Card>
        <InformationContainer description="El umbral para detección de un pinchazo es de 20 psi." />
        <InformationContainer description="Los parámetros de los filtros 'fecha_inicio' y 'fecha_fin' utilizan el formato mm/dd/aaaa." />
        <Card>
          <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/e37f3438-61e3-4b29-8091-bd28cfc3afb2/pinchazos?orgId=1&from=1706525177038&to=1706546777038&kiosk" width="100%" height="100%" />
        </Card>
      </CardComponent>
      {/* <CardComponent width="100%">
        <FlatTireTable />
      </CardComponent> */}
    </Stack>
  )
};

export default FlatTire;