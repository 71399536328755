export const MONITORING_TYPE = {
  'MONITORING-INITIAL': 'MONITORING-INITIAL',
  MAP: 'MAP',
  PERFORMANCE: 'PERFORMANCE',
  'LAST-SHIFT': 'LAST-SHIFT',
  CHARGING: 'CHARGING',
  'TRANSFER-B57-DISCHARGING': 'TRANSFER-B57-DISCHARGING',
  DISCHARGING: 'DISCHARGING',
  'TRANSFER-B57': 'TRANSFER-B57',
  IDLE: 'IDLE',
  'EFFECTIVE-TIME-DRIVER': 'EFFECTIVE-TIME-DRIVER',
  PUNCTURES: 'PUNCTURES',
  COMPOSTING: 'COMPOSTING',
  ROLLER: 'ROLLER',
  CAN: 'CAN',
  GEOFENCEG1: 'GEOFENCEG1',
  LOWVELOCITY: 'LOWVELOCITY',
};

export const PRODUCTIVITY_TYPE = {
  'PRODUCTIVITY-INITIAL': 'PRODUCTIVITY-INITIAL',
  HISTORIC: 'HISTORIC',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  IDLE: 'IDLE',
  PUNCTURES: 'PUNCTURES',
  ASARCO: 'ASARCO',
  SPEED: 'SPEED',
  KPITREE: 'KPITREE',
  BREAKFAST: 'BREAKFAST',
  SPEEDGEOFENCE: 'SPEEDGEOFENCE',
  DAILYTURN: 'DAILYTURN',
};

export const SECURITY_TYPE = {
  'SECURITY-INITIAL': 'SECURITY-INITIAL',
  'TRUCK-APPROACH': 'TRUCK-APPROACH',
  'VELOCITY-VIOLATION': 'VELOCITY-VIOLATION',
  'BRAKE-RETARDER': 'BRAKE-RETARDER',
  'VELOCITY-HISTORIC': 'VELOCITY-HISTORIC',
  CAN: 'CAN',
  FATIGUE: 'FATIGUE',
  'SENSORS-COUNT': 'SENSORS-COUNT',
  TRAIN: 'TRAIN',
};

export const MAINTENANCE_TYPE = {
  'MAINTENANCE-INITIAL': 'MAINTENANCE-INITIAL',
  PREVENTIVE: 'PREVENTIVE',
  PREDICTIVE: 'PREDICTIVE',
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED',
  PERMANENCE: 'PERMANENCE',
};

export const RECORDS_TYPE = {
  'RECORDS-INITIAL': 'RECORDS-INITIAL',
  DRIVER: 'DRIVER',
  MACHINES: 'MACHINES',
  // GEOFENCES: 'GEOFENCES',
  ROUTES: 'ROUTES',
  GATES: 'GATES',
};

export const DATABASE_TYPE = {
  'DATABASE-INITIAL': 'DATABASE-INITIAL',
  GPS: 'GPS',
  CANBUS: 'CANBUS',
  PRODUCTIVITY: 'PRODUCTIVITY',
  HOURMETER: 'HOURMETER',
};

export const USERS_TYPE = {
  'USERS-INITIAL': 'USERS-INITIAL',
  RECOMENDATIONS: 'RECOMENDATIONS',
};

// Path names with prettier names for the 'Last Visited' component
export const PATH_NAMES = {
  '/': 'Inicio',
  '/dashboard': 'Inicio',
  '/login': 'Login',
  '/documentation': 'Documentación',
  '/documentation/general/create-geofence': 'Como crear una geocerca',
  '/documentation/security/collect-team-data':
    'Como recolectar datos de un equipo',
  '/documentation/security/update-license': 'Como actualizar una licencia',
  '/documentation/maintenance/enter-truck':
    'Como ingresar un camion a mantenimiento',
  '/documentation/maintenance/complete-form': 'Como completar un mantenimiento',
  '/maintenance/maintenance-initial': 'Mantenimientos',
  '/maintenance/preventive': 'Gestión de Mantenimiento Preventivo',
  '/maintenance/predictive': 'Gestión de Mantenimiento Predictivo',
  '/maintenance/ongoing': 'Mantenimientos en Curso',
  '/maintenance/completed': 'Mantenimientos Completados',
  '/maintenance/permanence': 'Registro de permanencia en talleres',
  '/monitoring/monitoring-initial': 'Mapa y Monitoreo',
  '/monitoring/map': 'Mapa de Flota',
  '/monitoring/performance': 'Desempeño Operación y Desperdicios',
  '/monitoring/last-shift': 'Desempeño Turno Anterior',
  '/monitoring/charging': 'Registro Tiempos de Carga',
  '/monitoring/transfer-b57-unloading': 'Traslado a Punto de Descarga',
  '/monitoring/discharging': 'Tiempo en Muro de Descarga',
  '/monitoring/transfer-b57': 'Tiempo Traslado a B57',
  '/monitoring/idle': 'Tiempo en Ralenti',
  '/monitoring/effective-time-driver': 'Conductores Operativos',
  '/monitoring/punctures': 'Pinchazos',
  '/monitoring/composting': 'Composteo Combustible',
  '/monitoring/roller': 'Pasadas de Rodillo',
  '/monitoring/can': 'Dashboard CAN Última Hora',
  '/monitoring/geofenceg1': 'Paso Geocerca G1',
  '/monitoring/lowvelocity': 'Velocidades Bajas',
  '/productivity/productivity-initial': 'Producción y Reportabilidad',
  '/productivity/daily': 'Reportes Diarios',
  '/productivity/weekly': 'Reportes Semanales',
  '/productivity/historic': 'Producción Histórica',
  '/productivity/monthly': 'Reportes Mensuales',
  '/productivity/idle': 'Tiempo en Ralenti',
  '/productivity/punctures': 'Registro de Pinchazos',
  '/productivity/asarco': 'Asarco',
  '/productivity/speed': 'Velocidades',
  '/productivity/kpitree': 'Árbol de Kpi',
  '/productivity/breakfast': 'Duración Colación',
  '/productivity/speedgeofence': 'Velocidades por Geocerca',
  'productivity/dailyturn': 'Reporte Diario por Turno',
  '/security/security-initial': 'Seguridad',
  '/security/truck-approach': 'Acercamiento entre camiones',
  '/security/velocity-violation': 'Transgresiones de Velocidad',
  '/security/brake-retarder': 'Frenos y Retardador',
  '/security/velocity-historic': 'Histórico de Velocidad',
  '/records/records-initial': 'Fichas',
  '/security/can': 'Dashboard CAN Histórico',
  '/security/fatigue': 'Fatiga y Somnolencia',
  '/security/sensors-count': 'Sensores Presión',
  '/security/train': 'Registro Cruce Tren',
  '/records/driver': 'Conductores',
  '/records/machines': 'Equipos',
  // '/records/geofences': 'Geocercas',
  '/records/routes': 'Rutas',
  '/records/gates': 'Pórtico',
  '/database/database-initial': 'Bases de Datos',
  '/database/gps': 'gps',
  '/database/canbus': 'CanBus',
  '/database/productivity': 'Cuadro Mando de Productividad',
  '/database/hourmeter': 'Horómetro',
  '/map': 'Mapa',
  '/fronts': 'Geocercas Frentes',
  '/users/users-initial': 'Usuarios',
  '/users/recomendations': 'Recomendaciones',
};

// Viewer: has access to everything
// Security: has access to security module and map in monitoging
// Maintenance: has access to maintenance module and map in monitoring
export const permissionsMap = {
  viewer: [
    'MONITORING-INITIAL',
    'PRODUCTIVITY-INITIAL',
    'SECURITY-INITIAL',
    'MAINTENANCE-INITIAL',
    'RECORDS-INITIAL',
    'DATABASE-INITIAL',
    'USERS-INITIAL',
    'RECOMENDATIONS',
    'MAP',
    'PERFORMANCE',
    'LAST-SHIFT',
    'CHARGING',
    'TRANSFER-B57-DISCHARGING',
    'DISCHARGING',
    'TRANSFER-B57',
    'IDLE',
    'EFFECTIVE-TIME-DRIVER',
    'PUNCTURES',
    'COMPOSTING',
    'ROLLER',
    'CAN',
    'FATIGUE',
    'HISTORIC',
    'DAILY',
    'WEEKLY',
    'MONTHLY',
    'ASARCO',
    'SPEED',
    'TRUCK-APPROACH',
    'VELOCITY-VIOLATION',
    'VELOCITY-HISTORIC',
    'PREVENTIVE',
    'PREDICTIVE',
    'ONGOING',
    'COMPLETED',
    'DRIVER',
    'MACHINES',
    'GEOFENCES',
    'ROUTES',
    'GPS',
    'CANBUS',
    'PRODUCTIVITY',
    'HOURMETER',
    'GEOFENCE-MAP',
    'KPITREE',
    'GATES',
    'SENSORS-COUNT',
    'BRAKE-RETARDER',
    'TRAIN',
    'PERMANENCE',
    'GEOFENCEG1',
    'BREAKFAST',
    'SPEEDGEOFENCE',
    'DAILYTURN',
    'LOWVELOCITY',
  ],
  security: [
    'SECURITY-INITIAL',
    'MONITORING-INITIAL',
    'MAP',
    'TRUCK-APPROACH',
    'VELOCITY-VIOLATION',
    'VELOCITY-HISTORIC',
    'BRAKE-RETARDER',
    'FATIGUE',
  ],
  maintenance: [
    'MAINTENANCE-INITIAL',
    'MONITORING-INITIAL',
    'MAP',
    'ONGOING',
    'COMPLETED',
  ],
};
