import React from 'react'
import TitleText from '../../../components/Layouts/Title'
import Typography from '../../../components/Basics/Typography'
import CardButton from '../../../components/Basics/CardButton.container'
import { Column, Root } from './Productivity.styles'
import routes from '../../../config/settings/routes'
import { g100 } from '@carbon/themes';
import { Stack } from '@carbon/react';
import { Separator } from '../../Dashboard/Dashboard.styles'

/**
 * The Productivity's component
 */
const Productivity = () => {
  return (
    <>
      <TitleText
        title='Producción y Reportabilidad'
        subtitle='Módulo Histórico para el registro del rendimiento productivo y generación de reportes.'
      />
      <Root>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Producción</Typography>
            <Separator />
            <CardButton title='Producción Histórica' route={routes.productivityHistoric} />
            <CardButton title='Reportes por Turnos' route={routes.producivityDailyTurn} />
            <CardButton title='Reportes Diarios' route={routes.productivityDaily} />
            <CardButton title='Reportes Semanales' route={routes.productivityWeekly} />
            <CardButton title='Reportes Mensuales' route={routes.productivityMonthly} />
          </Stack>
        </Column>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>ASARCO</Typography>
            <Separator />
            <CardButton title='ASARCO' route={routes.productivityAsarco} />
            <CardButton title='Árbol de Kpi' route={routes.producivityKpiTree} />
          </Stack>
        </Column>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Registros</Typography>
            <Separator />
            <CardButton title='Tiempo en Ralentí' route={routes.productivityIdle} />
            <CardButton title='Pinchazos' route={routes.productivityFlatTire} />
            <CardButton title='Velocidad Geocercas' route={routes.productivitySpeedGeofence} />
          </Stack>
        </Column>
      </Root>
    </>
  )
}

export default Productivity
