import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import routes from '../../config/settings/routes'
import { onLogOutThunk } from './Authentication.actions'
import { Button, Modal } from '@carbon/react'
import Typography from '../../components/Basics/Typography'

/**
 * The Logout Modal's container.
 */
const LogoutModal = ({ button, open, setOpen }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const { loading } = useSelector(state => state.auth)

  const handleLogout = () => {
    dispatch(
      onLogOutThunk({
        onCallback: () => history.push(routes.login)
      })
    )
  }
  return (
    <>
      <Modal onRequestSubmit={handleLogout} onSecondarySubmit={() => setOpen(false)} danger modalHeading='¿Desea continuar y cerrar su sesión?' launcherButtonRef={button} primaryButtonText="Salir" secondaryButtonText={"Volver"} open={open} onRequestClose={() => setOpen(false)}>

      </Modal>
    </>
  )
}

export default LogoutModal
