import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "../../../components/Basics/Iframe.styles";


const LowVelocity = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            VELOCIDADES BAJAS
          </Typography>
        </Card>
        <CardRow width='inherit'>
          <Card width='100%' padding="0" flexDirection='column'>
            <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/d8f2db30-a8e7-4ada-a487-20080e56df1e/excesos-de-velocidad-baja?orgId=1&from=1731948401053&to=1731970001053&kiosk" width="100%" height="100%" />
          </Card>
        </CardRow>
      </CardComponent>
    </Stack>
  )
}

export default LowVelocity