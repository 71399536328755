import { makeActionCreator } from '../../../config/store/utils';
import { getDailyAsarco } from '../../../services/productivityService';

export const GET_ASARCO = 'GET_ASARCO';
export const GET_ASARCO_ERROR = 'GET_ASARCO_ERROR';
export const GET_ASARCO_SUCCESS = 'GET_ASARCO_SUCCESS';
export const onGetAsarco = makeActionCreator(GET_ASARCO);
export const onGetAsarcoError = makeActionCreator(GET_ASARCO_ERROR, 'payload');
export const onGetAsarcoSuccess = makeActionCreator(
  GET_ASARCO_SUCCESS,
  'payload'
);

// Modificar el `onGetAsarcoThunk` para recibir el parámetro `shift`.
export const onGetAsarcoThunk = (date, truck, shift = 'dia') => async dispatch => {
  dispatch(onGetAsarco());
  try {
    let endpointParams = {};
    if (date) {
      endpointParams = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    } else {
      const today = new Date();
      const yesterday = new Date(today);
      date = new Date(yesterday.setDate(yesterday.getDate() - 1));
    }
    
    if (truck) {
      endpointParams['truck'] = truck.id;
    } else {
      truck = {
        id: 'Total',
        text: 'Total',
      };
    }

    // Incluir el turno `shift` en los parámetros de la consulta
    endpointParams['shift'] = shift;

    // Llamar a la API y obtener los datos
    const { data } = await getDailyAsarco(endpointParams);

    // Actualizar `truckList` usando el campo `truck_list` que devuelve la API
    const truckList = data.truck_list.map(item => {
      return { id: item, text: item };
    });

    return dispatch(
      onGetAsarcoSuccess({
        asarco: data.data, // `asarco` ahora contiene solo la data del gráfico
        truckList: truckList,
        truck: truck,
        date: date.toISOString(),
        shift,  // Guardar el turno en el estado
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetAsarcoError({
        error: error,
      })
    );
  }
};
