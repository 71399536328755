import React from "react";
import { AreaChart } from "@carbon/charts-react";
import PropTypes from 'prop-types';
import { toolbarOptions } from "./utils";

const Sparkline = props => {
	const { title, data, yAxisTitle } = props
	// Normalize data
	const normalizedData = data.map(item => ({
		hourly: parseInt(item.hourly), // Ensure it's a number
		value: item.value
	}));
	const state = {
		data: normalizedData,
		options: {
			title: title,
			toolbar: {
				"enabled": true
			},
			"height": "25vh",
			"grid": {
				"x": {
					"enabled": false
				},
				"y": {
					"enabled": false
				}
			},
			axes: {
				bottom: {
					visible: false,
					title: "hora",
					mapsTo: "hourly",
					scaleType: "linear",
				},
				left: {
					visible: false,
					title: yAxisTitle,
					mapsTo: "value",
					scaleType: "linear",
				},
			},
			"color": {
				"gradient": {
					"enabled": true
				}
			},
			"points": {
				"enabled": false
			},
			"legend": {
				"enabled": false
			},
			"theme": "g100",
			...toolbarOptions
		},
	};


	return (
		<AreaChart
			data={state.data}
			options={state.options}>
		</AreaChart>
	)
}

Sparkline.defaultProps = {
	data: [
		{
			hourly: 0,
			value: 2,
		},
		{
			hourly: 1,
			value: 3,
		},
		{
			hourly: 2,
			value: 5,
		},
		{
			hourly: 3,
			value: 1,
		},
		{
			hourly: 4,
			value: 4,
		},
		{
			hourly: 5,
			value: 4,
		},
	]
}

Sparkline.propTypes = {
	height: PropTypes.string,
	title: PropTypes.string,
	xAxisTitle: PropTypes.string,
	yAxisTitle: PropTypes.string,
	data: PropTypes.array
}

export default Sparkline
