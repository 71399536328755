import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../../config/settings/routes'
import { useSession } from '../../modules/hooks/security'
import { putIntoCookie } from '../../modules/storages/cookies'
import { onSilentLogInThunk } from '../../containers/Authentication/Authentication.actions'

/**
 * The ProtectBySession' component.
 */
const ProtectBySession = props => {
  const { children, redirectTo, rule } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const { hasSession, loading, userId, username } = useSession()
  const { loading: loadingAuth, signedIn } = useSelector(state => state.auth)
  const result = useMemo(() => rule(hasSession, signedIn), [hasSession, loading, loadingAuth, signedIn])
  useEffect(() => {
    if (loading || loadingAuth || result || result === undefined) {
      return
    }

    if (pathname !== redirectTo) {
      putIntoCookie('redirect_path', pathname)
    }
    history.push(redirectTo)
  }, [loading, loadingAuth, result])

  useEffect(() => {
    if (!loading && !signedIn) {
      dispatch(onSilentLogInThunk({ userId, username }))
    }
  }, [loading, signedIn])

  if (loading || !result) {
    return null
  }

  return <>{children}</>
}

ProtectBySession.defaultProps = {
  redirectTo: routes.login,
  rule: () => undefined
}
ProtectBySession.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
  rule: PropTypes.func
}

export default ProtectBySession
