import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import { ChartLine } from '@carbon/icons-react'
import Card from '../../../components/Layouts/Card'
import Typography from '../../../components/Basics/Typography'
import { DatePicker, DatePickerInput, Dropdown, Stack } from '@carbon/react'
import LineGraph from '../../../components/Graphs/LineChart.container'
import { green40, blue40, red40 } from "@carbon/colors";
import { useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import { onGetHistoricThunk } from './Historic.actions'
import { checkErrorRequired } from '../../../modules/errors/validations';
import LoadingContent from '../../../components/Animations/LoadingContent'
import { spacing05 } from "@carbon/themes";
import NetworkDiagram from '../../../components/Graphs/NetworkDiagram.container'


/**
 * The Performance's container.
 */
const HistoricReportContainer = () => {
  const dispatch = useDispatch()
  const today = new Date();
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const { error, loading, lastre, walls, wallsList, trucks, productivity, cycleTime } = useSelector(state => state.historic)
  const [currWall, setCurrWall] = useState([])


  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: [yesterday, today],
    errorCallbacks: [checkErrorRequired()]
  })

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetHistoricThunk())
  }, [])

  const height = 170;
  const width = 250;

  const handleChange = date => {
    handleDateChange(date)
    if (date.length === 2) {
      dispatch(onGetHistoricThunk(date[0], date[1]))
    }
  }

  const handleChangeDropdown = value => {
    const _currWall = walls[value.selectedItem.id]
    setCurrWall(_currWall)
  }

  return (
    <>
      {loading && !error && (
        <LoadingContent />
      )}
      {!loading && error && (
        <>
          <DatePicker disable={[(date) => date > today]} datePickerType="range" onClose={handleChange} value={dateValue} dateFormat='d-m-Y'>
            <DatePickerInput labelText="Fecha de Inicio" id="daily-datepicker-start-error" size="md" />
            <DatePickerInput labelText="Fecha de Término" id="daily-datepicker-end-error" size="md" />
          </DatePicker>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              No hay datos para este dia
            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && !error && (
        <Stack gap={3}>
          <DatePicker datePickerType="range" value={dateValue} invalidText={dateError} onClose={handleChange} dateFormat='d-m-Y' disable={[(date) => date > today]}>
            <DatePickerInput id={`date-picker-input-id-start`} labelText="Fecha de Inicio" size="md" />
            <DatePickerInput id={`date-picker-input-id-finish`} labelText="Fecha de Término" size="md" />
          </DatePicker>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <ChartLine size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  PRODUCCIÓN HISTORICA - LASTRE MOVILIZADO
                </Typography>
              </Card>
              <CardRow width='inherit'>
                <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
                  <LineGraph title='Lastre movilizado por día (media, miles de m3)' height='47.5rem' xAxisTitle="Día" yAxisTitle="Miles de M3" lineData={lastre} />
                </Card>
              </CardRow>
            </CardComponent>
          </CardRow>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <ChartLine size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  EVOLUCIÓN DE PRODUCCIÓN POR FRENTE DE DESCARGA
                </Typography>
              </Card>
              <Dropdown style={{ alignSelf: "flex-start" }} onChange={handleChangeDropdown} id="default" titleText="Frente de Descarga" label="Seleccionar Frente de Descarga" items={wallsList} itemToString={item => item ? item.text : ''} />
              <CardRow width='inherit'>
                <Card flexDirection='column' justifyContent="center" width="100%" padding={spacing05}>
                  <LineGraph title='Producción Frente...(cantidad total)' height='25rem' xAxisTitle="Día" yAxisTitle="M3" lineData={currWall} />
                </Card>
              </CardRow>
            </CardComponent>
          </CardRow>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <ChartLine size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  RENDIMIENTO HISTORICO - CAMIONES OPERATIVOS
                </Typography>
              </Card>
              <CardRow width='inherit'>
                <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
                  <LineGraph title='Camiones Tolva Operativos por día (cantidad total)' height='25rem' xAxisTitle="Día" yAxisTitle="Camiones" lineData={trucks} />
                </Card>
              </CardRow>
            </CardComponent>
          </CardRow>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <ChartLine size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  RENDIMIENTO HISTORICO - TIEMPO EFECTIVO
                </Typography>
              </Card>
              <CardRow width='inherit'>
                <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
                  <LineGraph title='Tiempo efectivo por día de camiones tolva (media, horas)' height='25rem' xAxisTitle="Día" yAxisTitle="Horas" lineData={productivity} />
                </Card>
              </CardRow>
            </CardComponent>
          </CardRow>
          <CardRow>
            <CardComponent width="100%">
              <Card justifyContent='flex-start' >
                <ChartLine size={18} />
                <Typography className='cds--type-heading-compact-01'>
                  RENDIMIENTO HISTORICO - DURACIÓN CICLOS
                </Typography>
              </Card>
              <CardRow width='inherit'>
                <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
                  <LineGraph title='Duración de Ciclos de Camiones Tolva (media, minutos)' height='25rem' xAxisTitle="Día" yAxisTitle="Minutos" lineData={cycleTime} />
                </Card>
              </CardRow>
            </CardComponent>
          </CardRow>
        </Stack>
      )}
    </>
  )
}

export default HistoricReportContainer
