import {
  GET_REAL_TIME_CHARGING,
  GET_REAL_TIME_CHARGING_ERROR,
  GET_REAL_TIME_CHARGING_SUCCESS,
} from './ChargingTime.actions';

const realTimeCharging = {
  loading: true,
  error: undefined,
  chargeTime: [],
  table: [],
  boxPlot: [],
  chargeMean: [],
};

/**
 * The real time Charging reducer.
 */
const realTimeChargingReducer = (
  state = realTimeCharging,
  { payload, type }
) => {
  switch (type) {
    case GET_REAL_TIME_CHARGING: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_REAL_TIME_CHARGING_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_REAL_TIME_CHARGING_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        chargeTime: payload.chargeTime,
        table: payload.table,
        boxPlot: payload.boxPlot,
        chargeMean: payload.chargeMean,
      };
    }
    default: {
      return state;
    }
  }
};

export default realTimeChargingReducer;
