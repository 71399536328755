import {
    GET_HDOP,
    GET_HDOP_ERROR,
    GET_HDOP_SUCCESS,
} from './Recomendations.actions';

const userState = {
    hdop: [],
    loading_hdop: false,
    error_hdop: undefined,
};

/**
 * The Driver reducer.
 */
const userReducer = (state = userState, { payload, type }) => {
    switch (type) {
        case GET_HDOP: {
            return {
                ...state,
                loading_hdop: true,
                error_hdop: undefined,
            };
        }
        case GET_HDOP_ERROR: {
            return {
                ...state,
                loading_hdop: false,
                error_hdop: payload.error,
            };
        }
        case GET_HDOP_SUCCESS: {
            return {
                ...state,
                loading_hdop: false,
                error_hdop: undefined,
                hdop: payload.hdop,
            };
        }
        default:
            return state;
    }
};

export default userReducer;