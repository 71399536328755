import Typography from "../../components/Basics/Typography";
import { Stack } from "@carbon/react";
import { CardComponent } from "../../components/Layouts/Card.styles";
import Card from '../../components/Layouts/Card';
import routes from "../../config/settings/routes";

const UpdateLicense = () => {
    return <CardComponent width="100%">
        <Card justifyContent='flex-start'><Stack gap={5}>
            <Typography className="cds--type-fluid-heading-05">Como Actualizar una Licencia</Typography>
            <Typography className="cds--type-fluid-heading-03">
                Como utilizar el formulario de actualización de licencia
            </Typography>
            <Typography className="cds--type-body-compact-02">
                Para simplificar la gestión de licencias en la plataforma, el submódulo de <a className="document-link" href={routes.recordsDriver}>Fichas de Conductores</a> incluye un formulario específico para la actualización de las licencias. Este formulario permite a los usuarios cargar una nueva fecha de vencimiento y una fotografía de su licencia, la cual será utilizada como registro dentro del sistema.<br />
                <br />
                Pasos a Seguir:<br />
                1. Ingresar al submódulo <a className="document-link" href={routes.recordsDriver}>Fichas de Conductores</a> en el módulo de Seguridad.<br />
                2. Presionar el botón del formulario 'Actualizar estado de licencia'.<br />
                3. Rellenar los campos del formulario.<br />
                4. Subir una foto de la licencia, el formato recomendado es .jpg de un tamaño menor a 500kb.<br />
                5. Verificar la información y enviar.
            </Typography>
            <video width="880px" height="391px" controls autoPlay>
                <source src="/assets/videos/update_license.mp4" type="video/mp4" />
            </video>
        </Stack>
        </Card>
    </CardComponent>
}

export default UpdateLicense;