import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetRealTimeThunk } from './LastShift.actions'
import { CardRow, CardComponent } from '../../../components/Layouts/Card.styles'
import { FirstRow, SecondRow, ThirdRow, FourthRow, FifthRow } from './LastShift.row'
import { Button, Stack } from '@carbon/react'
import Typography from '../../../components/Basics/Typography'
import LoadingContent from '../../../components/Animations/LoadingContent'
import { DashboardReference } from '@carbon/icons-react'
import routes from '../../../config/settings/routes'

/**
 * The LastShift's container.
 */
const LastShiftContainer = () => {
  const dispatch = useDispatch()
  const { error, loading } = useSelector(state => state.performance)

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetRealTimeThunk())
  }, [])

  return (
    <>
      {loading && !error && (
        <>
          <LoadingContent />
        </>
      )}
      {!loading && error && (
        <>
          <CardComponent width="10%">
            <Typography className='cds--type-heading-compact-01'>
              Error al buscar data tiempo real. Estamos solucionado el problema
            </Typography>
          </CardComponent>
        </>
      )}
      {!loading && !error && (
        <Stack gap={3}>
          <CardRow justifyContent="flex-start">
            <Button disabled renderIcon={DashboardReference} size='sm' href={routes.monitoringPerf}>
              Desempeño Turno Actual
            </Button>
          </CardRow>
          <CardRow>
            <FirstRow />
          </CardRow>
          <CardRow>
            <SecondRow />
          </CardRow>
          <CardRow>
            <ThirdRow />
          </CardRow>
          <CardRow>
            <FourthRow />
          </CardRow>
          <CardRow>
            <FifthRow />
          </CardRow>
        </Stack>
      )}
    </>

  )
}

export default LastShiftContainer
