import React from "react";
import { RadarChart } from "@carbon/charts-react";
import { toolbarOptions } from "./utils";
import { RadarChartContainer } from './RadarChart.styles';

function Radar({ data, title, colorScale, width }) {
    const options = {
        "title": title,
        "radar": {
            "axes": {
                "angle": "feature",
                "value": "score"
            }
        },
        "data": {
            "groupMapsTo": "product"
        },
        "height": "400px",
        "width": width,
        "color": {
            "scale": colorScale
        },
        "theme": "g100",
        ...toolbarOptions
    };

    return (
        <RadarChartContainer>
            <RadarChart
                data={data}
                options={options}>
            </RadarChart>
        </RadarChartContainer>
    );
}

export default Radar;
