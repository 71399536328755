import {
  GET_REAL_TIME,
  GET_REAL_TIME_ERROR,
  GET_REAL_TIME_SUCCESS,
} from './Performance.actions';

const realTimeState = {
  loading: true,
  error: undefined,
  m3Production: [],
  loadTimes: [],
  queueTimes: [],
  cycleCount: [],
  avgSpeed: [],
  cycleTime: [],
  heatmapHour: [],
  unloadsPerHour: [],
  cycleCountTotal: [],
  truckQty: {},
  productionPerHour: 0,
  dailyProduction: 0,
  totalTrucks: 0,
  loadedTrucks: 0,
  numberOfTrucksToReturn: 0,
  trucksTurn: {},
  textToKeyMap: {},
  lastTrucks: {},
};

/**
 * The real time reducer.
 */
const realTimeReducer = (state = realTimeState, { payload, type }) => {
  switch (type) {
    case GET_REAL_TIME: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_REAL_TIME_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_REAL_TIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        m3Production: payload.m3Production,
        dailyProduction: payload.dailyProduction,
        loadTimes: payload.loadTimes,
        queueTimes: payload.queueTimes,
        cycleCount: payload.cycleCount,
        avgSpeed: payload.avgSpeed,
        cycleTime: payload.cycleTime,
        heatmapHour: payload.heatmapHour,
        totalTrucks: payload.totalTrucks,
        unloadsPerHour: payload.unloadsPerHour,
        cycleCountTotal: payload.cycleCountTotal,
        truckQty: payload.truckQty,
        productionPerHour: payload.productionPerHour,
        loadedTrucks: payload.loadedTrucks,
        numberOfTrucksToReturn: payload.numberOfTrucksToReturn,
        trucksTurn: payload.trucksTurn,
        textToKeyMap: payload.textToKeyMap,
        lastTrucks: payload.lastTrucks,
      };
    }
    default: {
      return state;
    }
  }
};

export default realTimeReducer;
