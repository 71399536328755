import React, { forwardRef } from 'react';
import { Button, Modal, Stack } from "@carbon/react";
import Typography from '../../../components/Basics/Typography';
import { Camera } from '@carbon/icons-react';
import SimpleTable from '../../../components/Basics/SimpleTable.container';
import { Separator } from '../../Dashboard/Dashboard.styles';

const MachineRecordModal = forwardRef(({ open, setOpen }, ref) => {
    const type = open?.cells[0].value || 'Tipo de Equipo';
    const patent = open?.cells[1].value || 'Patente';
    // const truck = open?.cells[2].value || 'Equipo';
    const model = open?.cells[3].value || 'Modelo';
    const brand = open?.cells[4].value || 'Marca';
    const year = open?.cells[5].value || 'Año';

    const productionRows = [{
        id: '1',
        production: '00'
    }];
    const productionHeaders = [
        {
            header: 'Producción por turno (media, descargas y cargas)',
            key: 'production'
        }
    ];

    const wasteRows = [{
        id: '1',
        flat: '00',
        ralenti: '00',
        transgresions: '00'
    }]
    const wasteHeaders = [
        {
            header: 'Pinchazos Registrados (total)',
            key: 'flat',
        },
        {
            header: 'Tiempo en Ralenti (media, horas)',
            key: 'ralenti',
        },
        {
            header: 'Transgresiones de velocidad (total)',
            key: 'transgresions',
        },
    ];

    return (
        <Modal
            danger
            launcherButtonRef={ref}
            modalHeading={patent}
            modalLabel="Ficha de Equipo"
            open={!!open}
            onRequestClose={() => setOpen(null)}
            passiveModal
        >
            <Stack gap={6}>
                <Typography className='cds--type-heading-compact-01'>Información básica</Typography>
                <Typography className='cds--type-label-01'>Tipo de Equipo</Typography>
                <Typography className='cds--type-label-02' padding="0 16px">{type}</Typography>
                <Separator />
                <Typography className='cds--type-label-01'>Modelo</Typography>
                <Typography className='cds--type-label-02' padding="0 16px">{model}</Typography>
                <Separator />
                <Typography className='cds--type-label-01'>Marca</Typography>
                <Typography className='cds--type-label-02' padding="0 16px">{brand}</Typography>
                <Separator />
                <Typography className='cds--type-label-01'>Año</Typography>
                <Typography className='cds--type-label-02' padding="0 16px">{year}</Typography>
                <Separator />
                <Typography className='cds--type-heading-compact-01'>Información sobre Transgresiones y Desperdicios</Typography>
                <SimpleTable headers={wasteHeaders} rows={wasteRows} />
                <Typography className='cds--type-heading-compact-01'>Información sobre Mantenimiento</Typography>
                <SimpleTable headers={productionHeaders} rows={productionRows} />
                <Typography className='cds--type-heading-compact-01'>Utilidad</Typography>
                <Button disabled renderIcon={Camera}>Ver Equipo</Button>
            </Stack>
        </Modal>
    )
})

export default MachineRecordModal
