import { Stack, Tabs, TabList, Tab, TabPanels, TabPanel } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { HeatMap, Dashboard } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "../../../components/Basics/Iframe.styles";


const DashboardCan = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            DASHBOARD CAN
          </Typography>
        </Card>
        <CardRow width='inherit'>
          <Card width='100%' padding="0" flexDirection='column'>
            <Tabs>
              <TabList activation="manual" aria-label="List of tabs">
                <Tab renderIcon={Dashboard}>Volvo</Tab>
                <Tab renderIcon={Dashboard}>Mercedes Benz</Tab>
                <Tab renderIcon={Dashboard}>Buses</Tab>
              </TabList>
              <TabPanels>
                <TabPanel style={{ width: '100%' }}>
                  <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/c40af1f4-7e51-4e6c-bbc4-f1562828eb62/can?orgId=1&var-patente=TBGK79&from=1706788361943&to=1706791961943&kiosk" width="100%" height="100%" />
                </TabPanel>
                <TabPanel width='100%' style={{ width: '100%' }}>
                  <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/ccc15626-eba3-45d0-ac9d-a13edf9e0a79/can-mercedes?orgId=1&refresh=30s&from=1709140476743&to=1709144076743&kiosk" width="100%" height="100%" />
                </TabPanel>
                <TabPanel width='100%' style={{ width: '100%' }}>
                  <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/a60ab8ce-9f60-4d31-8d24-2b8b67c134cb/can-buses?orgId=1&var-patente=TCSD22&from=1713388832506&to=1713392432506&kiosk" width="100%" height="100%" />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </CardRow>
      </CardComponent>
    </Stack>
  )
}

export default DashboardCan