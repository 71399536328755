import { useState, useEffect, useCallback } from 'react';
import { isEqual, isEmpty } from 'lodash-es';

/**
 *
 * To apply a bunch of formatters to the given value.
 */
const makeFormat = (callbacks, value) =>
  callbacks.reduce((updatedValue, callback) => callback(updatedValue), value);

/**
 * To apply a bunch of validators to the given value.
 */
const makeError = (callbacks, value) =>
  callbacks.reduce((message, callback) => {
    if (message !== '') {
      return message;
    }

    return callback(value);
  }, '');

/**
 * The Input's custom hook.
 */
export const useInput = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  formatCallbacks = [],
  initialValue = '',
  pasteCallback = () => undefined,
} = {}) => {
  const [error, setError] = useState('');
  const [value, setValue] = useState(initialValue);
  const handleChange = event => {
    const updatedValue = makeFormat(
      formatCallbacks,
      event?.target?.value || ''
    );
    const updatedError = makeError(errorCallbacks, updatedValue);

    setError(updatedError);
    setValue(updatedValue);

    if (updatedValue === value) {
      return;
    }

    changeCallback(updatedValue);
  };
  const handlePaste = event => pasteCallback(event);

  const executeErrors = () => {
    const updatedError = makeError(errorCallbacks, value);
    setError(updatedError);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return {
    error,
    value,
    setValue,
    onChange: handleChange,
    onPaste: handlePaste,
    executeErrors,
  };
};

/**
 * The Select' custom hook.
 */
export const useSelect = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  initialValue = '',
} = {}) => {
  const [error, setError] = useState('');
  const [value, setValue] = useState(initialValue);
  const handleChange = event => {
    const updatedValue = event?.target?.value || '';
    const updatedError = makeError(errorCallbacks, updatedValue);

    setError(updatedError);
    setValue(updatedValue);

    if (updatedValue === value) {
      return;
    }

    changeCallback(updatedValue);
  };
  const handleReset = () => setValue(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return { error, value, onChange: handleChange, onReset: handleReset };
};

/**
 * The Dropdown' custom hook.
 */
export const useDropdown = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  initialValue = '',
} = {}) => {
  const [error, setError] = useState('');
  const [value, setValue] = useState(initialValue);
  const handleChange = event => {
    const updatedValue = event?.selectedItem?.id || '';
    const updatedError = makeError(errorCallbacks, updatedValue);

    setError(updatedError);
    setValue(updatedValue);

    if (updatedValue === value) {
      return;
    }

    changeCallback(updatedValue);
  };
  const handleReset = () => setValue(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return { error, value, onChange: handleChange, onReset: handleReset };
};

export const useMultiSelect = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  initialSelectedItems = [],
} = {}) => {
  const [error, setError] = useState('');
  const [selectedItems, setSelectedItems] = useState(
    initialSelectedItems || []
  );

  const handleChange = useCallback(
    event => {
      const updatedItems = event?.selectedItems || [];
      const updatedError = makeError(errorCallbacks, updatedItems);

      // Use setTimeout to defer both state updates until the next event loop cycle
      setTimeout(() => {
        setSelectedItems(updatedItems);
        setError(updatedError);
      }, 0);

      // Call the change callback immediately (assuming it doesn’t modify state)
      changeCallback(updatedItems);
    },
    [changeCallback, errorCallbacks]
  );

  const handleReset = useCallback(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  return { error, selectedItems, onChange: handleChange, onReset: handleReset };
};

/**
 * The Select' custom hook for carbon components which gives different event.
 */
export const useSelectComboBox = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  initialValue = null, // Initial value should be null, an object, or undefined
} = {}) => {
  const [error, setError] = useState('');
  const [value, setValue] = useState(initialValue);

  const handleChange = event => {
    const selectedItem = event?.selectedItem || null;
    const updatedValue = selectedItem ? selectedItem : null;
    const updatedError = makeError(errorCallbacks, updatedValue?.id || ''); // Use the item's id for error checking

    setError(updatedError);
    setValue(updatedValue); // Store the entire selected object

    if (updatedValue?.id !== value?.id) {
      changeCallback(updatedValue);
    }
  };

  const handleReset = () => setValue(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Ensure value is an empty string when null to prevent warning
  return {
    error,
    value: value || '', // Return empty string instead of null
    onChange: handleChange,
    onReset: handleReset,
  };
};

/**
 * The Radio's custom hook.
 */
export const useRadio = ({ initialValue = '' } = {}) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = event => setValue(event);

  return { value, onChange: handleChange };
};

/**
 * The DatePicker's custom hook.
 */
export const useDatePicker = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  formatCallbacks = [],
  initialValue = new Date(),
} = {}) => {
  const [error, setError] = useState('');
  const [value, setValue] = useState(initialValue);
  const handleChange = date => {
    if (isEqual(date, value) || isEmpty(date)) return;

    const updatedValue = makeFormat(formatCallbacks, date === null ? '' : date);
    const updatedError = makeError(errorCallbacks, updatedValue);

    setError(updatedError);
    setValue(updatedValue);

    if (updatedValue === value) {
      return;
    }

    changeCallback(updatedValue);
  };

  return { error, value, setValue, onChange: handleChange };
};
