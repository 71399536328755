import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { DataTable } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "../../../components/Basics/Iframe.styles";
// import EffectiveTimeDriverTable from "./EffectiveTimeDriverTable.container";

const EffectiveTimeDriver = () => {
    return (
        <Stack gap={3}>
            <CardComponent width="100%">
                <Card justifyContent='flex-start' >
                    <DataTable size={18} />
                    <Typography className='cds--type-heading-compact-01'>
                        CONDUCTORES OPERATIVOS - TABLA DE DATOS
                    </Typography>
                </Card>
                <CardRow width='inherit'>
                    <Card width='100%' padding="0">
                        <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/dfe82650-afa5-4932-aff6-3e67350d6b0d/camiones-sin-conductor-registrado?orgId=1&from=1728560770151&to=1728582370151&kiosk" width="100%" height="100%" />
                    </Card>
                </CardRow>
            </CardComponent>
        </Stack>
    )
};

export default EffectiveTimeDriver;