import styled from 'styled-components';
import { g90, g100 } from '@carbon/themes';

export const Root = styled.div`
  align-items: left;
  background-color: ${g90.background};
  border: 1px ${g100.borderTile01};
  border-style: solid;
  height: inherit;
  width: 22.5rem;
  padding: 15px;
  display: ${props => (props.hidden ? 'none' : 'block')};

  // https://carbondesignsystem.com/patterns/disabled-states/#style
  opacity: ${props => props.disabled && 0.5};
  &:hover {
    cursor: ${props => props.disabled && 'not-allowed'};
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
