import React, { useState, useEffect, useMemo } from "react";
import ELK from "elkjs/lib/elk.bundled";
import { path as d3Path } from "d3-path";
import { g90 } from "@carbon/themes";
import { CardNode, CardNodeSubtitle, CardNodeTitle, Edge, TeeMarker } from "@carbon/charts-react";

const Link = ({ link }) => {
    const sections = link.sections[0];
    const path = d3Path();

    path.moveTo(sections.startPoint.x, sections.startPoint.y);

    if (sections.bendPoints) {
        sections.bendPoints.forEach((b) => {
            path.lineTo(b.x, b.y);
        });
    }

    path.lineTo(sections.endPoint.x, sections.endPoint.y);

    return (
        <Edge
            path={path.toString()}
            markerEnd="tee"
            variant="dash-lg"
        />
    );
};

const Node = ({ x, y, height, width, color, title, subtitle, val1, val2 }) => {
    return (
        <foreignObject
            transform={`translate(${x},${y})`}
            height={height}
            width={width}
            style={{ overflow: "visible" }}
        >
            <div style={{ height, width }}>
                <CardNode tag="div" style={{
                    background: g90.backgroundActive,
                    /* This needs to be set instead of `color={green40}` since the background color overwrites it. */
                    borderColor: color
                }}>
                    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px", alignItems: "center" }}>
                        <div></div>
                        <CardNodeTitle>Media 10d</CardNodeTitle>
                        <CardNodeTitle>Media 20d</CardNodeTitle>
                        <CardNodeSubtitle>
                            <div style={{
                                flexDirection: 'column'
                            }}>
                                <h6>{title}</h6>
                                <small>{subtitle}</small>
                            </div>
                        </CardNodeSubtitle>
                        <CardNodeSubtitle>{val1}</CardNodeSubtitle>
                        <CardNodeSubtitle>{val2}</CardNodeSubtitle>
                    </div>
                </CardNode>
            </div>
        </foreignObject>
    );
};

const NetworkDiagram = ({ nodes, links, layout }) => {
    const elk = useMemo(() => new ELK(), []);

    const [positions, setPositions] = useState(null);

    const graph = useMemo(() => ({
        id: "root",
        layoutOptions: {
            "elk.algorithm": layout,
            "elk.padding": "[left=50, top=50, right=50, bottom=50]",
            separateConnectedComponents: false,
            "spacing.nodeNode": 100,
            "spacing.nodeNodeBetweenLayers": 100
        },
        children: nodes,
        edges: links
    }), [layout, links, nodes]);

    useEffect(() => {
        elk
            .layout(graph)
            .then((g) => setPositions(g))
            .catch(console.error);
    }, [elk, graph]);

    if (!positions) return null;

    const buildNodes = () => {
        const { children } = positions;
        if (!children) return;
        return children.map((node, i) => {
            return (
                <Node
                    key={`node_${i}`}
                    x={node.x}
                    y={node.y}
                    height={node.height}
                    width={node.width}
                    color={node.color}
                    title={node.title}
                    subtitle={node.subtitle}
                    val1={node.val1}
                    val2={node.val2}

                />
            );
        });
    };

    const buildLinks = () => {
        const { edges } = positions;
        if (!edges) return;
        return edges.map((edge, i) => {
            return <Link key={`link_${i}`} link={edge} />;
        });
    };

    const nodeElements = buildNodes();
    const linkElements = buildLinks();

    return (
        <div
            style={{
                position: `relative`,
                height: '100%',
                width: '100%',
                margin: 20
            }}
        >
            <svg
                style={{ height: "100%", width: "100%" }}
                viewBox={`0 0 2000 2500`} // Set a large viewBox
            >
                <defs>
                    <TeeMarker id="tee" />
                </defs>
                {linkElements}
                {nodeElements}
            </svg>
        </div>
    );
};

export default NetworkDiagram;
