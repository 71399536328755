import {
  GET_GENERAL_REPORT,
  GET_GENERAL_REPORT_ERROR,
  GET_GENERAL_REPORT_SUCCESS,
} from './Idle.actions';

const idleReportState = {
  data: [],
  loading: true,
  error: undefined,
};

/**
 * The Idle Report Reducer.
 */
const idleReportReducer = (state = idleReportState, { payload, type }) => {
  switch (type) {
    case GET_GENERAL_REPORT: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_GENERAL_REPORT_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_GENERAL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default idleReportReducer;
