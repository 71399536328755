import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { BoxPlot, ChartBarStacked, CheckmarkFilled, DataTable, SummaryKpi, WarningAltFilled, WarningFilled } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import InfoCard from "../../../components/Basics/InfoCard.container";
import Sparkline from "../../../components/Graphs/Sparkline.container";
import BoxPlotContainer from "../../../components/Graphs/BoxPlot.container";
import Card from '../../../components/Layouts/Card';
import TransferB57ToDischargingPointTable from "./TransferB57ToDischargingPointTable.container";
import { Div } from "../../../components/Basics/Div.styles";
import LoadingContent from '../../../components/Animations/LoadingContent'
import { onGetRealTimeReportsThunk } from "./FromB57.actions";
import FlexHeatMap from "../../../components/Graphs/FlexHeatMap.container";
import { spacing05 } from "@carbon/themes"

const TransferB57ToDischargingPoint = () => {
    const dispatch = useDispatch()
    const { error, loading, chargeTime, chargeMean, graphBoxPlot } = useSelector(state => state.FromB57)
    // TODO: Replace with real data
    const { cycleTime: DummyHeatMapData } = useSelector(state => state.daily)

    useEffect(() => {
        if (!loading) {
            return
        }
        dispatch(onGetRealTimeReportsThunk())
    }, [])

    const sparklineYTitle = 'Tiempo Traslado desde B57 a Frente Descarga'

    return (
        <>
            {loading && !error && (
                <>
                    <LoadingContent />
                </>
            )}
            {!loading && error && (
                <>
                    <CardComponent width="10%">
                        <Typography className='cds--type-heading-compact-01'>
                            Error al buscar data tiempo real. Estamos solucionado el problema
                        </Typography>
                    </CardComponent>
                </>
            )}
            {!loading && !error && (
                <Stack gap={3}>
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <SummaryKpi size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                TIEMPOS DE TRASLADO A FRENTE DE DESCARGA - TENDENCIA
                            </Typography>
                        </Card>
                        <Div flexWrap="wrap" display="flex" justifyContent="space-between" gap="0.5rem">
                            {chargeTime.map((item, index) => (
                                <InfoCard
                                    key={index}
                                    icon={<CheckmarkFilled className='success' size={33} />}
                                    title={item.group}
                                    body={`${chargeMean.filter(_ => _.key == item.group)[0].value} min`}
                                    subtitle='Tiempo de Traslado Promedio'
                                    graphTitle='Tiempo de Traslado (media, minutos)'
                                    graph={<Sparkline data={item.value} yAxisTitle={sparklineYTitle} />}
                                />
                            ))}
                        </Div>
                    </CardComponent>
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <BoxPlot size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                TIEMPOS DE TRASLADO A FRENTE DE DESCARGA - COMPARATIVO
                            </Typography>
                        </Card>
                        <CardRow width='inherit'>
                            <Card flexDirection='column' justifyContent='center' width='100%'>
                                <BoxPlotContainer height='40vh' title="Tiempos de Traslado Promedio (media, minutos)" xAxisTitle="Frente de Destino" yAxisTitle="Minutos" data={graphBoxPlot} />
                            </Card>
                        </CardRow>
                    </CardComponent>
                    {/* Waiting for data from back end
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <ChartBarStacked size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                RUTAS DE TRANSPORTE
                            </Typography>
                        </Card>
                        <CardRow width="inherit">
                            <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
                                <FlexHeatMap data={DummyHeatMapData} title='Tiempos de Traslado (media, horas)' />
                            </Card>
                        </CardRow>
                    </CardComponent>
                    */}
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <DataTable size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                TIEMPOS DE TRASLADO A FRENTES DE DESCARGA - TABLA DE DATOS
                            </Typography>
                        </Card>
                        <CardRow width='inherit'>
                            <Card width='100%' padding="0">
                                <TransferB57ToDischargingPointTable />
                            </Card>
                        </CardRow>
                    </CardComponent>
                </Stack>
            )}
        </>
    )
};

export default TransferB57ToDischargingPoint;