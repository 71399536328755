import React from "react";
import PropTypes from 'prop-types'
import { BoxplotChart } from "@carbon/charts-react";
import { toolbarOptions } from "./utils";

const BoxPLot = props => {
  const { height, title, xAxisTitle, yAxisTitle, data } = props
  const state = {
    data: data,
    options: {
      "title": title,
      "axes": {
        "bottom": {
          "scaleType": "labels",
          "mapsTo": "group",
          "title": xAxisTitle,
        },
        "left": {
          "mapsTo": "value",
          "title": yAxisTitle,
        }
      },
      "height": height,
      "width": "95%",
      "theme": "g100",
      ...toolbarOptions
    }
  };

  return (
    <BoxplotChart
      data={state.data}
      options={state.options}
    />
  )
}

BoxPLot.defaultProps = {
  height: "15rem",
  title: "Boxplot Graph",
  data: [
    {
      group: 'Q1',
      value: 65000
    },
    {
      group: 'Q1',
      value: 29123
    },
    {
      group: 'Q1',
      key: 'Wednesday',
      value: 35213
    },
    {
      group: 'Q1',
      key: 'Thursday',
      value: 51213
    },
    {
      group: 'Q1',
      key: 'Friday',
      value: 16932
    },
    {
      group: 'Q2',
      key: 'Monday',
      value: 32432
    },
    {
      group: 'Q2',
      key: 'Tuesday',
      value: 14312
    },
    {
      group: 'Q2',
      key: 'Wednesday',
      value: 66456
    },
    {
      group: 'Q2',
      key: 'Thursday',
      value: 21312
    },
    {
      group: 'Q2',
      key: 'Friday',
      value: 37234
    },
    {
      group: 'Q3',
      key: 'Monday',
      value: 5312
    },
    {
      group: 'Q3',
      key: 'Tuesday',
      value: 23232
    },
    {
      group: 'Q3',
      key: 'Wednesday',
      value: 34232
    },
    {
      group: 'Q3',
      key: 'Thursday',
      value: 12312
    },
    {
      group: 'Q3',
      key: 'Friday',
      value: 44234
    },
    {
      group: 'Q4',
      key: 'Monday',
      value: 32423
    },
    {
      group: 'Q4',
      key: 'Tuesday',
      value: 21313
    },
    {
      group: 'Q4',
      key: 'Wednesday',
      value: 64353
    },
    {
      group: 'Q4',
      key: 'Thursday',
      value: 24134
    },
    {
      group: 'Q4',
      key: 'Friday',
      value: 45134
    }
  ]
}

BoxPLot.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
}

export default BoxPLot
