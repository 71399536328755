import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import routes from '../../config/settings/routes';
import { HeaderMenuItem, HeaderNavigation, HeaderMenu } from '@carbon/react';
import "./ManagementMenu.styles.scss"

/**
 * The ManagementMenu's component
 * 
 * To disable a HeaderMenuItem, wrap it aroud this component
 * `<div className='div-disabled'><HeaderMenuItem .../></div>`
 * and apply a className to it
 * `<HeaderMenuItem className='disabled' />`.
 */
export const ManagementMenu = props => {
  const { current } = props;

  const isToolHeaderActive =
    current === routes.map
    || current === routes.fronts
    || current === routes.userManual
    || current === routes.devices;

  return (
    <HeaderNavigation aria-label="Talabre">
      <HeaderMenu
        aria-label="Usuarios"
        menuLinkName="Usuarios"
        isActive={isToolHeaderActive}
      >
        <HeaderMenuItem as={Link} to={routes.usersInitial}>
          Inicio de Módulo
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.usersRecomendations}>
          Recomendaciones
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.notifications}>
          Notificaciones
        </HeaderMenuItem>
      </HeaderMenu>
      <HeaderMenu
        aria-label="Tiempo Real"
        menuLinkName="Tiempo Real"
        isActive={current === routes.monitoring}
      >
        <HeaderMenuItem as={Link} to={routes.monitoringInitial}>
          Inicio de Módulo
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringmap}>
          Mapa de Flota
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringPerf}>
          Desempeño Operación y Desperdicios
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringCan}>
          Dashboard CAN Última Hora
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringCharg}>
          Registro Tiempos de Carga
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringTUnload}>
          Tiempos de Traslado a Frentes de Descarga
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringDischarging}>
          Tiempos en Frente de Descarga
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringTLoad}>
          Tiempo Traslado a B57
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringIdle}>
          Tiempo en Ralenti
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringEffectiveTimeDriver}>
          Conductores Operativos
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringPunctures}>
          Pinchazos
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringComposting}>
          Composteo de Combustible
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringGeofenceG1}>
          Paso Geocerca G1
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.monitoringLowVelocity}>
          Velocidades Bajas
        </HeaderMenuItem>
      </HeaderMenu>
      <HeaderMenu
        aria-label="Seguridad"
        menuLinkName="Seguridad"
        isActive={current === routes.security}
      >
        <HeaderMenuItem as={Link} to={routes.securityInitial}>
          Inicio de Módulo
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securityVelocityViolation}>
          Transgresiones de Velocidad
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securityVelocityHistoric}>
          Transgresiones de Velocidad Histórico
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securityTruckApproach}>
          Acercamientos entre Camiones
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securityFatigue}>
          Fatiga y somnolencia
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securityCan}>
          Dashboard CAN Histórico
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securitySensorsCount}>
          Sensores Presión
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securityBrake}>
          Frenos y Retardador
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.securityTrain}>
          Registro Cruce Tren
        </HeaderMenuItem>
      </HeaderMenu>
      <HeaderMenu
        aria-label="Mantenimiento"
        menuLinkName="Mantenimiento"
        isActive={current === routes.maintenance}
      >
        <HeaderMenuItem as={Link} to={routes.maintenanceInitial}>
          Inicio de Módulo
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.maintenanceCompleted}>
          Mantenimientos Completados
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.maintenancePermanence}>
          Registro Permanencias
        </HeaderMenuItem>
      </HeaderMenu>
      <HeaderMenu
        aria-label="Productividad"
        menuLinkName="Productividad"
        isActive={current === routes.productivity}
      >
        <HeaderMenuItem as={Link} to={routes.productivityInitial}>
          Inicio de Módulo
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityHistoric}>
          Producción Historica
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.producivityDailyTurn}>
          Reportes por Turnos
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityDaily}>
          Reportes Diarios
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityWeekly}>
          Reportes Semanales
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityMonthly}>
          Reportes Mensuales
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityAsarco}>
          ASARCO
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.producivityKpiTree}>
          Árbol de Kpi
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityIdle}>
          Tiempo en Ralenti
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityFlatTire}>
          Pinchazos
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivityBreakfast}>
          Duración Colación
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.productivitySpeedGeofence}>
          Velocidad por Geocerca
        </HeaderMenuItem>
      </HeaderMenu>
      <HeaderMenu
        aria-label="Fichas"
        menuLinkName="Fichas"
        isActive={current === routes.records}
      >
        <HeaderMenuItem as={Link} to={routes.recordsInitial}>
          Inicio de Módulo
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.recordsDriver}>
          Conductores
        </HeaderMenuItem>
        {/* <HeaderMenuItem as={Link} to={routes.recordsGeofenches}>
          Geocercas
        </HeaderMenuItem> */}
        <HeaderMenuItem as={Link} to={routes.recordsGates}>
          Pórtico
        </HeaderMenuItem>
      </HeaderMenu>
      <HeaderMenuItem
        aria-label="Bases de Datos"
        isActive={current === routes.database}
        as={Link}
        to={routes.databaseInitial}
      >
        Bases de Datos
      </HeaderMenuItem>
      <HeaderMenu
        aria-label="Herramientas"
        menuLinkName="Herramientas"
        isActive={isToolHeaderActive}
      >
        <HeaderMenuItem as={Link} to={routes.map}>
          Geocercas Velocidad
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.fronts}>
          Geocercas Frentes
        </HeaderMenuItem>
        <HeaderMenuItem as={Link} to={routes.userManual}>
          Manual de Usuario
        </HeaderMenuItem>
      </HeaderMenu>
    </HeaderNavigation>
  )
}

ManagementMenu.propTypes = {
  current: PropTypes.string.isRequired
}

export default ManagementMenu
