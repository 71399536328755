import { makeActionCreator } from '../../config/store/utils';
import { batch } from 'react-redux';

const NotificationStatus = {
  SHOW: 'SHOW',
  HIDDEN: 'HIDDEN',
  REMOVED: 'REMOVED',
};

export const GET_NOTIFICATIONS_NUMBER = 'GET_NOTIFICATIONS_NUMBER';
export const onGetNotificationsNumber = makeActionCreator(
  GET_NOTIFICATIONS_NUMBER,
  'payload'
);

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const onSetNotifications = makeActionCreator(
  SET_NOTIFICATIONS,
  'payload'
);
export const onSetNotificationsThunk = newNotification => (
  dispatch,
  getState
) => {
  const { notifications } = getState().navbar;
  const elementAlreadyExist = notifications.some(
    notification => notification.id === newNotification.id
  );
  const updatedNotifications = elementAlreadyExist
    ? notifications
    : [
        ...notifications,
        {
          id: newNotification.id,
          title: newNotification.title,
          subtitle: newNotification.subtitle,
          caption: newNotification.date,
          status: newNotification.status,
          kind: newNotification.kind,
          route: newNotification.route,
        },
      ];
  return batch(() => {
    dispatch(
      onSetNotifications({
        notifications: updatedNotifications,
      })
    );
  });
};

export const onRemoveNotificationThunk = oldNotification => (
  dispatch,
  getState
) => {
  const { notifications } = getState().navbar;
  const updatedNotifications = notifications.map(notification =>
    notification.id === oldNotification.id
      ? { ...notification, status: NotificationStatus.REMOVED }
      : notification
  );

  return batch(() => {
    dispatch(
      onSetNotifications({
        notifications: updatedNotifications,
      })
    );
  });
};

export const onRemoveAllNotificationThunk = () => (dispatch, getState) => {
  return batch(() => {
    dispatch(
      onSetNotifications({
        notifications: [],
      })
    );
  });
};

export const onHideNotificationThunk = oldNotification => (
  dispatch,
  getState
) => {
  const { notifications } = getState().navbar;
  const updatedNotifications = notifications.map(notification =>
    notification.id === oldNotification.id &&
    notification.status === NotificationStatus.SHOW
      ? { ...notification, status: NotificationStatus.HIDDEN }
      : notification
  );
  return batch(() => {
    dispatch(
      onSetNotifications({
        notifications: updatedNotifications,
      })
    );
  });
};
