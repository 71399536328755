import { Stack, Tabs, TabList, Tab, TabPanels, TabPanel } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { HeatMap, Dashboard } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "../../../components/Basics/Iframe.styles";

const CanHistoric = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            DASHBOARD CAN
          </Typography>
        </Card>
        <CardRow width='inherit'>
          <Card width='100%' padding="0" flexDirection='column'>
            <Tabs>
              <TabList activation="manual" aria-label="List of tabs">
                <Tab renderIcon={Dashboard}>Volvo</Tab>
                <Tab renderIcon={Dashboard}>Mercedes Benz</Tab>
                <Tab renderIcon={Dashboard}>Buses</Tab>
              </TabList>
              <TabPanels>
                <TabPanel style={{ width: '100%' }}>
                  <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/c8f3d5cd-c508-47cb-b511-ceb82097f208/can-historico?orgId=1&from=1709027660979&to=1709027799140&kiosk" width="100%" height="100%" />
                </TabPanel>
                <TabPanel width='100%' style={{ width: '100%' }}>
                  <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/a137f395-c4bb-4c06-ae17-c6ef823a360e/can-mercedes-historico?orgId=1&from=1709078112944&to=1709078540325&kiosk" width="100%" height="100%" />
                </TabPanel>
                <TabPanel width='100%' style={{ width: '100%' }}>
                  <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/fb03b093-7267-4aeb-bc7a-206db2a5ed89/can-buses-historico?orgId=1&from=1713388928090&to=1713392528090&kiosk" width="100%" height="100%" />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </CardRow>
      </CardComponent>
    </Stack>
  )
};

export default CanHistoric;