import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REACT_APP_BASE_URL } from '../../config/settings/environments'
import { checkErrorRequired } from '../../modules/errors/validations'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { getFromCookie } from '../../modules/storages/cookies'
import routes from '../../config/settings/routes'
import { onRecoverPasswordThunk } from './Authentication.actions'
import {
  Content, Button, Stack, Form, TextInput, Grid, Row, Column, ButtonSkeleton
} from '@carbon/react';
import { Logo, Root, LogoContainer, BorderRoot } from './Login.styles';
import Typography from '../../components/Basics/Typography'
import { gray70 } from '@carbon/colors'
import { ArrowRight } from '@carbon/icons-react'

/**
 * The Password Recovery' container.
 */
const PasswordRecoveryContainer = () => {
  const dispatch = useDispatch()
  const { error, loading, resetMessage } = useSelector(state => state.auth)
  const redirectPath = getFromCookie('redirect_path') || routes.root

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: passwordError, value: passwordValue, onChange: handlePasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: codeError, value: codeValue, onChange: handlecodeChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const canNotSubmit = useCallback(() => {
    return passwordError !== '' || usernameError !== '' || codeError !== ''
  }, [passwordError, usernameError, codeError])
  const disabledSubmit = canNotSubmit()

  const handlePasswordSubmit = event => {
    event.preventDefault()
    dispatch(
      onRecoverPasswordThunk({
        username: usernameValue,
        code: codeValue,
        newPassword: passwordValue,
        onSuccessCallback: () =>
          setTimeout(() => {
            window.open(`${REACT_APP_BASE_URL}${redirectPath}`, '_top')
          }, 3000)
      })
    )
  }
  return (
    <>
      <Root>
        <BorderRoot>
          <Form className='login-form' onSubmit={handlePasswordSubmit}>
            <Stack gap={3}>
              <LogoContainer>
                <Logo src="/assets/images/codelco.svg" alt="Codelco" />
                <Logo src="/assets/images/acciona.svg" alt="Acciona" />
                <Typography className="cds--type-body-01" justify='center' display='flex'>
                  Ingrese su nueva contraseña
                </Typography>
              </LogoContainer>
              <TextInput labelText='Correo Electronico' placeholder='Correo' id='recovery-mail' onChange={handleUsernameChange} value={usernameValue} invalid={Boolean(usernameError)} invalidText={usernameError} />
              <TextInput.PasswordInput type='password' placeholder='Codigo' labelText='Código' id='codigo' onChange={handlecodeChange} value={codeValue} invalid={Boolean(codeError)} invalidText={codeError} />
              <TextInput.PasswordInput type='password' placeholder='Contraseña' labelText='Contraseña' id='password' onChange={handlePasswordChange} value={passwordValue} invalid={Boolean(passwordError)} invalidText={passwordError} />

              {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Nombre de usuario incorrecto
                </Typography>
              )}

              {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Lo sentimos, estamos presentando problemas con nuestro servidor
                </Typography>
              )}

              {!loading && error && error.reason === 'USERNAME_CANNOT_BE_EMPTY' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Por favor ingrese su nombre de usuario
                </Typography>
              )}

              {!loading && error && error.reason === 'PASSWORD_DOES_NOT_CONFORM_TO_POLICY' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Contraseña nueva debe contener minimo 8 caracteres
                </Typography>
              )}

              {!loading && error && error.reason === 'EXPIRED_CODE' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Código expirado, favor solicite un nuevo codigo
                </Typography>
              )}

              {!loading && error && error.reason === 'LIMIT_EXCEEDED_EXCEPTION' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Limite excedido, por favor inténtelo de nuevo más tarde
                </Typography>
              )}

              {!loading && error && error.reason === 'CODE_MISMATCH' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Código invalido, por favor intente de nuevo
                </Typography>
              )}

              {!loading && error && error.reason === 'POR FAVOR CREE UNA NUEVA CONTRASEÑA' && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                  Por favor crear una nueva contraseña
                </Typography>
              )}
              {loading && (
                <ButtonSkeleton className='login-button' disabled={disabledSubmit} />
              )}
              {!loading && (
                <Button disabled={disabledSubmit} type="submit" className='login-button' renderIcon={ArrowRight}>
                  INGRESAR
                </Button>
              )}
              {!loading && !error && (
                <Typography className="cds--type-helper-text-01" justify='center' display='flex'>
                  {resetMessage}
                </Typography>
              )}
              <Typography className="cds--type-helper-text-01" justify='center' display='flex' color={gray70}>
                ¿Tienes algún problema? Contáctanos en talabre@terrestra.tech
              </Typography>
            </Stack>
          </Form>
        </BorderRoot>
      </Root>
    </>
  )
}

export default PasswordRecoveryContainer