import { GET_GATES_SUCCESS } from '../../Records/Gates/Gates.actions';
import {
  GET_SENSORS,
  GET_SENSORS_ERROR,
  GET_SENSORS_SUCCESS,
} from './SensorsDetector.actions';

// Get yesterday's date
const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString();
};

const sensorsState = {
  data: [],
  loading: true,
  error: undefined,
  date: getYesterdayDate(),
};

/**
 * The Sensors reducer.
 */

const sensorsDetectorReducer = (state = sensorsState, { payload, type }) => {
  switch (type) {
    case GET_SENSORS: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_SENSORS_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_SENSORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: payload.data,
        date: payload.date,
      };
    }
    default:
      return state;
  }
};

export default sensorsDetectorReducer;
