import React from 'react'
import { SECURITY_TYPE } from "../config/settings/constant";
import routes from "../config/settings/routes";
import { usePathParam } from "../modules/hooks/route";
import ProtectBySession from "../components/Security/ProtectBySession";
import BrandLayout from "../components/Layouts/BrandLayout";
import { Security as SecurityIcon } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import Navbar from "../containers/Navbar/Navbar.container";
import ManagementMenu from "../containers/ManagementMenu/ManagementMenu.container";
import Heading from "../components/Layouts/Heading";
import Security from '../containers/Security/Initial/Security.container';
import SpeedViolationContainer from '../containers/Security/SpeedViolation/SpeedViolation.container';
import Brakes from '../containers/Security/Brakes/Brakes.container';
import Fatigue from '../containers/Security/Fatigue/Fatigue.container';
import TruckApproachContainer from '../containers/Security/TruckApproach/TruckApproach.container';
import SpeedHistoric from '../containers/Security/SpeedHistoric/SpeedHistoric.container';
import AccessControl from '../components/Security/ProtectByGroup';
import CanHistoric from '../containers/Database/Can/Can.container';
import SensorsCountContainer from '../containers/Security/SensorsDetector/SensorsDetector.container';
import TrainCrossingContainer from '../containers/Security/TrainCrossing/TrainCrossing.container';

/**
 * The Security page.
 */
const SecurityPage = () => {
  const type = usePathParam('type').toUpperCase();

  const TypeTitle = type => {
    switch (type) {
      case SECURITY_TYPE['SECURITY-INITIAL']:
        return 'Seguridad'
      case SECURITY_TYPE['TRUCK-APPROACH']:
        return 'Acercamiento entre camiones'
      case SECURITY_TYPE['VELOCITY-VIOLATION']:
        return 'Transgresiones de Velocidad'
      case SECURITY_TYPE['BRAKE-RETARDER']:
        return 'Frenos y Retardador'
      case SECURITY_TYPE['VELOCITY-HISTORIC']:
        return 'Transgresiones de Velocidad Histórico'
      case SECURITY_TYPE.CAN:
          return 'Dashboard CAN Histórico'
      case SECURITY_TYPE.FATIGUE:
          return 'Fatiga y Somnolencia'
      case SECURITY_TYPE['SENSORS-COUNT']:
        return 'Sensores presión instalados'
      case SECURITY_TYPE.TRAIN:
        return 'Registro Cruce Tren'
      default:
        return 'Inicio de modulo'
    }
  };

  const getTooltipContent = type => {
    switch (type) {
      case SECURITY_TYPE['SECURITY-INITIAL']:
        return 'Módulo de Seguridad';
      case SECURITY_TYPE['TRUCK-APPROACH']:
        return 'Registro de eventos detectados por el sensor ADAS de los equipos camión tolva.';
      case SECURITY_TYPE['VELOCITY-VIOLATION']:
        return 'Registro de transgresiones de velocidad  durante el día con información sobre la velocidad del equipo y los limites de velocidad que detectaron la transgresión.';
      case SECURITY_TYPE['BRAKE-RETARDER']:
        return 'Registro de los eventos de frenado detectados, incluye todas las variables activables por frenados.';
      case SECURITY_TYPE['VELOCITY-HISTORIC']:
        return 'Vista histórica de transgresiones de velocidad, enfocada en la consulta de fechas especificas.';
      case SECURITY_TYPE.CAN:
          return 'Vista histórica del estado de los equipos, ideal para consultar una linea de tiempo de los equipos, con información sobre velocidad, conductor, ruta, etc.';
      case SECURITY_TYPE.FATIGUE:
        return 'Fatiga y somnolencia.';
      case SECURITY_TYPE['SENSORS-COUNT']:
        return 'Información sobre los sensores de presión instalados.';
      case SECURITY_TYPE['TRAIN']:
        return 'Registros en cruce del tren.';
      default:
        return '';
    }
  };

  const ProductivityBreadcrumbs = () => {
    return (
      <Breadcrumb noTrailingSlash>
        <BreadcrumbItem>
          <a href="/">UCO</a>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={SECURITY_TYPE[type] === SECURITY_TYPE['SECURITY-INITIAL']} href={routes.securityInitial}>
          Seguridad
        </BreadcrumbItem>
        {SECURITY_TYPE[type] !== SECURITY_TYPE['SECURITY-INITIAL'] && <BreadcrumbItem isCurrentPage>{TypeTitle(type)}</BreadcrumbItem>}
      </Breadcrumb>
    )
  }
  const typeToComponentMap = {
    [SECURITY_TYPE['SECURITY-INITIAL']]: Security,
    [SECURITY_TYPE['TRUCK-APPROACH']]: TruckApproachContainer,
    [SECURITY_TYPE['VELOCITY-VIOLATION']]: SpeedViolationContainer,
    [SECURITY_TYPE['BRAKE-RETARDER']]: Brakes,
    [SECURITY_TYPE['VELOCITY-HISTORIC']]: SpeedHistoric,
    [SECURITY_TYPE.CAN]: CanHistoric,
    [SECURITY_TYPE.FATIGUE]: Fatigue,
    [SECURITY_TYPE['SENSORS-COUNT']]: SensorsCountContainer,
    [SECURITY_TYPE['TRAIN']]: TrainCrossingContainer
  }
  const ComponentToRender = typeToComponentMap[type];

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout
        navbar={<Navbar managementMenu={<ManagementMenu current={routes.security} />} />}
        header={<Heading title={TypeTitle(type)} icon={<SecurityIcon size={50} />} breadcrumbs={<ProductivityBreadcrumbs />} tooltipContent= {getTooltipContent(type)}/>}
      >
        <AccessControl type={type}>
          {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
        </AccessControl>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default SecurityPage
