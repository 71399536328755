// WaterfallContainer.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { AgCharts } from 'ag-charts-react';
import "ag-charts-enterprise";

/**
 * Componente contenedor para el gráfico de cascada.
 */
const WaterfallContainer = ({ options }) => {
  // Definir las opciones del gráfico con los parámetros recibidos
  const chartOptions = {
    ...options,
  };

  return (
    <div style={{ height: '800px', width: '100%' }}>
      <AgCharts options={chartOptions} />
    </div>
  );
};

// Definir los valores por defecto de las propiedades
WaterfallContainer.defaultProps = {
  options: {
    autoSize: true,
    height: 800,
    background: {
        fill: 'rgb(38, 38, 38)', // Color de fondo para el gráfico
      },
    data: [
      { financials: "Tiempo\nNominal", amount: 12 },
      { financials: "Mantenciones", amount: -1 },
      { financials: "Reservas", amount: -1 },
      { financials: "Perdidas", amount: -1 },
    ],
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "waterfall",
        xKey: "financials",
        xName: "Concepto",
        yKey: "amount",
        yName: "Horas",
        totals: [
          { totalType: 'total', index: 1, axisLabel: 'Tiempo\nDisponible' },
          { totalType: 'total', index: 2, axisLabel: 'Tiempo\nOperativo' },
          { totalType: 'total', index: 3, axisLabel: 'Tiempo\nEfectivo' },
        ],
        direction: 'vertical',
        item: {
          positive: {
            fill: '#4a90e2',
            stroke: '#333333',
          },
          negative: {
            fill: '#e57373',
            stroke: '#333333',
          },
        },
        line: {
          stroke: '#bbbbbb',
          strokeWidth: 1,
        },
      },
    ],
    axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            color: '#ffffff', // Color blanco para el eje x
          },
        },
        {
          type: 'number',
          position: 'left',
          label: {
            color: '#ffffff', // Color blanco para el eje y
          },
        },
      ],
  },
};

// Definir tipos de propiedades con PropTypes
WaterfallContainer.propTypes = {
  height: PropTypes.string,
  options: PropTypes.object,
};

// Exportar el componente
export default WaterfallContainer;
