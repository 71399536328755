import { Stack } from "@carbon/react";
import { CardComponent } from "../../../components/Layouts/Card.styles";
import { HeatMap_02 as HeatMap } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { Iframe } from "./TrainCrossing.styles";

const TrainCrossingContainer = () => {
  return (
    <Stack gap={3}>
      <CardComponent width="100%">
        <Card justifyContent='flex-start' >
          <HeatMap size={18} />
          <Typography className='cds--type-heading-compact-01'>
            CRUCE DEL TREN - TABLA DE REGISTRO
          </Typography>
        </Card>
        <Card>
          <Iframe title="Monitoring map" src="https://grafana.terrestra.tech/d/bc498c0d-144f-4bf9-ac26-a0df8b510301/cruce-tren?orgId=1&var-patente=All&var-fecha_inicio=2024-06-19+00%3A00%3A00&var-fecha_fin=2024-06-21+00%3A00%3A00&var-timezone=4&from=1721831007740&to=1721852607740&kiosk" width="100%" height="100%" />
        </Card>
      </CardComponent>
    </Stack>
  )
};

export default TrainCrossingContainer;