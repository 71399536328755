import React from 'react';
import { AlluvialChart } from "@carbon/charts-react";
import PropTypes from 'prop-types';
import { getValueFromList } from '../../modules/utils/formatters';
import { toolbarOptions } from "./utils";

/**
 * Dict of colors of each node following the figma design
 */
const green = '#6FDC8C'
const red = '#FA4D56'
export const nodeColors = {
  'Tiempo Nominal': green,
  'Tiempo Disponible': green,
  'Tiempo Operativo': green,
  'Tiempo Efectivo': green,
  'Fuera de Servicio': red,
  'Reservas Programadas': red,
  'Reservas No Programadas': red,
  'TMNP': red,
  'TMP': red,
  'Tiempo Carga Combustible': red,
  'Tiempo en Colación': red,
  'Tiempo cambio de Turno': red,
  'Tiempo de Recepción': red,
  'Demoras': red,
  'Perdidas Operacionales': red,
  'Reservas': red,

}

/** Creates a color scale for each node */
function createColorScaleFromNodes(nodes) {
  return nodes.reduce((prev, current) => {
    prev[current.name] = getValueFromList(nodeColors, current.name);
    return prev;
  }, {})
}

/**
 * They Sankey Diagram's container
 */

const Sankey = props => {
  const { title, height, data, node } = props

  // filter empty data values
  const filteredData = data.filter(item => item.value !== 0);
  // filter any empty nodes
  let filteredNodes = node;
  if (filteredData.length !== data.length) {
    // 1. get non-empty nodes
    const nonEmptyNodes = filteredData.reduce((prev, current) => {
      return [...prev, current.source, current.target];
    }, []).filter((v, i, a) => a.indexOf(v) === i);
    // 2. get correct nodes
    filteredNodes = filteredNodes.filter(item => nonEmptyNodes.includes(item.name));
  }

  const state = {
    data: filteredData,
    options: {
      alluvial: {
        nodes: filteredNodes
      },
      color: {
        scale: createColorScaleFromNodes(filteredNodes),
        gradient: {
          enabled: true
        },
      },
      "height": '1000px',
      "width": '95%',
      "title": `${title}`,
      "theme": "g100",
      ...toolbarOptions
    }
  }
  return (
    <AlluvialChart data={state.data} options={state.options} />
  )
}

Sankey.defaultProps = {
  data: [
    {
      source: 'Tipo',
      target: 'TMNP',
      value: 0.5
    },
    {
      source: 'Tipo',
      target: 'TMP',
      value: 0.5
    },
    {
      source: 'Tiempo Nominal',
      target: 'Tiempo Disponible',
      value: 7
    },
    {
      source: 'Tiempo Nominal',
      target: 'Fuera de Servicio',
      value: 1
    },
    {
      source: 'Fuera de Servicio',
      target: 'Tipo',
      value: 1
    },
    {
      source: 'Tiempo Disponible',
      target: 'Reservas Programadas',
      value: 3
    },
    {
      source: 'Tiempo Disponible',
      target: 'Tiempo Operativo',
      value: 4
    },
    {
      source: 'Reservas Programadas',
      target: 'Tiempo Carga Combustible',
      value: 1
    },
    {
      source: 'Reservas Programadas',
      target: 'Tiempo en Colacion',
      value: 1
    },
    {
      source: 'Reservas Programadas',
      target: 'Tiempo cambio de Turno',
      value: 0.5
    },
    {
      source: 'Reservas Programadas',
      target: 'Tiempo de Recepcion',
      value: 0.5
    },
    {
      source: 'Tiempo Operativo',
      target: 'Demoras',
      value: 1
    },
    {
      source: 'Tiempo Operativo',
      target: 'Tiempo Efectivo',
      value: 3
    }
  ],
  node: [
    {
      name: 'Tiempo Nominal',
      category: 'Tiempo Total'
    },
    {
      name: 'Fuera de Servicio',
      category: 'Disponibilidad'
    },
    {
      name: 'Tiempo Disponible',
      category: 'Disponibilidad'
    },
    {
      name: 'Tipo',
      category: 'Operativo'
    },
    {
      name: 'Reservas Programadas',
      category: 'Operativo'
    },
    {
      name: 'Tiempo Operativo',
      category: 'Operativo'
    },
    {
      name: 'TMNP',
      category: 'Final'
    },
    {
      name: 'TMP',
      category: 'Final'
    },
    {
      name: 'Tiempo Carga Combustible',
      category: 'Final'
    },
    {
      name: 'Tiempo en Colacion',
      category: 'Final'
    },
    {
      name: 'Tiempo cambio de Turno',
      category: 'Final'
    },
    {
      name: 'Tiempo de Recepcion',
      category: 'Final'
    },
    {
      name: 'Demoras',
      category: 'Final'
    },
    {
      name: 'Tiempo Efectivo',
      category: 'Final'
    }
  ]
}

Sankey.propTypes = {
  title: PropTypes.string,
  height: PropTypes.string,
}

export default Sankey