import Typography from "../../components/Basics/Typography";
import { Stack } from "@carbon/react";
import { CardComponent } from "../../components/Layouts/Card.styles";
import Card from '../../components/Layouts/Card';
import routes from "../../config/settings/routes";

const CollectTeamData = () => {
    return <CardComponent width="100%">
        <Card justifyContent='flex-start'><Stack gap={5}>
            <Typography className="cds--type-fluid-heading-05">Recopilación de Información para Eventos/Incidentes</Typography>
            <Typography className="cds--type-fluid-heading-03">
                Como recolectar datos de un equipo
            </Typography>
            <Typography className="cds--type-body-compact-02">
                En caso de un evento o incidente la plataforma está equipada para mostrar información relevante sobre el estado de los equipos durante estos eventos. Esta información está disponible en el submódulo de <a className="document-link" href={routes.securityCan}>Dashboard CAN histórico.</a>
                <br />
                <br />
                Pasos a Seguir:<br />
                1. Ingresar al submódulo de <a className="document-link" href={routes.securityCan}>Dashboard CAN histórico</a>.<br />
                2. Seleccionar la marca del Equipo a consultar.<br />
                3. Seleccionar la Patente del Equipo a consultar.<br />
                4. Escribir el rango de Fecha del Evento. <i>El rango de fecha utiliza el formato 0000-00-00 00:00:00 y es necesario poner ambas fechas.</i><br />
                <br />
                Siguiendo estos pasos es posible acceder a información sobre:<br />
                &#x2022; Nivel de Combustible<br />
                &#x2022; Velocidad<br />
                &#x2022; Rpm<br />
                &#x2022; Odómetro<br />
                &#x2022; Horómetro<br />
                &#x2022; Registro de Velocidad<br />
                &#x2022; Presión de frenos de servicio<br />
                &#x2022; Presión neumáticos<br />
                &#x2022; Presión aceite<br />
                &#x2022; Temperatura del motor<br />
                &#x2022; Carga del motor<br />
                &#x2022; Ruta<br />
                <br />
                Consejos<br />
                <br />
                Para revisar en detalle los datos que componen un gráfico o un medidor, es posible presionar la tecla 'v' para ver el gráfico en pantalla completa.<br />
                <br />
                <video width="880px" height="357px" controls autoPlay>
                    <source src="/assets/videos/collect_team_data_1.mp4" type="video/mp4" />
                </video>
                <br />
                Para ver la tabla de datos de un gráfico o medidor es posible presionar la tecla 'i' para ver la data en representación tabular y en formato .csv descargable.<br />
                <br />
                <video width="880px" height="357px" controls autoPlay>
                    <source src="/assets/videos/collect_team_data_2.mp4" type="video/mp4" />
                </video>
                <br />
                Problemas Comunes y Soluciones<br />
                <br />
                Signos de Advertencia sobre los medidores y gráficos.<br />
                Esto se debe a una fecha incorrecta en los campos de 'Fecha_inicio' ó 'Fecha_término'.<br />
                <br />
                <img src="/assets/images/collect_team_data.png" width="880px" height="250px" alt="signos de advertencia" />
            </Typography>
        </Stack>
        </Card>
    </CardComponent>
}

export default CollectTeamData;