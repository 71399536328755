import React from 'react'
import TitleText from '../../../components/Layouts/Title'
import Typography from '../../../components/Basics/Typography'
import CardButton from '../../../components/Basics/CardButton.container'
import { Column, Root } from './Users.styles'
import routes from '../../../config/settings/routes'
import { g100 } from '@carbon/themes';
import { Stack } from '@carbon/react';
import { Separator } from '../../Dashboard/Dashboard.styles'

/**
 * The Records's container
 * 
 * In order to disable a CardButton simply add them the `disabled` prop.
 */
const Users = () => {
  return (
    <>
      <TitleText
        title='Dashboard Usuarios'
        subtitle='Módulo para la revisión del estado de la plataforma, monitoreo de tareas y registro de notificaciones.'
      />
      <Root>
        <Column>
          <Stack gap={3}>
            <Typography className='cds--type-helper-text-02' color={g100.textHelper}>Submódulos</Typography>
            <Separator />
            <CardButton title='Tareas y Recomendaciones' route={routes.usersRecomendations} />
            <CardButton title='Notificaciones' route={routes.notifications} />
          </Stack>
        </Column>
      </Root>
    </>
  )
}

export default Users
