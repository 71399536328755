import styled from 'styled-components';
import { g90, g100 } from '@carbon/themes';
import { gray90, gray80, teal80 } from '@carbon/colors';

export const CardItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  column-gap: 1rem;
`;

export const Separator = styled.hr`
  background: ${props => props.color ?? g100.borderSubtle00};
  border: 0;
  box-sizing: content-box;
  height: 1px;
  margin: ${props => props.margin ?? 0};
  overflow: visible;
  width: 100%;
`;

export const OuterContainer = styled.div`
  margin: 20px 0;
`;

export const ListItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  column-gap: 0.5rem;
  grid-auto-rows: min-content;
`;

export const GridItem = styled.div`
  padding: 20px;
  border: 1px solid ${gray80};
  background: ${gray90};
  align-self: start;
  visibility: ${props => (props.isVisible !== false ? 'visible' : 'hidden')};
  ${props =>
    props.borderTopColor && `border-top: 8px solid ${props.borderTopColor};`}
`;
