import {
  GET_REAL_TIME_TO,
  GET_REAL_TIME_TO_ERROR,
  GET_REAL_TIME_TO_SUCCESS,
} from './TransferToB57.actions';

const realTimeTo = {
  loading: true,
  error: undefined,
  chargeTime: [],
  table: [],
  boxPlot: [],
  chargeMean: [],
};

/**
 * The real time To B57 reducer.
 */
const realTimeToB57Reducer = (state = realTimeTo, { payload, type }) => {
  switch (type) {
    case GET_REAL_TIME_TO: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_REAL_TIME_TO_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case GET_REAL_TIME_TO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        chargeTime: payload.chargeTime,
        table: payload.table,
        boxPlot: payload.boxPlot,
        chargeMean: payload.chargeMean,
      };
    }
    default: {
      return state;
    }
  }
};

export default realTimeToB57Reducer;
