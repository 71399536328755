import React, { useEffect } from "react";
import { PopupContainer, CloseIcon, Header, SubHeader, ItemsContainer } from "./NotificationHolder.styles";

const NotificationHolder = ({ isOpen, onClose, header, subheader, children, animate }) => {
	return (
		<PopupContainer isOpen={isOpen} animate={animate}>
			<Header>
				<>
					{header}
					<div>
						<CloseIcon onClick={onClose} />
					</div>
				</>
			</Header>
			<SubHeader>{subheader}</SubHeader>
			<ItemsContainer>
				{children}
			</ItemsContainer>
		</PopupContainer>
	);
};

export default NotificationHolder