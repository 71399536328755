import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    DataTable, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
    TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
    TableToolbarSearch
} from '@carbon/react';
import { Filter } from '@carbon/icons-react'
import DailyReportModal from "./DailyReportModal.container";
import { onGetTruckModalThunkV2 } from "./DailyReport.actions";
import { useSorting } from "../../../modules/hooks/sorting";

const DailyReportTable = _ => {
    const dispatch = useDispatch()
    const button = useRef();
    const [open, setOpen] = useState(false);
    const { trucks, currDate } = useSelector(state => state.dailyTurn)
    const handleToggleModal = currTruck => () => {
        dispatch(onGetTruckModalThunkV2(currDate, currTruck, _.currentTurn))
        setOpen(true)
    }

    const headerData = [
        {
            header: 'Camión',
            key: 'truckPatent',
        },
        {
            header: 'Id dispositivo',
            key: 'device_id',
        },
        {
            header: 'Ciclos',
            key: 'ciclo',
            sortDirection: "DESC",
        },
        {
            header: 'Volumen Transportado (m3)',
            key: 'm3',
            sortDirection: "DESC",
        },
        {
            header: 'Tiempo efectivo (hrs)',
            key: 'horas_productivas',
            sortDirection: "DESC",
            isDefaultSortable: true
        }
    ];
    const { rows, headers } = useSorting(trucks, headerData);

    const Default = () => <DataTable rows={rows} headers={headers} width="100%">
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getExpandedRowProps,
            getTableProps,
            getTableContainerProps,
            onInputChange,
            sortBy
        }) => <TableContainer {...getTableContainerProps()}>
                <TableToolbar>
                    <TableToolbarContent>
                        {/* pass in `onInputChange` change here to make filtering work */}
                        <TableToolbarSearch onChange={onInputChange} />
                        <Button renderIcon={Filter} kind="secondary" hasIconOnly iconDescription="Filter table" />
                    </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            <TableExpandHeader aria-label="expand row" />
                            {headers.map((header, i) => <TableHeader
                                key={i}
                                sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                                {...getHeaderProps({
                                    header,
                                    isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                                    onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                                })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <React.Fragment key={row.id}>
                            <TableExpandRow {...getRowProps({
                                row
                            })}>
                                {row.cells.map(cell => <TableCell key={cell.id}>
                                    {cell.value}
                                </TableCell>)}
                            </TableExpandRow>
                            <TableExpandedRow colSpan={headers.length + 1} className="demo-expanded-td" {...getExpandedRowProps({
                                row
                            })}>
                                <Button onClick={handleToggleModal(row.cells[1].value)}>Ver Reporte Ciclos de Transporte</Button>
                            </TableExpandedRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable>;

    return (
        <>
            <Default />
            <DailyReportModal button={button} open={open} setOpen={setOpen} />
        </>

    )
}

DailyReportTable.defaultProps = {
    rows: Array(20).fill().map((_, index) => ({
        id: `${index}`,
        geofence_id: 'id_geocerca',
        truck_id: 'id_camión',
        driver_id: 'id_conductor',
        turns_completed: '000',
        load_transported: '000 m3',
        distance_traveled: '000 km',
        work_hours: '000 horas',
    })),
    currentTurn: String()
}

export default DailyReportTable;