import { CLEAN_DNI } from '../../config/settings/regexes';

/**
 * To clean a value that is formatted as a Chilean DNI.
 * Returns the given value without points and hyphen.
 */
export const cleanDNI = value => {
  return typeof value === 'string'
    ? value.replace(CLEAN_DNI, '').toUpperCase()
    : '';
};

export const getTimeDifference = (timestamp, endDate = new Date()) => {
  // Remove 'Z' from the timestamp if it's present to ensure it's treated as local time
  const localTimestamp = timestamp.replace('Z', '');

  // Create Date objects for both timestamps
  const then = new Date(localTimestamp);
  const now = new Date(endDate);

  // Calculate the difference in milliseconds
  const diff = Math.abs(now - then);

  // Convert the difference to hours and minutes
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff / (1000 * 60)) % 60);

  return `${hours} horas y ${minutes} minutos`;
};

/**
 * To check if a DNI corresponds to a natural person or not.
 * This implementation is momentary.
 */
export const isNaturalPerson = value => {
  value = cleanDNI(value?.toString());
  const dni = value.substring(0, value.length - 1);

  return parseInt(dni, 10) < 50000000;
};

/**
 * To create a blob object from the given data
 */
export const generateBlob = (data, mime) => {
  return new Blob([data], { type: mime || 'application/octet-stream' });
};

/**
 * To download a file.
 */
export const fileDownload = (blob, filename) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

/**
 * To capitalize an string.
 */
export const capitalize = value =>
  value && value[0].toUpperCase() + value.slice(1);

/**
 * To capitalize every string in a word after a whitespace, useful for names with surnames.
 */
export const capitalizeNames = value =>
  value.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
