import { makeActionCreator } from '../../../config/store/utils';
import { getRealTime } from '../../../services/monitoringService';
import { red50, green40, yellow30, gray60 } from '@carbon/colors';
import { capitalizeNames } from '../../../modules/utils/helpers';
import { truckList, getValueFromList } from '../../../modules/utils/formatters';

// Get yesterday's date
const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString();
};

const getColor = (item, beforeItem) => {
  if (beforeItem) {
    if (item.value > beforeItem.value) {
      return green40; // Higher value
    } else if (item.value < beforeItem.value) {
      return red50; // Lower value
    } else {
      return yellow30; // Same value
    }
  } else {
    return gray60; // Default color if no corresponding item is found
  }
};

const getCurrentShift = () => {
  const now = new Date();
  const hours = now.getHours();

  // Turn A is from 08:00 to 19:59
  if (hours >= 8 && hours < 20) {
    return 'TURNO DIA';
  }

  // Turn B is from 20:00 to 07:59
  return 'TURNO NOCHE';
};

export const GET_REAL_TIME = 'GET_REAL_TIME';
export const GET_REAL_TIME_ERROR = 'GET_REAL_TIME_ERROR';
export const GET_REAL_TIME_SUCCESS = 'GET_REAL_TIME_SUCCESS';
export const onGetRealTime = makeActionCreator(GET_REAL_TIME);
export const onGetRealTimeError = makeActionCreator(
  GET_REAL_TIME_ERROR,
  'payload'
);
export const onGetRealTimeSuccess = makeActionCreator(
  GET_REAL_TIME_SUCCESS,
  'payload'
);
export const onGetRealTimeThunk = () => async dispatch => {
  dispatch(onGetRealTime());
  try {
    const { data } = await getRealTime();
    const currentTurn = data.current_turn.tablas;
    const lastTrucks = JSON.parse(data.last_trucks);

    const m3Production = currentTurn.producción_m3.filter(
      entry => entry.value !== 0
    );
    const dailyProduction = currentTurn.produccion_total_m3.value;
    const productionPerHour = currentTurn.produccion_por_hora.value;
    const unloadsPerHour = currentTurn.descargas_por_hora.value.map(obj => {
      return {
        group: 'Turno Actual',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });

    const currShift = getCurrentShift();
    const _loadTimes = currentTurn.tiempo_cargas;

    const loadTimes = _loadTimes
      .filter(item => item.group === currShift)
      .map(item => ({
        ...item,
        group: 'TURNO ACTUAL',
        value: item.value,
        key: item.key,
      }));

    const queueTimes = currentTurn.tiempos_cola.filter(
      entry => entry.value !== 0
    );
    const totalTrucks = currentTurn.cantidad_camiones.value;

    const heatmapHour = currentTurn.heatmap_m3_hora
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            currentTurn.heatmap_m3_hora
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? yellow30 : '#292929',
        };
      });

    const cycleTime = currentTurn.heatmap_tiempos_ciclo
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            currentTurn.heatmap_tiempos_ciclo
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? yellow30 : '#292929',
        };
      });

    const avgSpeed = currentTurn.heatmap_velocidad_promedio
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            currentTurn.heatmap_velocidad_promedio
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? yellow30 : '#292929',
        };
      });

    const cycleCount = currentTurn.heatmap_conteo_ciclos
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            currentTurn.heatmap_conteo_ciclos
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? yellow30 : '#292929',
        };
      });
    /*
    const cycleCountTotal = currentTurn.heatmap_conteo_ciclos_total
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            currentTurn.heatmap_conteo_ciclos_total
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const color = Math.round(item.value);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? yellow30 : '#292929',
        };
      });
    */
    // Camiones
    const mappingDay = {
      tolva: 'cantidad_camiones_tolva_turno_dia',
      retro: 'cantidad_excavadora_turno_dia',
      aljibe: 'cantidad_aljibe_turno_dia',
      bulldozer: 'cantidad_bulldozer_turno_dia',
      bus: 'cantidad_bus_turno_dia',
      fuel: 'cantidad_camiones_combustible_turno_dia',
      rodillo: 'cantidad_rodillo_turno_dia',
    };

    const mappingNight = {
      tolva: 'cantidad_camiones_tolva_turno_noche',
      retro: 'cantidad_excavadora_turno_noche',
      aljibe: 'cantidad_aljibe_turno_noche',
      bulldozer: 'cantidad_bulldozer_turno_noche',
      bus: 'cantidad_bus_turno_noche',
      fuel: 'cantidad_camiones_combustible_turno_noche',
      rodillo: 'cantidad_rodillo_turno_noche',
    };

    const initializeCount = () => ({
      tolva: 0,
      retro: 0,
      aljibe: 0,
      bulldozer: 0,
      bus: 0,
      fuel: 0,
      pluma: 0,
      rodillo: 0,
      front: 0,
      level: 0,
      dig: 0,
      minibus: 0,
    });

    const mapData = (data, mapping) => {
      const result = initializeCount();
      data.forEach(item => {
        for (let key in mapping) {
          if (mapping[key] === item.group) {
            result[key] = item.value;
          }
        }
      });
      return result;
    };

    // Process day data
    const trucksCountDay = mapData(currentTurn.cantidad_camiones, mappingDay);

    // Process night data
    const trucksCountNight = mapData(
      currentTurn.cantidad_camiones,
      mappingNight
    );

    const textToKeyMap = {
      'Camión Tolva': 'tolva',
      'Camión Aljibe': 'aljibe',
      'Camión Combustible': 'fuel',
      'Camión Pluma': 'pluma',
      Rodillo: 'rodillo',
      'Cargadora Frontal': 'front',
      Retroexcavadora: 'retro',
      Motoniveladora: 'level',
      Excavadora: 'dig',
      Bulldozer: 'bulldozer',
      Bus: 'bus',
      'Mini Bus': 'minibus',
    };

    const sumValues = countObject => {
      return Object.values(countObject).reduce((sum, value) => sum + value, 0);
    };

    const numberTrucks = sumValues(trucksCountDay);

    const numberTrucksNight = sumValues(trucksCountNight);

    const numberOfTrucksToReturn =
      currShift === 'TURNO DIA' ? numberTrucks : numberTrucksNight;
    const trucksTurn =
      currShift === 'TURNO DIA' ? trucksCountDay : trucksCountNight;

    return dispatch(
      onGetRealTimeSuccess({
        m3Production: m3Production,
        dailyProduction: dailyProduction,
        loadTimes: loadTimes,
        queueTimes: queueTimes,
        cycleCount: cycleCount,
        avgSpeed: avgSpeed,
        cycleTime: cycleTime,
        heatmapHour: heatmapHour,
        totalTrucks: totalTrucks,
        unloadsPerHour: unloadsPerHour,
        productionPerHour: productionPerHour,
        numberOfTrucksToReturn: numberOfTrucksToReturn,
        trucksTurn: trucksTurn,
        textToKeyMap: textToKeyMap,
        lastTrucks: lastTrucks,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetRealTimeError({
        error: error,
      })
    );
  }
};
