export const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_BASE_URL,
  REACT_APP_CLIENT_NAME,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_IDENTITY_POOL_ID,
  REACT_APP_API_BASE_URL,
  REACT_APP_BASE_NAME,
  REACT_APP_BASE_SUB_PATH,
  REACT_APP_BASE_KEY,
  REACT_APP_WEBSOCKET,
  REACT_APP_UPLOAD_BUCKET,
} = process.env;

export const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod';
