import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from "@carbon/react";
import { CardComponent, CardRow } from "../../../components/Layouts/Card.styles";
import { BoxPlot as BoxPlotIcon, ChartBarStacked, CheckmarkFilled, DataTable, SummaryKpi, WarningAltFilled, WarningFilled } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import InfoCard from "../../../components/Basics/InfoCard.container";
import Sparkline from "../../../components/Graphs/Sparkline.container";
import BoxPlotContainer from "../../../components/Graphs/BoxPlot.container";
import Card from '../../../components/Layouts/Card';
import TransferToB57Table from "./TransferToB57Table.container";
import { Div } from "../../../components/Basics/Div.styles";
import { spacing05 } from "@carbon/themes";
import { onGetRealTimeReportsThunk } from "./TransferToB57.actions";
import LoadingContent from '../../../components/Animations/LoadingContent'
import FlexHeatMap from "../../../components/Graphs/FlexHeatMap.container";

const TransferToB57 = () => {
    const dispatch = useDispatch()
    const { error, loading, chargeTime, chargeMean, boxPlot } = useSelector(state => state.ToB57)
    // TODO: Replace with real data.
    const { cycleTime: DummyHeatMapData } = useSelector(state => state.daily)
    useEffect(() => {
        if (!loading) {
            return
        }
        dispatch(onGetRealTimeReportsThunk())
    }, [])

    const sparklineYTitle = 'Tiempos Traslado a B57'
    return (
        <>
            {loading && !error && (
                <>
                    <LoadingContent />
                </>
            )}
            {!loading && error && (
                <>
                    <CardComponent width="10%">
                        <Typography className='cds--type-heading-compact-01'>
                            Error al buscar data tiempo real. Estamos solucionado el problema
                        </Typography>
                    </CardComponent>
                </>
            )}
            {!loading && !error && (
                <Stack gap={3}>
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <SummaryKpi size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                TIEMPOS DE TRASLADO A B57 - TENDENCIA
                            </Typography>
                        </Card>
                        <Div flexWrap="wrap" display="flex" justifyContent="space-between" gap="0.5rem">
                            {chargeTime.map((item, index) => {
                                console.log(item.value, item.group)
                                return (
                                    <InfoCard
                                        key={index}
                                        icon={<CheckmarkFilled className='success' size={33} />}
                                        title={item.group}
                                        body={`${chargeMean.filter(_ => _.key == item.group)[0].value} min`}
                                        subtitle='Tiempo de Traslado a B57 Promedio'
                                        graphTitle='Tiempos de Traslado a B57 - Sparkline'
                                        graph={<Sparkline data={item.value} yAxisTitle={sparklineYTitle} id={index} />}
                                    />
                                );
                            })}
                        </Div>
                    </CardComponent >
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <BoxPlotIcon size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                TIEMPOS DE TRASLADO A B57 - COMPARATIVO
                            </Typography>
                        </Card>
                        <CardRow width='inherit'>
                            <Card flexDirection='column' justifyContent='center' width='100%' padding={spacing05}>
                                <BoxPlotContainer height='40vh' title="Tiempos de Traslado en Minutos" xAxisTitle="Frente de Origen" yAxisTitle="Minutos" data={boxPlot} />
                            </Card>
                        </CardRow>
                    </CardComponent>
                    {/* waiting for heat map data
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <ChartBarStacked size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                RUTAS DE TRANSPORTE
                            </Typography>
                        </Card>
                        <CardRow width="inherit">
                            <Card flexDirection='column' justifyContent='center' width="100%" padding={spacing05}>
                                <FlexHeatMap data={DummyHeatMapData} title='Tiempos de Traslado (media, horas)' />
                            </Card>
                        </CardRow>
                    </CardComponent>
                    */}
                    <CardComponent width="100%">
                        <Card justifyContent='flex-start' >
                            <DataTable size={18} />
                            <Typography className='cds--type-heading-compact-01'>
                                TIEMPOS DE TRASLADO A B57 - TABLA DE DATOS
                            </Typography>
                        </Card>
                        <CardRow width='inherit'>
                            <Card width='100%' padding="0">
                                <TransferToB57Table />
                            </Card>
                        </CardRow>
                    </CardComponent>
                </Stack >
            )}
        </>
    )
};

export default TransferToB57;