import { makeActionCreator } from '../../../config/store/utils';
import {
  getDailyReport,
  getDailyTruckInfo,
} from '../../../services/productivityService';
import { red50, green40, yellow30, gray60 } from '@carbon/colors';
import { capitalizeNames } from '../../../modules/utils/helpers';
import { truckList, getValueFromList } from '../../../modules/utils/formatters';

// Get yesterday's date
const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString();
};

const formatKeyToDayNightAppointment = input => {
  // Helper function to normalize text (lowercase, remove accents)
  const normalize = str =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  return input.map(obj => ({
    ...obj,
    key: obj.key.toLowerCase().includes('turno noche')
      ? 'Turno Noche'
      : normalize(obj.key)
          .toLowerCase()
          .includes('turno dia')
      ? 'Turno Día'
      : obj.key,
  }));
};

const formatKeyToDayNightAppointmentGroup = input => {
  // Helper function to normalize text (lowercase, remove accents)
  const normalize = str =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

  return input.map(obj => ({
    ...obj,
    group: obj.group.toLowerCase().includes('turno noche')
      ? 'Turno Noche'
      : normalize(obj.group)
          .toLowerCase()
          .includes('turno dia')
      ? 'Turno Día'
      : obj.group,
  }));
};

const getColor = (item, beforeItem) => {
  if (beforeItem) {
    if (item.value > beforeItem.value) {
      return green40; // Higher value
    } else if (item.value < beforeItem.value) {
      return red50; // Lower value
    } else {
      return yellow30; // Same value
    }
  } else {
    return gray60; // Default color if no corresponding item is found
  }
};

const transformKeys = data => {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data.map(item => {
    const transformedValue = Object.keys(item.value || {}).reduce(
      (acc, key) => {
        const transformedKey = key
          .replace(/_/g, ' ')
          .replace(/\b\w/g, char => char.toUpperCase());
        acc[transformedKey] = item.value[key] || 0; // Default to 0 if value is missing or undefined
        return acc;
      },
      {}
    );

    return {
      ...item,
      value: transformedValue,
    };
  });
};

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const onGetReport = makeActionCreator(GET_REPORT);
export const onGetReportError = makeActionCreator(GET_REPORT_ERROR, 'payload');
export const onGetReportSuccess = makeActionCreator(
  GET_REPORT_SUCCESS,
  'payload'
);
export const onGetReportThunk = date => async dispatch => {
  dispatch(onGetReport());
  try {
    let endpointParams = {};
    if (date) {
      endpointParams = {
        day: date[0].getDate(),
        month: date[0].getMonth() + 1,
        year: date[0].getFullYear(),
      };
    }
    const { data } = await getDailyReport(endpointParams);
    const yesterday = data.yesterday.tablas;
    const before = data.before?.tablas || {};
    const url = data.report_url;
    let tableCounter = 0;
    let cycleTable = [];

    if (
      data.table &&
      typeof data.table === 'string' &&
      data.table.trim() !== '[]'
    ) {
      try {
        cycleTable = JSON.parse(data.table).map(item => {
          return { ...item, id: `${tableCounter++}` };
        });
      } catch (error) {
        console.error('Error parsing yesterday.table:', error);
      }
    } else {
      console.warn('yesterday.table is empty or not a valid JSON string');
    }

    const _transformedData = [];
    const loadedTrucks = transformKeys(before.camiones_cargados_fin_turno);
    loadedTrucks.forEach(item => {
      Object.entries(item.value).forEach(([key, value]) => {
        _transformedData.push({
          group: item.group.toUpperCase(),
          key: key,
          value: value,
        });
      });
    });
    const transformedData = formatKeyToDayNightAppointmentGroup(
      _transformedData
    );
    const _m3Production = yesterday.producción_m3.filter(
      entry => entry.value !== 0
    );
    const m3Production = formatKeyToDayNightAppointmentGroup(_m3Production);
    const dailyProduction = yesterday.produccion_total_m3.value;
    const dailyProductionBefore = before?.produccion_total_m3?.value || 0;
    const cycleCountYesterday = yesterday.heatmap_conteo_ciclos;
    const cycleCountBefore = before?.heatmap_conteo_ciclos || [];
    const cycleCount = cycleCountYesterday
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            cycleCountYesterday
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = cycleCountBefore.find(
          item2 => item2.group === item.group && item2.key === item.key
        );
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });
    const prodHoursyesterday = yesterday.horas_productivas_sin_vel_0.map(
      obj => ({
        ...obj,
        group: 'Día del reporte',
      })
    );
    const prodHoursBefore =
      before?.horas_productivas_sin_vel_0?.map(obj => ({
        ...obj,
        group: 'Día anterior',
      })) ?? [];
    const prodHours = formatKeyToDayNightAppointment([
      ...prodHoursyesterday,
      ...prodHoursBefore,
    ]);
    const prodHoursIdleYesterday = yesterday.horas_vel_0.map(obj => ({
      ...obj,
      group: 'Día del reporte',
    }));
    const prodHoursIdleBefore =
      before?.horas_vel_0?.map(obj => ({
        ...obj,
        group: 'Día anterior',
      })) ?? [];
    const prodHoursIdle = formatKeyToDayNightAppointment([
      ...prodHoursIdleYesterday,
      ...prodHoursIdleBefore,
    ]);

    const mappingDay = {
      tolva: 'cantidad_camiones_tolva_turno_dia',
      retro: 'cantidad_excavadora_turno_dia',
      aljibe: 'cantidad_aljibe_turno_dia',
      bulldozer: 'cantidad_bulldozer_turno_dia',
      bus: 'cantidad_bus_turno_dia',
      fuel: 'cantidad_camiones_combustible_turno_dia',
      rodillo: 'cantidad_rodillo_turno_dia',
    };

    const mappingNight = {
      tolva: 'cantidad_camiones_tolva_turno_noche',
      retro: 'cantidad_excavadora_turno_noche',
      aljibe: 'cantidad_aljibe_turno_noche',
      bulldozer: 'cantidad_bulldozer_turno_noche',
      bus: 'cantidad_bus_turno_noche',
      fuel: 'cantidad_camiones_combustible_turno_noche',
      rodillo: 'cantidad_rodillo_turno_noche',
    };

    const initializeCount = () => ({
      tolva: 0,
      retro: 0,
      aljibe: 0,
      bulldozer: 0,
      bus: 0,
      fuel: 0,
      pluma: 0,
      rodillo: 0,
      front: 0,
      level: 0,
      dig: 0,
      minibus: 0,
    });

    const mapData = (data, mapping) => {
      const result = initializeCount();
      data.forEach(item => {
        for (let key in mapping) {
          if (mapping[key] === item.group) {
            result[key] = item.value;
          }
        }
      });
      return result;
    };

    // Process day data
    const trucksCountDay = mapData(yesterday.cantidad_camiones, mappingDay);
    const trucksCountDayBefore = mapData(before.cantidad_camiones, mappingDay);

    // Process night data
    const trucksCountNight = mapData(yesterday.cantidad_camiones, mappingNight);
    const trucksCountNightBefore = mapData(
      before.cantidad_camiones,
      mappingNight
    );

    const textToKeyMap = {
      'Camión Tolva': 'tolva',
      'Camión Aljibe': 'aljibe',
      'Camión Combustible': 'fuel',
      'Camión Pluma': 'pluma',
      Rodillo: 'rodillo',
      'Cargadora Frontal': 'front',
      Retroexcavadora: 'retro',
      Motoniveladora: 'level',
      Excavadora: 'dig',
      Bulldozer: 'bulldozer',
      Bus: 'bus',
      'Mini Bus': 'minibus',
    };

    const sumValues = countObject => {
      return Object.values(countObject).reduce((sum, value) => sum + value, 0);
    };

    const numberTrucks = sumValues(trucksCountDay);
    const numberTrucksBefore = sumValues(trucksCountDayBefore) || 0;

    const numberTrucksNight = sumValues(trucksCountNight);
    const numberTrucksNightBefore = sumValues(trucksCountNightBefore) || 0;

    const _discharge = yesterday.cantidad_descargas.filter(
      item => item.value !== 0
    );
    const discharge = formatKeyToDayNightAppointmentGroup(_discharge);
    const _wallTime = yesterday.tiempo_en_muros.filter(
      item => item.value !== 0
    );
    const wallTime = formatKeyToDayNightAppointmentGroup(_wallTime);
    const avgSpeedYesterday = yesterday.heatmap_velocidad_promedio;
    const avgSpeedBefore = before?.heatmap_velocidad_promedio || [];
    const avgSpeed = avgSpeedYesterday
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            avgSpeedYesterday
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = avgSpeedBefore.find(
          item2 => item2.group === item.group && item2.key === item.key
        );
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });
    const goodStart = yesterday.inicio_efectivo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const ending = yesterday.fin_efectivo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const startCycle = yesterday.inicio_primero_ciclo.value.map(obj => {
      return {
        group: 7 < obj && obj < 20 ? 'Turno Día' : 'Turno Noche',
        value: obj >= 0 && obj <= 7 ? obj + 24 : obj,
      };
    });
    const breakFastDay = yesterday.tiempos_colacion[0].value.map(obj => {
      return { group: yesterday.tiempos_colacion[0].group, value: obj };
    });
    const breakFastNight = yesterday.tiempos_colacion[1].value.map(obj => {
      return { group: yesterday.tiempos_colacion[1].group, value: obj };
    });
    const breakFast = [...breakFastDay, ...breakFastNight];
    let _counter = 0;
    const trucks = JSON.parse(yesterday.tabla_camiones).map((item, index) => {
      return { ...item, id: `${_counter++}` };
    });
    const _loadCount = yesterday.cantidad_cargas;
    const loadCount = formatKeyToDayNightAppointmentGroup(_loadCount);
    const _loadTime = yesterday.tiempo_cargas;
    const loadTime = formatKeyToDayNightAppointmentGroup(_loadTime);
    const meanCyclePerTurnYesterday = yesterday.ciclos_por_turno_promedio.map(
      obj => ({ ...obj, group: 'Día del reporte' })
    );
    const meanCyclePerTurnBefore =
      before?.ciclos_por_turno_promedio?.map(obj => ({
        ...obj,
        group: 'Día anterior',
      })) ?? [];
    const meanCyclePerTurn = [
      ...meanCyclePerTurnYesterday,
      ...meanCyclePerTurnBefore,
    ];
    const dailyProductionMeanTurn = yesterday.daily_production_turnos_promedio;
    const _queueTimes = yesterday.tiempos_cola.filter(
      entry => entry.value !== 0
    );
    const queueTimes = formatKeyToDayNightAppointmentGroup(_queueTimes);
    const heatmapHourYesterday = yesterday.heatmap_m3_hora;
    const heatmapHourBefore = before?.heatmap_m3_hora || [];
    const heatmapHour = heatmapHourYesterday
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            avgSpeedYesterday
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = heatmapHourBefore.find(
          item2 => item2.group === item.group && item2.key === item.key
        );
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);
        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });
    const _cycleTime = yesterday.heatmap_tiempos_ciclo;
    const _cycleTimeBefore = before?.heatmap_tiempos_ciclo || [];
    const cycleTime = _cycleTime
      .filter(obj => {
        if (obj.value >= 1) {
          // Non-zero value, keep the object
          return true;
        } else {
          // Zero value, check if there's another non-zero value with the same key
          const key = obj.key;
          const sameKeys =
            _cycleTime
              .filter(_ => _.key === key)
              .filter(_inclusion => _inclusion.value >= 1).length > 0;
          return sameKeys;
        }
      })
      .map(item => {
        const beforeItem = _cycleTimeBefore.find(
          item2 => item2.group === item.group && item2.key === item.key
        );
        const color = Math.round(item.value);
        const colorScale = getColor(item, beforeItem);

        return {
          x: item.key,
          y: item.group,
          color: color,
          // Overwrite color values for zero cycle time.
          colorScale: color ? colorScale : '#292929',
        };
      });
    const m3MovedYesterday = yesterday.media_produccion_por_turno.map(obj => ({
      ...obj,
      group: 'Día del reporte',
    }));
    const m3MovedBefore =
      before?.media_produccion_por_turno?.map(obj => ({
        ...obj,
        group: 'Día anterior',
      })) ?? [];
    const m3Moved = formatKeyToDayNightAppointment([
      ...m3MovedYesterday,
      ...m3MovedBefore,
    ]);

    const distanceTraveledY = yesterday.distancia_recorrida_por_turno.map(
      obj => ({
        ...obj,
        key: capitalizeNames(obj.key),
        group: 'Día del reporte',
      })
    );
    const distanceTraveledB =
      before?.distancia_recorrida_por_turno?.map(obj => ({
        ...obj,
        key: capitalizeNames(obj.key),
        group: 'Día anterior',
      })) ?? [];
    const distanceTraveled = [...distanceTraveledY, ...distanceTraveledB];

    const maintenanceY = yesterday.tiempo_mantencion_por_turno.map(obj => ({
      ...obj,
      key: capitalizeNames(obj.key),
      group: 'Día del reporte',
    }));
    const maintenanceB =
      before?.tiempo_mantencion_por_turno?.map(obj => ({
        ...obj,
        key: capitalizeNames(obj.key),
        group: 'Día anterior',
      })) ?? [];
    const maintenance = [...maintenanceY, ...maintenanceB];

    const currDate = date ? date : getYesterdayDate();

    return dispatch(
      onGetReportSuccess({
        m3Production: m3Production,
        dailyProduction: dailyProduction,
        dailyProductionBefore: dailyProductionBefore,
        cycleCount: cycleCount,
        cycleCountBefore: cycleCountBefore,
        prodHours: prodHours,
        prodHoursIdle: prodHoursIdle,
        numberTrucks: numberTrucks,
        numberTrucksBefore: numberTrucksBefore,
        numberTrucksNight: numberTrucksNight,
        numberTrucksNightBefore: numberTrucksNightBefore,
        discharge: discharge,
        wallTime: wallTime,
        avgSpeed: avgSpeed,
        goodStart: goodStart,
        ending: ending,
        startCycle: startCycle,
        breakFast: breakFast,
        trucks: trucks,
        loadCount: loadCount,
        loadTime: loadTime,
        meanCyclePerTurn: meanCyclePerTurn,
        dailyProductionMeanTurn: dailyProductionMeanTurn,
        queueTimes: queueTimes,
        heatmapHour: heatmapHour,
        cycleTime: cycleTime,
        m3Moved: m3Moved,
        distanceTraveled: distanceTraveled,
        maintenance: maintenance,
        url: url,
        currDate: currDate,
        trucksDay: trucksCountDay,
        trucksDayBefore: trucksCountDayBefore,
        trucksNight: trucksCountNight,
        trucksNightBefore: trucksCountNightBefore,
        truckMapping: textToKeyMap,
        transformedData: transformedData,
        cycleTable: cycleTable,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetReportError({
        error: error,
      })
    );
  }
};

export const GET_TRUCK_MODAL = 'GET_TRUCK_MODAL';
export const GET_TRUCK_MODAL_ERROR = 'GET_TRUCK_MODAL_ERROR';
export const GET_TRUCK_MODAL_SUCCESS = 'GET_TRUCK_MODAL_SUCCESS';
export const onGetTruckModal = makeActionCreator(GET_TRUCK_MODAL);
export const onGetTruckModalError = makeActionCreator(
  GET_TRUCK_MODAL_ERROR,
  'payload'
);
export const onGetTruckModalSuccess = makeActionCreator(
  GET_TRUCK_MODAL_SUCCESS,
  'payload'
);
export const onGetTruckModalThunk = (date, truck) => async dispatch => {
  dispatch(onGetTruckModal());
  try {
    let endpointParams = {};
    if (date) {
      const _date = new Date(date);
      endpointParams = {
        day: _date.getDate(),
        month: _date.getMonth() + 1,
        year: _date.getFullYear(),
      };
    } else {
      const today = new Date();
      const yesterday = new Date(today);

      date = new Date(yesterday.setDate(yesterday.getDate() - 1));
    }
    if (truck) {
      endpointParams['truck'] = truck;
    }

    const { data } = await getDailyTruckInfo(endpointParams);
    let _id = 0;
    const modalData = JSON.parse(data.data).map((item, index) => {
      return { ...item, id: `${_id++}` };
    });
    return dispatch(
      onGetTruckModalSuccess({
        currTruck: getValueFromList(truckList, truck),
        modalData: modalData,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetTruckModalError({
        error: error,
      })
    );
  }
};
