import styled from 'styled-components';
import { FilterableMultiSelect, MultiSelect } from '@carbon/react';

// Create a styled DatePickerInput
export const MultiSelectFilter = styled(FilterableMultiSelect)`
  width: 100%;
  .cds--multi-select--filterable .cds--text-input.cds--text-input--empty {
    background-color: #262626 !important;
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
  }
`;

// Create a styled DatePickerInput
export const MultiSelectStyled = styled(MultiSelect)`
  width: 100%;
  .cds--list-box__field {
    background-color: #262626 !important;
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
  }
`;
