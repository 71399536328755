import {
  GET_ASARCO,
  GET_ASARCO_ERROR,
  GET_ASARCO_SUCCESS,
} from './Asarco.actions';

const INITIAL_TRUCK_VALUE = { id: 'Total', text: 'Total' };

// Obtener la fecha de ayer como valor inicial
const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString();
};

const dailyAsarcoState = {
  asarco: [],
  truck: INITIAL_TRUCK_VALUE,
  truckList: [],
  date: getYesterdayDate(),
  shift: 'dia',  // Estado inicial para el turno
  error: undefined,
  loading: true,
};

/**
 * Reducer del reporte diario de Asarco
 */
const dailyAsarcoReducer = (state = dailyAsarcoState, { payload, type }) => {
  switch (type) {
    case GET_ASARCO_ERROR: {
      return { ...state, error: payload.error, loading: false };
    }
    case GET_ASARCO: {
      return { ...state, loading: true, error: undefined };
    }
    case GET_ASARCO_SUCCESS: {
      // Ordenar la lista de camiones para mostrar "Total" primero
      const truckList = payload.truckList.filter(
        truck => truck.id !== INITIAL_TRUCK_VALUE.id
      );
      truckList.unshift(INITIAL_TRUCK_VALUE);

      return {
        ...state,
        asarco: payload.asarco,    // `asarco` ahora es un array con los datos del gráfico
        truckList,
        truck: payload.truck,
        date: payload.date,
        shift: payload.shift,      // Guardar el valor del turno en el estado
        loading: false,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default dailyAsarcoReducer;
