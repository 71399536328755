import React from "react";
import { useSelector } from 'react-redux'
import {
  Button,
  DataTable, Table, TableBody, TableCell, TableContainer,
  TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
  TableToolbarSearch,
} from '@carbon/react';
import { Filter } from '@carbon/icons-react';
import { useSorting } from "../../../modules/hooks/sorting";

const DischargeTimeTable = _ => {
  const { table } = useSelector(state => state.discharge)

  const headerData = [
    {
      header: 'Punto de Carguío',
      key: 'lugar_carga',
    },
    {
      header: 'Punto de Descarga',
      key: 'lugar_descarga',
    },
    {
      header: 'Fecha / Hora Término',
      key: 'event_time_max',
      sortDirection: "DESC",
      isDefaultSortable: true
    },
    {
      header: 'Tiempo en Frente de Descarga (minutos)',
      key: 'time_diff_sum',
      sortDirection: "DESC",
    },
    {
      header: 'Tiempos en Colas (segundos)',
      key: 'tiempo_colas',
      sortDirection: "DESC",
    }
  ];
  const { rows, headers } = useSorting(table, headerData);

  const Default = () => <DataTable rows={rows} headers={headers} width="100%">
    {({
      rows,
      headers,
      getHeaderProps,
      getRowProps,
      getTableProps,
      getTableContainerProps,
      onInputChange,
      sortBy
    }) => <TableContainer {...getTableContainerProps()}>
        <TableToolbar>
          <TableToolbarContent>
            {/* pass in `onInputChange` change here to make filtering work */}
            <TableToolbarSearch onChange={onInputChange} />
          </TableToolbarContent>
        </TableToolbar>
        <Table {...getTableProps()} aria-label="sample table">
          <TableHead>
            <TableRow>
              {headers.map((header, i) => <TableHeader
                key={i}
                sortDirection={headerData[i]?.sortDirection ?? "NONE"}
                {...getHeaderProps({
                  header,
                  isSortable: (headerData[i]?.sortDirection ?? "NONE") !== "NONE",
                  onClick: (_, sortState) => sortBy(sortState.sortHeaderKey)
                })}>
                {header.header}
              </TableHeader>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => <React.Fragment key={row.id}>
              <TableRow {...getRowProps({
                row
              })}>
                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
              </TableRow>
            </React.Fragment>)}
          </TableBody>
        </Table>
      </TableContainer>}
  </DataTable>;

  return (
    <Default />
  )
}

export default DischargeTimeTable