import React from "react";
import { useSelector } from 'react-redux'
import {
  Button,
  DataTable, DataTableSkeleton, Table, TableBody, TableCell, TableContainer, TableExpandHeader, TableExpandRow,
  TableExpandedRow, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent,
  TableToolbarSearch, Stack
} from '@carbon/react';
import { Root } from './DailyReports.styles'
import { Filter } from '@carbon/icons-react'
import { CardComponent, CardRow } from '../../../components/Layouts/Card.styles'
import Card from '../../../components/Layouts/Card'
import Typography from "../../../components/Basics/Typography";
import { DataTable as TableIcon } from '@carbon/icons-react'

const DailyReportModal = ({ button, open, setOpen }) => {
  const { modalData, currTruck, loadingModal, errorModal } = useSelector(state => state.daily)

  const headers = [
    {
      header: 'Ciclo',
      key: 'ciclo'
    },
    {
      header: 'Punto de Carguío',
      key: 'lugar_carga',
    },
    {
      header: 'Frente de Descarga',
      key: 'lugar_descarga',
    },
    {
      header: 'Hora Inicio de Ciclo',
      key: 'hora_inicio',
    },
    {
      header: 'Hora Fin de Ciclo',
      key: 'hora_termino'
    },
    {
      header: 'Duración (minutos)',
      key: 'duracion_ciclo_minutos'
    },
    {
      header: 'Tipo Ciclo',
      key: 'tipo_ciclo'
    }
  ]
  const rows = modalData

  const Default = () => <DataTable rows={rows} headers={headers} width="100%">
    {({
      rows,
      headers,
      getHeaderProps,
      getRowProps,
      getTableProps,
      getTableContainerProps,
      onInputChange
    }) => <TableContainer {...getTableContainerProps()}>
        <TableToolbar>
          <TableToolbarContent>
            {/* pass in `onInputChange` change here to make filtering work */}
            <TableToolbarSearch onChange={onInputChange} />
            <Button renderIcon={Filter} kind="secondary" hasIconOnly iconDescription="Filter table" />
          </TableToolbarContent>
        </TableToolbar>
        <Table {...getTableProps()} aria-label="sample table">
          <TableHead>
            <TableRow>
              {headers.map((header, i) => <TableHeader key={i} {...getHeaderProps({
                header
              })}>
                {header.header}
              </TableHeader>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => <React.Fragment key={row.id}>
              <TableRow {...getRowProps({
                row
              })}>
                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
              </TableRow>
            </React.Fragment>)}
          </TableBody>
        </Table>
      </TableContainer>}
  </DataTable>;

  return (
    <Root danger modalLabel="Tiempos de Operación" modalHeading={currTruck} primaryButtonDisabled passiveModal launcherButtonRef={button} primaryButtonText="Completar" open={open} onRequestClose={() => setOpen(false)}>
      <Stack gap={3}>
        <CardRow>
          <CardComponent width="100%">
            <Card justifyContent='flex-start'>
              <TableIcon size={18} />
              <Typography className='cds--type-heading-compact-01'>
                Ciclos de Transporte
              </Typography>
            </Card>
          </CardComponent>
        </CardRow>
        {loadingModal && !errorModal && (
          <DataTableSkeleton headers={headers} />
        )}
        {!loadingModal && errorModal && (
          <Typography className='cds--type-heading-compact-01'>No hay datos para este camión</Typography>
        )}
        {
          !loadingModal && !errorModal && (
            <Card width='100%' padding="0">
              <Default />
            </Card>
          )
        }
      </Stack>
    </Root>
  )
}

export default DailyReportModal
