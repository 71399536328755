import styled from 'styled-components';
import { ComboBox } from '@carbon/react';

export const StyledComboBox = styled(ComboBox)`
  background-color: #262626; /* ComboBox background color */
  border: none; /* Remove all borders */
  width: 100%;

  /* Increase specificity by including the ComboBox class */
  .cds--text-input {
    background-color: #262626 !important; /* Match the ComboBox background color */
    border-bottom: 1px solid #6f6f6f; /* Remove border from input */
    padding: 8px; /* Optional: Padding for better appearance */
    width: 100%; /* Full width of the container */
  }

  /* Placeholder styling */
  & .cds--text-input::placeholder {
    color: #aaa; /* Optional: Change placeholder color */
  }

  /* Style for the dropdown items if needed */
  & .cds--list-box {
    background-color: #262626; /* Dropdown background color */
    border-radius: 0 0 4px 4px; /* Optional: Rounded corners for dropdown */
  }
`;
