import React, { useEffect, useRef, useState } from "react";
import { Button, Stack, Tabs, TabList, Tab, TabPanels, TabPanel, ComboBox, DatePicker, DatePickerInput } from "@carbon/react";
import { DataTable as DataTableIcon, Identification, UpdateNow, Person, SummaryKpi, ChartRadar, ChartColumn, ListNumbered } from "@carbon/icons-react";
import Typography from "../../../components/Basics/Typography";
import Card from '../../../components/Layouts/Card';
import { CardRow, CardComponent } from "../../../components/Layouts/Card.styles";
import DriverDetails from "./DriverDetails.container";
import { useSelector, useDispatch } from 'react-redux';
import { onGetDriverThunk, onGetDriverLicenseThunk } from "./Driver.actions";
import LicenseEditModal from "./LicenseEditModal.container";
import ExpansionTable from "../../../components/Basics/ExpansionTable.container";
import LicenseUpdateModal from "./LicenseUpdateModal.container";
import { useSorting } from "../../../modules/hooks/sorting";
import LoadingContent from "../../../components/Animations/LoadingContent";
import ProgressBar from "../../../components/Graphs/ProgressBar.container";
import Radar from "../../../components/Graphs/Radar.container";
import AlertDashboard from "../../../components/Basics/Alertas.container";
import LineChartComponent from "../../../components/Graphs/Linealchart.container";
import CarbonTableComponent from "../../../components/Basics/Table.container";
import { spacing05 } from "@carbon/themes";
import { onGetAdasThunk, onGetPerdidasThunk, onGetFrenosThunk } from "./Driver.actions";

const DriverContainer = () => {
    const [selectedRut, setSelectedRut] = useState(null);
    const [selectedDriverName, setSelectedDriverName] = useState(null);
    const [selectedField, setSelectedField] = useState('tiempo_cambio_turno'); // Valor predeterminado
    const today_4 = new Date();
    const yesterday_4 = new Date(today_4);
    const date_4 = new Date(yesterday_4.setDate(yesterday_4.getDate() - 2));
    const [dateValue, setDateValue] = useState(date_4); // Estado para el DatePicker
    const items = [
        { label: 'Inicio de Turno Efectivo', value: 'tiempo_cambio_turno' },
        { label: 'Hora de Término Efectivo', value: 'tiempo_termino_turno' },
        { label: 'Duración de Colación', value: 'colacion_time' },
    ];

    const items_2 = [
        { label: 'Alerta de Velocidad + Retardador + Pisado de Frenos', value: 'Alerta de Velocidad + Retardador + Pisado de Frenos' },
        { label: 'Alerta de Velocidad + Retardador', value: 'Alerta de Velocidad + Retardador' },
        { label: 'Alerta exceso de Velocidad', value: 'Alerta exceso de Velocidad' },
        { label: 'Alerta de Velocidad + Frenos', value: 'Alerta de Velocidad + Frenos' },
        { label: 'Alerta, intensidad y periodicidad de frenado', value: 'Alerta, intensidad y periodicidad de frenado' },
        { label: 'Alerta Frenado + Retardador', value: 'Alerta Frenado + Retardador' },
        { label: 'Alerta Retardador Desactivado', value: 'Alerta Retardador Desactivado' },
    ];
    
    const [selectedOption, setSelectedOption] = useState(items[0]); // Valor inicial
    const [selectedOption_2, setSelectedOption_2] = useState(items_2[0]); // Valor inicial
    
    const [conduccion, setConduccion] = useState([
        { title: 'Advertencia de colisión frontal', total: 0, promedio: 1 },
        { title: 'Advertencia de colisión peatonal', total: 0, promedio: 1 },
        { title: 'Advertencia de colisión vehículo', total: 0, promedio: 1 },
        { title: 'Alerta de distancia insuficiente', total: 0, promedio: 1 },
        { title: 'Poca visibilidad', total: 0, promedio: 1 },
        { title: 'Cámara bloqueada', total: 0, promedio: 1 },
    ]);

    const [fatiga, setFatiga] = useState([
        { title: 'Uso de celular', total: 0, promedio: 1 },
        { title: 'Conductor Fumando', total: 0, promedio: 1 },
        { title: 'Conductor distraído', total: 0, promedio: 1 },
        { title: 'Advertencia de fatiga', total: 0, promedio: 1 },
        { title: 'Alarma de fatiga', total: 0, promedio: 1 },
        { title: 'Foto fatiga', total: 0, promedio: 1 },
    ]);

    const [tableRows, setTableRows] = useState([
        { id: 'row-1', name: 'Inicio de Turno Efectivo', status: 0, example: 0.5 },
        { id: 'row-2', name: 'Hora de Término Efectivo', status: 0, example: 0.5 },
        { id: 'row-3', name: 'Duración de Colación', status: 0, example: 1 },
    ]);

    const [tableRows_2, setTableRows_2] = useState([
        { id: 'row-1', name: 'Alerta de Velocidad + Retardador + Pisado de Frenos', status: 0, example: 5 },
        { id: 'row-2', name: 'Alerta de Velocidad + Retardador', status: 0, example: 5 },
        { id: 'row-3', name: 'Alerta exceso de Velocidad', status: 0, example: 5 },
        { id: 'row-4', name: 'Alerta de Velocidad + Frenos', status: 0, example: 5 },
        { id: 'row-5', name: 'Alerta, intensidad y periodicidad de frenado', status: 0, example: 5 },
        { id: 'row-6', name: 'Alerta Frenado + Retardador', status: 0, example: 5 },
        { id: 'row-7', name: 'Alerta Retardador Desactivado', status: 0, example: 5 },
    ]);

    const [tableRows_3, setTableRows_3] = useState([
        { id: 'row-1', name: '1', status: 'Conductor', example: 0.5 },
        { id: 'row-2', name: '2', status: 'Conductor', example: 0.5 },
        { id: 'row-3', name: '3', status: 'Conductor', example: 1 },
    ]);

    const [perdidasSemana, setPerdidasSemana] = useState([
        {"group":"Dataset 1","date":"2024-04-19","value":0},
        {"group":"Dataset 1","date":"2024-04-20","value":0},
        {"group":"Dataset 1","date":"2024-04-21","value":0},
        {"group":"Dataset 1","date":"2024-04-22","value":0},
        {"group":"Dataset 1","date":"2024-04-23","value":0},
        {"group":"Dataset 1","date":"2024-04-24","value":0},
        {"group":"Dataset 1","date":"2024-04-25","value":0},
    ]);

    const [frenosSemana, setFrenosSemana] = useState([
        {"group":"Dataset 1","date":"2024-04-19","value":0},
        {"group":"Dataset 1","date":"2024-04-20","value":0},
        {"group":"Dataset 1","date":"2024-04-21","value":0},
        {"group":"Dataset 1","date":"2024-04-22","value":0},
        {"group":"Dataset 1","date":"2024-04-23","value":0},
        {"group":"Dataset 1","date":"2024-04-24","value":0},
        {"group":"Dataset 1","date":"2024-04-25","value":0},
    ]);

    const [radarData_2, setRadarData_2] = useState([
        {
            "product": "Conductor",
            "feature": "Alerta de Velocidad + Retardador + Pisado de Frenos",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Alerta de Velocidad + Retardador",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Alerta exceso de Velocidad",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Alerta de Velocidad + Frenos",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Alerta, intensidad y periodicidad de frenado",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Alerta Frenado + Retardador",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Alerta Retardador Desactivado",
            "score": 0
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Alerta de Velocidad + Retardador + Pisado de Frenos",
            "score": 5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Alerta de Velocidad + Retardador",
            "score": 5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Alerta exceso de Velocidad",
            "score": 5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Alerta de Velocidad + Frenos",
            "score": 5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Alerta, intensidad y periodicidad de frenado",
            "score": 5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Alerta Frenado + Retardador",
            "score": 5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Alerta Retardador Desactivado",
            "score": 5
        }
    ]);

    const [radarData, setRadarData] = useState([
        {
            "product": "Conductor",
            "feature": "Inicio de Turno",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Término de Turno",
            "score": 0
        },
        {
            "product": "Conductor",
            "feature": "Colación",
            "score": 0
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Inicio de Turno",
            "score": 0.5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Término de Turno",
            "score": 0.5
        },
        {
            "product": "Rendimiento Optimo",
            "feature": "Colación",
            "score": 1
        }
    ]);

    const buttonRef = useRef();
    const [openLicenseEditModal, setOpenLicenseEditModal] = useState(false);
    const buttonUpdateRef = useRef();
    const [openLicenseUpdateModal, setOpenLicenseUpdateModal] = useState(null);

    const driverDetailsButtonRef = useRef();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const { error_data, loading_data, loading_adas, error_perdidas, loading_perdidas, error_frenos, loading_frenos, data, perdidas, frenos, perdidas_semana, perdidas_ranking, frenos_semana } = useSelector(state => state.driver)

    useEffect(() => {
        if (!loading_data) {
            return
        }
        dispatch(onGetDriverThunk())
    }, [loading_data, dispatch])

    useEffect(() => {
        if (perdidas && typeof perdidas === 'object' && !error_perdidas && perdidas['0']) {
            const updatedTableRows = [
                { id: 'row-1', name: 'Inicio de Turno Efectivo', status: perdidas['0'].tiempo_cambio_turno || 0, example: 0.5 },
                { id: 'row-2', name: 'Hora de Término Efectivo', status: perdidas['0'].tiempo_termino_turno || 0, example: 0.5 },
                { id: 'row-3', name: 'Duración de Colación', status: perdidas['0'].colacion_time || 0, example: 1 },
            ];
            setTableRows(updatedTableRows);

            const formattedSemanaData = perdidas_semana.map((item, index) => {
                const fecha = new Date();
                fecha.setDate(fecha.getDate() - (index + 2)); // Resta días dinámicamente

                return {
                    group: "Dataset 1",
                    date: fecha.toISOString(), // Formatea la fecha como YYYY-MM-DD
                    value: item[selectedOption.value] || 0,
                };
            });   
        
            setPerdidasSemana(formattedSemanaData);

            const updatedRadarData = radarData.map(data => {
                if (data.product === "Conductor") {
                    let score = 0;
                    switch (data.feature) {
                        case "Inicio de Turno":
                            score = perdidas['0'].tiempo_cambio_turno || 0;
                            break;
                        case "Término de Turno":
                            score = perdidas['0'].tiempo_termino_turno || 0;
                            break;
                        case "Colación":
                            score = perdidas['0'].colacion_time || 0;
                            break;
                        default:
                            score = 0;
                    }
                    return {
                        ...data,
                        product: "Conductor",
                        score: score
                    };
                }
                return data;
            });
            setRadarData(updatedRadarData);
        } else {
            setTableRows([
                { id: 'row-1', name: 'Inicio de Turno Efectivo', status: 0, example: 0.5 },
                { id: 'row-2', name: 'Hora de Término Efectivo', status: 0, example: 0.5 },
                { id: 'row-3', name: 'Duración de Colación', status: 0, example: 1 },
            ]);

            setPerdidasSemana([
                {"group":"Dataset 1","date":"2024-04-19","value":0},
                {"group":"Dataset 1","date":"2024-04-20","value":0},
                {"group":"Dataset 1","date":"2024-04-21","value":0},
                {"group":"Dataset 1","date":"2024-04-22","value":0},
                {"group":"Dataset 1","date":"2024-04-23","value":0},
                {"group":"Dataset 1","date":"2024-04-24","value":0},
                {"group":"Dataset 1","date":"2024-04-25","value":0},
            ]);

            setRadarData([
                {
                    "product": "Conductor",
                    "feature": "Inicio de Turno",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Término de Turno",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Colación",
                    "score": 0
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Inicio de Turno",
                    "score": 0.5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Término de Turno",
                    "score": 0.5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Colación",
                    "score": 1
                }
            ]);
        }
    }, [perdidas, selectedDriverName, error_perdidas, selectedOption]);

    useEffect(() => {
        if (frenos && typeof frenos === 'object' && !error_frenos && frenos['0']) {
            const updatedTableRows_2 = tableRows_2.map((row) => {
                const matchingData = Object.values(frenos).find(freno => freno.plan_marcha === row.name);
                if (matchingData) {
                    return {
                        ...row,
                        status: matchingData.count || 0, // Cambié 'status' a 'count' para reflejar los datos correctos
                    };
                }
                return row;
            });
            setTableRows_2(updatedTableRows_2);

            const formattedSemanaData = frenos_semana.map((item) => {
                
                const frenoData = item.datos.find(
                    (freno) => freno.plan_marcha === selectedOption_2.value
                );

                const fecha = new Date(item.fecha);
                fecha.setHours(fecha.getHours() + 5);
                const fechaISO = fecha.toISOString();
            
                return {
                    group: "Dataset 1",
                    date: fechaISO, // Fecha en formato YYYY-MM-DD
                    value: frenoData ? frenoData.count : 0, // Toma el valor de count o 0 si no existe
                };
            });
            
            // Actualiza el estado con los datos formateados
            setFrenosSemana(formattedSemanaData);    

            // Actualizamos radarData_2
            const updatedRadarData_2 = radarData_2.map((data) => {
                // Solo actualizamos si el producto es "Conductor"
                if (data.product === "Conductor") {
                    const matchingData = Object.values(frenos).find(
                        (freno) => freno.plan_marcha === data.feature
                    );
                    if (matchingData) {
                        return {
                            ...data,
                            score: matchingData.count || 0,
                        };
                    }
                }
                // Si no es "Conductor", devolvemos el objeto tal como está
                return data;
            });
            setRadarData_2(updatedRadarData_2);
            
        } else {
            setTableRows_2([
                { id: 'row-1', name: 'Alerta de Velocidad + Retardador + Pisado de Frenos', status: 0, example: 5 },
                { id: 'row-2', name: 'Alerta de Velocidad + Retardador', status: 0, example: 5 },
                { id: 'row-3', name: 'Alerta exceso de Velocidad', status: 0, example: 5 },
                { id: 'row-4', name: 'Alerta de Velocidad + Frenos', status: 0, example: 5 },
                { id: 'row-5', name: 'Alerta, intensidad y periodicidad de frenado', status: 0, example: 5 },
                { id: 'row-6', name: 'Alerta Frenado + Retardador', status: 0, example: 5 },
                { id: 'row-7', name: 'Alerta Retardador Desactivado', status: 0, example: 5 },
            ]);
            setPerdidasSemana([
                {"group":"Dataset 1","date":"2024-04-19","value":0},
                {"group":"Dataset 1","date":"2024-04-20","value":0},
                {"group":"Dataset 1","date":"2024-04-21","value":0},
                {"group":"Dataset 1","date":"2024-04-22","value":0},
                {"group":"Dataset 1","date":"2024-04-23","value":0},
                {"group":"Dataset 1","date":"2024-04-24","value":0},
                {"group":"Dataset 1","date":"2024-04-25","value":0},
            ]);
            setRadarData_2([
                {
                    "product": "Conductor",
                    "feature": "Alerta de Velocidad + Retardador + Pisado de Frenos",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta de Velocidad + Retardador",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta exceso de Velocidad",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta de Velocidad + Frenos",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta, intensidad y periodicidad de frenado",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta Frenado + Retardador",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta Retardador Desactivado",
                    "score": 0
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta de Velocidad + Retardador + Pisado de Frenos",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta de Velocidad + Retardador",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta exceso de Velocidad",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta de Velocidad + Frenos",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta, intensidad y periodicidad de frenado",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta Frenado + Retardador",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta Retardador Desactivado",
                    "score": 5
                }
            ]);
        }
    }, [frenos, selectedDriverName, error_frenos, selectedOption_2]);

    const updateAlertData = (adasData) => {
        if (adasData && adasData.adas && Array.isArray(adasData.adas)) {
            setFatiga((prevFatiga) => {
                const updatedFatiga = prevFatiga.map((item) => {
                    const matchingData = adasData.adas.find((ada) => {
                        switch (ada.label) {
                            case 'ftgphone':
                                return item.title === 'Uso de celular';
                            case 'ftgsmoking':
                                return item.title === 'Conductor Fumando';
                            case 'ftgdistrct':
                                return item.title === 'Conductor distraído';
                            case 'ftgwarning':
                                return item.title === 'Advertencia de fatiga';
                            case 'ftgalarm':
                                return item.title === 'Alarma de fatiga';
                            case 'ftgphoto':
                                return item.title === 'Foto fatiga';
                            default:
                                return false;
                        }
                    });
    
                    if (matchingData) {
                        return { ...item, total: matchingData.count };
                    }
                    return item;
                });
                return updatedFatiga;
            });
    
            setConduccion((prevConduccion) => {
                const updatedConduccion = prevConduccion.map((item) => {
                    const matchingData = adasData.adas.find((ada) => {
                        switch (ada.label) {
                            case 'mdas9acf':
                                return item.title === 'Advertencia de colisión frontal';
                            case 'mdas9acp':
                                return item.title === 'Advertencia de colisión peatonal';
                            case 'mdas9acv':
                                return item.title === 'Advertencia de colisión vehículo';
                            case 'mdas9ccf2s':
                                return item.title === 'Alerta de distancia insuficiente';
                            case 'mdas9pv':
                                return item.title === 'Poca visibilidad';
                            case 'camblck':
                                return item.title === 'Cámara bloqueada';
                            default:
                                return false;
                        }
                    });
    
                    if (matchingData) {
                        return { ...item, total: matchingData.count };
                    }
                    return item;
                });
                return updatedConduccion;
            });
        } else {
            console.warn("No se encontraron datos válidos de ADAS para actualizar.");
        }
    }; 

    const handleDriverChange = (selectedDriver) => {
        const driver = driverNames.find(driver => driver.name === selectedDriver);
        if (driver) {
            setSelectedRut(driver.rut);
            setSelectedDriverName(driver.name);
            setSelectedOption(items[0])
    
            // Reset conduccion, fatiga, tableRows, and radarData to initial state
            setConduccion([
                { title: 'Advertencia de colisión frontal', total: 0, promedio: 1 },
                { title: 'Advertencia de colisión peatonal', total: 0, promedio: 1 },
                { title: 'Advertencia de colisión vehículo', total: 0, promedio: 1 },
                { title: 'Alerta de distancia insuficiente', total: 0, promedio: 1 },
                { title: 'Poca visibilidad', total: 0, promedio: 1 },
                { title: 'Cámara bloqueada', total: 0, promedio: 1 },
            ]);
    
            setFatiga([
                { title: 'Uso de celular', total: 0, promedio: 1 },
                { title: 'Conductor Fumando', total: 0, promedio: 1 },
                { title: 'Conductor distraído', total: 0, promedio: 1 },
                { title: 'Advertencia de fatiga', total: 0, promedio: 1 },
                { title: 'Alarma de fatiga', total: 0, promedio: 1 },
                { title: 'Foto fatiga', total: 0, promedio: 1 },
            ]);

            setTableRows([
                { id: 'row-1', name: 'Inicio de Turno Efectivo', status: 0, example: 0.5 },
                { id: 'row-2', name: 'Hora de Término Efectivo', status: 0, example: 0.5 },
                { id: 'row-3', name: 'Duración de Colación', status: 0, example: 1 },
            ]);

            setPerdidasSemana([
                {"group":"Dataset 1","date":"2024-04-19","value":0},
                {"group":"Dataset 1","date":"2024-04-20","value":0},
                {"group":"Dataset 1","date":"2024-04-21","value":0},
                {"group":"Dataset 1","date":"2024-04-22","value":0},
                {"group":"Dataset 1","date":"2024-04-23","value":0},
                {"group":"Dataset 1","date":"2024-04-24","value":0},
                {"group":"Dataset 1","date":"2024-04-25","value":0},
            ]);

            setTableRows_2([
                { id: 'row-1', name: 'Alerta de Velocidad + Retardador + Pisado de Frenos', status: 0, example: 5 },
                { id: 'row-2', name: 'Alerta de Velocidad + Retardador', status: 0, example: 5 },
                { id: 'row-3', name: 'Alerta exceso de Velocidad', status: 0, example: 5 },
                { id: 'row-4', name: 'Alerta de Velocidad + Frenos', status: 0, example: 5 },
                { id: 'row-5', name: 'Alerta, intensidad y periodicidad de frenado', status: 0, example: 5 },
                { id: 'row-6', name: 'Alerta Frenado + Retardador', status: 0, example: 5 },
                { id: 'row-7', name: 'Alerta Retardador Desactivado', status: 0, example: 5 },
            ]);

            setPerdidasSemana([
                {"group":"Dataset 1","date":"2024-04-19","value":0},
                {"group":"Dataset 1","date":"2024-04-20","value":0},
                {"group":"Dataset 1","date":"2024-04-21","value":0},
                {"group":"Dataset 1","date":"2024-04-22","value":0},
                {"group":"Dataset 1","date":"2024-04-23","value":0},
                {"group":"Dataset 1","date":"2024-04-24","value":0},
                {"group":"Dataset 1","date":"2024-04-25","value":0},
            ]);
    
            setRadarData([
                {
                    "product": "Conductor",
                    "feature": "Inicio de Turno",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Término de Turno",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Colación",
                    "score": 0
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Inicio de Turno",
                    "score": 0.5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Término de Turno",
                    "score": 0.5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Colación",
                    "score": 1
                }
            ]);

            setRadarData_2([
                {
                    "product": "Conductor",
                    "feature": "Alerta de Velocidad + Retardador + Pisado de Frenos",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta de Velocidad + Retardador",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta exceso de Velocidad",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta de Velocidad + Frenos",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta, intensidad y periodicidad de frenado",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta Frenado + Retardador",
                    "score": 0
                },
                {
                    "product": "Conductor",
                    "feature": "Alerta Retardador Desactivado",
                    "score": 0
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta de Velocidad + Retardador + Pisado de Frenos",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta de Velocidad + Retardador",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta exceso de Velocidad",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta de Velocidad + Frenos",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta, intensidad y periodicidad de frenado",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta Frenado + Retardador",
                    "score": 5
                },
                {
                    "product": "Rendimiento Optimo",
                    "feature": "Alerta Retardador Desactivado",
                    "score": 5
                }
            ]);
    
            // Parámetros predefinidos
            const today = new Date();
            const yesterday = new Date(today);
            const date = new Date(yesterday.setDate(yesterday.getDate() - 2));
            let turno = 'TURNO DIA';
    
            // Llamar a las funciones con los parámetros adecuados
            const fetchData = () => {
                return Promise.all([
                    dispatch(onGetAdasThunk(date, turno, driver.rut)).catch(error => ({ error })),
                    dispatch(onGetPerdidasThunk(date, turno, driver.rut)).catch(error => ({ error })),
                    dispatch(onGetFrenosThunk(date, turno, driver.rut)).catch(error => ({ error }))
                ]);
            };
    
            fetchData().then(([adasResponse, perdidasResponse, frenosResponse]) => {
                const allFailed = [adasResponse, perdidasResponse, frenosResponse].every(response => response.payload && response.payload.error);
                if (allFailed) {
                    console.error("All requests failed with TURNO DIA, retrying with TURNO NOCHE");
                    turno = 'TURNO NOCHE';
                    fetchData().then(([adasResponse, perdidasResponse, frenosResponse]) => {
                        if (!adasResponse.error && adasResponse.payload && adasResponse.payload.adas['0']) {
                            updateAlertData(adasResponse.payload);
                        }
                        if (!perdidasResponse.error && perdidasResponse.payload && perdidasResponse.payload['0']) {
                            const updatedTableRows = [
                                { id: 'row-1', name: 'Inicio de Turno Efectivo', status: perdidasResponse.payload['0'].tiempo_cambio_turno || 0, example: 0.5 },
                                { id: 'row-2', name: 'Hora de Término Efectivo', status: perdidasResponse.payload['0'].tiempo_termino_turno || 0, example: 0.5 },
                                { id: 'row-3', name: 'Duración de Colación', status: perdidasResponse.payload['0'].colacion_time || 0, example: 1 },
                            ];
                            setTableRows(updatedTableRows);

                            const formattedSemanaData = perdidas_semana.map((item, index) => {
                                const fecha = new Date();
                                fecha.setDate(fecha.getDate() - (index + 2)); // Resta días dinámicamente
                
                                return {
                                    group: "Dataset 1",
                                    date: fecha.toISOString(), // Formatea la fecha como YYYY-MM-DD
                                    value: item[selectedOption.value] || 0,
                                };
                            });
                            setPerdidasSemana(formattedSemanaData);

                            const updatedRadarData = radarData.map(data => {
                                if (data.product === "Conductor") {
                                    let score = 0;
                                    switch (data.feature) {
                                        case "Inicio de Turno":
                                            score = perdidasResponse.payload['0'].tiempo_cambio_turno || 0;
                                            break;
                                        case "Término de Turno":
                                            score = perdidasResponse.payload['0'].tiempo_termino_turno || 0;
                                            break;
                                        case "Colación":
                                            score = perdidasResponse.payload['0'].colacion_time || 0;
                                            break;
                                        default:
                                            score = 0;
                                    }
                                    return {
                                        ...data,
                                        product: "Conductor",
                                        score: score
                                    };
                                }
                                return data;
                            });
                            setRadarData(updatedRadarData);
                        }
                        if (!frenosResponse.error && frenosResponse.payload && frenosResponse.payload['0']) {
                            const updatedTableRows_2 = tableRows_2.map((row) => {
                                const matchingData = Object.values(frenos).find(freno => freno.plan_marcha === row.name);
                                if (matchingData) {
                                    return {
                                        ...row,
                                        status: matchingData.count || 0, // Cambié 'status' a 'count' para reflejar los datos correctos
                                    };
                                }
                                return row;
                            });
                            setTableRows_2(updatedTableRows_2);

                            const formattedSemanaData = frenos_semana.map((item) => {
                
                                const frenoData = item.datos.find(
                                    (freno) => freno.plan_marcha === selectedOption_2.value
                                );
                            
                                const fecha = new Date(item.fecha);
                                fecha.setHours(fecha.getHours() + 5);
                                const fechaISO = fecha.toISOString();
                            
                                return {
                                    group: "Dataset 1",
                                    date: fechaISO, // Fecha en formato YYYY-MM-DD
                                    value: frenoData ? frenoData.count : 0, // Toma el valor de count o 0 si no existe
                                };
                            });
                            setFrenosSemana(formattedSemanaData);

                            const updatedRadarData_2 = radarData_2.map((data) => {
                                // Solo actualizamos si el producto es "Conductor"
                                if (data.product === "Conductor") {
                                    const matchingData = Object.values(frenos).find(
                                        (freno) => freno.plan_marcha === data.feature
                                    );
                            
                                    if (matchingData) {
                                        return {
                                            ...data,
                                            score: matchingData.count || 0,
                                        };
                                    }
                                }
                                // Si no es "Conductor", devolvemos el objeto tal como está
                                return data;
                            });
                            setRadarData_2(updatedRadarData_2);
                        }
                    }).catch(error => {
                        console.error("Error fetching data with TURNO NOCHE: ", error);
                    });
                } else {
                    if (!adasResponse.error && adasResponse.payload && adasResponse.payload.adas['0']) {
                        updateAlertData(adasResponse.payload);
                    }
                    if (!perdidasResponse.error && perdidasResponse.payload && perdidasResponse.payload['0']) {
                        const updatedTableRows = [
                            { id: 'row-1', name: 'Inicio de Turno Efectivo', status: perdidasResponse.payload['0'].tiempo_cambio_turno || 0, example: 0.5 },
                            { id: 'row-2', name: 'Hora de Término Efectivo', status: perdidasResponse.payload['0'].tiempo_termino_turno || 0, example: 0.5 },
                            { id: 'row-3', name: 'Duración de Colación', status: perdidasResponse.payload['0'].colacion_time || 0, example: 1 },
                        ];
                        setTableRows(updatedTableRows);

                        const formattedSemanaData = perdidas_semana.map((item, index) => {
                            const fecha = new Date();
                            fecha.setDate(fecha.getDate() - (index + 2)); // Resta días dinámicamente
            
                            return {
                                group: "Dataset 1",
                                date: fecha.toISOString(), // Formatea la fecha como YYYY-MM-DD
                                value: item[selectedOption.value] || 0,
                            };
                        });
                        setPerdidasSemana(formattedSemanaData);

                        const updatedRadarData = radarData.map(data => {
                            if (data.product === "Conductor") {
                                let score = 0;
                                switch (data.feature) {
                                    case "Inicio de Turno":
                                        score = perdidasResponse.payload['0'].tiempo_cambio_turno || 0;
                                        break;
                                    case "Término de Turno":
                                        score = perdidasResponse.payload['0'].tiempo_termino_turno || 0;
                                        break;
                                    case "Colación":
                                        score = perdidasResponse.payload['0'].colacion_time || 0;
                                        break;
                                    default:
                                        score = 0;
                                }
                                return {
                                    ...data,
                                    product: "Conductor",
                                    score: score
                                };
                            }
                            return data;
                        });
                        setRadarData(updatedRadarData);
                    }
                    if (!frenosResponse.error && frenosResponse.payload && frenosResponse.payload['0']) {
                        const updatedTableRows_2 = tableRows_2.map((row) => {
                            const matchingData = Object.values(frenos).find(freno => freno.plan_marcha === row.name);
                            if (matchingData) {
                                return {
                                    ...row,
                                    status: matchingData.count || 0, // Cambié 'status' a 'count' para reflejar los datos correctos
                                };
                            }
                            return row;
                        });
                        setTableRows_2(updatedTableRows_2);

                        const formattedSemanaData = frenos_semana.map((item) => {
                
                            const frenoData = item.datos.find(
                                (freno) => freno.plan_marcha === selectedOption_2.value
                            );
                        
                            const fecha = new Date(item.fecha);
                            fecha.setHours(fecha.getHours() + 5);
                            const fechaISO = fecha.toISOString();
                        
                            return {
                                group: "Dataset 1",
                                date: fechaISO, // Fecha en formato YYYY-MM-DD
                                value: frenoData ? frenoData.count : 0, // Toma el valor de count o 0 si no existe
                            };
                        });
                        setFrenosSemana(formattedSemanaData);

                        const updatedRadarData_2 = radarData_2.map((data) => {
                            // Solo actualizamos si el producto es "Conductor"
                            if (data.product === "Conductor") {
                                const matchingData = Object.values(frenos).find(
                                    (freno) => freno.plan_marcha === data.feature
                                );

                                if (matchingData) {
                                    return {
                                        ...data,
                                        score: matchingData.count || 0,
                                    };
                                }
                            }
                            // Si no es "Conductor", devolvemos el objeto tal como está
                            return data;
                        });
                        setRadarData_2(updatedRadarData_2);
                    }
                }
            }).catch(error => {
                console.error("Error fetching data with TURNO DIA: ", error);
            });
        }
    };

    const headerData = [
        {
            header: 'Nombre',
            key: 'fullName',
            sortDirection: "ASC",
        },
        {
            header: 'Rut',
            key: 'fullRut',
        },
        {
            header: 'Estado de licencia',
            key: 'isExpired',
        },
        {
            header: 'Fecha Expiración',
            key: 'expiry',
            sortDirection: "DESC",
            isDefaultSortable: true
        },
        {
            header: 'Activo',
            key: 'isActive',
        },
        {
            header: 'ID llavero',  // Nueva columna
            key: 'driverId',
        }
    ];
    const { rows, headers } = useSorting(data, headerData);
    const driverNames = data ? data.map(driver => ({ name: driver.fullName, rut: driver.rut })) : [];

    const handleTabClick = (tabName) => {
        if (tabName === 'ranking') {
            const today = new Date();
            const yesterday = new Date(today);
            const date = new Date(yesterday.setDate(yesterday.getDate() - 2));
            dispatch(onGetPerdidasThunk(date, null, null)); // Llamada a la acción con turno y rut como null
        }
    };

    const handleDateChange = (eventOrDates) => {
        console.log(eventOrDates);
        
        if (Array.isArray(eventOrDates) && eventOrDates.length > 0) {
            console.log("entra al if 1");
            
            const selectedDate = eventOrDates[0];
            if (selectedDate instanceof Date && !isNaN(selectedDate)) {
                console.log("entra al if 2");
                setDateValue(selectedDate);
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1; // Los meses comienzan desde 0
                const day = selectedDate.getDate();
                console.log("Fecha a enviar:", { year, month, day });
                dispatch(onGetPerdidasThunk(selectedDate, null, null)); // Llama la acción con año, mes y día
            }
        }
    };

    return (
        <>
            {(!error_data) && (loading_data) && (
                <LoadingContent />
            )}
            {!loading_data && error_data && (
                <CardComponent width="10%">
                    <Typography className='cds--type-heading-compact-01'>
                        No hay registros de conductores
                    </Typography>
                </CardComponent>
            )}
            {!(loading_data) && !error_data && (
                <>
                    <Tabs>
                        <TabList activation="manual" aria-label="List of tabs">
                            <Tab renderIcon={Identification}>Gestion de Licencias y Llaveros</Tab>
                            <Tab renderIcon={Person}>Rendimiento por Conductor</Tab>
                            <Tab renderIcon={ListNumbered} onClick={() => handleTabClick('ranking')}>Ranking Conductores</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel style={{ width: '100%' }}>
                                {(loading_data || loading_adas || loading_perdidas || loading_frenos) ? (
                                    <LoadingContent />
                                ) : (
                                    <Stack gap={3}>
                                        <Button size="sm" ref={buttonRef} renderIcon={UpdateNow} onClick={() => setOpenLicenseEditModal(true)}>Actualizar Estado de Licencia</Button>
                                        <Card justifyContent='flex-start' >
                                            <DataTableIcon size={18} />
                                            <Typography className='cds--type-heading-compact-01'>
                                                CONDUCTORES - TABLA DE DATOS
                                            </Typography>
                                        </Card>
                                        <CardRow width='inherit'>
                                            <Card width='100%' padding="0">
                                                <ExpansionTable
                                                    hasSearch
                                                    headers={headers}
                                                    rows={rows}
                                                    headerData={headerData}
                                                    detailsFunc={(row) =>
                                                        <Button
                                                            kind="danger--ghost"
                                                            onClick={() => setOpenLicenseUpdateModal(row)}
                                                            renderIcon={Identification}
                                                            style={{ minWidth: "100%" }}
                                                        >
                                                            Ver Ficha de Conductor
                                                        </Button>
                                                    }
                                                />
                                            </Card>
                                        </CardRow>
                                    </Stack>
                                )}
                            </TabPanel>
                            <TabPanel style={{ width: '100%' }}>
                                {(loading_adas || loading_perdidas || loading_frenos) ? (
                                    <LoadingContent />
                                ) : (
                                    <Stack gap={3}>
                                        <div style={{ width: '20%', padding: { spacing05 } }}>
                                            <ComboBox
                                                id='driver'
                                                items={driverNames.map(driver => driver.name)}
                                                onChange={({ selectedItem }) => handleDriverChange(selectedItem)}
                                                placeholder='Seleccionar Conductor'
                                                titleText="Conductor"
                                                selectedItem={selectedDriverName}
                                            />
                                        </div>
                                        <Card justifyContent='flex-start'>
                                            <DataTableIcon size={18} />
                                            <Typography className='cds--type-heading-compact-01'>
                                                RENDIMIENTO - VELOCIDADES
                                            </Typography>
                                        </Card>
                                        <Card width='100%' justifyContent='flex-start' padding={spacing05}>
                                            <ProgressBar
                                                value={45}     // El valor actual de la barra de progreso
                                                max={60}       // El valor máximo de la barra de progreso
                                                style={{ width: '100%' }}
                                            />
                                        </Card>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='49.5%' justifyContent='flex-start'>
                                                <SummaryKpi size={18} />
                                                <Typography className='cds--type-heading-compact-01'>
                                                    CONTADOR DE ALERTAS - ADAS - CONTROL DE CRUCERO
                                                </Typography>
                                                {/* Aquí puedes agregar más contenido o componentes */}
                                            </Card>
                                            <Card width='49.5%' justifyContent='flex-start'>
                                                <SummaryKpi size={18} />
                                                <Typography className='cds--type-heading-compact-01'>
                                                    CONTADOR DE ALERTAS - ADAS - FATIGA
                                                </Typography>
                                                {/* Aquí puedes agregar más contenido o componentes */}
                                            </Card>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='49.5%' justifyContent='flex-start' padding={spacing05}>
                                                <AlertDashboard alertData={conduccion} />
                                            </Card>
                                            <Card width='49.5%' justifyContent='flex-start' padding={spacing05}>
                                                <AlertDashboard alertData={fatiga} />
                                            </Card>
                                        </div>
                                        <Card justifyContent='flex-start' >
                                            <DataTableIcon size={18} />
                                            <Typography className='cds--type-heading-compact-01'>
                                                RENDIMIENTO - TIEMPOS DEL TURNO
                                            </Typography>
                                        </Card>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='40%' justifyContent='flex-start'>
                                                <ChartRadar size={18} />
                                                <Typography className='cds--type-heading-compact-01'>
                                                    RENDIMIENTO EN TIEMPOS
                                                </Typography>
                                                {/* Aquí puedes agregar más contenido o componentes */}
                                            </Card>
                                            <Card width='59%' justifyContent='flex-start'>
                                                <SummaryKpi size={18} />
                                                <Typography className='cds--type-heading-compact-01'>
                                                    COMPARACIÓN CON RENDIMIENTO OPTIMO
                                                </Typography>
                                                {/* Aquí puedes agregar más contenido o componentes */}
                                            </Card>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='40%' justifyContent='flex-start' padding={spacing05}>
                                                <Radar 
                                                    data={radarData} 
                                                    title="Tiempos del Turno" 
                                                    colorScale={{
                                                        "Conductor": "#FB7E50",
                                                        "Rendimiento Optimo": "#6929C4"
                                                    }}
                                                    width="400px"
                                                />
                                            </Card>
                                            <Card width='59%' justifyContent='flex-start' padding={spacing05}>
                                                <CarbonTableComponent 
                                                    secondColumnTitle="Tiempos de Turno Promedio (horas)" 
                                                    thirdColumnTitle="Tiempos de Turno Optimos (horas)" 
                                                    rows={tableRows} // Pasar las filas como parámetro
                                                />
                                            </Card>
                                        </div>
                                        <Card justifyContent='flex-start' >
                                            <ChartColumn size={18} />
                                            <Typography className='cds--type-heading-compact-01'>
                                                EVOLUCIÓN DEL RENDIMIENTO - TIEMPOS DEL TURNO - 7 D
                                            </Typography>
                                        </Card>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='100%' justifyContent='flex-start' padding={spacing05}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <div style={{ width: '20%', paddingBottom: spacing05 }}>
                                                    <ComboBox
                                                        id="tiempo"
                                                        items={items.map(item => item.label)} // Mostrar los labels en el ComboBox
                                                        onChange={({ selectedItem }) => {
                                                            const selected = items.find(item => item.label === selectedItem);
                                                            setSelectedOption(selected); // Actualizar la opción seleccionada
                                                        }}
                                                        placeholder="Tiempo a analizar"
                                                        titleText="Seleccionar tiempo del turno"
                                                    />
                                                    </div>
                                                    <LineChartComponent
                                                        chartData={perdidasSemana}  // Pasar los datos al gráfico
                                                        chartTitle={selectedOption.label}  // Pasar el título como prop
                                                    />
                                                </div>
                                            </Card>
                                        </div>
                                        <Card justifyContent='flex-start' >
                                            <DataTableIcon size={18} />
                                            <Typography className='cds--type-heading-compact-01'>
                                                RENDIMIENTO - CONDUCCIÓN
                                            </Typography>
                                        </Card>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='40%' justifyContent='flex-start'>
                                                <ChartRadar size={18} />
                                                <Typography className='cds--type-heading-compact-01'>
                                                    RADAR RENDIMIENTO - CONDUCCIÓN 
                                                </Typography>
                                                {/* Aquí puedes agregar más contenido o componentes */}
                                            </Card>
                                            <Card width='59%' justifyContent='flex-start'>
                                                <SummaryKpi size={18} />
                                                <Typography className='cds--type-heading-compact-01'>
                                                    PROMEDIOS
                                                </Typography>
                                                {/* Aquí puedes agregar más contenido o componentes */}
                                            </Card>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='40%' justifyContent='flex-start' padding={spacing05}>
                                                <Radar 
                                                    data={radarData_2} 
                                                    title="Rendimiento del Conductor" 
                                                    colorScale={{
                                                        "Conductor": "#FB7E50",
                                                        "Rendimiento Optimo": "#6929C4"
                                                    }}
                                                    width="600px"
                                                />
                                            </Card>
                                            <Card width='59%' justifyContent='flex-start' padding={spacing05}>
                                                <CarbonTableComponent 
                                                    secondColumnTitle="Cantidad" 
                                                    thirdColumnTitle="Promedio Rendimiento Optimo" 
                                                    rows={tableRows_2} // Pasar las filas como parámetro
                                                />
                                            </Card>
                                        </div>
                                        <Card justifyContent='flex-start' >
                                            <SummaryKpi size={18} />
                                            <Typography className='cds--type-heading-compact-01'>
                                                EVOLUCIÓN RENDIMIENTO - CONDUCCIÓN - 6 MESES
                                            </Typography>
                                        </Card>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Card width='100%' justifyContent='flex-start' padding={spacing05}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <div style={{ width: '20%', paddingBottom: spacing05 }}>
                                                        <ComboBox
                                                            id='conducir'
                                                            items={items_2.map(item => item.label)} // Mostrar los labels en el ComboBox
                                                            onChange={({ selectedItem }) => {
                                                                const selected = items_2.find(item => item.label === selectedItem);
                                                                setSelectedOption_2(selected); // Actualizar la opción seleccionada
                                                            }}
                                                            placeholder='Aspecto a analizar'
                                                            titleText="Seleccionar aspecto de la conducción"
                                                        />
                                                    </div>
                                                    <LineChartComponent
                                                        chartData={frenosSemana}  // Pasar los datos al gráfico
                                                        chartTitle={selectedOption_2.label}  // Pasar el título como prop
                                                    />
                                                </div>
                                            </Card>
                                        </div>
                                    </Stack>
                                )}
                            </TabPanel>
                            <TabPanel style={{ width: '100%' }}>
                            {(loading_perdidas) ? (
                                    <LoadingContent />
                                ) : (
                                <Stack gap={3}>
                                    <div style={{ width: '20%', padding: { spacing05 } }}>
                                        <DatePicker datePickerType="single" value={dateValue} onChange= {handleDateChange} dateFormat="d-m-Y" locale="es">
                                            <DatePickerInput id="date-picker-single" placeholder="dd/mm/yyyy" labelText="Fecha de inicio del análisis (Promedio 10 días hacia atrás)" size="md"/>
                                        </DatePicker>
                                        <ComboBox
                                            id='driverss'
                                            items={[
                                                { value: 'tiempo_cambio_turno', text: 'Inicio de Turno Efectivo' },
                                                { value: 'tiempo_termino_turno', text: 'Hora de Término Efectivo' },
                                                { value: 'colacion_time', text: 'Duración de Colación' },
                                            ]}
                                            itemToString={(item) => (item ? item.text : '')}
                                            placeholder='Seleccionar parámetro de comparación'
                                            titleText="Parámetro de comparación"
                                            selectedItem={{ value: selectedField, text: 'Inicio de Turno Efectivo' }} // Elemento seleccionado por defecto
                                            onChange={({ selectedItem }) => setSelectedField(selectedItem.value)} // Actualizar estado con la selección
                                        />
                                    </div>
                                    <Card justifyContent='flex-start'>
                                        <DataTableIcon size={18} />
                                        <Typography className='cds--type-heading-compact-01'>
                                            RANKING - TABLA DE CONDUCTORES
                                        </Typography>
                                    </Card>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <Card width='100%' justifyContent='flex-start' padding={spacing05}>
                                            <CarbonTableComponent
                                                firstColumnTitle="Ranking" 
                                                secondColumnTitle="Conductor" 
                                                thirdColumnTitle="Horas promedio por turno" 
                                                rows={
                                                    [...perdidas_ranking]
                                                        .sort((a, b) => b[selectedField] - a[selectedField]) // Ordenar de mayor a menor
                                                        .map((data, index) => ({
                                                            key: `row-${index}`,
                                                            name: index + 1,
                                                            status: data.conductor,
                                                            example: data[selectedField]
                                                        }))
                                                }
                                            />
                                        </Card>
                                    </div>
                                </Stack>
                                )}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <LicenseEditModal open={openLicenseEditModal} setOpen={setOpenLicenseEditModal} ref={buttonRef} />
                    <LicenseUpdateModal open={openLicenseUpdateModal} setOpen={setOpenLicenseUpdateModal} ref={buttonUpdateRef} />
                </>
            )}
        </>
    )
};

export default DriverContainer;
