import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, SkeletonPlaceholder, FilterableMultiSelect, MultiSelect, DatePicker, DatePickerInput, Modal, Select, Dropdown, SelectItem, Stack, TextInput, TimePicker, Link } from "@carbon/react";
import Typography from "../../../components/Basics/Typography";
import { checkErrorRequired } from '../../../modules/errors/validations';
import { useInput, useSelect, useDatePicker } from '../../../components/Inputs/Inputs.hooks'
import { Information } from '@carbon/icons-react';
import { Div } from '../../../components/Basics/Div.styles';
import { formatDate } from '../../../modules/utils/formatters';
import { onSendQueryThunk } from './Database.actions';
import { useSession } from '../../../modules/hooks/security';
import { onGetTrucksThunk } from '../../Records/Machines/Machine.actions';


const DatabaseForm = ({ button, open, setOpen }) => {
  const { username } = useSession()

  const [timeIn, setTimeIn] = useState('');
  const [isValidTimeIn, setIsValidTimeIn] = useState(false);
  const inPattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/
  const [timeOut, setTimeOut] = useState('');
  const [isValidTimeOut, setIsValidTimeOut] = useState(false);
  const outPattern = /^\d{1,}:[0-5]\d$/
  const [selectedTrucks, setSelectedTrucks] = useState([]);

  const dispatch = useDispatch()
  const { sending, submitError, submitSuccess, downloadUrl } = useSelector(state => state.database)
  const { error, loading, data } = useSelector(state => state.machines)

  const { error: typeError, value: typeValue, onChange: handleTypeChange } = useSelect({
    initialValue: 'historic',
  })

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const handleChangeTimeIn = (e) => {
    const value = e.target.value;
    setTimeIn(value);

    // Check if the input matches the regex pattern
    if (inPattern.test(value)) {
      setIsValidTimeIn(true);
    } else {
      setIsValidTimeIn(false);
    }
  };

  const handleChangeTimeOut = (e) => {
    const value = e.target.value;
    setTimeOut(value);

    // Check if the input matches the regex pattern
    if (outPattern.test(value)) {
      setIsValidTimeOut(true);
    } else {
      setIsValidTimeOut(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      return
    }
    dispatch(onGetTrucksThunk())
  }, [])

  const constructQuery = (type) => {
    let _vars = ['event_time']
    switch (type) {
      case 'gps':
        const gpsQuery = ['lat', 'lon']
        return [..._vars, ...gpsQuery]
    }
  }

  const handleSubmit = () => {
    const pattern = 'yyyy-MM-dd'
    const _dateInitial = formatDate(dateValue[0], pattern)
    const _dateFinal = formatDate(dateValue[1], pattern)
    const dateInitial = `${_dateInitial} ${timeIn}:00`
    const dateFinal = `${_dateFinal} ${timeOut}:00`
    const params = {
      "reportType": typeValue,
      "trucksSelected": selectedTrucks,
      "initialDate": dateInitial,
      "finalDate": dateFinal,
      "user": username
    }
    dispatch(onSendQueryThunk(params))
  }

  const canNotSubmit = useCallback(() => {
    if (!(dateValue.length === 2)) {
      return true
    }
    if (selectedTrucks.length === 0) {
      return true
    }
    if (sending) {
      return true
    }
    if (submitError) {
      return true
    }
    if (!isValidTimeIn) {
      return true
    }
    if (!isValidTimeOut) {
      return true
    }
  }, [dateValue, typeValue, isValidTimeIn, isValidTimeOut, selectedTrucks])

  const disabledSubmit = canNotSubmit()

  const items = [{
    id: 'downshift-1-item-0',
    text: 'Option 1'
  }, {
    id: 'downshift-1-item-1',
    text: 'Option 2'
  }, {
    id: 'downshift-1-item-2',
    text: 'Option 3 - a disabled item',
    disabled: true
  }, {
    id: 'downshift-1-item-3',
    text: 'Option 4'
  }, {
    id: 'downshift-1-item-4',
    text: 'An example option that is really long to show what should be done to handle long text'
  }, {
    id: 'downshift-1-item-5',
    text: 'Option 5'
  }];

  const transformedTrucks = data.map(truck => ({
    id: truck.id,
    text: `${truck.truckPatent}`
  }));

  // Add a new item at the first index
  transformedTrucks.unshift({
    id: '0',
    text: 'All'
  });

  const getLoadingStatus = () => {
    if (submitError) {
      return 'error'
    }
    if (sending) {
      return 'active'
    }
    return 'inactive'
  }
  return (
    <Modal
      danger
      launcherButtonRef={button}
      primaryButtonDisabled={disabledSubmit}
      modalHeading="Solicitud de Informe"
      modalLabel="Formulario"
      primaryButtonText="Solicitar"
      open={open}
      onRequestSubmit={handleSubmit}
      onRequestClose={() => setOpen(false)}
      loadingStatus={getLoadingStatus()}
    >
      <Stack gap={6}>
        <p>
          Este formulario está diseñado para que los usuarios puedan solicitar informes específicos de bases de datos. Por favor, complete el formulario con la información requerida para procesar su solicitud de informe.
        </p>
        <Select id="select-1" labelText="Tipo de Informe" value={typeValue} invalid={Boolean(typeError)} invalidText={typeError} onChange={handleTypeChange}>
          <SelectItem value="historic" text="Informe Histórico" />
          <SelectItem value="cycle" text="Informe Ciclos" />
          <SelectItem value="ralenti" text="Informe Ralentí" />
          <SelectItem value="geofence" text="Permanencia Geocercas" />
          <SelectItem value="horometer" text="Horómetro" />
        </Select>
        {loading && !error && (
          <SkeletonPlaceholder />
        )}
        {!loading && !error && (
          <FilterableMultiSelect label="" id="carbon-multiselect-example" titleText="Seleccionar Equipos" items={transformedTrucks} itemToString={item => item ? item.text : ''} selectionFeedback="top-after-reopen" onChange={(event) => setSelectedTrucks(event.selectedItems)} initialSelectedItems={[]} />
        )}
        <Div display="flex" gap="24px">
          <DatePicker
            datePickerType="range"
            value={dateValue}
            invalidText={dateError}
            onChange={handleDateChange}
            dateFormat='d-m-Y'
          >
            <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText="Fecha de Inicio" size="md" />
            <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText="Fecha de Término" size="md" />
          </DatePicker>
          <Div display="flex" gap="8px" width="100%">
            <Div>
              <TimePicker id="entrada" labelText="Hora Inicio" pattern='^(?:[01]\d|2[0-3]):[0-5]\d$' value={timeIn} invalid={!isValidTimeIn} onChange={handleChangeTimeIn} invalidText="Hora Inválida" />
            </Div>
            <Div>
              <TimePicker id="salida" labelText="Hora Fin" pattern='/^\d{1,}:[0-5]\d$/' value={timeOut} invalid={!isValidTimeOut} onChange={handleChangeTimeOut} invalidText="Hora Inválida" />
            </Div>
          </Div>
        </Div>

        <Div display="flex" margin="1rem 0 1rem 0" gap="8px" alignItems="center">
          <Information size={24} />
          <p>La cantidad de datos y el rango de fechas puede hacer mas extenso el tiempo para generar los datos.</p>
        </Div>
        {downloadUrl && !sending && (
          <>

            <Link href={downloadUrl}>
              <Button>
                Descargar
              </Button>
            </Link>
            <p>Descarga habilitada solo para esta ventana, si la cierra perderá el link de descarga </p>
          </>
        )}
        {submitError ? 'Error al tratar de enviar, favor pongase en contacto' : ''}
      </Stack>
    </Modal >
  )
}

export default DatabaseForm
