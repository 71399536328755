import React from 'react'
import Navbar from '../containers/Navbar/Navbar.container'
import Dashboard from '../containers/Dashboard/Dashboard.container'
import ManagementMenu from '../containers/ManagementMenu/ManagementMenu.container'
import routes from '../config/settings/routes'
import ProtectBySession from '../components/Security/ProtectBySession'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import { Book } from '@carbon/icons-react'
import { Breadcrumb, BreadcrumbItem } from '@carbon/react'
import { Route, Switch, useParams } from 'react-router'
import Typography from '../components/Basics/Typography'
import CreateGeofence from '../containers/Documentation/CreateGeofence'
import CollectTeamData from '../containers/Documentation/CollectTeamData'
import UpdateLicense from '../containers/Documentation/UpdateLicense'
import EnterTruck from '../containers/Documentation/EnterTruck'
import CompleteForm from '../containers/Documentation/CompleteForm'

/**
 * The Documentation page.
 */
const DocumentationPage = () => {
    const title = "Documentación y Recursos";

    const DocumentationBreadcrumbs = () => {
        return (
            <Breadcrumb noTrailingSlash>
                <BreadcrumbItem>
                    <a href="/">UCO</a>
                </BreadcrumbItem>
                <BreadcrumbItem href="/">Inicio</BreadcrumbItem>
                <BreadcrumbItem
                    href="#"
                    isCurrentPage
                >
                    {title}
                </BreadcrumbItem>
            </Breadcrumb>
        )
    }

    return (
        <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
            <BrandLayout
                navbar={<Navbar managementMenu={<ManagementMenu current={routes.dashboard} />} />}
                header={<Heading title={title} icon={<Book size={50} />} breadcrumbs={<DocumentationBreadcrumbs />} />}
                childrenStyles={{ paddingLeft: 0 }}
            >
                <Dashboard>
                    <Switch>
                        <Route exact path={routes.documentationCreateGeofence}>
                            <CreateGeofence />
                        </Route>
                        <Route exact path={routes.documentationCollectTeamData}>
                            <CollectTeamData />
                        </Route>
                        <Route exact path={routes.documentationUpdateLicense}>
                            <UpdateLicense />
                        </Route>
                        <Route exact path={routes.documentationEnterTruck}>
                            <EnterTruck />
                        </Route>
                        <Route exact path={routes.documentationCompleteForm}>
                            <CompleteForm />
                        </Route>
                    </Switch>
                </Dashboard>
            </BrandLayout>
        </ProtectBySession>
    )
}

export default DocumentationPage
