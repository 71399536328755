import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_ERROR,
  GET_NOTIFICATION_SUCCESS,
} from './Notifications.actions';

const notificationState = {
  loading: true,
  error: undefined,
  all: [],
  velocity: [],
  flat: [],
  adas: [],
  fatigue: [],
  yawn: [],
};

/**
 * The Notification reducer
 */

const notificationReducer = (state = notificationState, { payload, type }) => {
  switch (type) {
    case GET_NOTIFICATION: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case GET_NOTIFICATION_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        all: payload.all,
        velocity: payload.velocity,
        flat: payload.flat,
        adas: payload.adas,
        fatigue: payload.fatigue,
        yawn: payload.yawn,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default notificationReducer;
