import { makeActionCreator } from '../../../config/store/utils';
import { batch } from 'react-redux';
import {
  getDrivers,
  updateDrivers,
  uploadFileToS3,
  fetchImageFromS3,
} from '../../../services/recordService';
import { CheckmarkFilled } from '@carbon/icons-react';
import { getAdasfatiga, getPerdidasDrivers, getFrenosDrivers } from '../../../services/recordService';

// Function to convert date string from D-M-YYYY to a Date object
const parseDate = (dateString) => {
  if (!dateString || dateString === 'Sin Registro') return null;
  
  const parts = dateString.split('-');
  if (parts.length !== 3) {
    // Maneja el caso en el que el formato de la fecha no sea el esperado
    return null;
  }
  
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const GET_DRIVER = 'GET_DRIVER';
export const GET_DRIVER_ERROR = 'GET_DRIVER_ERROR';
export const GET_DRIVER_SUCCESS = 'GET_DRIVER_SUCCESS';
export const onGetDriver = makeActionCreator(GET_DRIVER);
export const onGetDriverError = makeActionCreator(GET_DRIVER_ERROR, 'payload');
export const onGetDriverSuccess = makeActionCreator(
  GET_DRIVER_SUCCESS,
  'payload'
);

export const onGetDriverThunk = () => async dispatch => {
  dispatch(onGetDriver());
  try {
    const { data } = await getDrivers();
    const driver = data
      .reduce((acc, current, index) => {
        const driverExpiryDate = current.expiry && current.expiry !== 'Sin Registro'
          ? `${current.expiry}T00:00:00`
          : null;

        const formattedDate = driverExpiryDate ? new Date(driverExpiryDate) : null;

        const currentDate = new Date();
        const good = (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <CheckmarkFilled color="#90EE90" />
            Vigente
          </div>
        );
        const bad = (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <CheckmarkFilled color="red" />
            Expirado
          </div>
        );
        let isExpired;
        if (current.expiry === 'Sin Registro') {
          isExpired = current.expiry;
        } else {
          isExpired = currentDate <= formattedDate ? good : bad;
        }

        const name = current.name
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        const surname = current.surname
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        let newObject = {
          ...current,
          id: `${index + 1}`,
          fullRut: `${current.rut}-${current.dv}`,
          rut: `${current.rut}`,
          fullName: `${name} ${surname}`,
          isExpired: isExpired,
          driverId: current.driverId,
        };
        acc.push(newObject);
        return acc;
      }, [])
      .sort((a, b) => {
        const dateA = parseDate(a.expiry);
        const dateB = parseDate(b.expiry);

        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return 1;
        if (dateB === null) return -1;

        return dateA - dateB;
      });

    return batch(() => {
      dispatch(
        onGetDriverSuccess({
          data: driver,
        })
      );
    });
  } catch (error) {
    console.log(error);
    return batch(() => {
      dispatch(onGetDriverError({ error }));
    });
  }
};

export const UPDATE_DRIVER_LICENSE = 'UPDATE_DRIVER_LICENSE';
export const UPDATE_DRIVER_LICENSE_ERROR = 'UPDATE_DRIVER_LICENSE_ERROR';
export const UPDATE_DRIVER_LICENSE_SUCCESS = 'UPDATE_DRIVER_LICENSE_SUCCESS';
export const CLEAR_DRIVER_LICENSE = 'CLEAR_DRIVER_LICENSE';
export const onUpdateDriverLicense = makeActionCreator(UPDATE_DRIVER_LICENSE);
export const onUpdateDriverLicenseError = makeActionCreator(
  UPDATE_DRIVER_LICENSE_ERROR,
  'payload'
);
export const onUpdateDriverLicenseSuccess = makeActionCreator(
  UPDATE_DRIVER_LICENSE_SUCCESS,
  'payload'
);
export const onClearDriverLicense = makeActionCreator(CLEAR_DRIVER_LICENSE);

export const onUpdateDriverLicenseThunk = params => async dispatch => {
  dispatch(onUpdateDriverLicense());

  try {
    let formData = {
      driverId: params.driverId,
    };
    if (params.imageBase64) {
      const uploadResult = await uploadFileToS3(
        params.imageBase64,
        params.driverId
      );
      if (uploadResult.status === 'Failed') {
        return dispatch(
          onUpdateDriverLicenseError({ error: 'Error uploading File' })
        );
      }
      formData['image'] = uploadResult.key;
    }
    if (params.updateValue) {
      formData['updateValue'] = params.updateValue.toISOString();
    }
    const { data: response } = await updateDrivers({
      formData,
    });
    return dispatch(onUpdateDriverLicenseSuccess({ response: response }));
  } catch (error) {
    return dispatch(onUpdateDriverLicenseError({ error }));
  }
};

export const clearDriverLicense = params => async dispatch => {
  return dispatch(onClearDriverLicense());
};

export const GET_DRIVER_LICENSE = 'GET_DRIVER_LICENSE';
export const GET_DRIVER_LICENSE_ERROR = 'GET_DRIVER_LICENSE_ERROR';
export const GET_DRIVER_LICENSE_SUCCESS = 'GET_DRIVER_LICENSE_SUCCESS';
export const onGetDriverLicense = makeActionCreator(GET_DRIVER_LICENSE);
export const onGetDriverLicenseError = makeActionCreator(
  GET_DRIVER_LICENSE_ERROR,
  'payload'
);
export const onGetDriverLicenseSuccess = makeActionCreator(
  GET_DRIVER_LICENSE_SUCCESS,
  'payload'
);
export const onGetDriverLicenseThunk = driverId => async dispatch => {
  dispatch(onGetDriverLicense());

  try {
    const url = await fetchImageFromS3(driverId);
    return dispatch(onGetDriverLicenseSuccess({ url }));
  } catch (error) {
    return dispatch(onGetDriverLicenseError({ error }));
  }
};

export const GET_ADAS = 'GET_ADAS';
export const GET_ADAS_ERROR = 'GET_ADAS_ERROR';
export const GET_ADAS_SUCCESS = 'GET_ADAS_SUCCESS';
export const onGetAdas = makeActionCreator(GET_ADAS);
export const onGetAdasError = makeActionCreator(GET_ADAS_ERROR, 'payload');
export const onGetAdasSuccess = makeActionCreator(
  GET_ADAS_SUCCESS,
  'payload'
);

// Modificar el `onGetAsarcoThunk` para recibir el parámetro `shift`.
export const onGetAdasThunk = (date, turno, rut) => async dispatch => {
  dispatch(onGetAdas());
  try {
    let endpointParams = {};
    if (date && turno) {
      endpointParams = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        turno: turno,
      };
    } else {
      const today = new Date();
      const yesterday = new Date(today);
      date = new Date(yesterday.setDate(yesterday.getDate() - 2));
    }
    
    if (rut) {
      endpointParams['rut'] = rut;
    }

    // Llamar a la API y obtener los datos
    const { data } = await getAdasfatiga(endpointParams);

    let adas = JSON.parse(data);

    adas = adas.map((adas, index) => ({
      ...adas,
      id: `${index + 1}`,
    }));

    return dispatch(
      onGetAdasSuccess({
        adas: adas, // `asarco` ahora contiene solo la data del gráfico
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetAdasError({
        error: error,
      })
    );
  }
};

export const GET_PERDIDAS = 'GET_PERDIDAS';
export const GET_PERDIDAS_ERROR = 'GET_PERDIDAS_ERROR';
export const GET_PERDIDAS_SUCCESS = 'GET_PERDIDAS_SUCCESS';
export const onGetPerdidas = makeActionCreator(GET_PERDIDAS);
export const onGetPerdidasError = makeActionCreator(GET_PERDIDAS_ERROR, 'payload');
export const onGetPerdidasSuccess = makeActionCreator(
  GET_PERDIDAS_SUCCESS,
  'payload'
);

// Modificar el `onGetAsarcoThunk` para recibir el parámetro `shift`.
export const onGetPerdidasThunk = (date, turno, rut) => async dispatch => {
  dispatch(onGetPerdidas());
  try {
    let endpointParams = {};
    if (date && turno) {
      endpointParams = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        turno: turno,
      };
    } else {
      endpointParams = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    }
    
    if (rut) {
      endpointParams['rut'] = rut;
    }

    // Llamar a la API y obtener los datos
    const { data } = await getPerdidasDrivers(endpointParams);

    // Verifica la estructura de los datos recibidos
    const { datos_del_dia, datos_de_la_semana, datos_de_10_dias } = data;

    // Asegúrate de que `datos_del_dia` y `datos_de_la_semana` son arrays
    const perdidas = Array.isArray(datos_del_dia)
      ? datos_del_dia.map((perdidas, index) => ({
          ...perdidas,
          id: `${index + 1}`,
        }))
      : [];

    const perdidasSemana = Array.isArray(datos_de_la_semana)
      ? datos_de_la_semana.map((perdida, index) => ({
          ...perdida,
          id: `${index + 1}`,
        }))
      : [];
    
    // Asegúrate de que `datos_de_10_dias` es un array o conviértelo en uno
    let datosDe10DiasArray = JSON.parse(datos_de_10_dias);

    const perdidas10 = datosDe10DiasArray.map((conductorData, index) => ({
      ...conductorData,
      id: `${index + 1}`,
    }));
  
    return dispatch(
      onGetPerdidasSuccess({
        perdidas: perdidas, // `asarco` ahora contiene solo la data del gráfico
        perdidas_semana: perdidasSemana,
        perdidas_ranking: perdidas10,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetPerdidasError({
        error: error,
      })
    );
  }
};

export const GET_FRENOS = 'GET_FRENOS';
export const GET_FRENOS_ERROR = 'GET_FRENOS_ERROR';
export const GET_FRENOS_SUCCESS = 'GET_FRENOS_SUCCESS';
export const onGetFrenos = makeActionCreator(GET_FRENOS);
export const onGetFrenosError = makeActionCreator(GET_FRENOS_ERROR, 'payload');
export const onGetFrenosSuccess = makeActionCreator(
  GET_FRENOS_SUCCESS,
  'payload'
);

// Modificar el `onGetAsarcoThunk` para recibir el parámetro `shift`.
export const onGetFrenosThunk = (date, turno, rut) => async dispatch => {
  dispatch(onGetFrenos());
  try {
    let endpointParams = {};
    if (date && turno) {
      endpointParams = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        turno: turno,
      };
    } else {
      const today = new Date();
      const yesterday = new Date(today);
      date = new Date(yesterday.setDate(yesterday.getDate() - 2));
    }
    
    if (rut) {
      endpointParams['rut'] = rut;
    }

    // Llamar a la API y obtener los datos
    const { data } = await getFrenosDrivers(endpointParams);

    // Verifica la estructura de los datos recibidos
    const { datos_del_dia, datos_de_la_semana } = data;

    const frenos = datos_del_dia.map((frenos, index) => ({
      ...frenos,
      id: `${index + 1}`,
    }));

    const frenosSemana = datos_de_la_semana.map((dia, index) => ({
      id: `${index + 1}`,
      fecha: dia.fecha,
      datos: dia.datos.map((frenos) => ({
        ...frenos,
      }))
    }));

    return dispatch(
      onGetFrenosSuccess({
        frenos: frenos, // `asarco` ahora contiene solo la data del gráfico
        frenos_semana: frenosSemana,
      })
    );
  } catch (error) {
    console.log(error);
    return dispatch(
      onGetFrenosError({
        error: error,
      })
    );
  }
};