import React, { useState, useEffect, useRef } from 'react'
import {
  REACT_APP_WEBSOCKET
} from '../../config/settings/environments'
import { NotificationPopup, NotificationItem, AnimatedNotification } from './Notifications.styles'
import routes from '../../config/settings/routes'
import { convertUTCToLocalTime } from '../../modules/utils/formatters'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { onGetNotificationsNumber, onSetNotificationsThunk, onRemoveNotificationThunk, onHideNotificationThunk, onHideNotification } from '../../containers/Navbar/Navbar.actions'

const TIMEOUT_MS = 2_500

export const NotificationStatus = {
  SHOW: 'SHOW',
  HIDDEN: 'HIDDEN',
  REMOVED: 'REMOVED'
}

const Notification = () => {
  const webSocket = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const { notifications: notific } = useSelector(state => state.navbar)
  const history = useHistory();
  const dispatch = useDispatch()
  useEffect(() => {
    // Function to connect to WebSocket
    const connectWebSocket = () => {
      webSocket.current = new WebSocket(REACT_APP_WEBSOCKET); // Replace with your WebSocket server URL

      webSocket.current.onopen = () => {
        console.log('WebSocket connected');
      };

      webSocket.current.onmessage = (event) => {
        console.log('Received message:', event.data)
        const message = JSON.parse(event.data)
        if (message === 'Connected') {
          console.log("Received initial message succesfully")
          return 
        }
        try {
          const data = message[0]
          const date = convertUTCToLocalTime(data.timestamp)
          let title, subtitle, kind, id, route
          // TODO detect notifications by type and iron out this part of the module
          if ('quantity_of_trucks' in data) {
            const trucks = data.quantity_of_trucks
            title = 'Transgresión de Velocidad'
            subtitle = `Número de Camiones: ${trucks}`
            kind = 'warning'
            id = data.id
            route = routes.securityVelocityViolation
          } else if ('quantity_of_drivers' in data) {
            const drivers = data.quantity_of_drivers
            title = 'Licencias Vencidas';
            subtitle = `Número de Conductores: ${drivers}`
            kind = 'error'
            id = data.unique_id
            route = routes.recordsDriver
          } else if (data.id.includes('punctures')) {
            const truck = data.patente
            title = `Alerta de pinchazo: ${truck}`
            subtitle = `a las ${convertUTCToLocalTime(data.timestamp)}`
            kind = 'error'
            id = data.unique_id
            route = routes.monitoringPunctures  
          } else if (data.id.includes('adas')) {
            const trucksQty = data.quantity
            title = `Alerta de adas: ${trucksQty}`
            subtitle = `a las ${data.timestamp}`
            kind = 'error'
            id = data.unique_id
            route = routes.securityTruckApproach  
          }  else if (data.id.includes('collision')) {
            const truck = data.patente
            title = `Alerta de Colisión: ${truck}`
            subtitle = `a las ${convertUTCToLocalTime(data.timestamp)}`
            kind = 'error'
            id = data.unique_id
            route = routes.securityTruckApproach  
          } else if (data.id.includes('lunch-time-transgretion')) {
            const trucks = data.quantity
            title = 'Transgresión de Colación'
            subtitle = `Número de Conductores: ${trucks}`
            kind = 'warning'
            id = data.unique_id
            route = routes.productivityBreakfast
          } else if (data.id.includes('fatigue')) {
            const trucksQty = data.quantity
            title = `Alerta de fatiga: ${trucksQty}`
            subtitle = `a las ${data.timestamp}`
            kind = 'error'
            id = data.unique_id
            route = routes.securityFatigue
          } else if (data.id.includes('yawn')) {
            const trucksQty = data.patente
            title = `Advertencia de bostezo: ${trucksQty}`
            subtitle = `a las ${data.timestamp}`
            kind = 'error'
            id = data.unique_id
            route = routes.securityFatigue
          } else {
            // Handle unexpected data format
            console.error('Unexpected data format:', data)
            return;
          }
          const currNotification = {
            id: id, // Unique identifier for the notification
            title: title,
            subtitle: subtitle,
            caption: date,
            status: NotificationStatus.SHOW,
            kind: kind,
            route: route
          }
          dispatch(onSetNotificationsThunk(currNotification))
        } catch (error) {
          console.warn("Message could not be parsed: ", error)
        }
      };

      webSocket.current.onclose = () => {
        console.log('WebSocket disconnected');
        // Automatically reconnect after 1 seconds
        setTimeout(connectWebSocket, 1_000);
      };

      return webSocket.current
    };

    // Connect to WebSocket when component mounts
    const ws = connectWebSocket();

    // Clean up function to close WebSocket when component unmounts
    return () => ws.close();
  }, []); // Only run this effect once when component mounts

  const removeNotification = (notification) => {
    return dispatch(onRemoveNotificationThunk(notification))
  }

  const hideNotification = (notification) => {
    setTimeout(() => {
      return dispatch(onHideNotificationThunk(notification))
    }, TIMEOUT_MS);
  }

  useEffect(() => {
    // For every notification with SHOW status trigger a function that hides them
    notific.filter(notification =>
      notification.status === NotificationStatus.SHOW
    ).forEach(notification =>
      hideNotification(notification)
    );

    // Update number of notifications counter
    const shownNotifications = notific.filter(notification => notification.status !== NotificationStatus.REMOVED)
    dispatch(onGetNotificationsNumber({ numberOfNotifications: shownNotifications.length }))
  }, [notific, dispatch])

  return (
    <NotificationPopup>
      {notific.filter(notification => notification.status === NotificationStatus.SHOW).map(notification => (
        <NotificationItem key={notification.id}>
          <AnimatedNotification
            role="status"
            actionButtonLabel="Más información"
            onActionButtonClick={() => history.push(notification.route)}
            title={notification.title}
            subtitle={<span>{notification.subtitle}<br />{notification.caption}</span>}
            onClose={() => removeNotification(notification)}
            kind={notification.kind}
          />
        </NotificationItem>
      ))
      }
    </NotificationPopup >
  )
}

export default Notification