import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { REACT_APP_BASE_URL } from '../../config/settings/environments'
import routes from '../../config/settings/routes'
import { getFromCookie } from '../../modules/storages/cookies'
import { checkErrorRequired, checkErrorPassword } from '../../modules/errors/validations'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { onLogInThunk, onLogOutThunk } from './Authentication.actions'
import {
  Content, Button, Stack, Form, TextInput, Grid, Row, Column, ButtonSkeleton, Checkbox
} from '@carbon/react';
import { Logo, Root, LogoContainer, BorderRoot } from './Login.styles';
import { ArrowRight } from '@carbon/icons-react';
import Typography from '../../components/Basics/Typography';
import ChangePasswordModal from './ChangePasswordModal.container';
import ForgotPasswordModal from './ResetPassword.container';
import { g100 } from "@carbon/themes";
import EmailLink from '../../components/Basics/EmailLink.container';

/**
 * The Login's container.
 */
const Login = () => {
  const button = useRef();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [openReset, setOpenReset] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const { error, loading } = useSelector(state => state.auth)
  const redirectPath = getFromCookie('redirect_path') || routes.root

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: passwordError, value: passwordValue, onChange: handlePasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const handleLogIn = event => {
    event.preventDefault()
    dispatch(
      onLogInThunk({
        username: usernameValue,
        password: passwordValue,
        onSuccessCallback: () => window.open(`${REACT_APP_BASE_URL}${redirectPath}`, '_top')
      })
    )
  }

  const canNotSubmit = useCallback(() => {
    return passwordError !== '' || usernameError !== ''
  }, [passwordError, usernameError])
  const disabledSubmit = canNotSubmit()
  return (
    <>
      <Root>
        <BorderRoot>
          <Form className='login-form' onSubmit={handleLogIn}>
            <Stack className="center" gap={6}>
              <LogoContainer>
                <Logo className="color" src="/assets/images/codelco.svg" alt="Codelco" width="110px" height="66px" />
                <Logo src="/assets/images/acciona.svg" alt="Acciona" width="104.84px" height="45px" />
                <Typography className="cds--type-body-01" justify='center' display='flex'>
                  Unidad de Control Operativo
                </Typography>

                <TextInput className='text-input' labelText='' placeholder='Correo' id='email' onChange={handleUsernameChange} value={usernameValue} invalid={Boolean(usernameError)} invalidText={usernameError} />
                <TextInput.PasswordInput className='text-input' type='password' placeholder='Contraseña' labelText='Ingresar Contraseña' id='login-password' onChange={handlePasswordChange} value={passwordValue} invalid={Boolean(passwordError)} invalidText={passwordError} />

                {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
                  "Su email o contraseña son incorrectas. Si no recuerdas tu contraseña, contáctanos."
                )}

                {!loading && error && error.reason === 'POR FAVOR CREE UNA NUEVA CONTRASEÑA' && (
                  <>
                    <p>Por favor crear una nueva contraseña</p>
                    <Button size='sm' onClick={() => setOpen(true)} renderIcon={ArrowRight}>
                      Crear nueva contraseña
                    </Button>
                  </>

                )}

                {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
                  "Lo sentimos, estamos presentando problemas con nuestro servidor"
                )}
                {loading && (
                  <ButtonSkeleton className='login-button' disabled={disabledSubmit} />
                )}
                {!loading && (
                  <Button disabled={disabledSubmit} type="submit" className='login-button' renderIcon={ArrowRight}>
                    INGRESAR
                  </Button>
                )}
                <Checkbox id="remember" labelText="Recordarme" checked={rememberMe} onChange={(_, { checked }) => setRememberMe(checked)} />
                <Typography className="cds--type-helper-text-01" justify='center' display='inline' color={g100.textSecondary}>
                  ¿Tienes algún problema? Contáctanos en {" "}
                  <EmailLink href="mailto:talabre@terrestra.tech" color="inherit" textDecoration="underline">
                    talabre@terrestra.tech
                  </EmailLink>
                </Typography>
              </LogoContainer>
            </Stack>
          </Form>
        </BorderRoot>
        <Logo className="bottom-right" src="/assets/images/terrestra.svg" alt="Terrestra" width="105.26px" height="16px" />
      </Root>
      <ChangePasswordModal button={button} open={open} setOpen={setOpen} />
      <ForgotPasswordModal button={button} open={openReset} setOpen={setOpenReset} />
    </>
  )
}

export default Login
